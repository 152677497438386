import styled from 'styled-components';

const theme = {'green': '#A2B533',
                'black': '#000000',
                'darkdark': '#040508',
                'dark':  '#08090F',
                'light': '#1F263B',
                'lightlight': '#2B344B',
                'white': '#FFFFFF',
                'buttonblue':  "#1565C0",
                'blue':  "#001E3C",
                'pink':  '#9C2780',
                'disabledText' : '#AAAAAA',
                'fontSizeSmall': "0.8rem",
                'fontSizeMedium': '1.0rem',
                'fontSizeLarge': '1.4rem',
                'padding0': '0px', 
                'padding1': '10px',
                'padding2': '20px', 
                'gap0': '0px',
                'gap1': '10px',
                'gap2': '20px',
                'margin0': '0px',
                'margin1': '10px',
                'margin2': '20px',
                'borderRadius': '12px',
                'borderRadiusSmall': '7px',
                'buttonThickness1': '90px',
                'buttonThickness2': '60px', 
                'buttonStyle': {'borderRadius':'20px', 'fontWeight':'bold', 'fontSize': '0.8em'},
                'buttonSX': {'p':1, 'm':0, 'height': '60px', 'width': '100%'}
            }
                
                // buttonSX={{p:1, m:0, height: theme.buttonThickness2, width: '100%'}}
                // buttonStyle=}

const HorzDiv = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    ${'' /* height: ${props => props.height ? props.height : "auto"}; */} //messes things up for some reason. Use style={{height: '100%'}}
    max-width: ${props => props.maxWidth ? props.maxWidth : "none"};
    width: ${props => props.width ? props.width : "none"};
    max-height: ${props => props.maxHeight ? props.maxHeight : "100%"};
    height: ${props => props.height ? props.height : "none"};

    padding: ${props => props.padding ? props.padding : theme.padding1};
    gap: ${props => props.gap ? props.gap : theme.gap1};
    margin: ${props => props.margin ? props.margin : theme.margin0};
    color: ${props => props.color ? props.color : "#fff"};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "none"};
    background-image: ${props => props.backgroundImage ? props.backgroundImage : "none"};
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    align-content: ${props => props.alignContent ? props.alignContent : "normal"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : "wrap"};
    flex-grow: ${props => props.flexGrow ? props.flexGrow : "0"};
    border-style: ${props => props.borderStyle ? props.borderStyle : "solid"};
    border-color: ${props => props.borderColor ? props.borderColor : theme.green};
    border-width: ${props => props.borderWidth ? props.borderWidth : "1px"};
    border-radius: ${props => props.borderRadius ? props.borderRadius : theme.borderRadius};
    zIndex: ${props => props.zIndex ? props.zIndex : "0"};
`;

const VertDiv = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    ${'' /* height: ${props => props.height ? props.height : "auto"}; */} //messes things up for some reason. Use style={{height: '100%'}}
    max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
    width: ${props => props.width ? props.width : "none"};
    max-height: ${props => props.maxHeight ? props.maxHeight : "100%"};
    height: ${props => props.height ? props.height : "none"};
    padding: ${props => props.padding ? props.padding : "10px"};
    gap: ${props => props.gap ? props.gap : theme.gap1};
    margin: ${props => props.margin ? props.margin : theme.margin0};
    color: ${props => props.color ? props.color : "#fff"};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "none"};
    background-image: ${props => props.backgroundImage ? props.backgroundImage : "none"};
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    align-content: ${props => props.alignContent ? props.alignContent : "normal"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : "wrap"};
    flex-grow: ${props => props.flexGrow ? props.flexGrow : "0"};
    border-style: ${props => props.borderStyle ? props.borderStyle : "solid"};
    border-color: ${props => props.borderColor ? props.borderColor : "#A2B533"};
    border-width: ${props => props.borderWidth ? props.borderWidth : "1px"};
    border-radius: ${props => props.borderRadius ? props.borderRadius : theme.borderRadius};
    zIndex: ${props => props.zIndex ? props.zIndex : "0"};
`;

const LabelInputs = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    gap:5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    padding: 5px;
    text-align: right;
    color: #FFFFFF;
`;
const VerticalLabelInputs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 5px;
    border: 1px solid #A2B533;
    border-radius: 5px;

`;
const PlotInputs = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    ${'' /* margin-top: 5px;
    margin-bottom: 5px; */}
    gap:10px;
    ${'' /* border: 1px solid #A2B533;
    border-radius: 10px; */}
`;
const DataInputs = styled.div`
    ${'' /* background-color: #f5f5f5; */}
    ${'' /* background-color: #06406F; */}
    ${'' /* background-color: #001E3C; */}
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 250px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    gap: 5px;
    padding: 5px;
`;
const StrategyInputs = styled.div`
    ${'' /* background-color: #f5f5f5; */}
    ${'' /* background-color: #06406F; */}
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    gap: 5px;
    padding: 5px;
`;
const DepositInputs = styled.div`
    ${'' /* background-color: #f5f5f5; */}
    background-color: #06406F;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    gap: 5px;
    padding: 5px;
`;
const PlotWindow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    width: 100%;
    ${'' /* height: 98%; */}
    padding: 10px;
    gap: 5px;
`;
const PlotControls = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    color: white;
`;
const PlotBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    ${'' /* margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #A2B533;
    border-radius: 5px; */}
`;

export {theme, PlotBody, PlotInputs, DataInputs, LabelInputs, StrategyInputs, VerticalLabelInputs, 
        DepositInputs, PlotWindow, PlotControls, HorzDiv, VertDiv};