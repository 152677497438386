import "./styles.css";
import { Routes, Route, useLocation } from 'react-router-dom';
import { useState, createContext, useEffect } from "react";
import Home from "./pages/index.js";
import Backtest from "./components/Backtest";
// import AutotradePage from "./pages/autotradePage.js";

// import VaultsOverviewPage from "./pages/vaultsOverviewPage.js";
import VaultsOverviewPageV2 from "./pages/vaultsOverviewPageV2.js";
import StrategyDemo from "./pages/strategyDemo";

// import ExistingVaultPage from "./pages/existingVaultPage";
import ExistingVaultPageV2 from "./pages/existingVaultPageV2";
import Docs from "./pages/Docs";
import Header from "./components/Header";
// import ConnectWalletButton from './components/Header/ConnectWalletButton';
import Footer from "./components/Footer";
// import {Test} from "./pages/test";
import {VertDiv} from "./styles";

import styled from 'styled-components';
import { ethers } from "ethers";

import { getWindowDimensions, getBaseURL } from "./functions/functions";
import OrbitalSceneSimple from "./components/OrbitalSceneSimple";
import OrbitalSceneWithControls from "./components/OrbitalSceneWithControls";

import globalParams from "./globalParams.json";

import devContractAddressesIn from './components/Interfaces/VaultV2/addresses/dev/addresses.json';
import releaseContractAddressesIn from './components/Interfaces/VaultV2/addresses/release/addresses.json';


export const UserContext = createContext({windowDimensions: {}, setWindowDimensions: () => {},
                                          baseURL: '', setBaseURL: () => {},
                                          networkDict: {}, setNetworkDict: () => {}, 
                                          defaultNetworkDict: {}, setDefaultNetworkDict: () => {},
                                          txPending: false, setTxPending: () => {},
                                          txSuccess: false, setTxSuccess: () => {}, 
                                          errorDialogOpen: false, setErrorDialogOpen: () => {},
                                          errorMessage: '', setErrorMessage: () => {},
                                          lastTxHash: null, setLastTxHash: () => {}, 
                                          walletUpdated: false, setWalletUpdated: () => {},
                                          devMode: false, 
                                          selectedAddress: null, setSelectedAddress: () => {},
                                          contractAddresses: {}, setContractAddresses: () => {},
                                          devContractAddresses: {}, releaseContractAddresses: {}, 
                                          walletAddress: {}, setWalletAddress: () => {}}
                                          );

const MidDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100vh - 160px);
`;

const AppHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
`;

export default function App() {
    // const currentPath = window.location.pathname;
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [launchButtonText, setLaunchButtonText] = useState(currentPath === '/' ? 'Launch App' : 'Vaults List');
    // const launchButtonText = currentPath === '/' ? 'Launch App' : 'Vaults List';
    // const showLaunchButton = currentPath === '/autotrade' ? false : true;
    const [showLaunchButton, setShowLaunchButton] = useState(currentPath === '/autotrade' ? false : true);
    const devBool = globalParams.devMode;
    // console.log('IN APP: devBool:', devBool);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // const [buttonTextSize, setButtonTextSize] = useState('small');
    const [baseURL, setBaseURL] = useState(getBaseURL());
    const [networkDict, setNetworkDict] = useState(null);
    const [defaultNetworkDict, setDefaultNetworkDict] = useState(null);
    const [currentRoute, setCurrentRoute] = useState('/');
    const [txPending, setTxPending] = useState(false);
    const [lastTxHash, setLastTxHash] = useState(null);
    const [txSuccess, setTxSuccess] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [walletUpdated, setWalletUpdated] = useState(false);
    const [devMode, setDevMode] = useState(devBool);
    // const [selectedAddress, setSelectedAddress] = useState(null);
    const [contractAddresses, setContractAddresses] = useState(null);
    const [devContractAddresses, setDevContractAddresses] = useState(devContractAddressesIn);
    const [releaseContractAddresses, setReleaseContractAddresses] = useState(releaseContractAddressesIn);
    const [walletAddress, setWalletAddress] = useState(null);

    useEffect(() => {
        const cp = location.pathname;
        setCurrentPath(cp);

        const lbt = cp === '/' ? 'Launch App' : 'Vaults List';
        setLaunchButtonText(lbt);

        const slb = cp === '/autotrade' ? false : true;
        setShowLaunchButton(slb);

        // setCurrentRoute(location.pathname);
        // console.log('in App UseEffect: location:', location);
    }, [location, launchButtonText, showLaunchButton]);

    useEffect(() => {
        function handleResize() {
            const wd = getWindowDimensions();
            // console.log('APP window resized', wd);
            setWindowDimensions(wd);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    useEffect(() => {
        // console.log('in App UseEffect: networkInf:', networkDict);
        async function getDefaultNetworkDict() {
            const providerNew = new ethers.providers.JsonRpcProvider({'url':'https://arb1.arbitrum.io/rpc'}) //CORS Erros here big time
            const network = await providerNew.getNetwork();
            const networkInf = {'provider': providerNew,
                                'signer': null, //providerNew.getSigner(),
                                'accounts': [], 
                                'address': null,
                                'network': network,
                                'scanner': 'https://arbiscan.io',
                                'chainID': network.chainId};
            // const networkInf = {};
            return networkInf;        
        }
        async function init() {
            const networkInf = await getDefaultNetworkDict();  //Sometimes throws "CORS" error
            // const networkInf = {};
            setDefaultNetworkDict(networkInf);
            // setNetworkDict(networkDict);

            if (devMode) {
                if (devContractAddresses[networkInf.chainID.toString()] !== undefined) {
                    setContractAddresses(devContractAddresses[networkInf.chainID.toString()]);
                }
            } else {
                if (releaseContractAddresses[networkInf.chainID.toString()] !== undefined) {
                    setContractAddresses(releaseContractAddresses[networkInf.chainID.toString()]);
                }
            }
        }
        init();
    }, [])

    // console.log('in App:', defaultNetworkDict, networkDict)
    // console.log('in App:', currentPath);
    return (
        <UserContext.Provider value={{windowDimensions, setWindowDimensions,
                                      baseURL, setBaseURL,
                                      networkDict, setNetworkDict, 
                                      currentRoute, setCurrentRoute, 
                                      defaultNetworkDict, setDefaultNetworkDict,
                                      txPending, setTxPending, 
                                      lastTxHash, setLastTxHash, 
                                      txSuccess, setTxSuccess, 
                                      errorDialogOpen, setErrorDialogOpen,
                                      errorMessage, setErrorMessage,
                                      walletUpdated, setWalletUpdated,
                                      devMode, 
                                      contractAddresses, setContractAddresses, 
                                      devContractAddresses, releaseContractAddresses, 
                                      walletAddress, setWalletAddress}}
        >
            <div className="App">
                <VertDiv 
                    borderStyle='none' 
                    alignItems='center' 
                    justifyContent='space-between' 
                    alignContent='stretch' 
                    padding='0px' 
                    margin='0px' 
                    gap='0px' 
                    height='100%'
                >   
                    <AppHeader>
                        <Header 
                            showConnectButton={true} 
                            showAutotrade={showLaunchButton}
                            launchText={launchButtonText}     
                        />
                    </AppHeader>
                    <MidDiv >
                        <Routes>
                            <Route path="/" element={<Home />} />
                            {/* <Route path="/backtest" element={<Backtest />} /> */}
                            <Route path="/strategydemo" element={<StrategyDemo />} />
                            {/* <Route path="/autotrade" element={<VaultsOverviewPage />} /> */}
                            <Route path="/autotrade" element={<VaultsOverviewPageV2 />} />
                            <Route path="/existingvault" element={<ExistingVaultPageV2 />} />
                            {/* <Route path="/existingvaultV2" element={<ExistingVaultPageV2 />} /> */}
                            <Route path='/hyd' element={<OrbitalSceneWithControls />} />
                            <Route path="/docs" element={<Docs />} />
                            <Route path="*" element={<div>404 Not found</div>} /> 
                        </Routes> 
                    </MidDiv>
                    <Footer />
                </VertDiv>
            </div>
        </UserContext.Provider>
    );
}
