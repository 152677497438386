import { useState, useEffect, useContext} from 'react';
import { UserContext } from '../App'
import { ethers, BigNumber } from "ethers";

import { Button, Slider, Typography, Divider, Link } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {Box as MuiBox} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// import Table from 'react-bootstrap/Table';
// import { DataGrid, GridRowsProp, GridColDef  } from '@mui/x-data-grid';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
// import TradingViewWidget, { Themes, IntervalTypes } from 'react-tradingview-widget';
// import { AdvancedChart } from "react-tradingview-embed";
import Plot from 'react-plotly.js';
import axios from 'axios';
import { Label } from 'semantic-ui-react';

import { abbreviateAddress, bigNumToHuman, bigNumToFloat, humanToBigNum, 
         BN, bigNumDivToFloat,  getBaseURL, isDefined,  getPrefetch, 
         timeIntervalSeconds, roundToDigits, inList, isUndefined} from '../functions/functions';

import { Wallet, TokenLibrary} from '../classes/classes.js'

import { VaultFactoryV2, VaultManagerV2, VaultInfo, VaultV2, Trade, 
        Quoter, ChainlinkInterface, VaultAggregated, PriceFetcher} from '../classes/classes_02.js'

import { theme, VertDiv, HorzDiv } from '../styles.js';
import { arbiScanUrl} from '../globals/variables';

// import Backtest from './Backtest';
import {Image, Text, Input, UnorderedList, ListItem} from "@chakra-ui/react";
import { ArrowRightIcon } from '@chakra-ui/icons';


import { InputWithLabel } from './SimpleComponents.js';

import { FadeInAnimation } from './Animations/animations';

import { Info as InfoIcon } from '@mui/icons-material';
import { InfoDialog, ActionDialog, AlertDialog } from '../components/Dialogs/Dialogs';

import { Tweet } from 'react-twitter-widgets'


import { IndicatorGroup } from './Indicators/IndicatorGroup.js';

import { TriangleUpMarker, TriangleDownMarker } from './Markers/CustomMarkers.js';


const muiTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              background: theme.dark,
              color: theme.disabledText
            }
          }
        }
      }
    }
});


function ExistingVaultForm({address, 
                            minimal, 
                            vaultManagerObjIn=null, 
                            vaultFactoryObjIn=null,
                            vaultV2ObjIn=null,
                            VaultAggregatedObjIn=null, 
                            prefetchIn=null, 
                            onCompletion=null}) {
    const { windowDimensions, 
            baseURL, 
            networkDict, 
            txPending, setTxPending, 
            setLastTxHash,
            setErrorDialogOpen,
            setErrorMessage,
            setTxSuccess,
			devMode, 
            contractAddresses,  
            walletAddress, setWalletAddress} = useContext(UserContext);


    const [wallet, setWallet] = useState(null);
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    
    const [chainlinkInterface, setChainlinkInterface] = useState(null);
    const [chainlinkPrice, setChainlinkPrice] = useState(null);
    const [chainlinkPriceColor, setChainlinkPriceColor] = useState('#FFFFFF');
    // const [vaultManager, setVaultManager] = useState(null);
    const priceFetcher = new PriceFetcher();

    const [prefetch, setPrefetch] = useState(prefetchIn);
    
    // const [chartReady, setChartReady] = useState(false);
    const [depInputValuesUpdated, setDepInputValuesUpdated] = useState(false);
    const [tradeUpdated, setTradeUpdated] = useState(false);
    const [depAmt0, setDepAmt0] = useState(BigNumber.from(0));
    const [depAmt1, setDepAmt1] = useState(BigNumber.from(0));
    const [vaultGainPercent, setVaultGainPercent] = useState(0);
    const [vaultAPR, setVaultAPR] = useState(0);
    const [vaultTimeSeconds, setVaultTimeSeconds] = useState(0);
    const [insufficientFunds0, setInsufficientFunds0] = useState(false);
    const [insufficientFunds1, setInsufficientFunds1] = useState(false);
    const [balance0InWallet, setBalance0InWallet] = useState(BigNumber.from(0));
    const [balance1InWallet, setBalance1InWallet] = useState(BigNumber.from(0));
    const [userCanTrade, setUserCanTrade] = useState(false);
    const [trade, setTrade] = useState(null);
    const [tradeLoadedFirstTime, setTradeLoadedFirstTime] = useState(false);
    const [slippage, setSlippage] = useState(0.1);
    const [sliderSwapValue, setSliderSwapValue] = useState(0);
    const [sliderWithdrawValue, setSliderWithdrawValue] = useState(0);
    const [amtOut, setAmtOut] = useState(BigNumber.from(0));
    const [priceDisplayMode, setPriceDisplayMode] = useState(0);
    const [quoter, setQuoter] = useState(null);
    const [numTradeUpdates, setNumTradeUpdates] = useState(0);
    const [dummyTxList, setDummyTxList] = useState([]);
    // const [priceData, setPriceData] = useState({});
    
    
    const [plotData, setPlotData] = useState({apr:0.0, totalGainPercent:0.0, traces:[], tracesExtra:[]});
    const [tradeList, setTradeList] = useState([]);
    const [showTradeList, setShowTradeList] = useState(false);
    const [priceHistory, setPriceHistory] = useState({'times': [], 'close': [], 'high': [], 'low': [], 'open': [], 'volume': []});
    const [vault, setVault] = useState(null);  //VaultAggregated Object

    const [tokenPrimaryNum, setTokenPrimaryNum] = useState(0);
    const [tokenPrimary, setTokenPrimary] = useState(null);
    const [tokenSecondary, setTokenSecondary] = useState(null);


    const [initialDeposits, setInitialDeposits] = useState([0, 0]);

    const [refreshChartText, setRefreshChartText] = useState('Refresh Chart');
    const [showAutotrade, setShowAutotrade] = useState(false);
    
    const [tokenLibrary, setTokenLibrary] = useState(new TokenLibrary());

    const [dummyChecked, setDummyChecked] = useState(true);
    const [infoUpdated, setInfoUpdated] = useState(false);
    const [firstPass, setFirstPass] = useState(true);

    const [showDepositDiv, setShowDepositDiv] = useState(false);
    const [showTradeDiv, setShowTradeDiv] = useState(false);
    const [showBacktestDiv, setShowBacktestDiv] = useState(true);

    const [newOwnerAddress, setNewOwnerAddress] = useState(''); //for transfer ownership

    //from Backtest
    const [stratStringPlain, setStratStringPlain] = useState('');
    const [stratStringEnc, setStratStringEnc] = useState('');

    //from vault
    const [stratStringEncOnChain, setStratStringEncOnChain] = useState('');
    const [stratStringPlainOnChain, setStratStringPlainOnChain] = useState('');
    const [autotradeActive, setAutotradeActive] = useState(false);

    const [statusText, setStatusText] = useState('');

    const [gasStationDepositAmount, setGasStationDepositAmount] = useState(0);
    const [gasStationWithdrawAmount, setGasStationWithdrawAmount] = useState(0);

    // const [plotExtras, setPlotExtras] = useState({traces: []});
    // const [showKeltner, setShowKeltner] = useState(true);
    // const [keltnerParams, setKeltnerParams] = useState({emaPeriod: 30, atrPeriod: 30, multiplier: [5.0, 10.0]});
    // const [stratKeltnerObj, setStratKeltnerObj] = useState(new stratKeltner('1h', 30, 30, [5, 10], true));
    const timeFrameOptions = ['1h', '4h', '1d', '1w' ];
    const [graphTimeframe, setGraphTimeframe] = useState('1h');

    const [vaultInfoFetched, setVaultInfoFetched] = useState(false);

    const [dialogOpenBool, setDialogOpenBool] = useState(false);

    const strategyIDs = ["keltner", "keltnerAdvanced", "sma", "ema", "smaCross", "limit", "limitBuy", 'limitSell'];

    const initialState = strategyIDs.reduce((acc, id) => {
        acc[id] = { traces: [], params: {} };
        return acc;
    }, {});
    
    const [strategyData, setStrategyData] = useState(initialState);
    
    const [showBuysAndSells, setShowBuysAndSells] = useState(true);
    const [pnlHistory, setPnlHistory] = useState([]);
    const [daysPrefetch, setDaysPrefetch] = useState(300);

    const [strategyFullUpdates, setStrategyFullUpdates] = useState(0); //used to force a refresh stupid react

    const [priceUpdateInterval, setPriceUpdateInterval] = useState(minimal ? 10*60*1000 : 30*1000);

    const [numRefreshes, setNumRefreshes] = useState(0);

    const updateStrategyData = (key, value) => {
        // console.log('Parent updateStrategyData:', key, value);
        setStrategyData(
            prevData => (
                {...prevData,[key]: value}
            )
        );
    };

    async function fetchVaultInfo(firstPass=false) {
        const thisQuoter = new Quoter(networkDict.provider);

        var addressesIn = [];
        if (networkDict.address !== null && networkDict.address !== undefined && networkDict.address !== '') {
            addressesIn.push(networkDict.address);
        }

        var thisVault = null;
        
        let prv = networkDict.provider;
        let fac = null;
        let vm = null;
        let vi = new VaultInfo(contractAddresses.VaultInfo, prv);;
        let v2 = null;
        let va = null;

        let ci = null;

        let prefetchNew = null;

        ci = new ChainlinkInterface(contractAddresses.ChainlinkInterface, prv);
        setChainlinkInterface(ci);
    
        if (firstPass === true || firstPass === false) {
            
            if (prefetchIn !== null && prefetchIn !== undefined) {
                prefetchNew = prefetchIn;
            } else {
                prefetchNew = await getPrefetch(devMode, networkDict.chainID);
            }

            if (VaultAggregatedObjIn !== undefined && VaultAggregatedObjIn !== null) {
                va = VaultAggregatedObjIn;
            } else {
                if (vaultFactoryObjIn !== undefined && vaultFactoryObjIn !== null) {
                    fac = vaultFactoryObjIn;
                } else {
                    fac = new VaultFactoryV2(contractAddresses.VaultFactoryV2, prv);
                    await fac.init(prefetchNew);
                }

                if (vaultManagerObjIn !== undefined && vaultManagerObjIn !== null) {
                    vm = vaultManagerObjIn;
                } else {
                    vm = new VaultManagerV2(contractAddresses.VaultManagerV2, prv);
                    await vm.init(prefetchNew);
                }

                if (vm.history.length === 0){
                    await vm.fetchHistory(devMode, networkDict.chainID, false);
                }

                if (vaultV2ObjIn !== undefined && vaultV2ObjIn !== null) {
                    v2 = vaultV2ObjIn;
                } else {
                    v2 = new VaultV2(address, prv, vi);
                    await v2.init(prefetchNew);
                }

                va = new VaultAggregated(fac, vm, vi, v2, networkDict, addressesIn, devMode);
                await va.init(prefetchNew);
            }

            // console.log('VAULT:', va);
            thisVault = va;
        }

        setPrefetch(prefetchNew);
        setAutotradeActive(thisVault.autotradeActive);

        // await thisVault.update();
        var wall = null;
        if (networkDict.address !== null) {
            wall = new Wallet(networkDict.address, [thisVault.token0, thisVault.token1]);
            await wall.update(); 
        }
        if (wall !== null && wall.address.toLowerCase() === thisVault.operator.toLowerCase()) {
            setUserCanTrade(true);
        } else {
            setUserCanTrade(false);
        }

        setStratStringEncOnChain(thisVault.strategy);
        setWallet(wall);
        
        // const creationDate = new Date(bigNumToFloat(thisVault.creationTime, 0)*1000);
        const ti = timeIntervalSeconds(graphTimeframe);
        console.log('ti:', ti, graphTimeframe);
        const priceStartDate = new Date((bigNumToFloat(thisVault.creationTime) - ti*24*200)*1000)
        

        const todayDate = new Date();

        setQuoter(thisQuoter);
        
        var tokenPrimeNum = thisVault.tokenPrimaryNum;
        var tokenPrime = thisVault.tokenPrimary;
        var tokenSec = thisVault.tokenSecondary;
        // if (thisVault.token1.symbol === 'DAI' || thisVault.token1.symbol === 'USDC' || thisVault.token1.symbol === 'USDT') {
        //     tokenPrimeNum = 1;
        //     tokenPrime = thisVault.token1;
        //     tokenSec = thisVault.token0;
        // } else {
        //     tokenPrimeNum = 0;
        //     tokenPrime = thisVault.token0;
        //     tokenSec = thisVault.token1;
        // }

        setTokenPrimaryNum(tokenPrimeNum);
        setTokenPrimary(tokenPrime);
        setTokenSecondary(tokenSec);
        
        
        if (ci !== null) {
            let currentPrice = await ci.getRelativePrice(tokenSec.address, tokenPrime.address);
            setChainlinkPrice(currentPrice);
        } else if (ChainlinkInterface !== null) {
            let currentPrice = await chainlinkInterface.getRelativePrice(tokenSec.address, tokenPrime.address);
            setChainlinkPrice(currentPrice);
        }
        
        var newPriceData = priceHistory;
        if (priceHistory && priceHistory.close && priceHistory.close.length === 0) {
            console.log('fetching from:', priceStartDate);
            newPriceData = await priceFetcher.fetchHistory(tokenSec.symbol, 
                                                           tokenPrime.symbol, 
                                                           priceStartDate, 
                                                           todayDate, 
                                                           graphTimeframe);
            console.log('newPriceData startDate:', newPriceData.times[0], newPriceData.times.length);
            setPriceHistoryTest(newPriceData);
        }

        var plotData = [];
        let vmTemp = thisVault.managerObj;
        if (thisVault !== null && vmTemp.history.length > 0 && newPriceData.times && newPriceData.times.length > 0){
            plotData = createChartTraces(vmTemp.history, newPriceData, tokenPrime, tokenSec, thisVault);
            setPnlHistory(plotData.pnlHistory);
            setTradeList(plotData.tradeInfoList);
            if (vaultGainPercent !== plotData.totalGainPercent) {
                setVaultGainPercent(plotData.totalGainPercent);
            }
            if (vaultAPR !== plotData.apr) {
                setVaultAPR(plotData.apr);
            }
            if (vaultTimeSeconds !== plotData.totalTimeSeconds) {
                setVaultTimeSeconds(plotData.totalTimeSeconds);
            }
        }


        var temp = new Trade(thisVault, thisQuoter, 0, slippage, tokenPrimeNum);
        temp.update(BigNumber.from(0), slippage, true);
        
        setProvider(networkDict.provider);
        setVaultInfoFetched(false);
        setSigner(networkDict.signer);
        setWalletAddress(networkDict.address)
        
        setTrade(temp);
        setTradeUpdated(true);

        setPlotData(plotData);
        
		setVault(thisVault);
        setVaultInfoFetched(true);

        if (tradeLoadedFirstTime === false) {
            setTradeLoadedFirstTime(true);
        }
    }

    async function updateInfo() {
        var newVault = null;
        if (vault){
            console.log('updating vault info...');
            newVault = await vault.update();
            setVault(newVault);
            setAutotradeActive(newVault.autotradeActive);

            const balsWallet = vault.getBalances(networkDict.address);
            const bal0Wallet = balsWallet[0];
            const bal1Wallet = balsWallet[1];

            setBalance0InWallet(bal0Wallet);
            setBalance1InWallet(bal1Wallet);
            if (trade && showTradeDiv) {
                console.log('updating trade info...');
                const newTrade = new Trade(newVault, trade.quoter, trade.tokenFromNum, trade.slippage, tokenPrimaryNum);
                newTrade.update(BN(0), trade.slippage, true)
                setTrade(newTrade);
                setTradeUpdated(true);
                setTradeLoadedFirstTime(true);
            }
            setStratStringEncOnChain(newVault.strategy);
        }
        if (wallet) {
            console.log('updating wallet info...');
            setWallet(await wallet.update());
        }
        if (trade && newVault && quoter) {
            var temp = new Trade(newVault, quoter, 0, slippage, tokenPrimaryNum);
            if (showTradeDiv) {
                temp.update(BigNumber.from(0), slippage);
            } else {
                temp.update(BigNumber.from(0), slippage, true);
            }
            setTrade(temp);
            setTradeUpdated(true);
        }
        await fetchChartInfo(true);
        setInfoUpdated(true);
        setNumRefreshes(numRefreshes+1);
    }

    useEffect(() => {
        const fetchInitialInfo = async (networkDict) => {
            if (networkDict !== null && networkDict !== undefined && 'provider' in networkDict && contractAddresses !== null) {
                // console.log('fetching initial info...', networkDict, firstPass);
                await fetchVaultInfo(firstPass);
                setFirstPass(false)
                if (onCompletion !== null) {
                    onCompletion();
                }
            }
        }

        fetchInitialInfo(networkDict);
    }, [networkDict, contractAddresses]);

    async function updatePriceStuff() {
        const oldPrice = chainlinkPrice;
        var priceHistoryNew, currentPrice;

        var ret = await priceFetcher.updatePriceData(priceHistory, 
                                                    chainlinkInterface,
                                                    graphTimeframe,
                                                    tokenSecondary,
                                                    tokenPrimary);

        priceHistoryNew = ret.priceHistoryNew;
        currentPrice = ret.currentPrice;
        // console.log('currentPrice:', currentPrice, oldPrice, currentPrice-oldPrice);

        setPriceHistory(priceHistoryNew);
        setChainlinkPrice(currentPrice);

        if (currentPrice > oldPrice) {
            setChainlinkPriceColor('#66FF66');
        } else if (currentPrice < oldPrice) {
            setChainlinkPriceColor('#FF6666');
        }  

        const vmTemp = vault.managerObj;
        if (vault !== null && vmTemp.history.length > 0 && priceHistoryNew.times && priceHistoryNew.times.length > 0){
            var plotData = createChartTraces(vmTemp.history, priceHistoryNew, tokenPrimary, tokenSecondary, vault);
            setPnlHistory(plotData.pnlHistory);
            setTradeList(plotData.tradeInfoList);
            if (vaultGainPercent !== plotData.totalGainPercent) {
                setVaultGainPercent(plotData.totalGainPercent);
            }
            if (vaultAPR !== plotData.apr) {
                setVaultAPR(plotData.apr);
            }
            if (vaultTimeSeconds !== plotData.totalTimeSeconds) {
                setVaultTimeSeconds(plotData.totalTimeSeconds);
            }
            setPlotData(plotData);
        }
    }

    function handleStrategyUpdate(newStratDict) {
        // console.log('handleStrategyUpdate:', strategyData, newStratDict, plotData);
        setStrategyData(newStratDict);
        setStrategyFullUpdates(strategyFullUpdates+1);
    }

    //keep price up to date
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (ChainlinkInterface !== null && tokenPrimary !== null && tokenSecondary !== null && dialogOpenBool === false) {
                updatePriceStuff();
            }
        }, priceUpdateInterval);  //refresh interval (microseconds)
    
        return () => {
          clearInterval(intervalId);  // clear the interval on component unmount
        };
    }, [vaultInfoFetched, chainlinkPrice, 
        graphTimeframe, priceHistory, 
        dialogOpenBool, strategyData, 
        showBuysAndSells]); 

    useEffect(() => {
        if (ChainlinkInterface !== null && 
            tokenPrimary !== null && 
            tokenSecondary !== null && 
            dialogOpenBool === false && 
            priceHistory && priceHistory.times && priceHistory.times.length > 0) {
            updatePriceStuff();
        }
    }, [graphTimeframe, dialogOpenBool, chainlinkInterface, tokenPrimary, tokenSecondary]);

    useEffect(() => {
            setDepInputValuesUpdated(false);
            setTradeUpdated(false);
            setInfoUpdated(false);
    }, [depInputValuesUpdated, depAmt0, depAmt1, tradeUpdated, 
        tradeLoadedFirstTime, slippage, sliderSwapValue, refreshChartText, 
        dummyChecked, infoUpdated, stratStringPlainOnChain]);

    useEffect(() => {
        encryptStrategy(stratStringPlain);
    }, [stratStringPlain]);

    useEffect(() => {
        // console.log('QQQQQQQQQQQQQQQQQQ USEEFFECT stratDataUpdated:', strategyData, strategyFullUpdates); 
        var plotData = [];
        if (vault !== null){
            // console.log('create chart Traces from useEffect...', showBuysAndSells);
            plotData = createChartTraces(vault.managerObj.history, 
                                        priceHistory);
            setPnlHistory(plotData.pnlHistory);
            setTradeList(plotData.tradeInfoList);
            if (vaultGainPercent !== plotData.totalGainPercent) {
                setVaultGainPercent(plotData.totalGainPercent);
            }
            if (vaultAPR !== plotData.apr) {
                setVaultAPR(plotData.apr);
            }
            if (vaultTimeSeconds !== plotData.totalTimeSeconds) {
                setVaultTimeSeconds(plotData.totalTimeSeconds);
            }
        }

        setPlotData(plotData);
    }, [strategyData, showBuysAndSells, strategyFullUpdates]);

    async function encryptStrategy(message) {
        if (message !== '') {
            var temp = getBaseURL()
            if (temp.includes('localhost')) {
                temp = temp+':8000';
            }
            const url = temp+'/api/enc/' + message;
            console.log('ENC url:', url);
    
            // setFetchButtonText('Running...');
            axios.get(url).then(res => 
                {
                    setStratStringEnc(res.data.message);
                });
        } else {
            setStratStringEnc('');
        }
    }
    function setPriceHistoryTest(priceHistoryIn) {
        setPriceHistory(priceHistoryIn);
    }
    async function approveToken(tokenNumber, revoke=false) {
        const tokenContract = tokenNumber === 0 ? vault.token0 : vault.token1;
        var tx;
        if (revoke) {
            tx = await tokenContract.approve(vault.manager.address, 0);
            await tx.wait();
        } else {
            tx = await tokenContract.approve(vault.manager.address, ethers.constants.MaxUint256);
            await tx.wait();
        }

        await updateInfo();
        console.log('approveToken: ' + tx);
    }
    async function deactivateVault() {
        setTxPending(true);
        try {
            const tx = await vault.deactivate();
            await tx.wait();
            setTxPending(false);
            setTxSuccess(true);
            setLastTxHash(tx.hash);
            await updateInfo();
        } catch (e) {
            setTxPending(false);
            setTxSuccess(false);
            console.log('deactivateVault error: ', e);
        }
    }
    async function toggleVaultPublic() {
        setTxPending(true);
        try {
            const tx = await vault.togglePublic();
            await tx.wait();
            setTxPending(false);
            setTxSuccess(true);
            setLastTxHash(tx.hash);
            await updateInfo();
        } catch (e) {
            setTxPending(false);
            setTxSuccess(false);
            console.log('toggleVaultPublic error: ', e);
        }
    }
    async function transferOwnership() {
        if (ethers.utils.isAddress(newOwnerAddress)) {
            setTxPending(true);
            try {
                const tx = await vault.setOperator(newOwnerAddress);
                await tx.wait();
                setTxPending(false);
                setTxSuccess(true);
                setLastTxHash(tx.hash);
                await updateInfo();
            } catch (e) {
                setTxPending(false);
                setTxSuccess(false);
                console.log('transferOwnership error: ', e);
            }
        } else {
            console.log('invalid address');
            alert('invalid address!!!');
        }
    }
    function HeaderForm() {
        if (!vaultInfoFetched) {
        // if (false === true) {
            return(<></>);
        } else {
            return(
                <HorzDiv alignItems={"center"} backgroundColor={theme.black} margin={theme.margin0}>
                    <Button 
                            sx={{marginLeft:'10px', m:0, width: '180px', height: '60px'}}
                            variant='outlined'
                            color='primary'
                            size='small'
                            style={{'borderRadius': theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                            onClick={() => updateInfo()}
                        >
                            Refresh All Info
                        </Button>
                </HorzDiv>            
            );
        }
    }
    ///Basic Info
    function VaultInfoForm() {
        const fontSZ = 14;
        const fontSX = {fontSize: fontSZ, fontWeight: 'bold', color: '#FFF'};

        // const [dec0, setDec0] = useState(1);
        // const [dec1, setDec1] = useState(1);
        const [vaultAddress, setVaultAddress] = useState('0x');
        const [vaultPairString, setVaultPairString] = useState('Loading...');
        const [vaultName, setVaultName] = useState('loading...');
        const [vaultOperator, setVaultOperator] = useState('');
        const [vaultIsActive, setVaultIsActive] = useState(false);
        const [vaultIsPublic, setVaultIsPublic] = useState(false);
        const [vaultFeeUsers, setVaultFeeUsers] = useState(0);
        const [vaultFeeOperator, setVaultFeeOperator] = useState(0);
        const [vaultFeeOwner, setVaultFeeOwner] = useState(0);
        const [t0Sym, setT0Sym] = useState('');
        const [t1Sym, setT1Sym] = useState('');
        const [percents, setPercents] = useState([0, 0]);

        const [bals, setBals] = useState({pool: [0, 0], operator: [0, 0], wallet: [0, 0]});
        
        useEffect(() => {
            if (vault !== null && vault !== undefined) {
                var dec0 = tokenLibrary.getDecimalDisplay(vault.token0.symbol); //vault.token0.decimals;
                var dec1 = tokenLibrary.getDecimalDisplay(vault.token1.symbol); //vault.token1.decimals;
                // setDec0(dec0);
                // setDec1(dec1);
                setVaultAddress(vault.address);
                setVaultPairString(vault.pairString);
                setVaultName(vault.name);
                setVaultOperator(vault.operator);
                setVaultIsActive(vault.isActive);
                setVaultIsPublic(vault.allowOtherUsers);
                setVaultFeeUsers(vault.feeUsers);
                setVaultFeeOperator(vault.feeOperator);
                setVaultFeeOwner(vault.feeOwner);
                setT0Sym(vault.token0.symbol);
                setT1Sym(vault.token1.symbol);
                

                const b0Pool = vault.showBalance(0, dec0, 'pool')
                const b1Pool = vault.showBalance(1, dec1, 'pool')
                const b0Operator = vault.showBalance(0, dec0, vault.operator)
                const b1Operator = vault.showBalance(1, dec1, vault.operator)
                const b0Wallet = vault.showBalance(0, dec0, walletAddress)
                const b1Wallet = vault.showBalance(1, dec1, walletAddress)
                setBals({pool: [b0Pool, b1Pool], operator: [b0Operator, b1Operator], wallet: [b0Wallet, b1Wallet]});

                if (chainlinkPrice !== null) {
                    const amt0 = vault.balance0;
                    const amt1 = vault.balance1;
                    var val0 = 0;
                    var val1 = 0;
                    if (tokenPrimaryNum === 0) {
                        val0 = vault.token0.toFloat(amt0);
                        val1 = vault.token1.toFloat(amt1) * chainlinkPrice;
                    } else {
                        val0 = vault.token0.toFloat(amt0) * chainlinkPrice;
                        val1 = vault.token1.toFloat(amt1);
                    }
                    const totalVal = val0 + val1;
                    const percent0 = 100 * val0 / totalVal;
                    const percent1 = 100 * val1 / totalVal;
                    setPercents([percent0, percent1]);
                }
            }
        }, [vault, txPending, chainlinkPrice, wallet, numRefreshes]);
        

        return( 
            <HorzDiv alignItems={"stretch"} backgroundColor={theme.black} 
                    margin={theme.margin0} gap={theme.gap1} padding={theme.padding1}>
                <VertDiv borderColor={theme.light} borderWidth={'1px'} backgroundColor={theme.dark} 
                        margin={theme.margin0}>
                    <HorzDiv borderStyle="none" margin="0px" padding="0px">
                        <Jazzicon diameter={30} seed={jsNumberForAddress(vaultAddress)} />
                        <Typography variant='h6'>Pair: {vaultPairString}</Typography>
                    </HorzDiv>
                    
                    <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                    <Typography variant='h7'>Vault Name: {vaultName}</Typography>
                    <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                    <Link href={arbiScanUrl+'/address/'+vaultOperator} underline='hover' color='#fff'>
                        <Text fontSize={'0.9rem'} color={theme.white} margin="0px">Operator: {abbreviateAddress(vaultOperator)}</Text>
                    </Link>
                    <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                    <HorzDiv borderStyle="none" margin="0px" padding="0px">
                        <Text fontSize={'0.8rem'} color={theme.white} margin="0px">Is Active: {vaultIsActive ? "Yes" : "No"}</Text>
                        <Text fontSize={'0.8rem'} color={theme.white} margin="0px">Is Public: {vaultIsPublic ? "Yes" : "No"}</Text>
                    </HorzDiv>
                </VertDiv>
                <VertDiv borderColor={theme.light} borderWidth={'1px'} backgroundColor={theme.dark} 
                        margin={theme.margin0}>
                    {Graph(plotData, 'small', 200, 70)}
                    {/* <Graph plotDataIn={plotData} type='small' wid={200} hei={70} /> */}
                    <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                    <Typography variant='h7'>Gain: {parseFloat(vaultGainPercent).toFixed(2)}%</Typography>
                    <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                    <Typography variant='h7'>APR: {parseFloat(vaultAPR).toFixed(2)}%</Typography>
                </VertDiv>

                <VertDiv borderColor={theme.light} borderWidth={'1px'} backgroundColor={theme.dark} 
                        justifyContent='space-around' margin={theme.margin0}>
                    <TableContainer 
                        component={Paper} 
                        sx={{maxWidth: '400px', border: 'none', padding: '1px', 
                            backgroundColor: theme.dark}}
                    >
                        <Table size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow sx={{borderBottom: "1px solid black"}}>
                                    <TableCell align="center" sx={fontSX}>Deposits</TableCell>
                                    <TableCell align="center" sx={fontSX}>{t0Sym}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{t1Sym}</TableCell>
                                </TableRow> 
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>Total</TableCell>
                                    {/* <TableCell align="center" sx={fontSX}>{vault.showBalance(0, dec0, 'pool')}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{vault.showBalance(1, dec1, 'pool')}</TableCell> */}
                                    <TableCell align="center" sx={fontSX}>{bals['pool'][0]}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{bals['pool'][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>Operator</TableCell>
                                    {/* <TableCell align="center" sx={fontSX}>{vault.showBalance(0, dec0, vault.operator)}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{vault.showBalance(1, dec1, vault.operator)}</TableCell> */}
                                    <TableCell align="center" sx={fontSX}>{bals['operator'][0]}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{bals['operator'][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>My Wallet</TableCell>
                                    {/* <TableCell align="center" sx={fontSX}>{vault.showBalance(0, dec0, walletAddress)}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{vault.showBalance(1, dec1, walletAddress)}</TableCell> */}
                                    <TableCell align="center" sx={fontSX}>{bals['wallet'][0]}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{bals['wallet'][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>%</TableCell>
                                    {/* <TableCell align="center" sx={fontSX}>{vault.showBalance(0, dec0, walletAddress)}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{vault.showBalance(1, dec1, walletAddress)}</TableCell> */}
                                    <TableCell align="center" sx={fontSX}>{percents[0].toFixed(2)}</TableCell>
                                    <TableCell align="center" sx={fontSX}>{percents[1].toFixed(2)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </VertDiv>
                <VertDiv borderColor={theme.light} borderWidth={'1px'} backgroundColor={theme.dark} 
                        justifyContent='space-around' margin={theme.margin0}>
                    <TableContainer 
                        component={Paper} 
                        sx={{maxWidth: '400px', border: 'none', padding: '1px', 
                            backgroundColor: theme.dark}}
                    >
                        <Table size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center" sx={fontSX}>Fees</TableCell>
                                </TableRow> 
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>
                                        To Protocol
                                    </TableCell>
                                    <TableCell align="center" sx={fontSX}>
                                        {bigNumToFloat(vaultFeeOwner, 0)/1000}%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>
                                        To Operator
                                    </TableCell>
                                    <TableCell align="center" sx={fontSX}>
                                        {bigNumToFloat(vaultFeeOperator, 0)/1000}%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' sx={fontSX}>
                                        To Users
                                    </TableCell>
                                    <TableCell align="center" sx={fontSX}>
                                        {bigNumToFloat(vaultFeeUsers, 0)/1000}%
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </VertDiv>
            </HorzDiv>
        )
    }
    /////////////////Deposit Withdraw Section////////////////////
    async function adjustDepositInputs(newAmount, tokenNumber) {
        console.log('adjustDepositInputs', newAmount, tokenNumber);
        if (newAmount < 0) {
            newAmount = 0;
        }
        let T0 = vault.balance0;
        let T1 = vault.balance1;
        
        var a0 = BN(0);
        var a1 = BN(0);
        const B0 = BN(0)
        if (tokenNumber === 0) {
            a0 = BN(newAmount);
            if (T0.eq(B0) && T1.eq(B0)) {
                setDepAmt0(a0);
                a1 = depAmt1;
            } else if (T0.eq(B0)) {
                a0 = BN(0);
                setDepAmt0(a0);
                if (a0.gt(B0) || true){
                    alert('Entire balance is in ' + vault.token1.symbol);
                }
                
            } else if (T0.gte(T1)) {
                a1 = a0.mul(T1).div(T0);
                setDepAmt0(a0);
                setDepAmt1(a1);
            } else {  //T1 > T0 && T1 > 0
                a1 = (a0.mul(T1)).div(T0);
                a0 = (a1.mul(T0)).div(T1);
                setDepAmt0(a0);
                setDepAmt1(a1);
            }
        } else if (tokenNumber === 1) {
            a1 = BigNumber.from(newAmount);
            if (T0.eq(B0) && T1.eq(B0)) {
                a0 = depAmt0;
                setDepAmt1(a1);
            } else if (T1.eq(0)) {
                a1 = BigNumber.from(0);
                setDepAmt0(a1);
                if (a1.gt(B0) || true){
                    alert('Entire balance is in ' + vault.token0.symbol);
                }
                
            } else if (T1.gte(T0)) {
                a0 = a1.mul(T0).div(T1);
                setDepAmt1(a1);
                setDepAmt0(a0);
            } else {  //T1 > T0 && T1 > 0
                a0 = (a1.mul(T0)).div(T1);
                a1 = (a0.mul(T1)).div(T0);
                setDepAmt1(a1);
                setDepAmt0(a0);
            }
        } else {
            console.log('adjustDepositInputs: invalid tokenNumber');
        }
        setDepInputValuesUpdated(true);
        if (a0.gt(await vault.token0.getBalance(walletAddress))) {
            setInsufficientFunds0(true);
        } else {
            setInsufficientFunds0(false);
        }
        if (a1.gt(await vault.token1.getBalance(walletAddress))) {
            setInsufficientFunds1(true);
        }  else {
            setInsufficientFunds1(false);
        }
    }
    function ApproveRevokeButtons() {  
        const token0Approved = vault.allowances[walletAddress][0].gte(depAmt0)
        const token1Approved = vault.allowances[walletAddress][1].gte(depAmt1) 
        const buttonWidth = '180px';
        const buttonHeight = theme.buttonThickness2
        return(
            <HorzDiv margin={theme.margin0} gap={theme.gap1} padding={theme.padding1} 
                        backgroundColor={theme.dark} 
                        borderColor={theme.light} 
                        justifyContent="space-evenly">
                <Button
                    sx={{p:1, m:0, width: buttonWidth, height: buttonHeight}}
                    variant={token0Approved ? 'outlined' : 'contained'}
                    // color='primary'
                    size='small'
                    style={{'borderRadius':theme.borderRadiusSmall, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    // disabled={depAmt0 === 0 && token0Approved}
                    onClick={() => approveToken(0, token0Approved)}
                >
                    {(token0Approved ? "Revoke" : "Approve") + " " + vault.token0.symbol}
                </Button>
                <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                <Button
                    sx={{p:1, m:0, width: buttonWidth, height: buttonHeight}}
                    variant={token1Approved ? 'outlined' : 'contained'}
                    // color='primary'
                    size='small'
                    style={{'borderRadius':theme.borderRadiusSmall, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    // disabled={depAmt1 === 0 && token1Approved}
                    onClick={() => approveToken(1, token1Approved)}
                >
                    {(token1Approved ? "Revoke" : "Approve") + " " + vault.token1.symbol}
                </Button>   
            </HorzDiv>
        )

    }
    async function depositFunds() {
        if (depAmt0.lt(vault.token0MinDep) && depAmt1.lt(vault.token1MinDep)) {
            const alertString = 'Deposit amounts must be greater than ' + 
                            bigNumToHuman(vault.token0MinDep, vault.token0.decimals) + ' ' + 
                            vault.token0.symbol + ' OR ' + 
                            bigNumToHuman(vault.token1MinDep, vault.token1.decimals) + ' ' + vault.token1.symbol
            AlertDialog(alertString);
            return;
        } else {
            setTxPending(true);
            var tx;
            try {
                tx = await vault.deposit(depAmt0, depAmt1);
                await tx.wait();
                console.log('tx', tx);
                setTxSuccess(true);
                setTxPending(false);
                
                setLastTxHash(tx.hash);
                console.log('tx.hash', tx.hash);

                if (initialDeposits[0] === 0 && initialDeposits[1] === 0) {
                    setInitialDeposits([bigNumToFloat(depAmt0, vault.token0.decimals), bigNumToFloat(depAmt1, vault.token1.decimals)]);
                }
                console.log('depositFunds: ' + tx);
                // await fetchVaultInfo(); //is this one needed? It's not in withdrawFunds.
                setDepAmt0(BN(0));
                setDepAmt1(BN(0));
                setDepInputValuesUpdated(true);
    
                await updateInfo();
            } catch (e) {
                console.log(e);
                setTxPending(false);
                setTxSuccess(false);
            }
            
            return tx;
        }
    }
    async function withdrawFunds() {
        setTxPending(true);
        var tx;
        try {
            tx = await vault.withdraw(sliderWithdrawValue);
            setTxPending(false);
            setTxSuccess(true);
            setLastTxHash(tx.hash);
            console.log('tx.hash', tx.hash);
            console.log('withdrawFunds: ' + tx);
            updateInfo();
        } catch (e) {
            console.log(e);
            setTxPending(false);
            setTxSuccess(false);
        }
        
        
        return tx;
    }
    function DepositOrApprove() {
        const allowances = vault.getAllowances(walletAddress.toLowerCase());

        const token0Approved = allowances[0].gte(depAmt0)
        const token1Approved = allowances[1].gte(depAmt1)
        const needsApproval = !token0Approved || !token1Approved;
        if (needsApproval) {
            return <ApproveRevokeButtons />
        } else {
            return <DepositButton />
        }
    }
    function DepositButton() {
        // console.log('In DepositButton:', vault.token0MinDep, vault.token0.toHuman(vault.token0MinDep))
        const minDepCheck = (depAmt0.lt(vault.token0MinDep) && depAmt1.lt(vault.token1MinDep));
        // console.log('minDepCheck', minDepCheck);
        var buttonText = 'Deposit';
        if ((depAmt0.gt(BN(0)) || depAmt1.gt(BN(0))) && minDepCheck) {
            const alertMsg = "The minimum Deposit is " +bigNumToHuman(vault.token0MinDep, vault.token0.decimals) + ' ' + vault.token0.symbol + ' OR ' + 
            bigNumToHuman(vault.token1MinDep, vault.token1.decimals) + ' ' + vault.token1.symbol + ".";
            buttonText = 'Min Deposit Not Met'
            // alert(alertMsg);
        }
        return(
            <ThemeProvider theme={muiTheme}>
                <ActionDialog 
                    buttonInnards={buttonText}
                    buttonSX={{p:1, m:0, height: theme.buttonThickness2, width: '100%'}}
                    buttonStyle={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    buttonVariant='contained'
                    buttonColor='secondary'
                    disabled = {insufficientFunds0 || insufficientFunds1 || 
                                (depAmt0.eq(BN(0)) && depAmt1.eq(BN(0))) || 
                                minDepCheck}
                    handleAcceptIn={depositFunds}
                    titleString='Deposit'
                    setDialogOpenFlag={setDialogOpenBool}
                    contentString={
                        <VertDiv borderStyle='none' alignItems='flex-start'>
                            <Text>Depositing:</Text>
                            <UnorderedList>
                                <ListItem>{bigNumToHuman(depAmt0, vault.token0.decimals) + ' ' + vault.token0.symbol}</ListItem>
                                <ListItem>{bigNumToHuman(depAmt1, vault.token1.decimals) + ' ' + vault.token1.symbol}</ListItem>
                            </UnorderedList>
                            <Text>Are you sure?</Text>
                        </VertDiv>}
                />
            </ThemeProvider>

        )
    }
    function tokenWithIcon(tokenSymbol, iconSize='40px', fontSize=theme.fontSizeLarge) {
        const icon = tokenLibrary.getIcon(tokenSymbol); //getIcon(tokenSymbol);
        return(
            <HorzDiv borderStyle='none' margin='0px' padding='0px'>
                <Image boxSize={iconSize}
                        src={icon}
                        alt={tokenSymbol+" Logo"}
                        mr="0px"
                />
                <Text fontSize={fontSize} color={theme.white} margin="0px" padding='0px'>{tokenSymbol}</Text>
            </HorzDiv>
        );
    }
    function DepositInputField(tokenNumber, inputValuesUpdated=false) {
        const toke = tokenNumber === 0 ? vault.token0 : vault.token1;
        var depAmt;
        var dec;
        var disabled=false;
        if (tokenNumber === 0) {
            depAmt = depAmt0;
            dec = tokenLibrary.getDecimalDisplay(vault.token0.symbol);
            disabled=vault.balance0.eq(BN(0)) && vault.balance1.gt(0)
        } else {
            depAmt = depAmt1;
            dec = tokenLibrary.getDecimalDisplay(vault.token1.symbol);
            disabled=vault.balance1.eq(BN(0)) && vault.balance0.gt(0)
        }


        if (inputValuesUpdated) {
            return(
                <Input
                    fontWeight="500"
                    fontSize={theme.fontSizeLarge}
                    width="9rem"
                    size="19rem"
                    textAlign="center"
                    // bg="rgb(247, 248, 250)"
                    bg={theme.dark}
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    type="number"
                    color={"rgb(255,255,255)"}
                    value={toke.toHuman(depAmt, dec)}
                    disabled={disabled}
                    onBlurCapture={event=>adjustDepositInputs(toke.toBigNum(event.target.value), tokenNumber)}   
                />
            )
        } else {
            return(
                <Input 
                    fontWeight="500"
                    fontSize={theme.fontSizeLarge}
                    width="7rem"
                    size="19rem"
                    textAlign="center"
                    // bg="rgb(247, 248, 250)"
                    bg={theme.dark}
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    type="number"
                    color={"rgb(255,255,255)"}
                    defaultValue={toke.toHuman(depAmt, dec)}
                    disabled={disabled}
                    onBlurCapture={event=>adjustDepositInputs(toke.toBigNum(event.target.value), tokenNumber)}   
                />
            )
        }
    }
    function walletIsOperator() {
        return vault.operator.toLowerCase() === walletAddress.toLowerCase();
    }
    function setDepAmtToWalletBalance(tokenNumber) {
        var wallAmt;
        if (tokenNumber === 0) {
            console.log('setting depAmt0 to wallet balance');
            wallAmt = wallet.balances[vault.token0.address]
        } else {
            console.log('setting depAmt1 to wallet balance');
            wallAmt = wallet.balances[vault.token1.address]
        }
        console.log('wallet balance: ', wallAmt);
        adjustDepositInputs(wallAmt, tokenNumber)
    }
    function DepositWithdrawDiv() {
        // const inProps = { style: { color: "white", fontSize: 24 }, readOnly: true } ;
        // const wid = '14ch';
        // const marginBottom = '0px';
        // const size = 'small';
        // console.log('In DepositWithdrawDiv');
        if (!vaultInfoFetched) {
            return(<></>);
        } else {
            var depValuesUpdated;
            var dec0 = tokenLibrary.getDecimalDisplay(vault.token0.symbol);
            var dec1 = tokenLibrary.getDecimalDisplay(vault.token1.symbol);
            // console.log('HUH:', vault, walletAddress, sliderWithrawValue, vault.balances);
            
            var token0ToWithdrawInt = vault.getBalances(walletAddress.toLowerCase())[0].mul(sliderWithdrawValue).div(BN(100));
            var token0ToWithdraw = vault.token0.toHuman(token0ToWithdrawInt, dec0+3);
            var token1ToWithdrawInt = vault.getBalances(walletAddress.toLowerCase())[1].mul(sliderWithdrawValue).div(BN(100));
            var token1ToWithdraw = vault.token1.toHuman(token1ToWithdrawInt, dec1+3);
    
            if (depInputValuesUpdated) {
                depValuesUpdated = true;
            } else {
                depValuesUpdated = false;
            }
            var flexDir = 'row';
            var dividerDir = flexDir === 'row' ? 'vertical' : 'horizontal';
            return(
                <VertDiv alignItems={"center"} backgroundColor={theme.black}
                    margin={theme.margin0} gap={theme.gap1} padding={theme.padding2}
                >   
                    <Button onClick={() => setShowDepositDiv(!showDepositDiv)}>
                        {showDepositDiv ? "Hide Deposit/Withdraw" : "Show Deposit/Withdraw"}
                    </Button>
                    {showDepositDiv &&
                        FadeInAnimation(
                            <HorzDiv alignItems='stretch' borderStyle='none' margin='0px' gap={theme.gap1} padding={theme.padding0}
                                flexDirection={flexDir}
                            >
                                <VertDiv alignItems={"stretch"} justifyContent='space-between'
                                        backgroundColor={theme.dark} borderColor={theme.light}
                                        margin={theme.margin0} gap={theme.gap1} padding={theme.padding1}>
                                    <VertDiv borderStyle='none' borderColor={theme.light}
                                        margin='0px' padding='0px' gap='5px' 
                                        alignItems='center' justifyContent='center'
                                    >
                                        <HorzDiv margin='0px' padding='0px' borderStyle='none'>
                                            <Text fontSize={theme.fontSizeLarge} color={theme.white} margin="0px">Deposit</Text>
                                            <InfoDialog 
                                                buttonVariant='outlined'
                                                buttonInnards={<InfoIcon />}
                                                titleString='Deposit Info'
                                                setDialogOpenFlag={setDialogOpenBool}
                                                contentString={
                                                    <VertDiv borderStyle='none' margin='0px' padding='0px' alignItems='stretch'>
                                                        {vault.allowOtherUsers ? 
                                                            <VertDiv margin='0px' padding={theme.padding1} borderStyle='solid' alignItems='flex-start'>
                                                                <Text>
                                                                    This is a Public Vault. Anyone can deposit tokens. But the Vault Manager is the only wallet that can trade the funds. By depositing here, you are entrusting the Fund Manager to make wise trading decisions. Orbital is not responisble for poorly managed funds.
                                                                </Text> 
                                                                <Text>
                                                                    Deposits must be made in the same ratio as the current fund balances. Our front end will automatically adjust as needed.
                                                                </Text>
                                                            </VertDiv>
                                                            : 
                                                            <VertDiv margin='0px' padding={theme.padding1} borderStyle='solid' alignItems='flex-start'>
                                                                <Text>
                                                                    This is a Private Vault. Only the Vault Manager can deposit tokens.
                                                                </Text>
                                                                <Text>
                                                                    Deposits must be made in the same ratio as the current fund balances. Our front end will automatically adjust as needed.
                                                                </Text>
                                                            </VertDiv>
                                                        }
                                                        
                                                        <VertDiv margin='0px' padding={theme.padding1} borderStyle='solid' alignItems='center'>
                                                            <Text margin='0px'>
                                                                The Minimum Deposit is {" "+bigNumToHuman(vault.token0MinDep, vault.token0.decimals) + ' ' + vault.token0.symbol + ' OR ' + 
                                                                bigNumToHuman(vault.token1MinDep, vault.token1.decimals) + ' ' + vault.token1.symbol + "."}
                                                            </Text>
                                                        </VertDiv>
                                                        
    
                                                        {vault.operator.toLowerCase() !== walletAddress.toLowerCase() && 
                                                            <>
                                                            <VertDiv margin='0px' padding={theme.padding1} borderStyle='solid' alignItems='flex-start'>
                                                                <Text>
                                                                    This fund is subject to the following withdrawal fees:
                                                                </Text>
                                                                <UnorderedList>
                                                                    <ListItem>"Operator Fee:" {bigNumToFloat(vault.feeOperator, 0)/1000}% (To Fund Manager) </ListItem>
                                                                    <ListItem>"User Fee:" {bigNumToFloat(vault.feeUsers, 0)/1000}% (Distributed to current users of the Fund) </ListItem>
                                                                    <ListItem>"Protocol Fee:" {bigNumToFloat(vault.feeOwner, 0)/1000}%  </ListItem>   
                                                                </UnorderedList>
                                                            </VertDiv>
                                                            <VertDiv margin='0px' padding={theme.padding1} borderStyle='solid' alignItems='flex-start'>
                                                                <Text>
                                                                    The fees operate as follows. 
                                                                </Text>
                                                                <UnorderedList>
                                                                    <ListItem>Fees apply to all users of the fund, other than the Fund Creator (a.k.a. "Fund Manager" or "Fund Operator"). Fund Managers are exempt from all fees and the protocol is free to use.</ListItem>
                                                                    <ListItem>All Fees are collected at the time of withdrawal from the fund.</ListItem>
                                                                    <ListItem>"Operator Fee" is sent to the Fund Manager's Wallet. </ListItem>
                                                                    <ListItem>"User Fee" is kept in the Fund's Vault, and is effectively distributed to all current users of the vault, in proportion to their share of the total. The Fund Manager is treated as a user like any other, so that the Fund Manager's total Fee is the sum of the Operator Fee and their share of the User Fees.</ListItem>
                                                                    <ListItem>"Owner Fee" is sent to the Protocol Wallet and is fixed. </ListItem>
                                                                    <ListItem>All Fees are determined at the time of the Fund's creation and are unchangable afterward.</ListItem>
                                                                </UnorderedList>
                                                            </VertDiv>    
                                                            </>
                                                             
                                                        } 
                                                    </VertDiv>}
                                            />
                                        </HorzDiv>
                                        
                                        <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                                    </VertDiv>
                                    
                                    <VertDiv margin={theme.margin0} gap={theme.gap1} padding={theme.padding1} 
                                            backgroundColor={theme.dark} 
                                            borderColor={theme.light} justifyContent="space-evenly">
                                        <HorzDiv alignItems='stretch' borderStyle='none' margin={theme.margin0} padding={theme.padding0}>
                                            {tokenWithIcon(vault.token0.symbol)}
                                            <HorzDiv borderStyle='none' margin={theme.margin0} padding={theme.padding0}>
                                                <VertDiv alignItems={"center"} justifyContent="center" 
                                                    backgroundColor={theme.dark} 
                                                    borderColor={theme.light} borderStyle='none' 
                                                    margin="0px" gap='5px' padding='0px'>
                                                    <Text fontSize={theme.fontSizeSmall} color={theme.white} margin="0px">Deposit Amt:</Text>
                                                    <HorzDiv
                                                        margin={theme.margin0} gap={theme.gap1} padding={"2px 10px 2px 10px"}
                                                        borderColor={theme.pink} borderRadius={theme.borderRadiusSmall}
                                                    >
                                                    {DepositInputField(0, depValuesUpdated)}
                                                    </HorzDiv>
                                                </VertDiv>
                                                <VertDiv alignItems={"center"} justifyContent="center" 
                                                    backgroundColor={theme.dark} 
                                                    borderColor={theme.light} borderStyle='none' 
                                                    margin="0px" gap='5px' padding='0px'>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        sx={{'p':0, 'm':0, 'g':0, 'height': '19px', 'width': '100%'}}
                                                        style={{'borderRadius':'5px', 'fontSize': '0.7em'}}
                                                        onClick={() => {setDepAmtToWalletBalance(0)}}
                                                    >
                                                        Wallet Amt
                                                    </Button>
                                                    <HorzDiv
                                                        margin={theme.margin0} gap={theme.gap1} padding={"2px 10px 2px 10px"}
                                                        borderColor={theme.light} borderRadius={theme.borderRadiusSmall}
                                                    >
                                                        <Input 
                                                            fontWeight="500"
                                                            fontSize={theme.fontSizeLarge}
                                                            width="7rem"
                                                            size="16rem"
                                                            textAlign="center"
                                                            // bg="rgb(247, 248, 250)"
                                                            bg={theme.dark}
                                                            outline="none"
                                                            border="none"
                                                            focusBorderColor="none"
                                                            type="text"
                                                            color={"rgb(255,255,255)"}
                                                            readOnly={true}
                                                            value={wallet.showBalanceHuman(vault.token0.address, tokenLibrary.getDecimalDisplay(vault.token0.address))}   
                                                        />                                    
                                                    </HorzDiv>
                                                </VertDiv>
                                            </HorzDiv>
                                        </HorzDiv>
                                        <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                                        <HorzDiv alignItems='stretch' borderStyle='none' margin={theme.margin0} padding={theme.padding0}>
                                            {tokenWithIcon(vault.token1.symbol)}
                                            <HorzDiv borderStyle='none' margin={theme.margin0} padding={theme.padding0}>
                                                <VertDiv alignItems={"center"} justifyContent="center" 
                                                    backgroundColor={theme.dark} 
                                                    borderColor={theme.light} borderStyle='none'
                                                    margin="0px" gap='5px' padding='0px'>
                                                    <Text fontSize={theme.fontSizeSmall} color={theme.white} margin="0px">Deposit Amt:</Text>
                                                    
                                                    <HorzDiv
                                                        margin={theme.margin0} gap={theme.gap1} padding={"2px 10px 2px 10px"}
                                                        borderColor={theme.pink} borderRadius={theme.borderRadiusSmall}
                                                    >
                                                        {DepositInputField(1, depValuesUpdated)}
                                                    </HorzDiv>
                                                </VertDiv>
                                                <VertDiv alignItems={"center"} justifyContent="center" 
                                                    backgroundColor={theme.dark} 
                                                    borderColor={theme.light} borderStyle='none' 
                                                    margin="0px" gap='5px' padding='0px'>
                                                    <Button 
                                                        variant="text"
                                                        color="primary"
                                                        sx={{'p':0, 'm':0, 'g':0, 'height': '19px', 'width': '100%'}}
                                                        style={{'borderRadius':'5px', 'fontSize': '0.7em'}}
                                                        onClick={() => {setDepAmtToWalletBalance(1)}}
                                                    >
                                                        Wallet Amt
                                                    </Button>
                                                    <HorzDiv
                                                        margin={theme.margin0} gap={theme.gap1} padding={"2px 10px 2px 10px"}
                                                        borderColor={theme.light} borderRadius={theme.borderRadiusSmall}
                                                    >
                                                        <Input 
                                                            fontWeight="500"
                                                            fontSize={theme.fontSizeLarge}
                                                            width="7rem"
                                                            size="16rem"
                                                            textAlign="center"
                                                            // bg="rgb(247, 248, 250)"
                                                            bg={theme.dark}
                                                            outline="none"
                                                            border="none"
                                                            focusBorderColor="none"
                                                            type="text"
                                                            color={"rgb(255,255,255)"}
                                                            readOnly={true}
                                                            value={wallet.showBalanceHuman(vault.token1.address, tokenLibrary.getDecimalDisplay(vault.token1.address))}   
                                                        />                                    
    
                                                    </HorzDiv>
                                                </VertDiv>
                                            </HorzDiv>
                                            
                                        </HorzDiv>
                                    </VertDiv>
                                    {DepositOrApprove()}
                                    
                                </VertDiv>
                                <VertDiv alignItems='stretch' justifyContent='flexStart'
                                    backgroundColor={theme.dark} borderColor={theme.light} 
                                    margin="0px" gap={theme.gap1} padding={theme.padding1}
                                >
                                    <VertDiv borderStyle='none' borderColor={theme.light}
                                        margin='0px' padding='0px' gap='5px' 
                                        alignItems='center' justifyContent='center'
                                    >
                                        <Text fontSize={theme.fontSizeLarge} color={theme.white} margin="0px">Withdraw</Text>
                                        <Divider flexItem={true} sx={{ bgcolor: "white" }} />
                                    </VertDiv>
    
                                    <VertDiv margin='0px' padding='0px' gap='0px' borderStyle='none'>
                                        <HorzDiv borderStyle='none' margin="0px" padding='0px 30px 4px 30px' >
                                            <Text fontSize={'1rem'} color={theme.white} margin="0px" padding='0px'>Withdraw Ratio:</Text>
                                            <Label>{sliderWithdrawValue + '%'}</Label>
                                        </HorzDiv>
                                        <MuiBox sx={{ width: 200 }}>
                                            <Slider
                                                aria-label="Percentage"
                                                defaultValue={0}
                                                getAriaValueText={setSliderWithdrawValue}
                                                valueLabelDisplay="auto"
                                                step={5}
                                                marks
                                                min={0}
                                                max={100}
                                                color="secondary"
                                            />
                                        </MuiBox>
                                    </VertDiv>
    
                                    <HorzDiv justifyContent="center" alignItems='center' 
                                                margin="0px" padding='0px' 
                                                borderColor={theme.light} borderStyle='none'>
                                        <VertDiv borderColor={theme.light} alignItems='center' 
                                            margin='0px' gap={theme.gap1} padding={theme.padding1}>
                                            {tokenWithIcon(vault.token0.symbol)}
                                            <VertDiv
                                                borderStyle='none' 
                                                margin='0px' padding='0px' gap={theme.gap0}
                                            >
                                                <Text
                                                    fontSize={theme.fontSizeSmall}
                                                    color='white'
                                                    padding='0px'
                                                    margin='0px 0px 0px 0px'   
                                                >
                                                    Withdraw Amt
                                                </Text>
                                                <Text 
                                                    fontSize={theme.fontSizeLarge}
                                                    color='white'
                                                    padding='0px'
                                                    margin='0px'
                                                >
                                                    {token0ToWithdraw}
                                                </Text>
                                            </VertDiv>
    
                                        </VertDiv>
                                        <VertDiv borderColor={theme.light} alignItems='center' 
                                            margin='0px' gap={theme.gap1} padding={theme.padding1}>
                                            {tokenWithIcon(vault.token1.symbol)}
                                            <VertDiv
                                                borderStyle='none' 
                                                margin='0px' padding='0px' gap={theme.gap0}
                                            >
                                                <Text
                                                    fontSize={theme.fontSizeSmall}
                                                    color='white'
                                                    padding='0px'
                                                    margin='0px 0px 0px 0px'   
                                                >
                                                    Withdraw Amt
                                                </Text>
                                                <Text 
                                                    fontSize={theme.fontSizeLarge}
                                                    color='white'
                                                    padding='0px'
                                                    margin='0px'
                                                >
                                                    {token1ToWithdraw}
                                                </Text>
                                            </VertDiv>
    
                                        </VertDiv>
                                    </HorzDiv>
                                    <ThemeProvider theme={muiTheme}>
                                        <ActionDialog 
                                            buttonVariant='contained'
                                            buttonColor='secondary'
                                            buttonSX={theme.buttonSX}
                                            buttonStyle={theme.buttonStyle}
                                            handleAcceptIn={() => withdrawFunds()}
                                            buttonInnards={"Withdraw"}
                                            disabled={token0ToWithdrawInt.eq(0) && token1ToWithdrawInt.eq(0)}
                                            titleString={"Withdraw Funds?"}
                                            setDialogOpenFlag={setDialogOpenBool}
                                            contentString={
                                                !walletIsOperator() ?
                                                <VertDiv borderStyle='none' alignItems='flex-start'>
                                                    <Text>
                                                        Withdraw {token0ToWithdraw} {vault.token0.symbol} and {token1ToWithdraw} {vault.token1.symbol}?
                                                    </Text>
                                                    <Text>
                                                        This fund is subject to the following withdrawal fees:
                                                    </Text>
                                                    <UnorderedList>
                                                        <ListItem>"Operator Fee:" {bigNumToFloat(vault.feeOperator, 0)/1000}% (To Fund Manager) </ListItem>
                                                        <ListItem>"User Fee:" {bigNumToFloat(vault.feeUsers, 0)/1000}% (Distributed to current users of the Fund) </ListItem>
                                                        <ListItem>"Protocol Fee:" {bigNumToFloat(vault.feeOwner, 0)/1000}%  </ListItem>   
                                                    </UnorderedList>
                                                </VertDiv> :
                                                <VertDiv borderStyle='none' alignItems='flex-start'>
                                                    <Text>
                                                        Withdraw {token0ToWithdraw} {vault.token0.symbol} and {token1ToWithdraw} {vault.token1.symbol}?
                                                    </Text>
                                                </VertDiv>
                                                }
                                        />
                                    </ThemeProvider>
                                </VertDiv>
                            </HorzDiv>
                        )
                    }
                </VertDiv> 
            )
        }

    }
    async function setSliderFunction(val, forceUpdate=false){
        if (val !== sliderSwapValue || forceUpdate) {
            setSliderSwapValue(val);
            var temp;
        
            if (trade.tokenFromNum === 0) {
                temp = vault.balance0.mul(val).div(100);
                console.log('setSliderFunction: ', val, temp, bigNumToHuman(temp, vault.token0.decimals));
            } else {
                temp = vault.balance1.mul(val).div(100);
                console.log('setSliderFunction: ', val, temp, bigNumToHuman(temp, vault.token1.decimals));
            }

            setAmtOut(temp);
            var newTrade = await trade.update(temp, slippage);
            setTrade(newTrade);
            setTradeUpdated(true);
        }
    }
    async function swapFromToken() {
        var newFromToken;
        var newAmtOut;
        var newTrade = await trade.update(trade.amtOut, trade.slippage, true);
        if (true) {
            if (trade.tokenFromNum === 0) {
                newFromToken = 1;
                newAmtOut = vault.balance1.mul(sliderSwapValue).div(100);
            } else {
                newFromToken = 0;
                newAmtOut = vault.balance0.mul(sliderSwapValue).div(100);
            }
            var newTrade = new Trade(vault, quoter, newFromToken, slippage, tokenPrimaryNum)
            await newTrade.update(newAmtOut, slippage);
        } 
        setTrade(newTrade);
        setTradeUpdated(true);
    }
    function priceDisplay() {
        const displayDigits = 6;
        if (trade.price !== undefined) {
            if (priceDisplayMode === 0) {
                return(
                    <div>
                        <Label>1 {vault.tokenSecondary.symbol}={(trade.price).toFixed(displayDigits)} {vault.tokenPrimary.symbol}</Label>
                    </div>
                )
            } else {
                return(
                    <div>
                        <Label>1 {vault.tokenPrimary.symbol}={(1.0/trade.price).toFixed(displayDigits)} {vault.tokenSecondary.symbol}</Label>
                    </div>
                )
            }
        } else {
            return(
                <div>
                    <Label>Price: Loading...</Label>
                </div>
            )
        }
    }
    async function setSlippageFunction(val) {
        if (val !== slippage) {
            setSlippage(val);
            var newTrade = await trade.update(trade.amtOut, val);
            setTrade(newTrade);
            setTradeUpdated(true);
        }
    }
    function createChartTraces(
                vmHistory, 
                priceHistoryIn, 
                tokenPrimaryIn=null, 
                tokenSecondaryIn=null, 
                vaultIn=null) {
        var tokenPrimaryHere = tokenPrimaryIn === null ? tokenPrimary : tokenPrimaryIn;
        var tokenSecondaryHere = tokenSecondaryIn === null ? tokenSecondary : tokenSecondaryIn;
        var vaultHere = (vaultIn === null) ? vault : vaultIn;

        var priceHistory = {}

        const lastDisplayTime = new Date(2223, 11, 22, 18, 0, 0, 0);
        const lastDisplayEpoch = lastDisplayTime.getTime()/1000.0;
        var lastDisplayIndex = priceHistoryIn.times.length-1;
        
        for (var i=0; i<priceHistoryIn.times.length; i++) {
            if (priceHistoryIn.times[i] > lastDisplayTime) {
                lastDisplayIndex = i;
                break;
            }
        }


        priceHistory.times = priceHistoryIn.times.slice(0, lastDisplayIndex);
        priceHistory.timesEpoch = priceHistoryIn.timesEpoch.slice(0, lastDisplayIndex);
        priceHistory.open = priceHistoryIn.open.slice(0, lastDisplayIndex);
        priceHistory.high = priceHistoryIn.high.slice(0, lastDisplayIndex);
        priceHistory.low = priceHistoryIn.low.slice(0, lastDisplayIndex);
        priceHistory.close = priceHistoryIn.close.slice(0, lastDisplayIndex);
        priceHistory.volume = priceHistoryIn.volume.slice(0, lastDisplayIndex);
        priceHistory.lastRow = priceHistoryIn.lastRow;

        const lastRow = priceHistory.lastRow

        if (lastDisplayEpoch >= lastRow.time) {
            priceHistory.timesEpoch.push(lastRow.time);
            priceHistory.times.push(new Date(lastRow.time*1000))
            priceHistory.open.push(lastRow.open);
            priceHistory.high.push(lastRow.high);
            priceHistory.low.push(lastRow.low);
            priceHistory.close.push(lastRow.close);
            priceHistory.volume.push(lastRow.volume);
        }

        var trace = {};
        var traces = [];
        var depositInfoList = [];
        var tradeInfoList = [];
        var currentVaultValue = 1.0; // start at 1 ETH and calculate returns due to trading only.
        var pnlHistory = [];
        var pnlState;

        
        // var test = bigNumDivToFloat(1,3)
        // var test2 = bigNumDivToFloat(1000,3)
        // console.log("test: ", test)
        var atList = [];
        if (prefetch && prefetch.VaultManagerV2 && prefetch.VaultManagerV2.autoTraders) {
            for (var i=0; i<prefetch.VaultManagerV2.autoTraders.length; i++) {
                atList.push(prefetch.VaultManagerV2.autoTraders[i].toLowerCase());
            }
        }

        for (var i=0; i < vmHistory.length; i++) {
            var vh = vmHistory[i];
            var timestamp = parseInt(vh.timestamp);
            if (vh.inputs !== undefined && 
                vh.inputs.vaultAddress !== undefined &&
                vh.inputs.vaultAddress.toLowerCase() === vaultHere.address.toLowerCase() && 
                vh.status === "1" && timestamp <= lastDisplayEpoch) {
                if (vh.methodName === 'trade') {
                    // console.log('trade in history:', i, vh);
                    var tradeInfo = {};
                    tradeInfo.vaultAddress = vh.inputs.vaultAddress;
                    tradeInfo.hash = vh.hash;
                    tradeInfo.spendAmt = BN(vh.event.spendAmt);
                    tradeInfo.receiveAmt = BN(vh.event.receiveAmt);
                    tradeInfo.spendTokenTotalBefore = BN(vh.event.spendTokenTotalBefore);
                    tradeInfo.receiveTokenTotalBefore = BN(vh.event.receiveTokenTotalBefore);

                    tradeInfo.user = vh.from;  //operator
                    tradeInfo.timestamp = parseInt(vh.timestamp);
                    tradeInfo.time = new Date(vh.timestamp*1000.0); //new Date(temp + 'Z');
                    
                    tradeInfo.autotrade = false;
                    // console.log(vh.from, prefetch.VaultManagerV2.autoTraders[0]);
                    if (inList(atList, vh.from.toLowerCase())) {
                        tradeInfo.autotrade = true;
                    }
                    
                    var price;
                    if (vh.event.spendToken.toLowerCase() === tokenPrimaryHere.address.toLowerCase()) { 
                        tradeInfo.type = 'buy';
                        tradeInfo.spendAmtFloat = bigNumDivToFloat(tradeInfo.spendAmt, BN(10).pow(tokenPrimaryHere.decimals));
                        tradeInfo.receiveAmtFloat = bigNumDivToFloat(tradeInfo.receiveAmt, BN(10).pow(tokenSecondaryHere.decimals));
                        tradeInfo.spendTokenTotalBeforeFloat = bigNumDivToFloat(tradeInfo.spendTokenTotalBefore, BN(10).pow(tokenPrimaryHere.decimals));
                        tradeInfo.receiveTokenTotalBeforeFloat = bigNumDivToFloat(tradeInfo.receiveTokenTotalBefore, BN(10).pow(tokenSecondaryHere.decimals));
                        price = (tradeInfo.spendAmtFloat)/(tradeInfo.receiveAmtFloat);
                        // tradeInfo.price = price;
                        // tradeInfo.tradeRatio = tradeInfo.spendAmtFloat/(tradeInfo.spendTokenTotalBeforeFloat + tradeInfo.receiveTokenTotalBeforeFloat*price);
                    } else { 
                        tradeInfo.type = 'sell';
                        // tradeInfo.spendAmtFloat = tradeInfo.spendAmt/10**tokenSecondaryHere.decimals;
                        // tradeInfo.receiveAmtFloat = tradeInfo.receiveAmt/10**tokenPrimaryHere.decimals;
                        // tradeInfo.spendTokenTotalBeforeFloat = tradeInfo.spendTokenTotalBefore/10**tokenSecondaryHere.decimals;
                        // tradeInfo.receiveTokenTotalBeforeFloat = tradeInfo.receiveTokenTotalBefore/10**tokenPrimaryHere.decimals;

                        tradeInfo.spendAmtFloat = bigNumDivToFloat(tradeInfo.spendAmt, BN(10).pow(tokenSecondaryHere.decimals));
                        tradeInfo.receiveAmtFloat = bigNumDivToFloat(tradeInfo.receiveAmt, BN(10).pow(tokenPrimaryHere.decimals));
                        tradeInfo.spendTokenTotalBeforeFloat = bigNumDivToFloat(tradeInfo.spendTokenTotalBefore, BN(10).pow(tokenSecondaryHere.decimals));
                        tradeInfo.receiveTokenTotalBeforeFloat = bigNumDivToFloat(tradeInfo.receiveTokenTotalBefore, BN(10).pow(tokenPrimaryHere.decimals));
                        price = (tradeInfo.receiveAmtFloat)/(tradeInfo.spendAmtFloat);
                        // tradeInfo.price = price;
                        // tradeInfo.tradeRatio = tradeInfo.spendAmtFloat/(tradeInfo.spendTokenTotalBeforeFloat + tradeInfo.receiveTokenTotalBeforeFloat/price);
                    }
                    tradeInfo.price = price;
                    tradeInfo.tradeRatio = tradeInfo.spendAmtFloat/(tradeInfo.spendTokenTotalBeforeFloat);
                    tradeInfoList.push(tradeInfo);
                } else if (vh.methodName === 'deposit' || vh.methodName == 'withdraw') {
                    var depositInfo = {};
                    depositInfo.type = vh.methodName;
                    depositInfo.timestamp = parseInt(vh.timestamp);
                    depositInfo.time = new Date(vh.timestamp*1000.0);
                    depositInfo.vaultAddress = vh.inputs.vaultAddress;
                    depositInfo.user = vh.from;
                    let amts = [];
                    if (vh.methodName === 'deposit') {
                        // amts = vh.event.amts;
                        for (const amt of vh.event.amts) {
                            amts.push(BN(amt));
                        }
                    } else { //withdraw
                        const balsBefore = [];
                        for (const amt of vh.event.balancesBefore) {
                            balsBefore.push(BN(amt));
                        }
                        const deltaN = BN(vh.event.deltaN);
                        const deltaNFees = vh.event.deltaNFees;
                        const dNOwner = BN(deltaNFees[0]);
                        const dNOperator = BN(deltaNFees[1]);
                        const dNUsers = BN(deltaNFees[2]);
                        const dNLeftover = deltaN.sub(dNOwner).sub(dNOperator).sub(dNUsers);
                        const D = BN(vh.event.DBefore);
                        let amtsOutCaller = [];
                        let amtsOutOwner = [];
                        for (var j=0; j<balsBefore.length; j++) {
                            const outCaller = BN(balsBefore[j]).mul(dNLeftover).div(D);
                            const outOwner = BN(balsBefore[j]).mul(dNOwner).div(D);
                            amtsOutCaller.push(outCaller)
                            amtsOutOwner.push(outOwner)
                            amts.push(outCaller.add(outOwner));
                        }
                    }
                    depositInfo.amount0 = amts[0];
                    depositInfo.amount1 = amts[1];
                    
                    depositInfo.amount0Float = bigNumDivToFloat(depositInfo.amount0, BN(10).pow(vaultHere.token0.decimals));
                    depositInfo.amount1Float = bigNumDivToFloat(depositInfo.amount1, BN(10).pow(vaultHere.token1.decimals));
                    if (tokenPrimaryHere.address.toLowerCase() === vaultHere.token0.address.toLowerCase()) {
                        depositInfo.amountPrimary = depositInfo.amount0;
                        depositInfo.amountSecondary = depositInfo.amount1;
                        depositInfo.amountPrimaryFloat = depositInfo.amount0Float;
                        depositInfo.amountSecondaryFloat = depositInfo.amount1Float;
                    } else {
                        depositInfo.amountPrimary = depositInfo.amount1;
                        depositInfo.amountSecondary = depositInfo.amount0;
                        depositInfo.amountPrimaryFloat = depositInfo.amount1Float;
                        depositInfo.amountSecondaryFloat = depositInfo.amount0Float;
                    }
                    depositInfoList.push(depositInfo);
                }
            }
        }

        var sellTimes = [];
        var sellPrices = [];
        var buyTimes = [];
        var buyPrices = [];

        var sellTimesAuto = [];
        var sellPricesAuto = [];
        var buyTimesAuto = [];
        var buyPricesAuto = [];

        for (var i=0; i<tradeInfoList.length; i++) {
            var ti = tradeInfoList[i];
            var user = ti.user;

            if (prefetch && 
                prefetch.VaultManagerV2 && 
                prefetch.VaultManagerV2.autoTraders &&
                user in prefetch.VaultManagerV2.autoTraders) {

                if (ti.type === 'sell') {
                    sellTimesAuto.push(ti.time);
                    sellPricesAuto.push(ti.price);
                } else {
                    buyTimesAuto.push(ti.time);
                    buyPricesAuto.push(ti.price);
                }

            } else {
                if (ti.type === 'sell') {
                    sellTimes.push(ti.time);
                    sellPrices.push(ti.price);
                } else {
                    buyTimes.push(ti.time);
                    buyPrices.push(ti.price);
                }
            }
        }

        // // //DELETE
        // buyTimes.pop();
        // buyPrices.pop();
        // tradeInfoList.pop();

        const selltrace = {x: sellTimes, y: sellPrices,
                            xaxis:'x1', yaxis:'y1',
                            legendgroup: 'main',
                            type: 'scatter',
                            mode: 'markers',
                            marker: {color:'#ff0000', size: 8},
                            name: 'sells'};
        // traces.push(selltrace);
        const buytrace = {x: buyTimes, y: buyPrices,
                            xaxis:'x1', yaxis:'y1',
                            legendgroup: 'main',
                            type: 'scatter',
                            mode: 'markers',
                            marker: {color: '#00ff00'},
                            name: 'buys'};
        // traces.push(buytrace);
        
        if (depositInfoList.length > 0) {
            depositInfo = depositInfoList[0];
            var closestTimeIndex = 0;
            var closestPrice = -1;
            for (var i=0; i<priceHistory.times.length; i++) {
                if (Math.abs(priceHistory.times[i].getTime() - depositInfo.time.getTime()) 
                        < Math.abs(priceHistory.times[closestTimeIndex].getTime() - depositInfo.time.getTime())) {
                    closestTimeIndex = i;
                    closestPrice = priceHistory.close[i];
                }
            }
            //Build pnl history based on a theoretical initial deposit with total value of 1 ETH, measured in USDC 
            //(primary or reference token). For each subsequent trade, determine the price and the ratio of the trade.
            //Then simulate how this 1 ETH value portfolio would have changed over time. At each time step, the total value
            //in usdc divided by the initial value is the pnl at that time step.
            const valPrimary = depositInfo.amountPrimaryFloat;    //usdc val of usdc
            const valSecondary = depositInfo.amountSecondaryFloat*closestPrice;  //usdc val of eth
            pnlState = {'time': depositInfo.time,
                        'price': closestPrice,
                        'amountPrimary': closestPrice*valPrimary/(valPrimary+valSecondary),   //valPrimary/(valPrimary + valSecondary),
                        'amountSecondary': valSecondary/((valPrimary+valSecondary)) ,  //valSecondary/(valPrimary + valSecondary),
                        'value': closestPrice,
                        'pnl': 1.0,
                        'pnlTotal': 1}

            pnlHistory.push(pnlState);
            for (var i=0; i<tradeInfoList.length; i++) {
                var ti = tradeInfoList[i];
                // const percentTrade = 100.0*ti.spendAmtFloat/ti.spendTokenTotalBeforeFloat
                const pnlPrevious = pnlHistory[pnlHistory.length-1];
                pnlState = {'time': ti.time, 
                            'price': ti.price,
                            'type': ti.type, 
                            'tradeRatio': ti.tradeRatio};
                if (ti.type === 'sell') {
                    pnlState['amountSecondary'] = pnlPrevious.amountSecondary*(1.0 - ti.tradeRatio);
                    pnlState['amountPrimary'] = pnlPrevious.amountPrimary + 
                                                    (ti.price*pnlPrevious.amountSecondary*ti.tradeRatio);   
                } else {
                    pnlState['amountSecondary'] = pnlPrevious.amountSecondary + 
                                                    (pnlPrevious.amountPrimary*ti.tradeRatio)/ti.price;
                    pnlState['amountPrimary'] = pnlPrevious.amountPrimary*(1.0 - ti.tradeRatio);
                }
                pnlState['value'] = pnlState['amountPrimary'] + pnlState['amountSecondary']*ti.price;
                pnlState['pnl'] = pnlState.value/pnlPrevious.value;
                pnlState['pnlTotal'] = pnlState.value/pnlHistory[0].value;
                
                pnlHistory.push(pnlState);
            }
            //add one more for the current value...
            if (lastDisplayEpoch >= new Date().getTime()/1000.0) {
                
            }
            const currentTime = new Date();
            const tackedOnTime = lastDisplayTime >= currentTime ? currentTime : lastDisplayTime;
            const pnlPrevious = pnlHistory[pnlHistory.length-1];
            pnlState = {'time': tackedOnTime,
                        'price': priceHistory.close[priceHistory.close.length-1],
                        'amountPrimary': pnlPrevious.amountPrimary,
                        'amountSecondary': pnlPrevious.amountSecondary}

            pnlState['value'] = pnlPrevious.amountPrimary + pnlPrevious.amountSecondary*pnlState.price;
            pnlState['pnl'] = pnlState.value/pnlPrevious.value;
            pnlState['pnlTotal'] = pnlState.value/pnlHistory[0].value;
            pnlHistory.push(pnlState);
        }

        trace = {x: priceHistory.times, y: priceHistory.close, 
                    xaxis:'x1', yaxis:'y1',
                    legendgroup: 'main',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'#ffffff'}, //{color:'#6666ff'}, 
                    name: 'price'}; 
        traces.push(trace);



        const pnlTimes = pnlHistory.map(d => d.time);
        const pnlValues = pnlHistory.map(d => d.value);

        const totalGainRatio = pnlValues.length > 1 ? pnlValues[pnlValues.length-1]/pnlValues[0] : 1.0;
        const totalGainRatioVsSecondary = pnlValues.length > 1 ? pnlValues[pnlValues.length-1]/priceHistory.close[priceHistory.close.length-1] : 1.0;
        const totalTime = pnlTimes.length > 1 ? ((new Date()).getTime() - pnlTimes[0].getTime())/1000.0 : 0.0;
        const totalGainPercent = 100.0*(totalGainRatio - 1.0);
        const totalGainPercentVsSecondary = 100.0*(totalGainRatioVsSecondary - 1.0);
        const apr = 100.0*(Math.pow(totalGainRatio, 365.0*24.0*60.0*60.0/totalTime) - 1.0);
        const aprVsSecondary = 100.0*(Math.pow(totalGainRatioVsSecondary, 365.0*24.0*60.0*60.0/totalTime) - 1.0);


        
        if (showBuysAndSells) {
            trace = {x: pnlTimes, y: pnlValues,
                xaxis:'x1', yaxis:'y1',
                legendgroup: 'main',
                type: 'scatter',
                mode: 'lines',
                marker: {color:'yellow'},
                name: 'performance'};
            traces.push(trace);

            if (pnlTimes.length > 0) {
                trace = {x: [pnlTimes[0]], y: [pnlValues[0]],
                                xaxis:'x1', yaxis:'y1',
                                legendgroup: 'main',
                                type: 'scatter',
                                mode: 'markers',
                                marker: {color:theme.pink, size: 10},
                                name: 'initial deposit'};
                traces.push(trace);
            }
        
        // console.log("createTraces, showBuysAndSells: ", showBuysAndSells);
        
            traces.push(buytrace);
            traces.push(selltrace);
        }
        
        

        var tracesExtra = [];
        for (var i=0; i<strategyIDs.length; i++) {
            var id = strategyIDs[i];
            var theseTraces = strategyData[id].traces;
            for (var j=0; j<theseTraces.length; j++) {
                tracesExtra.push(theseTraces[j]);
            }
        }

        
        return {tradeInfoList: tradeInfoList,
                pnlHistory: pnlHistory,
                'traces': traces, 
                'tracesExtra': tracesExtra,
                'totalGainPercent': totalGainPercent, 
                'totalGainPercentVsSecondary': totalGainPercentVsSecondary,
                'apr': apr, 
                'aprVsSecondary': aprVsSecondary,
                'totalTimeSeconds': totalTime};     
    }
    function ChainlinkPriceForm() {
        return(
            <VertDiv 
                padding='4px'
                margin='0px'
                gap='2px'
                borderStyle='solid'
                borderColor={theme.light}
                justifyContent='center'
                alignItems='center'
            >
                <Text fontSize={theme.fontSizeMedium} color='white' margin="0px" padding='0px'>Oracle Price</Text>
                <Text fontSize={theme.fontSizeMedium} color={chainlinkPriceColor} margin="0px" padding='0px'>{parseFloat(chainlinkPrice).toFixed(4)}</Text>
            </VertDiv>
        )
    }
    async function selectGraphTimeframe(timeframe) {
        setGraphTimeframe(timeframe);
        await fetchChartInfo(true, timeframe);
    }
    function ChartForm(type='large') {
        // console.log('ChartForm pnlHistory:', pnlHistory);
        const [graphXRange, setGraphXRange] = useState({min: 0, max: 0});
        return(
            <VertDiv alignItems={"center"} backgroundColor={theme.black} 
                     margin={theme.margin0} gap={theme.gap1} padding={theme.padding1} >
                
                <HorzDiv 
                    margin='0px' 
                    padding='0px' 
                    alignItems='space-evenly' 
                    justifyContent='center'
                    width='100%' 
                    borderStyle='none'
                >
                    <HorzDiv 
                        gap = {'4px'}
                        margin='0px'
                        padding='4px'
                        alignItems='center'
                        justifyContent='center'
                        borderColor={theme.light}
                    >
                        <select 
                            onChange={event => selectGraphTimeframe(event.target.value)} 
                            style={{width: '60px'}}
                        >
                            {timeFrameOptions.map(item => 
                                <option 
                                    value={item} 
                                    selected={item===graphTimeframe ? true : false}>{item}
                                </option>)
                            }
                        </select>
                        <Button
                            variant='contained'
                            sx={{p:1, m:0}}
                            size='small'
                            style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                            onClick={() => {setShowBuysAndSells(!showBuysAndSells)}}
                        >
                            {showBuysAndSells ? 'Hide' : 'Show'} Trades
                        </Button>
                        <Button
                            variant='contained'
                            sx={{p:1, m:0}}
                            size='small'
                            style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                            onClick={() => {fetchChartInfo(true)}}
                        >
                            {refreshChartText}
                        </Button>
                    </HorzDiv>
                    
                    <ChainlinkPriceForm />
                </HorzDiv>
                
                <VertDiv
                    borderStyle='none'
                    margin='0px'
                    padding='0px'
                    gap={theme.gap1}
                >
                    {Graph(plotData, type, 100, 40, setGraphXRange)}
                    {chainlinkPrice &&
                        <IndicatorGroup
                            priceHistory={priceHistory}
                            chainlinkPrice={chainlinkPrice}
                            strategyIDsIn={strategyIDs}
                            strategyData={strategyData}
                            updateStrategyData={updateStrategyData}
                            updateAllStrategyData={handleStrategyUpdate}
                            vaultObj={vault}
                            toggleAutotradeFunctionHandle={toggleAutotrade}
                            tokenSecondaryIn={tokenSecondary}
                            graphTimeframe={graphTimeframe}
                            setGraphTimeframe={setGraphTimeframe}
                            graphXRange={graphXRange}
                        />
                    }
                </VertDiv>
            </VertDiv>            
        )
    }

    function Graph(plotDataIn, type='large', wid=100, hei=40, setXRangeIn=null) {
        const [layout, setLayout] = useState({});
        const [xRange, setXRange] = useState({min: "1970-10-15 0:0:0.0", 
                                              max: "2050-10-15 20:10:25.599"});
        const aspectRatio = 2.0;
        const numPlotRows = 1;
        const maxWidth = 1200;
        const maxHeight = Math.floor(maxWidth/aspectRatio);
        const plotWidth = Math.min(windowDimensions.width - 50, maxWidth);
        const plotHeight = Math.min(Math.floor(plotWidth/aspectRatio) + 100*(numPlotRows-1) + 150, maxHeight);
        const titleXPos = plotWidth > 450 ? 0.5 : 0.6;
                // const titleYPos = 1.0 - 0.01*plotHeight;
        const titleYPos = 1.1 + 0.01*plotHeight ;
        const legendXPos = 0.0;
        const legendYPos = 1.0 + 0.01*plotHeight - .05;
        const titleFontSize = 14;

        var tgp = 0.0;
        var apr = 0.0;
        if (plotDataIn && plotDataIn.totalGainPercent){
            tgp = plotDataIn.totalGainPercent;
            apr = plotDataIn.apr;
        }

        var titleText;
        if (isDefined(vault) && isDefined(vault.name)) {
            titleText = 'Trade History:<br>'+vault.name+'<br>'+
                        'Gain: '+tgp.toFixed(2)+'%'+'<br>'+
                        'APR: '+apr.toFixed(2)+'%'
        } else {
            titleText = 'Trade History:<br>'+
                        'Gain: '+tgp.toFixed(2)+'%'+'<br>'+
                        'APR: '+apr.toFixed(2)+'%'
        }
        const getLayout = () => {
            var pltLayout;
            if (type === 'large') {
                var tPrimeSym;
                var tSecSym;
                if (isDefined(tokenPrimary) && isDefined(tokenPrimary.symbol)) {
                    tPrimeSym = tokenPrimary.symbol;
                } else {
                    tPrimeSym = '';
                }
                if (isDefined(tokenSecondary) && isDefined(tokenSecondary.symbol)) {
                    tSecSym = tokenSecondary.symbol;
                } else {
                    tSecSym = '';
                }
                
                pltLayout = {
                                dragmode: 'pan',
                                // autosize: false,
                                width: plotWidth, height: plotHeight,
                                plot_bgcolor: theme.dark,
                                paper_bgcolor: theme.black,
                                font: {color: theme.white},
                                xaxis: {title: 'Date', 
                                        showgrid: true,
                                        gridcolor: theme.light},
                                yaxis: {title: tSecSym+' Price ('+tPrimeSym+')', 
                                        side: 'right', 
                                        showgrid: true,
                                        gridcolor: theme.light,
                                        zeroline: false, 
                                        autotick: true},
                                // title: {text: titleText,
                                //         font: {size: titleFontSize}, 
                                //         x: titleXPos, y: titleYPos, 
                                //         xanchor: 'center', yanchor: 'top'},
                                margin: {l: 10, r: 50, b: 50, t: 30},
                                showlegend: false
                                // legend: {x: legendXPos, 
                                //          y: legendYPos, 
                                //          orientation: 'v', 
                                //          font: {size: 11}}
                            };
            } else {
                // plotTraces = plotDataIn.traces;
                pltLayout = {
                                dragmode: 'pan',
                                autosize: false,
                                width: wid,
                                height: hei,
                                margin: {l: 0, r: 0, b: 0, t: 0, pad: 5},
                                plot_bgcolor: theme.dark,
                                paper_bgcolor: theme.black,
                                font: {color: theme.white},
                                'xaxis':  {'visible': false,
                                            showGrid: false,
                                            zeroline: false,
                                            visible: false},
                                'yaxis': {'visible': false,
                                            showGrid: false,
                                            zeroline: false,
                                            visible: false},
                                'showlegend': false,    
                            };   
            }
            return pltLayout;
        };
        const handleRelayout = (eventData) => {
            // Check if the range of x-axis has changed
            if (eventData["xaxis.range[0]"] && eventData["xaxis.range[1]"]) {
                const minX = eventData["xaxis.range[0]"];
                const maxX = eventData["xaxis.range[1]"];
    
                // console.log("Visible range of x-axis:", minX, maxX);
                setXRange({min: minX, max: maxX});
                // Use these values as needed, e.g., pass them to parent or other components
            }
        }
        useEffect(() => {
            setLayout(getLayout());
        }, []);
        
        useEffect(() => {
            //// Only update layout attributes that need to change, not 'xaxis' or 'yaxis'.
            // setLayout(prevLayout => ({...prevLayout, title: `Updated at ${new Date()}`}));
            
            var tgp = 0.0;
            var apr = 0.0;
            var tgp2 = 0.0;
            var apr2 = 0.0;
            if (plotDataIn && plotDataIn.totalGainPercent){
                tgp = plotDataIn.totalGainPercent;
                apr = plotDataIn.apr;
                tgp2 = plotDataIn.totalGainPercentVsSecondary;
                apr2 = plotDataIn.aprVsSecondary;
            }
            var titleText;
            if (isDefined(vault) && isDefined(vault.name)) {
                titleText = 'Trade History:<br>'+vault.name+'<br>'+
                            'Gain: '+tgp.toFixed(2)+'%'+' ('+tgp2.toFixed(2)+'%)<br>'+
                            'APR: '+apr.toFixed(2)+'%'+' ('+apr2.toFixed(2)+'%)'
            } else {
                titleText = 'Trade History:<br>'+
                            'Gain: '+tgp.toFixed(2)+'%'+'<br>'+
                            'APR: '+apr.toFixed(2)+'%'
            }

            var tPrimeSym;
            var tSecSym;
            if (isDefined(tokenPrimary) && isDefined(tokenPrimary.symbol)) {
                tPrimeSym = tokenPrimary.symbol;
            } else {
                tPrimeSym = '';
            }
            if (isDefined(tokenSecondary) && isDefined(tokenSecondary.symbol)) {
                tSecSym = tokenSecondary.symbol;
            } else {
                tSecSym = '';
            }
            setLayout(prevLayout => 
                        {
                            var title = prevLayout.title;
                            if (isDefined(title) && isDefined(title.text)) {
                                title.text = titleText;
                            }
                            
                            var yAxis = prevLayout.yaxis;
                            if (isDefined(yAxis.title)) {
                                yAxis.title = tSecSym+' Price ('+tPrimeSym+')';
                            }
                            return {...prevLayout, 
                                            // title: {text: titleText, font: {size: titleFontSize}, x: titleXPos, y: titleYPos, xanchor: 'center', yanchor: 'top'},
                                            title: title,
                                            yaxis: yAxis
                                    };
                        }
            );
        }, [plotDataIn]);

        useEffect(() => {
            if (type === 'large') {
                // console.log('EV plotting', windowDimensions);
                // const maxHeightNew = Math.floor(maxWidth/aspectRatio);
                const plotWidth = Math.min(windowDimensions.width - 70, maxWidth);
                const plotHeight = Math.min(Math.floor(plotWidth/aspectRatio) + 100*(numPlotRows-1) + 150, maxHeight);
                const titleXPos = plotWidth > 450 ? 0.5 : 0.6;
                        // const titleYPos = 1.0 - 0.01*plotHeight;
                const titleYPos = 1.1 + 0.01*plotHeight ;
                const legendXPos = 0.0;
                const legendYPos = 1.0 + 0.01*plotHeight - .05;
    
                setLayout(prevLayout =>
                    {
                        var prevTitle = prevLayout.title;
                        if (isDefined(prevTitle) && isDefined(prevTitle.x) && isDefined(prevTitle.y)) {
                            prevTitle.x = titleXPos;
                            prevTitle.y = titleYPos;
                        }
    
                        var prevLegend = prevLayout.legend;
                        if (isDefined(prevLegend) && isDefined(prevLegend.x) && isDefined(prevLegend.y)) {
                            prevLegend.x = legendXPos;
                            prevLegend.y = legendYPos;
                        }
    
                        return {...prevLayout,
                                width: plotWidth, 
                                height: plotHeight
                                // title: prevTitle,
                                // legend: prevLegend
                                };
                    }
                );
            }

        }, [windowDimensions]);

        useEffect(() => {
            if (isDefined(setXRangeIn)) {
                setXRangeIn(xRange);
            }
        }, [xRange]);

        if (!isDefined(plotDataIn) || !isDefined(plotDataIn.traces) || !isDefined(plotDataIn.tracesExtra)) {
            return(<>Fetching Vault History...</>)
        }
        // const candleTrace = {
        //     x
        // }
        const plotTraces = type === 'large' ? plotDataIn.traces.concat(plotDataIn.tracesExtra) : plotDataIn.traces;
        const config = {
            responsive: false, //true seems to cause rendering problems sometimes
            scrollZoom: true,
            displayModeBar: type === 'large',
        };

        const LegendItem = ({ trace }) => {
            let symbolElement;
            
            if (trace.mode === 'lines') {
                const clr = trace.line ? trace.line.color : trace.marker.color;
                const wd = trace.line ? trace.line.width : 2;
            
                const symbolStyle = {
                    width: '30px',
                    height: `${wd}px`,
                    borderBottom: `2px solid ${clr}`,
                };
            
                if (trace.line && trace.line.dash === 'dot') {
                    symbolStyle.backgroundImage = `repeating-linear-gradient(to right, ${clr}, ${clr} 4px, transparent 4px, transparent 6px)`;
                    symbolStyle.height = '2px';
                }
            
                symbolElement = <div style={{ ...symbolStyle }}></div>;
            } else if (trace.mode === 'markers') {
                const sz = trace.marker.size ? trace.marker.size : 6;
                const clr = trace.marker.color;
            
                if (trace.marker.symbol === 'triangle-up' ) {
                    symbolElement = <TriangleUpMarker color={clr} size={sz} />;
                } else if (trace.marker.symbol === 'triangle-down') {
                    symbolElement = <TriangleDownMarker color={clr} size={sz} />;
                } else {
                    // Handle other markers (e.g., circles)
                    const symbolStyle = {
                        backgroundColor: clr,
                        height: `${sz}px`,
                        width: `${sz}px`,
                        borderRadius: '50%',
                    };
                    symbolElement = <div style={{ ...symbolStyle }}></div>;
                }
            }
            
            return (
                <div style={{ display: 'grid', gridTemplateColumns: '30px auto', alignItems: 'center', gap: '5px', marginBottom: '0px' }}>
                    {symbolElement}
                    <span style={{ textAlign: 'left', fontSize: 12 }}>{trace.name}</span>
                </div>
            );
        };

        function GraphHeader() {
            var backtestGain = 0.0;
            var backtestAPR = 0.0;
            var tracesLeft = [];
            var tracesStrategies = [];
            var tracesBacktest = [];
            for (var i=0; i<plotTraces.length; i++) {
                var trace = plotTraces[i];
                if (trace.legendgroup === 'main') {
                    tracesLeft.push(trace);
                } else if (trace.legendgroup === 'backtest') {
                    tracesBacktest.push(trace);
                } else {
                    tracesStrategies.push(trace);
                }

                if (trace.name === 'backtest performance' && trace.y.length > 0) {
                    const startTime = trace.x[0].getTime();
                    const endTime = trace.x[trace.x.length-1].getTime();
                    const totalTime = (endTime - startTime)/1000.0;
                    const startVal = trace.y[0];
                    const endVal = trace.y[trace.y.length-1];
                    backtestGain = 100.0*(endVal/startVal - 1.0);
                    backtestAPR = 100.0*(Math.pow(endVal/startVal, 365.0*24.0*60.0*60.0/totalTime) - 1.0);
                }
            }
            const twoCols = tracesStrategies.length > 0 || tracesBacktest.length > 0;
            const just = twoCols ? 'space-between' : 'center';
            return(
                <div style={{ 
                              //position: 'absolute', top: '30px', left: 0, right: 0, zIndex: 10, 
                              display: 'flex', 
                              justifyContent: just, 
                              alignItems: 'center' }}>
                    <HorzDiv padding='0px' borderStyle='none'>
                        <VertDiv 
                            borderColor={theme.light}
                            padding='5px' 
                            margin='0px'
                            gap='2px'
                        >
                            
                            <div>
                                {tracesLeft.map((trace, i) => 
                                    <LegendItem key={i} trace={trace} />)
                                }
                            </div>
                        </VertDiv>
                        <VertDiv borderColor={theme.light}>
                            <div style={{ fontWeight: 'bold', size: titleFontSize }}>
                                <div>
                                    Trade Performance
                                </div>
                                <div>
                                    {'Gain: '+tgp.toFixed(2)+'%'}
                                </div>
                                <div>
                                    {'APR: '+apr.toFixed(2)+'%'}
                                </div>
                            </div>
                        </VertDiv>
                        
                    </HorzDiv>
                    
                    <HorzDiv borderStyle='none' padding='0px' margin='0px'>
                        {tracesStrategies.length > 0 && 
                            <VertDiv 
                                borderColor={theme.light} 
                                padding='5px' 
                                margin='0px'
                                gap='2px'
                            >
                                Strategies
                                <div>
                                    {tracesStrategies.map((trace, i) => 
                                        <LegendItem key={i} trace={trace} />)
                                    }
                                </div>
                            </VertDiv>
                        }
                        {tracesBacktest.length > 0 &&
                            <HorzDiv borderStyle='none' padding='0px'>
                                <VertDiv 
                                    borderColor={theme.light}
                                    padding='5px' 
                                    margin='0px'
                                    gap='2px'
                                >
                                    
                                    <div>
                                        {tracesBacktest.map((trace, i) => 
                                            <LegendItem key={i} trace={trace} />)
                                        }
                                    </div>
                                </VertDiv>
                                <VertDiv borderColor={theme.light}>
                                <div style={{ fontWeight: 'bold', size: titleFontSize }}>
                                    <div>
                                        Backtest
                                    </div>
                                    <div>
                                        {'Gain: '+backtestGain.toFixed(2)+'%'}
                                    </div>
                                    <div>
                                        {'APR: '+backtestAPR.toFixed(2)+'%'}
                                    </div>
                                </div>
                                    
                                </VertDiv>
                            </HorzDiv>   
                        }
                        
                            
                    </HorzDiv>
                    
                </div>
            );
        }

        return(
            <div style={{position: 'relative'}}>
                {type === 'large' && <GraphHeader />}
                <Plot
                    id='plotID'
                    data={plotTraces}
                    layout={layout}
                    config={config}
                    useResizeHandler={true}
                    onRelayout={handleRelayout}
                />
                
            </div>

        )
    }
    async function executeTrade() {
        setTxPending(true);
        var tx;
        try {
            tx = await trade.execute();
            tx.wait()
            setTxPending(false);
            setTxSuccess(true);
            setLastTxHash(tx.hash);
            console.log('executeTrade: ' + tx.hash);
            var tempTxList = dummyTxList;
            tempTxList.push(tx);
            setDummyTxList(tempTxList);
            updateInfo();
            setSliderFunction(0, true);
            
        } catch (e) {
            console.log('executeTrade: ' + e);
            var errorDefined=false;

            let finalMessage = '';
            const reason = e.reason !== undefined ? e.reason : '';
            if (reason !== '')
                finalMessage = reason + '\n';
            const message = e.message !== undefined ? e.message : '';
            if (message !== '')
                finalMessage = finalMessage + message + '\n';
            const dataMessage = e.data !== undefined ? e.data.message !== undefined ? e.data.message : '' : '';
            if (dataMessage !== '')
                finalMessage = finalMessage + dataMessage + '\n';

            setErrorMessage(finalMessage);
            errorDefined = finalMessage !== '';

            
            if (errorDefined) {
                setErrorDialogOpen(true);
            }
            setTxPending(false);
            setTxSuccess(false);
        }

        return tx;
    }
    function TradeForm() {
        const [swapDivDirection, setSwapDivDirection] = useState('row');
        const [buttonColor, setButtonColor] = useState("rgb(255,100,100)");
        const [newPercentFrom, setNewPercentFrom] = useState(0);
        const [newPercentTo, setNewPercentTo] = useState(0);

        const mainAxis = 'column';

        useEffect(() => {
            if (windowDimensions.width < 433) {
                setSwapDivDirection('column');
            } else {
                setSwapDivDirection('row');
            }
        }, [windowDimensions]);

        useEffect(() => {
            if (isDefined(trade) && isDefined(trade.tokenFromNum) && trade.tokenFromNum === trade.primaryTokenNum) {
                setButtonColor("rgb(100,255,100)")
            } else {
                setButtonColor("rgb(255,100,100)");
            }

        }, [trade]);

        useEffect(() => {
            if (chainlinkPrice !== null && trade !== null && trade.tokenFromNum !== undefined) {
                try {
                    var amtFromInVault = 0;
                    var amtToInVault = 0;
                    if (trade.tokenFromNum === 0){
                        amtFromInVault = vault.token0.toFloat(vault.balance0);
                        amtToInVault = vault.token1.toFloat(vault.balance1);
                    } else {
                        amtFromInVault = vault.token1.toFloat(vault.balance1);
                        amtToInVault = vault.token0.toFloat(vault.balance0);
                    }
                    
                    // const percentFrom = sliderSwapValue;
                    const amtFrom = trade.amtOutFloat;
                    const amtTo = trade.amtInExpectedFloat;
    
                    const amtFromNew = amtFromInVault - amtFrom;
                    const amtToNew = amtToInVault + amtTo;
    
                    var valFrom = 0;
                    var valTo = 0;
                    if (tokenPrimaryNum === trade.tokenFromNum) {
                        valFrom = amtFromNew;
                        valTo = amtToNew*chainlinkPrice;
                    } else {
                        valFrom = amtFromNew*chainlinkPrice;
                        valTo = amtToNew;
                    }
    
                    const totalVal = valFrom + valTo;
                    const newPercentFrom = 100.0*valFrom/totalVal;
                    const newPercentTo = 100.0*valTo/totalVal;
                    setNewPercentFrom(newPercentFrom);
                    setNewPercentTo(newPercentTo);
                } catch (e) {
                    console.log('TradeForm useEffect: ' + e);
                }
            }
        }, [sliderSwapValue, chainlinkPrice, trade]);

        if (!tradeLoadedFirstTime || 
            walletAddress === null || 
            isUndefined(vault) || 
            isUndefined(trade)) {
            return(<></>)
        } else {
            return(
                <HorzDiv flexDirection={mainAxis} alignItems={"stretch"} backgroundColor={theme.black} 
                        margin={theme.margin0} gap={theme.gap1} padding={theme.padding2}>
                    <Button onClick={() => {setShowTradeDiv(!showTradeDiv)}}>
                        {showTradeDiv ? "Hide Manual Trade" : "Show Manual Trade"}
                    </Button>
                    {showTradeDiv &&
                        FadeInAnimation(
                            <VertDiv alignItems='stretch' borderStyle='none' margin='0px' gap={theme.gap1} padding={theme.padding0}>
                            <VertDiv alignItems={"center"} justifyContent="center" 
                                    backgroundColor={theme.dark} borderColor={theme.light} margin="0px" gap='5px'>
                                <InputWithLabel 
                                    label="Slippage (%):"
                                    min={0.01}
                                    max={10}
                                    step={0.01}
                                    precision={2}
                                    defaultValue={slippage}
                                    width="7rem"
                                    onBlur={event=>setSlippageFunction(event.target.value)}
                                    borderColor={theme.pink}
                                    gap={theme.gap1}
                                />
                            </VertDiv>
                            <VertDiv backgroundColor={theme.dark} borderColor={theme.light} margin="0px" gap='5px'>
                                <HorzDiv borderStyle='none' margin="0px" padding='0px 30px 4px 30px' >
                                    <Text fontSize={'1rem'} color={theme.white} margin="0px" padding='0px'>Swap Amount:</Text>
                                    <Label>{sliderSwapValue + '%'}</Label>
                                </HorzDiv>
                                <MuiBox sx={{ width: 200 }}>
                                    <Slider
                                        aria-label="Percentage"
                                        defaultValue={0}
                                        getAriaValueText={setSliderFunction}
                                        valueLabelDisplay="auto"
                                        step={5}
                                        marks
                                        min={0}
                                        max={100}
                                        color="secondary"
                                    />
                                </MuiBox>
                                <HorzDiv justifyContent="center" alignItems='center' 
                                        margin="0px" padding='0px' gap='2px' flexDirection={swapDivDirection}
                                        borderColor={theme.light} borderStyle='none'>
                                    <VertDiv margin='0px' borderColor={theme.light} alignItems='center' gap='2px'>
                                        {trade.tokenFrom !== undefined && tokenWithIcon(trade.tokenFrom.symbol)}
                                        <Text
                                            fontSize={theme.fontSizeSmall}
                                            color='white'
                                            padding='0px'
                                            margin='5px 0px 0px 0px'   
                                        >
                                            Spend Amt
                                        </Text>
                                        <Text 
                                            fontSize={theme.fontSizeLarge}
                                            color='white'
                                            padding='0px'
                                            margin='0px'
                                        >
                                            {trade.amtOutFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenFrom.symbol))}
                                        </Text>
                                        <Text
                                            fontSize={theme.fontSizeSmall}
                                            color='white'
                                            padding='0px'
                                            margin='5px 0px 0px 0px'   
                                        >
                                            {"(new %: "+newPercentFrom.toFixed(2).toString()+")"}
                                        </Text>
                                    </VertDiv>
                                    <Button
                                        sx={{marginLeft:'5px', m:0, width: '20px', height: '30px'}}
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                        style={{'borderRadius':'30px', 'fontWeight':'bold', 'fontSize': '0.6em'}}
                                        endIcon={<ArrowRightIcon 
                                                    // bg="rgb(247, 248, 250)"
                                                    color={buttonColor}
                                                    h="1.2rem" width="1.2rem"
                                                />}
                                        onClick={() => {swapFromToken()}}    
                                    />  
                                    <VertDiv margin='0px' borderColor={theme.light} alignItems='center' gap='0px'>
                                        {trade.tokenTo !== undefined && tokenWithIcon(trade.tokenTo.symbol)}
                                        <Text
                                            fontSize={theme.fontSizeSmall}
                                            color='white'
                                            padding='0px'
                                            margin='5px 0px 0px 0px'   
                                        >
                                            Receive Amt
                                        </Text>
                                        <Text 
                                            fontSize={theme.fontSizeLarge}
                                            color='white'
                                            padding='0px'
                                            margin='0px'
                                        >
                                            {trade.amtInExpectedFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenTo.symbol))}
                                        </Text>
                                        <Text
                                            fontSize={theme.fontSizeSmall}
                                            color='white'
                                            padding='0px'
                                            margin='5px 0px 0px 0px'   
                                        >
                                            {"(new %: "+newPercentTo.toFixed(2).toString()+")"}
                                        </Text>
                                    </VertDiv>
                                </HorzDiv>
                            </VertDiv>
                            <VertDiv borderColor={theme.light} backgroundColor={theme.dark} 
                                    margin={theme.margin0} padding={theme.padding1} gap={'5px'}>
                                <HorzDiv margin='0px' padding='0px' borderStyle='none'>
                                    <Label>Price:</Label>
                                    <Button
                                        sx={{p:1, m:0}}
                                        variant='outlined'
                                        // color='primary'
                                        size='small'
                                        style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                                        onClick={() => {setPriceDisplayMode((priceDisplayMode + 1) % 2)}}
                                    >
                                        {priceDisplay()}
                                    </Button>
                                </HorzDiv>

                                <Divider flexItem={true} sx={{ bgcolor: "white", m:'0px', p:'0px' }} />
                                <Text fontSize={theme.fontSizeMedium} color='white' m='0px' p='0px'>Uniswap V3: {trade.fullPathList}</Text>
                                <Text fontSize={theme.fontSizeMedium} color='white' m='0px' p='0px'>Price Impact: {trade.priceImpact.toFixed(5)}%</Text>
                            
                                <Text fontSize={theme.fontSizeMedium} color='white' m='0px' p='0px'>Expected Received: {trade.amtInExpectedFloat.toFixed(5)}</Text>
                                <Text fontSize={theme.fontSizeMedium} color='white' m='0px' p='0px'>Min Received (after slippage): {trade.amtInMinFloat.toFixed(5)}</Text>
                                
                            </VertDiv>
                            <ThemeProvider theme={muiTheme}>
   
                                <ActionDialog 
                                    buttonVariant='contained'
                                    buttonColor='secondary'
                                    buttonSX={theme.buttonSX}
                                    buttonStyle={theme.buttonStyle}
                                    handleAcceptIn={() => executeTrade()}
                                    buttonInnards={userCanTrade ? ("Swap") : "Swap (Only Vault Manager)"}
                                    titleString={"Swap?"}
                                    setDialogOpenFlag={setDialogOpenBool}
                                    disabled={trade.amtOut.eq(BN(0)) || !userCanTrade}
                                    contentString={trade.amtOutFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenFrom.symbol)) + " " + trade.tokenFrom.symbol + " --> " + trade.amtInExpectedFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenTo.symbol)) + " " + trade.tokenTo.symbol + "?"}
                                />
                            </ThemeProvider>
                            </VertDiv>
                        )
                    }
                </HorzDiv>
            );
        }

    }
    async function fetchChartInfo(fetchNewInfo=false, timeFrame=null) {
        var timeFrameToUse = timeFrame === null ? graphTimeframe : timeFrame;
        // var endTime = new Date();
        // endTime = endTime.setDate(endTime.getDate() + 1)
        // var priceStartDate = new Date((bigNumToFloat(vault.creationTime, 0) - 60*60*24)*1000);
        // const priceStartDate = new Date((bigNumToFloat(vault.creationTime, 0) - 60*60*24*daysPrefetch)*1000);
        const ourEarliestDate = 1502668800;
        const priceStartDate = new Date((ourEarliestDate)*1000);
        var newPriceData = await priceFetcher.fetchHistory(tokenSecondary.symbol, 
                                                           tokenPrimary.symbol, 
                                                           priceStartDate, 
                                                           new Date(), 
                                                           timeFrameToUse);
        setPriceHistoryTest(newPriceData);

        const vaultTemp = await vault.fetchHistory(devMode, networkDict.chainID, fetchNewInfo);
        setVault(vaultTemp);
        const vmTemp = vaultTemp.managerObj;
        var plotData = {};
        if (vault && vmTemp.history.length > 0 && newPriceData.times && newPriceData.times.length > 0){
            console.log('creating chart traces:', vmTemp, newPriceData);
            plotData = createChartTraces(vmTemp.history, newPriceData);
            if (vaultGainPercent !== plotData.totalGainPercent) {
                setVaultGainPercent(plotData.totalGainPercent);
            }
            if (vaultAPR !== plotData.apr) {
                setVaultAPR(plotData.apr);
            }
            if (vaultTimeSeconds !== plotData.totalTimeSeconds) {
                setVaultTimeSeconds(plotData.totalTimeSeconds);
            }
        }

        if (plotData.traces !== undefined && plotData.traces.length > 0) {
            setPlotData(plotData);
            setTradeList(plotData.tradeInfoList);
        }
        
        console.log('EXITING fetching chart info...');
    }
    function ChartAndTradeDiv() {
        var flexDir = showTradeDiv ? 'row' : 'column';
        return(
            <HorzDiv 
                alignItems='center' margin='0px' gap={theme.gap1} padding='0px' borderStyle='none' 
                flexDirection={flexDir}
            >
                {FadeInAnimation(ChartForm())}
                {/* {walletAddress !== null && FadeInAnimation(TradeForm())}*/}
                {FadeInAnimation(TradeForm())}
            </HorzDiv>
        )
    }
    async function setVaultStrategy() {
        if (vault && stratStringEnc !== '') {
            setStatusText('Setting strategy...');
            setTxPending(true);
            var tx;
            try {
                tx = await vault.setStrategy(stratStringEnc);
                tx.wait()
                setTxPending(false);
                setTxSuccess(true);
                setLastTxHash(tx.hash);
                setStatusText('Tx complete.')
                console.log('tx:', tx);
                updateInfo();
            } catch (e) {
                console.log('executeTrade: ' + e);
                setTxPending(false);
                setTxSuccess(false);
            }
            
        }
    }
    async function toggleAutotrade() {
        console.log('toggleAutoTrade');
        const autoTradeActiveBefore = vault.autotradeActive;
        var tx;
        if (vault) {
            setStatusText('Toggling autoTrade...');
            setStatusText('Setting strategy...');
            setTxPending(true);
            try {
                tx = await vault.toggleAutotrade();
                tx.wait();
                setTxPending(false);
                setTxSuccess(true);
                setLastTxHash(tx.hash);
                updateInfo();
                setStatusText('Tx complete.')
                setAutotradeActive(vault.autotradeActive);
                console.log('tx:', tx);
                if (autoTradeActiveBefore === vault.autotradeActive) {
                    console.log('toggle autotrade failed.')
                }
            } catch (e) {
                console.log('executeTrade: ' + e);
                setTxPending(false);
                setTxSuccess(false);
            }

        }
        return tx;
    }
    async function depositGasStation() {
        console.log('depositGasStation');
        if (vault && gasStationDepositAmount > 0) {
            setStatusText('Depositing...');
            const tx = await vault.depositGasStation(humanToBigNum(gasStationDepositAmount, 18));
            updateInfo();
            setStatusText('Deposit into Gas Station Complete.')
            console.log('tx:', tx);
        }
    }
    async function withdrawGasStation() {
        console.log('withdrawGasStation');
        const gb = await vault.getGasStationBalance();
        if (vault && gb > 0) {
            setStatusText('Withdrawing...');
            const tx = await vault.withdrawGasStation(gb);
            updateInfo();
            setStatusText('Withdraw from Gas Station Complete.')
            console.log('tx:', tx);
        }
    }
    function GasStationForm() {
        return(
            <VertDiv>
                <Text>Gas Station</Text>
                <Text>balance: {bigNumToHuman(vault.gasStationBalance, 18, 6)}</Text>
                <HorzDiv>
                    <VertDiv>
                        <Text>Deposit</Text>
                        <Input
                            type='number'
                            value={gasStationDepositAmount}
                            onChange={(e) => setGasStationDepositAmount(e.target.value)}
                        />
                        <Button 
                            variant="contained"
                            onClick={() => depositGasStation()}
                        >
                            Deposit into Gas Station
                        </Button>
                    </VertDiv>
                    <VertDiv>
                        <Text>Withdraw</Text>
                        <Button 
                            variant="contained"
                            onClick={() => withdrawGasStation()}
                        >
                            Withdraw from Gas Station
                        </Button>
                    </VertDiv>
                </HorzDiv>
            </VertDiv>
        )
    }
    async function loadCurrentStrategy() {
        console.log('loadCurrentStrategy');
        if (vault && vault.strategy && vault.strategy !== '') {
            setTxPending(true);
            try {
                const signedMessage = await signer.signMessage(vault.address);
                setTxPending(false);
                setTxSuccess(true);

                //first check
                const singerAddress = ethers.utils.verifyMessage(vault.address, signedMessage);
                console.log('SIGN:', vault.address, signedMessage, singerAddress, vault.operator, vault.operator === singerAddress);
                if (singerAddress === vault.operator) {
                    //verify on the backend, send decrypted strategy back
                    // var temp = getBaseURL()
                    var temp = baseURL;
                    if (temp.includes('localhost')) {
                        temp = temp+':8000';
                    }
                    const url = temp+'/api/verify/' + devMode.toString() + '/' + 
                                networkDict.chainID.toString() + '/' + 
                                vault.address + '/' + signedMessage;
                    console.log('DEC url:', url);
                    setShowBacktestDiv(false);
                    axios.get(url).then(res =>
                        {
                            setStratStringPlainOnChain(res.data.message);
                            console.log('decrypted strategy:', res.data.message);
                            setShowBacktestDiv(true);
                            // return res.data.message;
                        });
                }
            } catch (e) {
                console.log('loadCurrentStrategy: ' + e);
                setTxPending(false);
                setTxSuccess(false);
            } 
        }
    }
    function TweetForm() {
        const [textFieldValue, setTextFieldValue] = useState('');
        const [twitterLink, setTwitterLink] = useState(null);
        const [tweetID, setTweetID] = useState(null);

        async function getTweet() {
            // console.log('getting tweet');
            if (vault !== null) {
                try {
    
                    //verify on the backend, get tweet from database on backend
                    var temp = baseURL;
                    if (temp.includes('localhost')) {
                        temp = temp+':8000';
                    }
                    const url = temp+'/api/gettweet/'+devMode.toString()+'/'+networkDict.chainID.toString()+'/'+vault.address;
                    // console.log('url:', url);
                    // setShowBacktestDiv(false);
                    axios.get(url).then(res =>
                        {
                            const tweetURL = res.data.tweetURL;
                            // console.log('get tweet:', msg);
                            if (tweetURL !== "") {
                                setTwitterLink(tweetURL);
                            }
                            
                        });
    
                } catch (e) {
                    console.log('getting tweet error: ' + e);
                    setTxPending(false);
                    setTxSuccess(false);
                } 
            }
        }
        async function setTweet(tweetURL) {
            console.log('setting tweet');
            if (vault !== null && walletAddress !== null && signer !== null) {
                setTxPending(true);
                try {
                    const signedMessage = await signer.signMessage(vault.address);
                    setTxPending(false);
                    setTxSuccess(true);
    
                    //first check
                    const singerAddress = ethers.utils.verifyMessage(vault.address, signedMessage);
                    console.log('SIGN:', vault.address, signedMessage, singerAddress, vault.operator, vault.operator === singerAddress);
                    if (singerAddress === vault.operator) {
                        //verify on the backend, set tweet in database on backend
                        var temp = baseURL;
                        if (temp.includes('localhost')) {
                            temp = temp+':8000';
                        }
                        const vaultAddress = vault.address;
                        const signature = signedMessage;
                        const encurl = encodeURIComponent(tweetURL);

                        const url = temp+`/api/settweet/${devMode.toString()}/${networkDict.chainID.toString()}/${vaultAddress}/${signature}?urlEncoded=${encodeURIComponent(encurl)}`;
                        console.log('url:', url);
                        // setShowBacktestDiv(false);
                        axios.get(url).then(res =>
                            {
                                const msg = res.data.message;
                                console.log('set tweet:', msg);
                                tweetURL = res.data.tweetURL;
                                if (tweetURL !== "") {
                                    setTwitterLink(tweetURL);
                                }
                            });
                    }
                } catch (e) {
                    console.log('setting tweet error: ' + e);
                    setTxPending(false);
                    setTxSuccess(false);
                } 
            }
        }
        useEffect(() => {
            if (vault !== null) {
                getTweet();
            }
        }, []);

        useEffect(() => {
            if (twitterLink !== null) {
                try {
                    const temp = twitterLink.split('/');
                    const temp2 = temp[temp.length - 1];
                    setTweetID(temp2);
                } catch (e) {
                    console.log('tweetForm useEffect: ' + e);
                }
            }
        }, [twitterLink]);

        if (vaultInfoFetched === false || twitterLink === null) {
            return(<></>);
        } else {
            return(
                <VertDiv backgroundColor={theme.black}>
                    {tweetID !== null && <Tweet tweetId={tweetID} options={{theme: "dark"}} />}
                    {walletAddress !== null && vault !== null && vault.operator !== null && walletAddress.toLowerCase() === vault.operator.toLowerCase() &&
                        <HorzDiv borderColor={theme.light}> 
                            <Input
                                // fontWeight="500"
                                // fontSize={theme.fontSizeLarge}
                                // width="9rem"
                                // size="19rem"
                                textAlign="center"
                                bg={theme.dark}
                                outline="none"
                                border="none"
                                focusBorderColor="none"
                                color={"rgb(255,255,255)"}
                                type='text'
                                value={textFieldValue}
                                // onBlur={(e) => setTextFieldValue(e.target.value)}
                                onChange={(e) => setTextFieldValue(e.target.value)}
                            />
                            <Button 
                                variant='contained'
                                size='small'
                                style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                                onClick={() => {setTweet(textFieldValue)}}
                            >
                                Set Twitter Link
                            </Button>
                        </HorzDiv>
                    }
                </VertDiv>
            );
        }
    }
    function FooterForm() {
        if (vaultInfoFetched === false || walletAddress === null) {
            return(<></>);
        } else {
            return(
                <VertDiv backgroundColor={theme.black}>
                    <Text fontSize={20} padding='2px' margin='2px'>
                        Vault Administration
                    </Text>
                    <HorzDiv borderStyle='none' margin='0px' padding='0px'>
                        <ActionDialog 
                            buttonVariant='outlined'
                            buttonSX={theme.buttonSX}
                            buttonStyle={theme.buttonStyle}
                            handleAcceptIn={() => deactivateVault()}
                            buttonInnards={vault.isActive ? "Deactivate Vault" : "Inactive"}
                            disabled={!vault.isActive || walletAddress.toLowerCase() !== vault.operator.toLowerCase()}
                            titleString='Deactivate Vault???'
                            setDialogOpenFlag={setDialogOpenBool}
                            contentString={
                                <VertDiv borderStyle='none'>
                                    <Text>You are about to deactivate this vault. This action cannot be undone.</Text>
                                </VertDiv>}
                        />
                        <ActionDialog 
                            buttonVariant='outlined'
                            buttonSX={theme.buttonSX}
                            buttonStyle={theme.buttonStyle}
                            handleAcceptIn={() => toggleVaultPublic()}
                            setDialogOpenFlag={setDialogOpenBool}
                            buttonInnards={vault.allowOtherUsers ? "Make Vault Private" : "Make Vault Public"}
                            titleString={vault.allowOtherUsers ? "Make Vault Private" : "Make Vault Public"}
                            contentString={
                                <VertDiv borderStyle='none'>
                                    {vault.allowOtherUsers &&
                                        <Text>Make Vault Private? This will disallow new users from depositing.</Text>
                                    }
                                    {!vault.allowOtherUsers &&
                                        <Text>Make Vault Public? This will allow new users to deposit.</Text>
                                    }
                                </VertDiv>}
                        />
                        <HorzDiv borderColor={theme.light}>
                                <ActionDialog 
                                    buttonVariant='outlined'
                                    buttonSX={theme.buttonSX}
                                    buttonStyle={theme.buttonStyle}
                                    handleAcceptIn={() => transferOwnership()}
                                    buttonInnards={"Transfer Ownership"}
                                    titleString={"Transfer Ownership?"}
                                    setDialogOpenFlag={setDialogOpenBool}
                                    contentString={
                                        <VertDiv borderStyle='none'>
                                            <Text>
                                                Set New Owner Address: {newOwnerAddress}    ???
                                            </Text>
                                        </VertDiv>}
                                /> 
                                <VertDiv margin='0px' padding='0px' borderStyle='none' gap='0px'>
                                    <Text marginBottom='1.5'>
                                        New Vault Manager Address
                                    </Text>
                                    <Input 
                                        label="New Owner Address"
                                        type={"text"}
                                        defaultValue={newOwnerAddress}
                                        width="20rem"
                                        fontSize={theme.fontSizeSmall}
                                        bg={theme.dark}
                                        focusBorderColor="none"
                                        color={"white"}
                                        onBlur={event=>setNewOwnerAddress(event.target.value)}
                                        borderColor={theme.blue}
                                        gap={theme.gap1}
                                    />    
                                </VertDiv>
                            </HorzDiv>
                    </HorzDiv>
                </VertDiv>

            );
        }
    }
    function formatDate(date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      
        const dayName = days[date.getDay()];
        const monthName = months[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`;
      }
    function TradeRowForm({trade}) {
        const txHash = trade.hash;
        const txTime = formatDate(trade.time);
        const fontSX = {fontSize: 14, fontWeight: 'bold', color: '#FFF'};
        const autoString = trade.autotrade ? 'Yes' : 'No';
        const st = {padding: '7px', margin: '0px', gap: '5px'};
        return(
            <TableRow>
                {/* <TableCell align='center' sx={fontSX}>{trade.index}</TableCell> */}
                <TableCell style={st} align='center' sx={fontSX}>{trade.type}</TableCell>
                <TableCell style={st} align='center' sx={fontSX}>{roundToDigits(trade.price,-4).toString()}</TableCell>
                <TableCell style={st} align='center' sx={fontSX}>{roundToDigits(100*trade.tradeRatio,-1).toString()}%</TableCell>
                <TableCell style={st} align='center' sx={fontSX}>{autoString}</TableCell>
                <TableCell style={st} align='center' sx={fontSX}>
                    <a href={networkDict['scanner'] + '/tx/' + txHash} target="_blank" rel="noreferrer">
                        {abbreviateAddress(txHash, 4)}
                    </a>
                </TableCell>
                <TableCell style={st} align='center' sx={fontSX}>{txTime}</TableCell>
            </TableRow>
           
        )
    }
    function TradeListForm() {
        const fontSZ = 14;
        const fontSX = {fontSize: fontSZ, fontWeight: 'bold', color: '#FFF'};
        const [formattedTradeList, setFormattedTradeList] = useState([]);
        const st = {padding: '0px', margin: '0px', gap: '0px'};

        useEffect(() => {
            if (tradeList.length > 0) {
                var temp = [];
                for (var i = tradeList.length-1; i >= 0; i--) {
                    var trade = tradeList[i];
                    trade.index = i;
                    // const tradeString = trade.tokenFrom.symbol + ' --> ' + trade.tokenTo.symbol + ': ' + trade.amtOutFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenFrom.symbol)) + ' --> ' + trade.amtInExpectedFloat.toFixed(tokenLibrary.getDecimalDisplay(trade.tokenTo.symbol));
                    temp.push(trade);
                }
                setFormattedTradeList(temp);
            }
        }, [tradeList]);

        if (formattedTradeList.length === 0) {
            return(<></>);
        } else {
            
            return(
                <VertDiv backgroundColor={theme.black}>
                    <Text fontSize={20} padding='2px' margin='2px'>
                        Trade History
                    </Text>
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <TableContainer 
                            component={Paper} 
                            sx={{
                                maxWidth: '3000px', 
                                maxHeight: '600px',
                                overflow: 'auto',
                                border: 'none', 
                                padding: '1px', 
                                backgroundColor: theme.dark,
                                '&::-webkit-scrollbar': {
                                    width: '15px', // Adjust the width of the scrollbar
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#BBB', // Color of the track
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#666', // Color of the scrollbar thumb
                                    borderRadius: '2px', // Optional: if you want rounded corners
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow sx={{borderBottom: "1px solid black"}}>
                                    {/* <TableCell style={st} align="center" sx={fontSX}>Num</TableCell> */}
                                    <TableCell style={st} align="center" sx={fontSX}>Type</TableCell>
                                    <TableCell style={st} align="center" sx={fontSX}>Price</TableCell>
                                    <TableCell style={st} align="center" sx={fontSX}>Size</TableCell>
                                    <TableCell style={st} align="center" sx={fontSX}>Autotrade</TableCell>
                                    <TableCell style={st} align="center" sx={fontSX}>Tx</TableCell>
                                    <TableCell style={st} align="center" sx={fontSX}>Time</TableCell>
                                </TableRow> 
                            </TableHead>
                            <TableBody>
                                {formattedTradeList.map((trade, index) => {
                                    return(
                                        <TradeRowForm trade={trade} />
                                    )
                                })}
                            </TableBody>
                            
                        </TableContainer>
                        
                    </VertDiv>
                </VertDiv>
            );
        }
    }

    ////Main output (try to get all crazy conditionals and stuff located here)
    // console.log('main loop:', networkDict, defaultNetworkDict, walletAddress);
    // console.log(trade)
    // console.log('IN EV: stratStringPlainOnChain:', stratStringPlainOnChain)
    // console.log("MAIN: ", tradeList);
    return(
        <VertDiv 
            borderStyle='none' 
            backgroundColor='none' 
            margin={theme.margin0} 
            gap={theme.gap1} 
            padding={theme.padding1}
        >
            {/* {defaultNetworkDict} */}
            {!minimal && FadeInAnimation(HeaderForm())}
            {FadeInAnimation(VaultInfoForm())}
            {!minimal && walletAddress !== null && <Divider flexItem={true} sx={{ bgcolor: "white", m:'0px', p:'0px' }} />}
            {!minimal && walletAddress !== null && FadeInAnimation(DepositWithdrawDiv())}
            {!minimal && <Divider flexItem={true} sx={{ bgcolor: "white", m:'0px', p:'0px' }} />}
            
            
            {!minimal && FadeInAnimation(ChartAndTradeDiv())}
            {!minimal && <Divider flexItem={true} sx={{ bgcolor: "white", m:'0px', p:'0px' }} />}
            {!minimal && FadeInAnimation(TradeListForm())}
            {!minimal && <Divider flexItem={true} sx={{ bgcolor: "white", m:'0px', p:'0px' }} />}
            {!minimal && FadeInAnimation(TweetForm())}
            {!minimal && FadeInAnimation(FooterForm())}
            
            {/* <DummyDummy /> */}
        </VertDiv>
    )

}

export default ExistingVaultForm;