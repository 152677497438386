////ORIGINAL VERSION
// import { render } from "react-dom";

// import App from "./App";


// const rootElement = document.getElementById("root");
// render(<App />, rootElement);

////NEW VERSION
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    //<React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    //</React.StrictMode>
);

