import { theme, VertDiv, HorzDiv } from '../styles.js';
// import { Checkbox } from '@mui/material';
import { Text, NumberInput, NumberInputField, NumberInputStepper, 
        NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react';



import { useState, useEffect } from 'react';
import { Autocomplete, TextField, Button, Dialog,
        Checkbox, createTheme, ThemeProvider, CssBaseline  } from '@mui/material';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        // Adjust these colors to ensure text visibility
        text: {
            primary: '#ffffff', // Light color for text
            secondary: '#cccccc' // Slightly dimmer color for secondary text
        },
        // If the brown color is part of your theme, set it here
        background: {
            // paper: '#a52a2a', // Example brown color
            default: '#000000', // Default background
        },
    },
});

export function SelectList({ label, items, selectedIn, setSelectedIn, width = 300 }) {
    const [selectedItems, setSelectedItems] = useState(selectedIn);
    const [open, setOpen] = useState(false);

    // Update the local state when selectedIn changes
    useEffect(() => {
        setSelectedItems(selectedIn);
    }, [selectedIn]);

    const handleToggleDropdown = () => {
        setOpen(!open);
    };

    const handleFinalizeSelection = () => {
        setSelectedIn(selectedItems); // Pass the selected items to the parent
        setOpen(false); // Close the dropdown or revert to minimal look
    };
    
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div>
                <Button onClick={handleToggleDropdown}>Select Strategies</Button>
 
                    <Dialog 
                        open={open} 
                        onClose={handleToggleDropdown}
                    >
                        <HorzDiv borderStyle={'none'}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={items}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                value={selectedItems} // Set the value to the selectedItems
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                style={{ width: width }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Items" placeholder="Items" />
                                )}
                                onChange={(event, newValue) => {
                                    setSelectedItems(newValue);
                                }}
                            />
                            <Button onClick={handleFinalizeSelection}>Select</Button>
                        </HorzDiv>

                    </Dialog>

            </div>
        </ThemeProvider>
    );
}

// export function SelectList({ label, items, selectedIn, setSelectedIn, width = 200 }) {
//     const [selectedItems, setSelectedItems] = useState(selectedIn);
//     const [open, setOpen] = useState(false);

//     // Update the local state when selectedIn changes
//     useEffect(() => {
//         setSelectedItems(selectedIn);
//     }, [selectedIn]);

//     const handleToggleDropdown = () => {
//         setOpen(!open);
//     };

//     const handleFinalizeSelection = () => {
//         setSelectedIn(selectedItems); // Pass the selected items to the parent
//         setOpen(false); // Close the dropdown or revert to minimal look
//     };
    
//     return (
//         <ThemeProvider theme={darkTheme}>
//             <CssBaseline />
//             <div>
//                 <Button onClick={handleToggleDropdown}>Select Strategies</Button>
//                 {open && (
//                     <>
//                         <Autocomplete
//                             multiple
//                             id="checkboxes-tags-demo"
//                             options={items}
//                             disableCloseOnSelect
//                             getOptionLabel={(option) => option}
//                             value={selectedItems} // Set the value to the selectedItems
//                             renderOption={(props, option, { selected }) => (
//                                 <li {...props} key={option}>
//                                     <Checkbox
//                                         style={{ marginRight: 8 }}
//                                         checked={selected}
//                                     />
//                                     {option}
//                                 </li>
//                             )}
//                             style={{ width: width }}
//                             renderInput={(params) => (
//                                 <TextField {...params} label="Select Items" placeholder="Items" />
//                             )}
//                             onChange={(event, newValue) => {
//                                 setSelectedItems(newValue);
//                             }}
//                         />
//                         <Button onClick={handleFinalizeSelection}>Finalize Selection</Button>
//                     </>
//                 )}
//             </div>
//         </ThemeProvider>
//     );
// }




export function BoolIndicator({ values, labels }) {
    if (values.length !== labels.length) {
        console.error('The length of values and labels arrays must match.');
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values.map((value, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div 
                    style={{
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    marginRight: 10,
                    backgroundColor: value ? "#03DD03" : '#ddd',
                    transition: 'background-color 0.3s',
                    }}
                ></div>
                <span>{labels[index]}</span>
                </div>
            ))}
        </div>
    );
}
      

      

    

export function SimpleCheckBox({label, state, setState, borderColor=theme.light}) {
    return(
        <HorzDiv borderStyle='none' margin='0px' padding='0px' gap='0px'>
            <HorzDiv backgroundColor={'none'}
                margin='0px' padding='0px' gap='0px'
                borderRadius='5px' borderColor={borderColor}       
            >
                <Checkbox 
                    sx={{ m:'0px',p:'0px'}}
                    checked={state}
                    onChange={event => setState(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}   
                />    
            </HorzDiv>
            <Text color={theme.white} fontSize='1.0rem' margin='5px' padding='0px' >
                {label}
            </Text> 
        
        </HorzDiv>
    )
}


export function InputWithLabel({label, onBlur, readOnly=false, 
                                withIncrementer=false, 
                                min=Number.MIN_SAFE_INTEGER, 
                                max=Number.MAX_SAFE_INTEGER, 
                                step=1, defaultValue=0, precision=0,
                                textAlign='center', 
                                inputFontSize=theme.fontSizeLarge, 
                                labelFontSize=theme.fontSizeMedium,
                                borderColor=theme.light, 
                                backgroundColor=theme.dark, 
                                color="rgb(255,255,255)",
                                width='12rem', disabled=false, allowMouseWheel=true,
                                margin=theme.margin0, padding=theme.padding0, gap=theme.gap0, 
                                useValueAndOnChange=false}) {
    const myOnBlur = (event) => {
        var temp;
        if (event.target.value < min) {
            temp = min;
        } else if (event.target.value > max) {
            temp = max;
        } else {
            temp = event.target.value;
        }
        event.target.value = temp;
        onBlur(event);
    }

    return(
        <VertDiv alignItems={"center"} justifyContent="center" 
            backgroundColor={theme.dark} 
            borderColor={theme.light} borderStyle='none' 
            margin={margin} gap={gap} padding={padding}>
            <Text fontSize={labelFontSize} color={theme.white} margin="0px">{label}</Text>
            <HorzDiv
                margin={theme.margin0} gap={theme.gap1} padding={"2px 10px 2px 10px"}
                borderColor={borderColor} borderRadius={theme.borderRadiusSmall}
            >
                {useValueAndOnChange ?
  
                    <NumberInput
                        allowMouseWheel={allowMouseWheel}
                        readOnly={readOnly}
                        onChange={(event) => myOnBlur(event)}
                        value={defaultValue}
                        disabled={disabled}
                        min={min}
                        max={max}
                        step={step}
                        precision={precision} 
                        keepWithinRange={true}
                        clampValueOnBlur={true}       
                    >
                        <NumberInputField
                            fontWeight="500"
                            fontSize={inputFontSize}
                            width={width}
                            size="19rem"
                            textAlign={textAlign}
                            outline="none"
                            border="none"
                            bg={backgroundColor}
                            focusBorderColor="none"
                            color={color}
                        /> 
                        {withIncrementer &&
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        }
                    </NumberInput>

                :
 
                    <NumberInput
                        allowMouseWheel={allowMouseWheel}
                        readOnly={readOnly}
                        onBlur={(event) => myOnBlur(event)}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        min={min}
                        max={max}
                        step={step}
                        precision={precision} 
                        keepWithinRange={true}
                        clampValueOnBlur={true}       
                    >
                        <NumberInputField
                            fontWeight="500"
                            fontSize={inputFontSize}
                            width={width}
                            size="19rem"
                            textAlign={textAlign}
                            outline="none"
                            border="none"
                            bg={backgroundColor}
                            focusBorderColor="none"
                            color={color}
                        /> 
                        {withIncrementer &&
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        }
                    </NumberInput>
                }

            </HorzDiv>
        </VertDiv>
    )
}


