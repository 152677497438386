import {useState, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import {Button} from '@mui/material';

import axios from "axios";
import {getBaseURL, splitArray, getArrayRandomElement, getRandomInt} from '../functions/functions.js';

// import {LabelInputs, VerticalLabelInputs} from '../styles.js';
import OrbitalScene from './OrbitalScene';
import AlertDialogSlide from './SlideDialog.tsx';

import styled from 'styled-components';

import { theme, VertDiv, HorzDiv } from '../styles.js';

import { SimpleCheckBox } from './SimpleComponents.js';

const LabelInputs = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    gap:5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
    padding: 5px;
    text-align: right;
    color: #FFFFFF;
`;
const VerticalLabelInputs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
`;

const VerticalLabelInputsDontSqueeze = styled.div`
    display: flex;
    flex-direction: column;
    ${'' /* justify-content: space-around; */}
    ${'' /* align-items: center; */}
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid #A2B533;
    border-radius: 5px;
`;

function OrbitalSceneWithControls() {
    async function fetchStates(stationaryBool=true) {
        var baseURL = getBaseURL();
        if (baseURL.includes('localhost')) {
            baseURL = baseURL+':8000';
        }
        const url = baseURL+'/api/fetchstates/'+stationaryBool.toString();
        await axios.get(url).then(res => {
            console.log('finished fetching...');
            // setAllStates(res.data.states);
            // console.log('set????:', allStates);
            parseAllStates(res.data.states);
        });   
    }

    useEffect(() => {
        fetchStates(stationary);
        // setCurrentState(createFullStateString());
    }, []);

    const [allStates, setAllStates] = useState([]);
    // console.log('allStates:', allStates, '...');

    const [firstDropdown, setFirstDropdown] = useState([]);
    const [secondDropdown, setSecondDropdown] = useState({});
    const [thirdDropdown, setThirdDropdown] = useState({});

    
    // const [firstStateInitial, setFirstStateInitial] = useState('3,2,1');
    // const [secondStateInitial, setSecondStateInitial] = useState('4,2,1');
    // const [thirdStateInitial, setThirdStateInitial] = useState('');
    const initialStatesList =   [
                                ['1,0,0', '', '']
                                // ['2,0,0', '2,1,1', ''],
                                // ['3,2,1', '4,2,1', ''],
                                // ['3,0,0','3,2,-1',''],
                                // ['2,1,1','3,2,-2',''],
                                // ['4,2,-2','4,3,3',''],
                                // ['2,0,0','3,0,0','4,2,-2'],
                                // ['2,1,0','2,1,1','3,2,2'],
                                // ['3,1,-1','4,1,0','']
                                ]
    const initialStateRand = getArrayRandomElement(initialStatesList);
    const firstStateInitial = initialStateRand[0];
    const secondStateInitial = initialStateRand[1];
    const thirdStateInitial = initialStateRand[2];

    const [firstState, setFirstState] = useState(firstStateInitial);
    const [secondState, setSecondState] = useState(secondStateInitial);
    const [thirdState, setThirdState] = useState(thirdStateInitial);
    
    var initialComplexity;
    if (isMobile) {
        initialComplexity = 0;
    } else {
        initialComplexity = 1;
    }
    // const initialComplexity = 2;
    const [currentComplexity, setCurrentComplexity] = useState(initialComplexity);
    const [loadedComplexity, setLoadedComplexity] = useState(initialComplexity);
    const [currentState, setCurrentState] = useState('');
    const [loadedState, setLoadedState] = useState('');
    const [statesLoadedFromBackend, setStatesLoadedFromBackend] = useState(false);
    const [stationary, setStationary] = useState(true);


    function createFullStateString(firstStateIn, secondStateIn, thirdStateIn) {
        var fullState = '';
        if (firstStateIn !== '' && firstStateIn !== '|>') {
            fullState += "{";
            fullState += "{1,{"+firstStateIn+"}},";
            if (secondStateIn !== '' && secondStateIn !== '|>') {
                fullState += "{1,{"+secondStateIn+"}},";
                if (thirdStateIn !== '' && thirdStateIn !== '|>') {
                    fullState += "{1,{"+thirdStateIn+"}},";
                } 
            }
            fullState = fullState.slice(0, -1) + "}";
        }
        console.log('in createFullStateString, fullState:', fullState);
        return fullState;
    }

    function handleE0Select(arg) {
        console.log(arg);
        var newFirstState = arg;
        var newSecondState = secondState;
        var newThirdState = thirdState;
        setFirstState(arg);
        if (secondDropdown[arg] === undefined) {
            newSecondState = '';
        } else {
            console.log('secondDropdown[arg]:', secondDropdown[arg]);
            if (!secondDropdown[arg].includes(secondState)) {
                newSecondState = secondDropdown[arg][0];
            }
        }
        var thirdArg = newFirstState + ',' + newSecondState;
        if (thirdDropdown[thirdArg] === undefined) {
            newThirdState = '';
        } else {
            if (!thirdDropdown[thirdArg].includes(thirdState)) {
                newThirdState = thirdDropdown[thirdArg][0];
            }
        }
        setSecondState(newSecondState);
        setThirdState(newThirdState);
        setCurrentState(createFullStateString(newFirstState, newSecondState, newThirdState));
    }
    function handleE1Select(arg) {
        console.log(arg);
        var newSecondState = arg;
        var newThirdState = thirdState;
        var thirdArg = firstState + ',' + newSecondState;
        if (thirdDropdown[thirdArg] === undefined) {
            newThirdState = '';
        } else {
            if (!thirdDropdown[thirdArg].includes(thirdState)) {
                newThirdState = thirdDropdown[thirdArg][0];
            }
        }
        setSecondState(newSecondState);
        setThirdState(newThirdState);
        setCurrentState(createFullStateString(firstState, newSecondState, newThirdState));
    }
    function handleE2Select(arg) {
        console.log(arg);
        setThirdState(arg);
        setCurrentState(createFullStateString(firstState, secondState, arg));
    }
    function handleComplexitySelect(arg) {
        setCurrentComplexity(parseInt(arg));
    }
    function handleLoadState() {
        const s = createFullStateString(firstState, secondState, thirdState)
        setCurrentState(s);
        console.log('loading state:', s);
        setLoadedState(s);
        setLoadedComplexity(currentComplexity);
    }
    function handleLoadRandomState() {
        var lowestEnergy = getArrayRandomElement([1,2,2,2,2,2,2,3,3,3,3,3,3,4,4,4,4,5,5,5,6,6,6])//getRandomInt(1, 6);
        var first = getArrayRandomElement(firstDropdown);
        var firstEnergy = parseInt(first.split(',')[0]);
        while (firstEnergy !== lowestEnergy) {
            first = getArrayRandomElement(firstDropdown);
            firstEnergy = parseInt(first.split(',')[0]);
        }


        var second = getArrayRandomElement(secondDropdown[first]);
        if (!second) {
            second = '';
        }
        var third = '';
        const s = createFullStateString(first, second, third);
        setFirstState(first);
        setSecondState(second);
        setThirdState(third);
        setCurrentState(s);
        setLoadedState(s);
        setLoadedComplexity(currentComplexity);
    }
    function parseAllStates(allStatesRaw) {
        var rawState;
        var i;
        var j;
        var firstDropdownList = [];
        var secondDropdownList = {};
        var thirdDropdownList = {};
        var key
        for (i = 0; i < allStatesRaw.length; i++) {
            rawState = allStatesRaw[i].replaceAll('{', '').replaceAll('}', '').split(',')
            rawState = splitArray(rawState, 4);
            for (j = 0; j < rawState.length; j++) {
                rawState[j].splice(0,1);
            }
            // console.log(rawState)
            for (j = 0; j < rawState.length; j++) {
                if (j === 0) {
                    if (!firstDropdownList.includes(rawState[j].toString())) {
                        firstDropdownList.push(rawState[j].toString());
                    }
                } else if (j === 1) {
                    key = rawState[j-1].toString();
                    if (secondDropdownList[key] === undefined) {
                        secondDropdownList[key] = [];
                    }
                    if (!secondDropdownList[key].includes(rawState[j].toString())) {
                        secondDropdownList[key].push(rawState[j].toString());
                    }
                } else if (j === 2) {
                    key = rawState[j-2].toString()+','+rawState[j-1].toString();
                    if (thirdDropdownList[key] === undefined) {
                        thirdDropdownList[key] = [];
                    }
                    if (!thirdDropdownList[key].includes(rawState[j].toString())) {
                        thirdDropdownList[key].push(rawState[j].toString());
                    }
                }
            }
            // rawState = rawState.map(item => item.splice(0,1));
            // allStates.push(rawState);
        }
        firstDropdownList.sort();
        for (key in secondDropdownList) {
            secondDropdownList[key].sort();
        }
        for (key in thirdDropdownList) {
            thirdDropdownList[key].sort();
        }
        setFirstDropdown(firstDropdownList);
        setSecondDropdown(secondDropdownList);
        setThirdDropdown(thirdDropdownList);
        
        setFirstState(firstStateInitial); 
        setSecondState(secondStateInitial);
        setThirdState(thirdStateInitial);
        setStatesLoadedFromBackend(true);
        setCurrentState(createFullStateString(firstStateInitial, secondStateInitial, thirdStateInitial));
        setLoadedState(createFullStateString(firstStateInitial, secondStateInitial, thirdStateInitial));
    }
    const complexityDict = {0: 'Low', 1: 'Med', 2: 'High', 3: 'Ultra', 4: 'Max'};

    async function handleSetStationary() {
        const newStationary = !stationary;
        setStationary(newStationary);
        
        await fetchStates(newStationary);
    }

    return (
        // <VertDiv 
        //     borderStyle='none'
        //     width='100vw'
        // >
        <div>
            <VerticalLabelInputsDontSqueeze>
                <VerticalLabelInputs>                       
                    <VertDiv>
                        <SimpleCheckBox 
                            borderColor={theme.pink}
                            label='Stationary Only'
                            state={stationary}
                            setState={handleSetStationary}
                        />
                        <LabelInputs>
                            <VerticalLabelInputs>
                                <label>Orbital 1</label>
                                <select onChange={event => handleE0Select(event.target.value)} style={{width: '100px'}}>
                                    {!statesLoadedFromBackend ? '': 
                                        firstDropdown.map(item => <option value={item} selected={item===firstState ? true : false}>{"|"+item+">"}</option>)
                                    }
                                </select>
                            </VerticalLabelInputs>   
                            <VerticalLabelInputs>
                                <label>Orbital 2</label>
                                <select onChange={event => handleE1Select(event.target.value)} style={{width: '100px'}}>
                                    {(!statesLoadedFromBackend || secondDropdown[firstState] === undefined) ? '': 
                                        secondDropdown[firstState].map(item => <option value={item} selected={item===secondState ? true : false}>{"|"+item+">"}</option>)
                                    }
                                </select>
                            </VerticalLabelInputs>
                            <VerticalLabelInputs>
                                <label>Orbital 3</label>
                                <select onChange={event => handleE2Select(event.target.value)} style={{width: '100px'}}>
                                    {(!statesLoadedFromBackend || thirdDropdown[firstState+','+secondState] === undefined) ? '': 
                                        thirdDropdown[firstState+','+secondState].map(item => <option value={item} selected={item===thirdState ? true : false}>{"|"+item+">"}</option>)
                                    }
                                </select>
                            </VerticalLabelInputs>
                            <VerticalLabelInputs>
                                <label>Complexity</label>
                                <select onChange={event => handleComplexitySelect(event.target.value)} style={{width: '100px'}}>
                                    { 
                                        [0,1,2,3,4].map(item => <option value={item} selected={item===initialComplexity ? true : false}>{complexityDict[item]}</option>)
                                    }
                                </select>
                            </VerticalLabelInputs>
                            <Button 
                                    className="loadButton"
                                    sx={{p:1, m:1}} 
                                    variant='contained'
                                    // color='primary'
                                    size='small'
                                    style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                                    onClick={handleLoadState}>
                                    {"Load Selected State"}
                            </Button>


                    </LabelInputs>
                    </VertDiv>
                    <Button
                        className="loadRandom"
                        sx={{p:1, m:1}}
                        variant='contained'
                        // color='primary'
                        size='small'
                        style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em', 'width': '300px'}}
                        onClick={handleLoadRandomState}>
                        {"Load Random State"}
                    </Button>
                 
                </VerticalLabelInputs>
                {/* {loadedState !== '' && <Child stateName={loadedState} />} */}
                {(loadedState !== '') && <OrbitalScene stateName={loadedState} complexity={loadedComplexity}/>}
                                
            </VerticalLabelInputsDontSqueeze>
            
        </div>
    );
}


export default OrbitalSceneWithControls;

// export default ({ numPaths, showKitty }) => (
//     <div>
//         <LabelInputs>
//             <label>e0</label>
//             <select onChange={event => handleE0Select(event.target.value)} style={{width: '80px'}}>
//                 {/* {validTokens.map(item => <option value={item} selected={item===token0 ? true : false}>{item}</option>)} */}
//                 <option value="{1,0,0}" selected={true}>{"{1,0,0}"}</option>
//                 <option value="{2,0,0}" selected={false}>{"{2,0,0}"}</option>
//             </select>
                
//         </LabelInputs>
//         <SceneComponent 
//             antialias 
//             onSceneReady={onSceneReady} 
//             onRender={onRender} 
//             id="babylon-canvas" 
//             // onSceneReadyDict={{'numPaths': numPaths, 
//             //                   'showKitty': showKitty}}
//             onSceneReadyDict={{'numPaths': numPaths}}
//         />
//     </div>
// );