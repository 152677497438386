import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getBaseURL } from '../functions/functions';

const baseURL = getBaseURL();
var homePage = baseURL;

if (baseURL.includes('localhost')) {
    homePage = baseURL + ':3000'
} else {
    homePage = baseURL
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} color="success">
        What am I looking at?
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        color='white'    
      >
        <DialogTitle>{"What's going on in these animations?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h5>What is it?</h5>
            <p>
                These animations are a visualization of the behavior of an Electron in a Hydrogen Atom.
                In Quantum Mechanics, particles are represented with a "wave function" that describes the probability 
                of finding the particle at a given point in space. These plots are essentially scattter plots of the 
                wave function. The density of the blue spheres represents the probability of finding an electron in
                that region of space, and they then move along trajectories determined by the "flow of probability". 
                The other elements of the animations (the yellow tubes and the blue streamers) are decorative elements
                intended to make the motion easier to follow.
            </p>
            <p>
                Any distribution is allowed phsyically, but some distributions are special
                in that the probablity density remains unchanged as the system evolves. 
                These are called "Stationary States", also known as "Eigenstates". 
                In the context of Atomic Physics, these are also referred to as as "Orbitals". 
                In our interface, you can select Eigenstates by clicking on the "Oribital 1" dropdown menu. 
                You can also see combinations of up to 3 Eigenstates added together by clicking the "Orbital 2" and "Orbital 3"
                dropdown menus. 
            </p>
            <p>
                We recommend viewing these on a desktop machine with a decent graphics card. Chrome seems to be the
                best browser for it.
            </p>
            <h5>Why is it here?</h5>
            <p>
                It's here as a promotional tool for the project. The lead dev had this code "in his back pocket"
                for awhile. When someone suggested the name "Orbital", he dropped it in the webpage and we couldn't believe
                what we were looking at. We hope you enjoy it (and the <a href={homePage+'/backtest'}>backtest tool</a>) 
                while we continue to build out the rest of the platform.
            </p>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
