
export function dateToEpoch(date) {
    return Math.round(date.getTime() / 1000);
}

export function epochToDate(ts){
    // ts = epoch timestamp
    // returns date obj
    return new Date(ts*1000);
}


export function arrayRemoveLast(arr, numToRemove=1) {
    return arr.slice(0, arr.length - numToRemove);
}

export function smaCalc(data, windowSize) {
    if (!Array.isArray(data) || data.length === 0 || windowSize <= 0) {
      return null;
    }
  
    let result = [];
    let sum = 0;
  
    for (let i = 0; i < data.length; i++) {
      sum += data[i];
      if (i < windowSize - 1) {
        result.push(sum / (i + 1));
      } else {
        result.push(sum / windowSize);
        sum -= data[i - windowSize + 1];
      }
    }
  
    return result;
  }

// export function smaCalc(L, period) {
//     var smaArray = [];
//     if (L && L.length > 0) {
//         smaArray.push(L[0]);
//         for (var n = 0; n < L.length-1; n++) {
//             var k = Math.min(smaArray.length, period);
//             var newVal = smaArray[n] + (L[n+1] - L[n+1-k]) / k;
//             smaArray.push(newVal);
//         }
//     }

//     return smaArray;
// }

export function emaCalc(mArray, mRange) {
    mRange = parseInt(mRange);
    var k = 2/(mRange + 1);
    // first item is just the same as the first item in the input
    var emaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
        emaArray.push(mArray[i] * k + emaArray[i - 1] * (1 - k));
    }
    return emaArray;
}

export function rmaCalc(mArray, mRange) {
    var k = 1/mRange;
    // first item is just the same as the first item in the input
    var rmaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
        rmaArray.push(mArray[i] * k + rmaArray[i - 1] * (1 - k));
    }
    return rmaArray;
}

export function trueRange(highList, lowList, closeList) {
    var trueRangeList = [highList[0] - lowList[0]];
    for (var i = 1; i < highList.length; i++) {
        var temp = highList[i] - lowList[i];
        var temp2 = Math.abs(highList[i] - closeList[i-1]);
        var temp3 = Math.abs(lowList[i] - closeList[i-1]);
        var temp4 = Math.max(temp, temp2, temp3);
        trueRangeList.push(temp4);
    }
    return trueRangeList;
}