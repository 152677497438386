import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App'
import { ethers } from "ethers";
import { Button, Typography, Checkbox} from '@mui/material';
// import vaultFactoryAbi from '../components/Interfaces/Arbitrum/VaultFactory.json';
import erc20Abi from '../components/Interfaces/Arbitrum/erc20Generic.json';
import ExistingVaultForm from '../components/ExistingVaultV2.js';
import { getBaseURL, BN, sleep, bigNumToFloat, getPrefetch } from '../functions/functions';
// import { VaultManager, Vault } from '../classes/classes';
import { VaultFactoryV2, VaultManagerV2, VaultV2, VaultInfo, AuxInfo, VaultAggregated } from '../classes/classes_02';
import { theme, VertDiv, HorzDiv } from '../styles.js';

import { Link } from "react-router-dom";

import {Text, Input, UnorderedList, ListItem} from "@chakra-ui/react";

import { SimpleCheckBox, InputWithLabel } from '../components/SimpleComponents';

import { FadeInAnimation } from '../components/Animations/animations';

import { QuestionMarkRounded, Info as InfoIcon } from '@mui/icons-material';
import { InfoDialog, ActionDialog, OpenNewVaultDialog } from '../components/Dialogs/Dialogs';
import axios from 'axios';

// const baseURL = getBaseURL();
// var homePage = baseURL;

// if (baseURL.includes('localhost')) {
//     homePage = baseURL + ':3000'
// } else {
//     homePage = baseURL
// }


function VaultsOverviewPage() {
    // const [walletAddress, setWalletAddress] = useState("");
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [connectedToWallet, setConnectedToWallet] = useState(false);
    const [createNewVaultBool, setCreateNewVaultBool] = useState(false);
    const [vaultName, setVaultName] = useState('');
    const [vault, setVault] = useState(null);
    const [newVaultAddress, setNewVaultAddress] = useState('');
    // const [vaultPair, setVaultPair] = useState('');
    const [possiblePairs, setPossiblePairs] = useState([]);
    const [possiblePairStrings, setPossiblePairStrings] = useState([]);
    const [selectedPair, setSelectedPair] = useState([]);
    const [selectedPairString, setSelectedPairString] = useState('');
    ////////////////////////////////////////////////////////////////////////
    // const [vaultFactory, setVaultFactory] = useState(null);
    const [vaultFactoryV2, setVaultFactoryV2] = useState(null);
    const [vaultManagerV2, setVaultManagerV2] = useState(null);
    const [vaultInfo, setVaultInfo] = useState(null);
    // const [vaultContracts, setVaultContracts] = useState([]);

    const [listUserVaultsOnlyBool, setListUserVaultsOnlyBool] = useState(false);
    const [existingVaults, setExistingVaults] = useState([]);
    const [existingVaultsAggregated, setExistingVaultsAggregated] = useState([]);
    const [existingVaultsAggregatedDict, setExistingVaultsAggregatedDict] = useState({});

    const [existingVaultsFetched, setExistingVaultsFetched] = useState(false);
    const [vaultsInfoLoaded, setVaultsInfoLoaded] = useState(false);
    const [fetchingInfo, setFetchingInfo] = useState(false);
    // const [thisWalletAddress, setThisWalletAddress] = useState('');

    const routerAddress = '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45';
    const [ownerFee, setOwnerFee] = useState(500);
    const [operatorFee, setOperatorFee] = useState(1000);
    const [userFee, setUserFee] = useState(2000);
    const [allowOtherUsers, setAllowOtherUsers] = useState(true);

    const [showAllVaults, setShowAllVaults] = useState(false);
    const [showActiveVaults, setShowActiveVaults] = useState(true);
    const [showPublicVaults, setShowPublicVaults] = useState(true);
    const [showVaultsIManage, setShowVaultsIManage] = useState(false);
    const [showVaultsIUse, setShowVaultsIUse] = useState(false);
    const [vaultsToShow, setVaultsToShow] = useState([]);
    const [smallScreen, setSmallScreen] = useState(false);

    const [mustRefreshVaultsListDiv, setMustRefreshVaultsListDiv] = useState(false);

    const [openNewVaultDialogOpen, setOpenNewVaultDialogOpen] = useState(false);
    const [openNewVaultDialogURL, setOpenNewVaultDialogURL] = useState('');

    const [dialogOpenBool, setDialogOpenBool] = useState(false);
    const [prefetch, setPrefetch] = useState(false);

    const [listVaultsIndex, setListVaultsIndex] = useState(0);

    const {windowDimensions,
        baseURL, setBaseURL,
        networkDict, setNetworkDict, 
        defaultNetworkDict, setDefaultNetworkDict,
        txPending, setTxPending, 
        lastTxHash, setLastTxHash,
        errorDialogOpen, setErrorDialogOpen,
        errorMessage, setErrorMessage,
        txSuccess, setTxSuccess,
        devMode, 
        contractAddresses, setContractAddresses, 
        devContractAddresses, releaseContractAddresses, 
        walletAddress, setWalletAddress} = useContext(UserContext);


    // const handleVaultRenderCompletion = () => {
    //     setListVaultsIndex(prevIndex => prevIndex + 1);
    // };

    //////////////////////////////////////////////////////////////////////////////////
    async function fetchPossiblePairs(facV2, provider, prefetchIn=null) {
        console.log('fetching possible pairs', facV2);
        console.log('vaultFactory.address', facV2.address);
        setFetchingInfo(true);

        const allowedPairs = facV2.allowedPairs;
        const numPairs = allowedPairs.length;
        console.log('numPairs', numPairs);
        var pairs = [];
        var pairStrings = [];
        var a0;
        var a1;

        var alreadyAdded = {};
        for (var i = 0; i < numPairs; i++) {
            var pair = facV2.allowedPairs[i];
            a0 = pair.token0;
            a1 = pair.token1;

            let p = [pair.token0, pair.token1];
            p.sort();

            const pString = p[0] + '-' + p[1];
            if (!(pString in alreadyAdded)) {
                alreadyAdded[pString] = true;
                [a0,a1] = p;
                
                var a0Symbol;
                var a1Symbol;
                if (prefetchIn !== null) {
                    a0Symbol = prefetchIn[a0].symbol;
                    a1Symbol = prefetchIn[a1].symbol;
                } else {
                    a0Symbol = await new ethers.Contract(a0, erc20Abi, provider).symbol();
                    a1Symbol = await new ethers.Contract(a1, erc20Abi, provider).symbol();
                }
                
                const pairString = a0Symbol + '-' + a1Symbol;
                console.log(pairString);

                pairs.push([a0, a1]);
                pairStrings.push(pairString);
            }
        }

        setPossiblePairs(pairs);
        setPossiblePairStrings(pairStrings);
        if (pairStrings.length > 0) {
            setSelectedPair(pairs[0]);
            setSelectedPairString(pairStrings[0]);
        }
        setFetchingInfo(false);
    }

    async function refreshVaultsToShow(existingVaultsIn=null) {
        console.log('IN refreshVaultsToShow()')
        var vaultsToShowTemp = [];
        // setVaultsToShow(vaultsToShowTemp);

        const toLoopOver = existingVaultsIn ? existingVaultsIn : existingVaults;

        for (let i = 0; i < toLoopOver.length; i++) {
            const vault = toLoopOver[i];
            // const balt0 = vault.balances[walletAddress][0];
            // const balt1 = vault.balances[walletAddress][1];
            const cond0 = showAllVaults;
            const cond1 = vault.isActive;
            const cond2 = vault.allowOtherUsers;
            const cond3 = showVaultsIManage && (vault.operator.toLowerCase() === walletAddress.toLowerCase());
            
            let vi = null;
            if (vaultInfo === null) {
                vi = new VaultInfo(contractAddresses['VaultInfo'], networkDict.provider);
            } else {
                vi = vaultInfo;
            }
            var thisWalletBalances;
            if (walletAddress === null) {
                let numTokens = vault.balances.length;
                thisWalletBalances = [];
                for (let j = 0; j < numTokens; j++) {
                    thisWalletBalances.push(BN(0));
                }
            } else {
                thisWalletBalances = await vi.getUserBalances(vault.address, walletAddress);
            }
            
            let hasBalance = false;
            for (let j = 0; j < thisWalletBalances.length; j++) {
                if (thisWalletBalances[j].gt(0)) {
                    hasBalance = true;
                    break;
                }
            }
            // const cond4 = showVaultsIUse && (vault.balances[walletAddress][0] > 0 || vault.balances[walletAddress][1] > 0);
            const cond4 = showVaultsIUse && (hasBalance);
            
            var toShow=true;
            if (cond0) {
                toShow = toShow && true;
            }
            else {
                if (showActiveVaults) {
                    toShow = toShow && cond1;
                }
                if (showPublicVaults) {
                    toShow = toShow && cond2;
                }
                if (showVaultsIManage) {
                    toShow = toShow && cond3;
                }
                if (showVaultsIUse) {
                    toShow = toShow && cond4;
                }
            }
            if (toShow) {
                vaultsToShowTemp.push(vault);
            }

            // if (cond0 || (cond1 || cond2)) {
            //     vaultsToShow.push(vault);
            // }
        }
        for (let i = 0; i < vaultsToShowTemp.length; i++) {
            const vault = vaultsToShowTemp[i];
            console.log('vault:', vault.name)
        }
        setVaultsToShow(vaultsToShowTemp);
        // setVaultsInfoLoaded(true);
        setMustRefreshVaultsListDiv(false);
    }

    async function fetchExistingVaults(vfV2In, vmV2In, viIn, provider, prefetchIn=null) {
        console.log('fetching existing vaults');
        setFetchingInfo(true);
        // const ownerFee = await vaultFactoryV2.getFeeOwner();

        
        const numVaults = (vfV2In.deployedVaults.length);
        console.log('numVaults', numVaults);
        var vaultV2List = [];
        var vaultAggregatedList = [];
        var vaultAggregatedDict = {};

        for (var i = 0; i < numVaults; i++) {
            const vaultAddress = vfV2In.deployedVaults[i];
            // console.log(i, 'vaultAddress', vaultAddress);

            let thisVault = new VaultV2(vaultAddress, provider, vaultInfo);
            await thisVault.init(prefetchIn);

            if (thisVault.isActive || thisVault.totalBalance.gt(0)) {
                vaultV2List.push(thisVault);
                var addressesForVault = [thisVault.operator];
                if (networkDict.address !== null && 
                    networkDict.address !== undefined && 
                    networkDict.address !== '' && 
                    networkDict.address !== thisVault.operator) {
                    addressesForVault.push(networkDict.address);
                }
                var va = new VaultAggregated(vfV2In, 
                                             vmV2In,
                                             viIn,
                                             thisVault, 
                                             networkDict, 
                                             addressesForVault);
                console.log(i, ' vaInit', va);
                await va.init(prefetchIn);
                console.log('done.');
                vaultAggregatedList.push(va);
                vaultAggregatedDict[vaultAddress] = va;
            }  
        }

        console.log('vaults', vaultV2List);

        setExistingVaults(vaultV2List);
        setExistingVaultsAggregated(vaultAggregatedList);
        setExistingVaultsAggregatedDict(vaultAggregatedDict);

        setExistingVaultsFetched(true);

        await refreshVaultsToShow(vaultV2List); //loads user balances

        // setOwnerFee(ownerFee); //MOVE TO MAIN FETCH
        setFetchingInfo(false);
        console.log('DONE fetching existing vaults');
    }
    //////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        const fetchInitialInfo = async (networkDict) => {
            var prefetchNew = await getPrefetch(devMode, networkDict.chainID);
            setPrefetch(prefetchNew);
            console.log('prefetchNew', prefetchNew);

            console.log('FETCHING INITIAL INFO');
            setFetchingInfo(true);
            
            const facV2address = contractAddresses.VaultFactoryV2;
            let facV2 = new VaultFactoryV2(facV2address, networkDict.provider);
            await facV2.init(prefetchNew);

            await fetchPossiblePairs(facV2, networkDict.provider, prefetchNew);
            setVaultFactoryV2(facV2);
            
            const vmAddress = contractAddresses.VaultManagerV2;
            const vmV2 = new VaultManagerV2(vmAddress, networkDict.provider);
            await vmV2.init(prefetchNew);
            await vmV2.fetchHistory(devMode, networkDict.chainID, false);

            setVaultManagerV2(vmV2);

            const vi = new VaultInfo(contractAddresses['VaultInfo'], networkDict.provider);
            setVaultInfo(vi);
            await fetchExistingVaults(facV2, vmV2, vi, networkDict.provider, prefetchNew);

            setOwnerFee(facV2.feeOwner);
            console.log('DONE FETCHING INITIAL INFO');
            setFetchingInfo(false);
        }
        console.log('useEffect 0');
        if (networkDict && 'provider' in networkDict) {
            console.log('networkDict', networkDict)
            setProvider(networkDict.provider);
            if (networkDict.signer !== null) {
                setSigner(networkDict.signer);
                setConnectedToWallet(true);
                setWalletAddress(networkDict.address);
            } else {
                setConnectedToWallet(false);
                setWalletAddress(null);
            }

            fetchInitialInfo(networkDict);

            // const vm = new VaultManager(networkDict.provider);
            // setVaultManager(vm);
            setConnectedToWallet(true);
        } else {
            setConnectedToWallet(false);
            setWalletAddress(null);
        }
    }, [networkDict]);

    useEffect(() => {
        console.log('useEffect 1');
        if (existingVaults !== null && existingVaults !== undefined && existingVaults.length > 0) {
            setVaultsInfoLoaded(false);
            refreshVaultsToShow();
        }
    }, [showAllVaults, showPublicVaults, showVaultsIManage, showVaultsIUse, mustRefreshVaultsListDiv]);

    useEffect(() => {
        console.log('IN useEffect::: vaultsToShow', vaultsToShow);
    }, [vaultsToShow]);

    useEffect(() => {
        // console.log('useEffect window dims');
        if (windowDimensions.width < 510) {
            setSmallScreen(true);
        } else {
            setSmallScreen(false);
        }
    }, [windowDimensions]);


    /////////////////////////////////////////////////////////////////////////////////
    async function createVault() {
        if (walletAddress === null) {
            alert('Please connect wallet first');
            return;
        }

        console.log('creating vault');
        console.log('selectedPair', selectedPair);
        console.log('vaultName', vaultName);
        console.log('userFee', userFee);
        console.log('operatorFee', operatorFee);
        const numVaults = await vaultFactoryV2.getNumVaults();
        console.log('numVaults', numVaults);

        const params = {'name': vaultName, 
                        'tokenList': [selectedPair[0], selectedPair[1]],
                        // 'token1': selectedPair[1],
                        'feeOperator': BN(operatorFee),
                        'feeUsers': BN(userFee),
                        'strategy': '', 
                        'allowOtherUsers': allowOtherUsers};
        //add tx alert stuff here for now

        setTxPending(true);
        
        try {
            ///////////////////////////////////////////////////////////////////
            const tx = await vaultFactoryV2.deploy(params);
            await tx.wait();
            console.log('tx', tx);
            setTxPending(false);
            setTxSuccess(true);
            setLastTxHash(tx.hash);
            console.log('tx.hash', tx.hash);
                    // const txHash = tx.hash;
            const newNumVaults = await vaultFactoryV2.getNumVaults();
            if (newNumVaults.eq(numVaults.add(BN(1)))) {
                console.log('vault created successfully');
                const newVault = await vaultFactoryV2.getVaultAddress(newNumVaults - 1);
                var url = getBaseURL()
                if (url.includes('localhost')) {
                    url = 'http://localhost:3000';
                }
                url = url + '/existingvault?address=' + newVault;
                console.log('opening url...', url, openNewVaultDialogOpen, openNewVaultDialogURL);
                // window.open(url, '_blank');
                setOpenNewVaultDialogURL(url);
                setOpenNewVaultDialogOpen(true);

                let fac2 = vaultFactoryV2;
                fac2.refresh();

                setVaultFactoryV2(fac2);

                setNewVaultAddress(newVault);
                setCreateNewVaultBool(false);
                fetchExistingVaults(fac2, provider);
            }
        }
        catch (error) {
            console.log('error', error);
            setTxPending(false);
            setTxSuccess(false);
            // setLastTxHash(null);
        }
    }

    function setSelectedPairFunction(pairString) {
        console.log('pairString', pairString);
        const index = possiblePairStrings.indexOf(pairString);
        console.log('index', index);
        setSelectedPair(possiblePairs[index]);
        setSelectedPairString(pairString);
    }

    function createNewVaultForm() {
        console.log('createNewVaultForm()');
        return(
            <VertDiv backgroundColor={theme.black} alignItems='stretch' 
                gap={theme.gap1} margin='0px' padding={theme.padding1}>
                <HorzDiv borderStyle='none'>
                    <Typography variant='h5' color='white'>
                        Create New Fund
                    </Typography>
                </HorzDiv>
                <HorzDiv borderColor={theme.light} backgroundColor={theme.dark} 
                        margin='0px' padding={theme.padding1} gap={theme.gap1}
                >
                    <VertDiv borderStyle='solid' borderColor={theme.light}
                        margin='0px' padding={theme.padding1} gap={theme.gap2}
                    >
                        <HorzDiv borderStyle='none' margin='0px' padding='0px'>
                            <Text fontSize={'1rem'} color={theme.white} margin="0px">Vault Name</Text>
                            <HorzDiv borderColor={theme.pink} borderRadius={theme.borderRadiusSmall}
                                margin='0px' gap='0px' padding='5px'>
                                <Input 
                                    fontWeight="500"
                                    fontSize={theme.fontSizeMedium}
                                    width="11rem"
                                    size="19rem"
                                    textAlign="center"
                                    // bg="rgb(247, 248, 250)"
                                    bg={theme.dark}
                                    outline="none"
                                    border="none"
                                    focusBorderColor="none"
                                    color='white'
                                    defaultValue={vaultName}
                                    onBlurCapture={event=>setVaultName(event.target.value)}   
                                />
                            </HorzDiv>
                        </HorzDiv>
                        <HorzDiv borderStyle='none' color='white' margin='0px' padding='0px'>
                            <div>Select Pair</div>
                            <select onChange={event => setSelectedPairFunction(event.target.value)} style={{width: '160px'}}>
                                {possiblePairStrings.map(item => <option value={item} selected={item===selectedPairString ? true : false} >{item}</option>)}
                            </select>
                        </HorzDiv>
                    </VertDiv>
                    <VertDiv borderColor={theme.light}>
                        <SimpleCheckBox 
                            borderColor={theme.pink} 
                            label='Public Vault' 
                            state={allowOtherUsers} 
                            setState={setAllowOtherUsers}     
                        />
                        <InfoDialog 
                            buttonVariant='outlined'
                            buttonInnards={<InfoIcon />}
                            titleString='Public vs Private Vaults'
                            setDialogOpenFlag={setDialogOpenBool}
                            contentString={
                                <VertDiv borderStyle='none' margin='0px' padding='0px'>
                                    <Text>
                                        Check "Public" to allow other users to deposit into the fund. These users will pay you (and/or other users) a withdrawal fee as you specify below.
                                    </Text>
                                    <Text>
                                        This property can be turned on or off later. If a Public Vault is later changed to Private, existing users will still be able to withdraw, but deposits will be deactivated.
                                    </Text>  
                                </VertDiv>}
                        />
                    </VertDiv>
                </HorzDiv>
                <HorzDiv borderColor={theme.light} backgroundColor={theme.dark} 
                        margin='0px' padding={theme.padding1} gap='5px'
                >
                    <InputWithLabel 
                        label='Operator Fee (%)'
                        defaultValue={(operatorFee/1000).toFixed(1)}
                        onBlur={event=>setOperatorFee(Math.floor(event.target.value*1000))}
                        min={0}
                        max={20}
                        step={0.1}
                        precision={1}
                        borderColor={theme.pink}
                        width='7rem'
                    />
                    <InputWithLabel
                        label='User Fee (%)'
                        defaultValue={(userFee/1000).toFixed(1)}
                        onBlur={event=>setUserFee(Math.floor(event.target.value*1000))}
                        min={0}
                        max={20}
                        step={0.1}
                        precision={1}
                        borderColor={theme.pink}
                        width='7rem'
                    />
                    <HorzDiv borderStyle='none'>
                        <InfoDialog 
                            buttonVariant='outlined'
                            buttonInnards={<InfoIcon />}
                            titleString='Fee Structure'
                            setDialogOpenFlag={setDialogOpenBool}
                            contentString={
                                <VertDiv borderStyle='none' margin='0px' padding='0px'>
                                    <Text>
                                        Protocol Fees are as follows. Please read carefully before creating a new Fund.
                                    </Text>
                                    <UnorderedList>
                                        <ListItem>Fees apply to all users of the fund, other than the Fund Creator (a.k.a. "Fund Manager" or "Fund Operator"). Fund Managers are exempt from all fees and the protocol is free to use.</ListItem>
                                        <ListItem>All Fees are collected at the time of withdrawal from the fund.</ListItem>
                                        <ListItem>"Operator Fee" is sent to the Fund Manager's Wallet. </ListItem>
                                        <ListItem>"User Fee" is kept in the Fund's Vault, and is effectively distributed to all current users of the vault, in proportion to their share of the total. The Fund Manager is treated as a user like any other, so that the Fund Manager's total Fee is the sum of the Operator Fee and their share of the User Fees.</ListItem>
                                        <ListItem>"Owner Fee" is sent to the Protocol Wallet and is fixed at {ownerFee/1000}%. </ListItem>
                                        <ListItem>All Fees are determined at the time of the Fund's creation and are unchangable afterward.</ListItem>
                                        <ListItem>The Total Fee (Operator Fee + User Fee + Protocol Fee) must be less than or equal to 20%</ListItem>
                                    </UnorderedList>
                                </VertDiv>}
                        />
                    </HorzDiv>
                    
                </HorzDiv>
                
                <ActionDialog 
                    buttonInnards={"Create Vault"}
                    buttonVariant='contained'
                    buttonSX={theme.buttonSX}
                    buttonStyle={theme.buttonStyle}
                    disabled={ownerFee/1000 + operatorFee/1000 + userFee/1000 > 20 || connectedToWallet === false}
                    handleAcceptIn={() => createVault()}
                    
                    titleString='Create New Vault???'
                    setDialogOpenFlag={setDialogOpenBool}
                    contentString={
                        <VertDiv borderStyle='none' margin='0px' padding='0px' alignItems='flex-start'>
                            <Text>You are about to create a new Vault with the following properties:</Text>
                            <UnorderedList>
                                <ListItem>Vault Name: {vaultName}</ListItem>
                                <ListItem>Pair: {selectedPairString}</ListItem>
                                <ListItem>Public: {allowOtherUsers ? 'Yes' : 'No'}</ListItem>
                                <ListItem>"Owner Fee" set to {ownerFee/1000}% (set by the protocol). </ListItem>
                                <ListItem>"Operator Fee" set to {operatorFee/1000}%</ListItem>
                                <ListItem>"User Fee" set to {userFee/1000}%</ListItem>
                            </UnorderedList>
                        </VertDiv>}
                />
                <OpenNewVaultDialog 
                    isOpenBool={openNewVaultDialogOpen} 
                    setIsOpenBool={setOpenNewVaultDialogOpen}
                    url={openNewVaultDialogURL}
                    setDialogOpenFlag={setDialogOpenBool}     
                />
            </VertDiv>
        )
    }

    function handleShowAllVaults(state) {
        setVaultsToShow([]);
        setShowAllVaults(state);
        setShowActiveVaults(state ? false : showActiveVaults);
        setShowPublicVaults(state ? false : showPublicVaults);
        setShowVaultsIManage(state ? false : showVaultsIManage);
        setShowVaultsIUse(state ? false : showVaultsIUse);
        setMustRefreshVaultsListDiv(true);
    }

    function handleShowActiveVaults(state) {
        setVaultsToShow([]);
        setShowActiveVaults(state);
        setShowAllVaults(state ? false : showAllVaults)
        setMustRefreshVaultsListDiv(true);
    }

    function handleShowPublicVaults(state) {
        setVaultsToShow([]);
        setShowPublicVaults(state);
        setShowAllVaults(state ? false : showAllVaults)
        setMustRefreshVaultsListDiv(true);
    }

    function handleShowVaultsIManage(state) {
        setVaultsToShow([]);
        setShowVaultsIManage(state);
        setShowAllVaults(state ? false : showAllVaults)
        setMustRefreshVaultsListDiv(true);
    }

    function handleShowVaultsIUse(state) {
        setVaultsToShow([]);
        setShowVaultsIUse(state);
        setShowAllVaults(state ? false : showAllVaults)
        setMustRefreshVaultsListDiv(true);
    }

    function Header() {
        return(
            <VertDiv maxWidth='800px' backgroundColor={theme.blue}>
                <Text fontSize={'2.0rem'} color={theme.white} margin="0px" padding='0px'>
                    Welcome!
                </Text>
                <Text>
                    Please have a look at our existing vaults or create your own. 
                    Vaults are limited to 2-token funds. We will be expanding the front-end capability to include 
                    multi-token funds in the near future.
                </Text>
            </VertDiv>
        );
    }

    function VaultsSelection() {    
        return(

            <HorzDiv backgroundColor={theme.dark}>
                <SimpleCheckBox 
                    label='Show All'
                    state={showAllVaults}
                    setState={handleShowAllVaults}
                />

                <SimpleCheckBox
                    label='Active Vaults'
                    state={showActiveVaults}
                    setState={handleShowActiveVaults}
                />

                <SimpleCheckBox
                    label='Public Vaults'
                    state={showPublicVaults}
                    setState={handleShowPublicVaults}
                />

                <SimpleCheckBox 
                    label='Vaults I Manage'
                    state={showVaultsIManage}
                    setState={handleShowVaultsIManage} 
                />
                <SimpleCheckBox
                    label='Vaults I Use'
                    state={showVaultsIUse}
                    setState={handleShowVaultsIUse}
                />
            </HorzDiv>

        );
    }

    return(
        <VertDiv 
            borderStyle='none' 
            alignItems='center' 
            margin='0px' 
            padding={theme.padding1}
        >
            {FadeInAnimation(Header())}

            <VertDiv borderStyle='none' margin='0px' padding='0px'>
                {FadeInAnimation(createNewVaultForm())}
                {fetchingInfo && <Text>Fetching vault info...</Text>}
                <VertDiv
                    backgroundColor={theme.blue} 
                    alignItems={"stretch"} 
                    padding={theme.padding1}
                    margin="0px" gap="0px"
                >
                    <VertDiv borderStyle='none' margin='0px' padding='0px' gap='0px'>
                        <Text fontSize={theme.fontSizeLarge} margin={theme.margin1}>
                            Existing Vaults
                        </Text>
                        <VaultsSelection />
                        <ListVaults 
                            items={vaultsToShow}
                            vaultsAggregatedDict={existingVaultsAggregatedDict}
                            smallScreen={smallScreen}
                        />
                    </VertDiv>
                </VertDiv>
            </VertDiv>  
        </VertDiv>
    )
}


function VaultDiv({vaultAddress, vaObj, onCompletion, smallScreen=false}) {
    var flexType;
    var wrapType;
    var bWidth;
    var bHeight;
    var alignType;
    var bStyle;
    var jContent;
    if (!smallScreen) {
        flexType='row';
        wrapType = 'nowrap';
        bWidth = '30px';
        bHeight = '230px';
        alignType='center';
        bStyle='none';
        jContent='left'
    } else {
        flexType='column';
        wrapType = 'wrap';
        bWidth = '50%';
        bHeight = '45px';
        alignType='stretch';
        bStyle='solid';
        jContent='center'
    }
    return(
        <HorzDiv
            flexDirection={flexType}
            borderStyle={bStyle}
            margin="0px" padding={theme.padding0} gap="0px" 
            alignItems={alignType} 
            flexWrap={wrapType}
            justifyContent='left'
        >
            <Link to={'/existingvault?address='+vaultAddress}> 
                <Button 
                    sx={{p:0, 
                        m:0, 
                        width: bWidth, 
                        height: bHeight, 
                        margin: '5px'}}
                    variant='contained'
                    size='small'
                    style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                >
                    Open Vault
                </Button>
            </Link>

            <ExistingVaultForm 
                address={vaultAddress}
                minimal={true} 
                vaultAggregatedObjIn={vaObj}
                // prefetchIn={prefetch}
                onCompletion={onCompletion}
            />
        </HorzDiv>
    );
}

function ListVaults({items, vaultsAggregatedDict, smallScreen=false}) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCompletion = () => {
        setCurrentIndex(prevIndex => prevIndex + 1);
    };

    return (
        <div>
            {items.slice(0, currentIndex + 1).map((item, index) => 
                <VaultDiv
                    key={item.address}
                    vaultAddress={item.address}
                    vaObj={vaultsAggregatedDict[item.address]}
                    onCompletion={index === currentIndex ? handleCompletion : null}
                    smallScreen={smallScreen}
                />
            )}
        </div>
    );
}
function ListDummys({items}) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCompletion = () => {
        setCurrentIndex(prevIndex => prevIndex + 1);
    };

    // Assuming you have an array of items to map (like vaultsToShow), 
    // just for this example let's use an array of numbers.
    // const items = [1, 2, 3, 4, 5];

    return (
        <div>
            {items.slice(0, currentIndex + 1).map((item, index) => 
                <DummyComponent 
                    // key={item} 
                    vaultAddress={item}
                    onCompletion={index === currentIndex ? handleCompletion : null} 
                />
            )}
        </div>
    );
}
function DummyComponent({ vaultAddress, onCompletion }) {
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        // console.log('dummy component useEffect')
        const timer = setTimeout(() => {
            setStatus('Done');
            if (onCompletion) onCompletion();
        }, 500); 

        return () => clearTimeout(timer); // Cleanup
    }, []);

    return <div>{vaultAddress}: {status}</div>;
}

function DummyDummy() {
    const [huh, setHuh] = useState(0);
    // console.log('dummy dummy', huh)
    return(
        <div>
            Dumy
            <button onClick={() => setHuh(huh+1)}>Click</button>
        </div>
    )
}

export default VaultsOverviewPage;
