import {useState, useEffect} from 'react';
import {isMobile, isChrome, isChromium, isFirefox} from 'react-device-detect';
import { getGPUTier } from 'detect-gpu';
import {Button} from '@mui/material';

import axios from "axios";
import {getBaseURL, splitArray, getArrayRandomElement, getRandomInt} from '../functions/functions.js';


import SceneSimple from './SceneSimple';
import AlertDialogSlide from './SlideDialog.tsx';



import backgroundImg from '../images/spheres1800.png';
import { View, Image } from 'react-native';


const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        objectFit: 'cover',
    },
};

function OrbitalSceneSimple() {
    const initialStatesList =   [
        ['4,1,0', '', ''],
        ['4,2,0', '', ''],
        ['4,2,-1', '4,2,1', ''],
        ['4,2,-2', '4,2,2', ''],
        ['4,3,0', '', ''],
        ['4,3,-1', '4,3,1', ''],
        ['4,3,-2', '4,3,2', ''],
        ['4,3,-3', '4,3,3', ''],
        ['5,1,0', '', ''],
        ['5,2,0', '', ''],
        ['5,2,-1', '5,2,1', ''],
        ['5,2,-2', '5,2,2', ''],
        ['5,3,0', '', ''],
        ['5,3,-1', '5,3,1', ''],
        ['5,3,-2', '5,3,2', ''],
        ['5,3,-3', '5,3,3', ''],
        ['5,4,0', '', ''],
        ['5,4,-1', '5,4,1', ''],
        ['5,4,-2', '5,4,2', ''],
        ['5,4,-3', '5,4,3', ''],
        ['5,4,-4', '5,4,4', ''],
        ['6,1,0', '', ''],
        ['6,2,0', '', ''],
        ['6,2,-1', '6,2,1', ''],
        ['6,2,-2', '6,2,2', ''],
        ['6,3,0', '', ''],
        ['6,3,-1', '6,3,1', ''],
        ['6,3,-2', '6,3,2', ''],
        ['6,3,-3', '6,3,3', ''],
        ['6,4,0', '', ''],
        ['6,4,-1', '6,4,1', ''],
        ['6,4,-2', '6,4,2', ''],
        ['6,4,-3', '6,4,3', ''],
        ['6,4,-4', '6,4,4', ''],
        ['6,5,0', '', ''],
        ['6,5,-1', '6,5,1', ''],
        ['6,5,-2', '6,5,2', ''],
        ['6,5,-3', '6,5,3', ''],
        ['6,5,-4', '6,5,4', ''],
        ['6,5,-5', '6,5,5', '']];//,
        // ['7,1,0', '', ''],
        // ['7,2,0', '', ''],
        // ['7,2,-1', '7,2,1', ''],
        // ['7,2,-2', '7,2,2', ''],
        // ['7,3,0', '', ''],
        // ['7,3,-1', '7,3,1', ''],
        // ['7,3,-2', '7,3,2', ''],
        // ['7,3,-3', '7,3,3', ''],
        // ['7,4,0', '', ''],
        // ['7,4,-1', '7,4,1', ''],
        // ['7,4,-2', '7,4,2', ''],
        // ['7,4,-3', '7,4,3', ''],
        // ['7,4,-4', '7,4,4', ''],
        // ['7,5,0', '', ''],
        // ['7,5,-1', '7,5,1', ''],
        // ['7,5,-2', '7,5,2', ''],
        // ['7,5,-3', '7,5,3', ''],
        // ['7,5,-4', '7,5,4', ''],
        // ['7,5,-5', '7,5,5', ''],
        // ['7,6,0', '', ''],
        // ['7,6,-1', '7,6,1', ''],
        // ['7,6,-2', '7,6,2', ''],
        // ['7,6,-3', '7,6,3', ''],
        // ['7,6,-4', '7,6,4', ''],
        // ['7,6,-5', '7,6,5', ''],
        // ['7,6,-6', '7,6,6', '']];


    const [useAnimation, setUseAnimation] = useState(false);
    const [numSpheres, setNumSpheres] = useState(100);

    const [loadedState, setLoadedState] = useState(null); // = createFullStateString(firstState, secondState, thirdState);

    const [cameraRad, setCameraRad] = useState(1);
    const [sphereRad, setSphereRad] = useState(1);

    const [initialized, setInitialized] = useState(false);
    // const [gpuTier, setGPUTier] = useState(null);

    // async function fetchStates() {
    //     var baseURL = getBaseURL();
    //     if (baseURL.includes('localhost')) {
    //         baseURL = baseURL+':8000';
    //     }
    //     const url = baseURL+'/api/fetchstates';
    //     await axios.get(url).then(res => {
    //         console.log('finished fetching...');
    //         // setAllStates(res.data.states);
    //         // console.log('set????:', allStates);
    //         parseAllStates(res.data.states);
    //     });   
    // }

    async function setGPUParams() {
        const gpuTier = await getGPUTier();
        console.log('gpuTier:', gpuTier);
        // setGPUTier(gpuTier);

        if (gpuTier.tier >= 2 && !gpuTier.isMobile) {
        // if (false) {
            const initialStateRand = getArrayRandomElement(initialStatesList);
            const firstStateInitial = initialStateRand[0];
            const secondStateInitial = initialStateRand[1];
            const thirdStateInitial = initialStateRand[2];

            const st = createFullStateString(firstStateInitial, secondStateInitial, thirdStateInitial);
            setLoadedState(st);

            // setFirstState(firstStateInitial);
            // setSecondState(secondStateInitial);
            // setThirdState(thirdStateInitial);

            const energyLevel = parseInt(firstStateInitial.split(',')[0]);

            setCameraRad(15*energyLevel**2/9);
            setSphereRad(0.12*energyLevel**2/9);

            setUseAnimation(true);

            var n = 0;
            if (gpuTier.tier == 2) {
                n = 700;
            } else {
                if (isChrome || isChromium) {
                    n = 3000;
                } else if (isFirefox){ 
                    n = 700;
                } else {
                    n = 1500;
                }
            }
            
            // if (!(isChrome || isChromium)) {
            //     n = n/2;
            // }

            setNumSpheres(n);
        }

        setInitialized(true);
    }

    useEffect(() => {
        setGPUParams();
    }, []);


    function createFullStateString(firstStateIn, secondStateIn, thirdStateIn) {
        var fullState = '';
        if (firstStateIn !== '' && firstStateIn !== '|>') {
            fullState += "{";
            fullState += "{1,{"+firstStateIn+"}},";
            if (secondStateIn !== '' && secondStateIn !== '|>') {
                fullState += "{1,{"+secondStateIn+"}},";
                if (thirdStateIn !== '' && thirdStateIn !== '|>') {
                    fullState += "{1,{"+thirdStateIn+"}},";
                } 
            }
            fullState = fullState.slice(0, -1) + "}";
        }
        console.log('in createFullStateString, fullState:', fullState);
        return fullState;
    }


    if (!initialized) {
        return (
            <div style={styles.container}>

            </div>
        );
    } else if (useAnimation) {
        return (
            <SceneSimple 
                stateName={loadedState} 
                numPaths={numSpheres}
                cameraRadius={cameraRad}
                sphereRadius={sphereRad}
            />  
        );
    } else {
        return(
            <div style={styles.container}>
                <img 
                    src={backgroundImg} 
                    alt="background" 
                    style={styles.image}  
                />
            </div>   
        );
    }
}


export default OrbitalSceneSimple;

