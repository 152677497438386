import {ethers, BigNumber} from 'ethers';
import {bigNumToFloat, bigNumToHuman, humanToBigNum, BN, getBaseURL, 
        sqrtX96ToPrice, decomposeTradePath, createFullPathDisplay, dateToEpoch } from '../functions/functions.js';
import erc20Abi from '../components/Interfaces/Arbitrum/erc20Generic.json';

import uniRouterAbi from '../components/Interfaces/Arbitrum/V3Router.json';
import uniFactoryAbi from '../components/Interfaces/Arbitrum/V3Factory.json';
// import uniNFTAbi from '../components/Interfaces/Arbitrum/V3NFT.json';
import uniPoolAbi from '../components/Interfaces/Arbitrum/V3Pool.json';
import vaultFactoryAbi from '../components/Interfaces/Arbitrum/VaultFactory.json';
import vaultManagerAbi from '../components/Interfaces/Arbitrum/VaultManager.json';
import gasStationAbi from '../components/Interfaces/Arbitrum/GasStation.json';
import quoterAbi from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';
import axios from 'axios';

// import { useState, useContext } from 'react';
// import { UserContext } from '../App'


import ethIcon from '../images/icon/eth.svg';
import btcIcon from '../images/icon/btc.svg';
import usdcIcon from '../images/icon/usdc.svg';
import gmxIcon from '../images/icon/gmx.svg';
import usdtIcon from '../images/icon/usdt.svg';
import arbIcon from '../images/arbitrum-logo.png';
import linkIcon from '../images/icon/link.svg';

export class TokenLibrary {
    constructor() { //make sure these are properly formatted (not all in lower case)
        this.info = [['USDT', '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', usdtIcon, 3, 1],
                     ['USDC', '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', usdcIcon, 3, 1],
                     ['WETH', '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', ethIcon, 4, 0.001],
                     ['WBTC', '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f', btcIcon, 6, 0.0001], 
                     ['GMX',  '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a', gmxIcon, 4, 0.01], 
                     ['ARB', '0x912CE59144191C1204E64559FE8253a0e49E6548', arbIcon, 4, 0.01], 
                     ['LINK', "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4", linkIcon, 4, 0.01]
                    ];
        this.dict = {};
        for (var i = 0; i < this.info.length; i++) {
            const sym = this.info[i][0];
            const addr = this.info[i][1];
            const icon = this.info[i][2];
            const decimalDisplay = this.info[i][3];
            const priceCheckTestAmt = this.info[i][4];
            const temp = {symbol: sym, address: addr, icon: icon, decimalDisplay: decimalDisplay, priceCheckTestAmt: priceCheckTestAmt};
            this.dict[sym] = temp;
            this.dict[sym.toLowerCase()] = temp;
            this.dict[addr] = temp;
            this.dict[addr.toLowerCase()] = temp;
        }
    }
    getSymbol(inp){
        return this.dict[inp].symbol;
    }
    getAddress(inp){
        return this.dict[inp].address;
    }
    getIcon(inp){
        return this.dict[inp].icon;
    }
    getDecimalDisplay(inp){
        try {
            return this.dict[inp].decimalDisplay;
        } catch (e) {
            console.log('Error in getDecimalDisplay: ', e);
            return 4;
        }
    }
    getPriceCheckTestAmt(inp){
        return this.dict[inp].priceCheckTestAmt;
    }
}

export class Token {
    constructor(address, contract, providerIn) {
        this.address = address;
        this.contract = contract;
        this.provider = providerIn;
        this.signer = this.provider.getSigner();
        this.initialized = false;
    }
    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && this.address in prefetch) {
            this.name = prefetch[this.address].name;
            this.symbol = prefetch[this.address].symbol;
            this.decimals = BN(prefetch[this.address].decimals);
        } else {
            this.name = await this.contract.name();
            this.symbol = await this.contract.symbol();
            this.decimals = await this.contract.decimals();
        }
        this.initialized = true;
    }
    toFloat(amt) {  
        return bigNumToFloat(amt, this.decimals);
    }
    toHuman(amt, decimalsToShow=4) {
        return bigNumToHuman(amt, this.decimals, decimalsToShow);
    }
    toBigNum(amtFloat) {
        if (amtFloat === '') {
            return BigNumber.from(0);
        }
        return humanToBigNum(amtFloat, this.decimals);
    }
    async getBalance(address) {
        return await this.contract.balanceOf(address);
    }
    async getAllowance(address, spender) {
        return await this.contract.allowance(address, spender);
    }
    async approve(spender, amt, signer) {
        return await this.contract.connect(this.signer).approve(spender, amt);
    }
}

export class PriceFetcher {
    constructor() {
        this.baseURL = getBaseURL()
        if (this.baseURL.includes('localhost')) {
            this.baseURL = this.baseURL+':8000';
        }
        this.tokenAlterations = {'DAI': 'USD', 'USDC': 'USD', 'USDT': 'USD', 'WETH': 'ETH', 'WBTC': 'BTC'} 
        // this.priceHistory = {'times': [], 'close': [], 'high': [], 'low': [], 'open': [], 'volume': []};  
    }
    async fetchHistory(tokenSecondaryIn, tokenPrimaryIn, startDateIn, endDateIn, dataInterval) {
        const tokenPrimary = this.tokenAlterations[tokenPrimaryIn] ? this.tokenAlterations[tokenPrimaryIn] : tokenPrimaryIn
        const tokenSecondary = this.tokenAlterations[tokenSecondaryIn] ? this.tokenAlterations[tokenSecondaryIn] : tokenSecondaryIn
        var startTime;
        var endTime;
        if (startDateIn instanceof Date){
            startTime = dateToEpoch(startDateIn).toString();
        } else {
            startTime = startDateIn;
        }
        if (endDateIn instanceof Date){
            endTime = dateToEpoch(endDateIn).toString();
        } else {
            endTime = endDateIn;
        }
        // const startDate = startDateIn.toISOString().split('T')[0];
        // const endDate = endDateIn.toISOString().split('T')[0];

        var url = this.baseURL+'/api/fetchhistorytv/'+tokenSecondary+'/'+tokenPrimary+'/'+startTime.toString()+'/'+endTime.toString()+'/'+dataInterval;
        var priceHistory;
        await axios.get(url).then(res => 
            {
                priceHistory = res.data;
                priceHistory.timesEpoch = res.data.times;
                // const localTimes = res.data.times.map(d => utcTimeToLocal(d));
                priceHistory.times = res.data.times.map(d => new Date(d*1000));
                priceHistory.dataInterval = dataInterval;
                // this.priceHistory = priceHistory;
            }
        );
        return priceHistory;
        // return this;
    }
}

export class VaultFactory {
    constructor(providerIn) {
        this.address = vaultFactoryAbi.address;
        this.abi = vaultFactoryAbi.abi;
        this.provider = providerIn;
        this.signer = providerIn.getSigner();
        this.contract = new ethers.Contract(this.address, this.abi, providerIn);
        this.initialized = false;
        this.history = [];
        this.birthBlock = 51290592; //= this.contract.getCreationBlock(); //added to contract
    }
}

export class VaultManager {
    constructor(providerIn) {
        this.address = vaultManagerAbi.address;
        this.abi = vaultManagerAbi.abi;
        this.provider = providerIn;
        this.signer = providerIn.getSigner();
        this.contract = new ethers.Contract(this.address, this.abi, providerIn);
        this.initialized = false;
        this.history = [];
        this.birthBlock = 51290592; //= this.contract.getCreationBlock(); //added to contract
    }

    async fetchHistory(fetchNewInfo=false) {
        var baseURL = getBaseURL()
        if (baseURL.includes('localhost')) {
            baseURL = baseURL+':8000';
        }
        var url0;
        if (fetchNewInfo) {
            url0 = baseURL+'/api/fetchvaultmanagerhistory/'+this.birthBlock.toString()+'/true';
        } else {
            url0 = baseURL+'/api/fetchvaultmanagerhistory/'+this.birthBlock.toString()+'/false';
        }
        
        await axios.get(url0).then(res0 => 
            {
                this.history = res0.data;
                this.initialized = true;
            }
        );
        return this;
    }
}

export class Router {
    constructor(routerAddress, providerIn) {
        this.address = routerAddress;
        this.provider = providerIn;
        this.contract = new ethers.Contract(routerAddress, uniRouterAbi, providerIn);
        this.signer = providerIn.getSigner();
        this.initialized = false;
    }
    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && this.address in prefetch) {
            this.factoryAddress = prefetch[this.address].factory;
        } else {
            this.factoryAddress = await this.contract.factory();
        }
        this.factory = new ethers.Contract(this.factoryAddress, uniFactoryAbi, this.provider);
        this.initialized = true;
    }

    //token0 and token1 are Token objects. Primary token is basis for the price (i.e. 1 ETH=1000 USDC, then primary token is USDC)
    //in this case token0 is ETH and token1 is USDC
    async getPoolPrice(token0, token1, fee, primaryToken=1) {
        const poolAddress = await this.factory.getPool(token0.address, token1.address, fee);
        const pool = new ethers.Contract(poolAddress, uniPoolAbi, this.provider);

        const slt = await pool.slot0();
        const sqrtPriceX96 = slt[0];
        //This seems all correct. single step works.
        var price = sqrtX96ToPrice(sqrtPriceX96, token0.decimals, token1.decimals);
        if (primaryToken === 0) {
            price = 1.0/price;
        }
        // console.log('price: ' + price, 1.0/price);
        return price;
    }

    async getPriceFromPath(feesOnPath, addressesOnPath, primaryToken=1) {
        var price = 1.0;
        for (var i=0; i<feesOnPath.length; i++) {
            const thisFee = feesOnPath[i];
            const address0 = addressesOnPath[i].toLowerCase();
            const address1 = addressesOnPath[i+1].toLowerCase();
            var t0;
            var t1;
            var primaryThisStep;
            if (address0 < address1) {
                t0 = address0;
                t1 = address1;
                primaryThisStep = 1;
            } else {
                t0 = address1;
                t1 = address0;
                primaryThisStep = 0;
            }
            // primaryThisStep = primaryToken;
            const t0Contract = new ethers.Contract(t0, erc20Abi, this.provider);
            const t1Contract = new ethers.Contract(t1, erc20Abi, this.provider);
            const token0 = new Token(t0, t0Contract, this.provider);
            const token1 = new Token(t1, t1Contract, this.provider);
            await token0.init();
            await token1.init();
            const stepPrice = await this.getPoolPrice(token0, token1, thisFee, primaryThisStep);
            price = price*stepPrice;
        }
        if (primaryToken === 1) {
            return price;
        } else {
            return 1.0/price;
        }
    }
}

export class Vault {
    constructor(addressIn, vaultManagerObject, providerIn, walletsToWatchList) {
        this.address = addressIn;
        this.manager = vaultManagerObject.contract;
        //needs to be merged with this.manager (remove this.manager). 
        //currently, this.VM is only for fetching vm history from the backend.
        this.VM = new VaultManager(providerIn); 
        this.provider = providerIn;
        this.signer = providerIn.getSigner();
        this.walletsToWatch = walletsToWatchList;
        this.allowances = {};
        this.balances = {};
        // this.factory = null;
        this.initialized = false;
        this.confirmations = 1;
    }
    async init() {
        // this.name = await this.contract.name();
        const facAddress = await this.manager.getFactoryAddress();
        this.vaultFactory = new ethers.Contract(facAddress, vaultFactoryAbi.abi, this.provider);
        this.vaultInfo = await this.manager.getVaultInfo(this.address);
        this.owner = await this.manager.owner();
        this.gasStationAddress = await this.manager.getGasStationAddress();
        this.useGasStation = await this.manager.getUseGasStation();
        this.gasStation = new ethers.Contract(this.gasStationAddress, gasStationAbi.abi, this.provider);
        this.gasStationBalance = await this.getGasStationBalance();

        this.name = this.vaultInfo.name;
        this.creationTime = this.vaultInfo.creationTime;
        this.strategy = this.vaultInfo.strategy;
        this.token0Add = this.vaultInfo.token0;
        this.token1Add = this.vaultInfo.token1;
        this.routerAddress = this.vaultInfo.routerAddress;
        this.isActive = this.vaultInfo.isActive;
        this.autotradeActive = this.vaultInfo.autotradeActive;
        this.allowOtherUsers = this.vaultInfo.allowOtherUsers;
        
        this.operator = this.vaultInfo.operator;

        this.fees = this.vaultInfo.feeRate;
        this.feeOwner = this.fees.owner;
        this.feeOperator = this.fees.operator;
        this.feeUsers = this.fees.users;
        this.walletsToWatch.push(this.operator);
 
        const t0Contract = new ethers.Contract(this.token0Add, erc20Abi, this.provider);
        const t1Contract = new ethers.Contract(this.token1Add, erc20Abi, this.provider);
        this.token0 = new Token(this.token0Add, t0Contract, this.provider);
        this.token1 = new Token(this.token1Add, t1Contract, this.provider);
        this.tokens = [this.token0, this.token1];

        this.token0MinDep = await this.vaultFactory.getMinDepositAmt(this.token0.address);
        this.token1MinDep = await this.vaultFactory.getMinDepositAmt(this.token1.address);
        this.router = new Router(this.routerAddress, this.provider);
        await this.router.init();

        await this.token0.init();
        await this.token1.init();
        this.pairString = this.token0.symbol + "-" + this.token1.symbol;
        await this.update()
        this.initialized = true;
    }
    async update(updateVMHistory=false) {
        this.vaultInfo = await this.manager.getVaultInfo(this.address);
        this.owner = await this.manager.owner();
        this.operator = this.vaultInfo.operator;
        this.routerAddress = this.vaultInfo.router;
        this.isActive = this.vaultInfo.isActive;
        this.allowOtherUsers = this.vaultInfo.allowOtherUsers;
        this.strategy = this.vaultInfo.strategy;
        this.useGasStation = await this.manager.getUseGasStation();
        this.gasStationBalance = await this.getGasStationBalance();
        // console.log('VAULTINFO', this.vaultInfo)
        // const balances = (this.vaultInfo.T0, this.vaultInfo.T1);
        this.balance0 = this.vaultInfo.T0;
        this.balance1 = this.vaultInfo.T1;
        
        for (const wallet of this.walletsToWatch) {
            this.allowances[wallet] = [
                await this.token0.getAllowance(wallet, this.manager.address),
                await this.token1.getAllowance(wallet, this.manager.address)
            ];
            this.balances[wallet] = await this.manager.getBalances(this.address, wallet);
        }
        this.paths = await this.getAllowedPaths();

        if (updateVMHistory) {
            this.VM.fetchHistory(true); //update vm history on server and pass back to here
        }
        return this;
    }
    async getGasStationBalance() {
        this.gasStationBalance = await this.gasStation.balanceOf(this.signer.getAddress());
        return this.gasStationBalance;
    }
    async depositGasStation(amountEth) {
        const tx = await this.gasStation.connect(this.signer).deposit({value: amountEth});
        await tx.wait(this.confirmations);
        await this.update();
    }
    async withdrawGasStation(amountEth) {
        const currentBalance =  await this.getGasStationBalance();
        var amountToWithdraw;
        if (amountEth > currentBalance) {
            amountToWithdraw = currentBalance;
        } else {
            amountToWithdraw = amountEth;
        }
        const tx = await this.gasStation.connect(this.signer).withdraw(amountToWithdraw);
        await tx.wait(this.confirmations);
        await this.update();
    }
    async getAllowedPaths() {
        const numPaths = await this.vaultFactory.getNumAllowedPaths(this.token0.address, this.token1.address);
        const paths = [];
        for (let i = 0; i < numPaths; i++) {
            paths.push(await this.vaultFactory.getAllowedPath(this.token0.address, this.token1.address, i));
        }
        return paths;
    }
    showBalance(num, decimalsToShow=4, account='pool') {
        if (account === 'pool') {
            if (num === 0) {
                return this.token0.toHuman(this.balance0, decimalsToShow);
            } else {
                return this.token1.toHuman(this.balance1, decimalsToShow);
            }
        } else {
            if (this.balances[account] !== undefined) {
                if (num === 0) {
                    return this.token0.toHuman(this.balances[account][0], decimalsToShow);
                } else {
                    return this.token1.toHuman(this.balances[account][1], decimalsToShow);
                }
            } else {
                return 0;
            }
        }
    }
    async setStrategy(strategy, activate=true) {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (activate) {
                tx = await this.manager.connect(this.signer).setStrategyAndActivate(this.address, strategy, true);
            } else {
                tx = await this.manager.connect(this.signer).setStrategy(this.address, strategy);
            }
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async deactivate() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.isActive) {
                tx = await this.manager.connect(this.signer).deactivate(this.address);
            } else {
                console.log('Vault is already inactive')
            }
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async setOperator(newOperator) {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            tx = await this.manager.connect(this.signer).setOperator(this.address, newOperator);
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async toggleAutotrade() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.autotradeActive) {
                tx = await this.manager.connect(this.signer).setAutotrade(this.address, false);
                await tx.wait(this.confirmations);
                await this.update(true);
            } else {
                if (this.isActive && this.strategy !== '') {
                    tx = await this.manager.connect(this.signer).setAutotrade(this.address, true);
                    await tx.wait(this.confirmations);
                    await this.update(true);
                }
            }
            
        }
        return tx;
    }
    async togglePublic() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.allowOtherUsers) {
                tx = await this.manager.connect(this.signer).setAllowOtherUsers(this.address, false);
            } else {
                tx = await this.manager.connect(this.signer).setAllowOtherUsers(this.address, true);
            }
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async deposit(amt0, amt1) {
        const tx = await this.manager.connect(this.signer).deposit(this.address, amt0, amt1);
        await tx.wait(this.confirmations);
        await this.update(true);
        return tx;
    }
    async withdraw(percentage) {
        const tx = await this.manager.connect(this.signer).withdraw(this.address, BN(percentage));
        await tx.wait(this.confirmations);
        await this.update(true);
        return tx;
    }
    async trade(spendTokenNumber, spendAmt, minReceived, pathIndex) {
        console.log( "in vault trade: ", spendTokenNumber, spendAmt, minReceived, pathIndex);
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            const params = {'vaultAddress': this.address,
                            'spendTokenNumber': BN(spendTokenNumber), 
                            'spendAmt': BN(spendAmt),
                            'receiveAmtMin': BN(minReceived), 
                            'pathIndex': BN(pathIndex)}
            tx = await this.manager.connect(this.signer).trade(params);
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
}
export class Wallet {
    constructor(address, tokensList) {
        this.address = address;
        this.tokensList = tokensList;
        this.tokens = {};
        this.balances = {};
        // this.initialized = false;
    }
    async update() {
        for (const token of this.tokensList) {
            this.tokens[token.address] = token;
            this.balances[token.address] = await token.getBalance(this.address);
        }
        return this;
    }
    showBalanceHuman(tokenAddress, decimalsToShow=4) {
        return bigNumToHuman(this.balances[tokenAddress], this.tokens[tokenAddress].decimals, decimalsToShow);
    }
}

export class Quoter {
    constructor(providerIn, addressIn='0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6') {
        this.abi = quoterAbi.abi;
        //!!!!!!!!!!!!!!!!!!!!!! needs fixing for other networks
        this.provider = ethers.getDefaultProvider('https://arb1.arbitrum.io/rpc');//providerIn;
        this.address = addressIn;
        this.contract = new ethers.Contract(addressIn, this.abi, this.provider);
    }
    async getQuoteSingle(tokenIn, tokenOut, amountIn, fee) {
        const tx = await this.contract.callStatic.quoteExactInputSingle(tokenIn, 
                                                                        tokenOut, 
                                                                        fee, 
                                                                        amountIn.toString(), 
                                                                        0);
        return tx;
    }
    async getQuote(encodedPath, amountIn) {
        const tx = await this.contract.callStatic.quoteExactInput(encodedPath,
                                                                  amountIn.toString());
        return tx;
    }
}

export class Trade {
    constructor(vault, quoterIn, tokenFromNum=0, slippage=0.1, primaryTokenNum=1) { 
        this.vault = vault;
        this.quoter = quoterIn;
        this.tokenFromNum = tokenFromNum;
        this.slippage = slippage;
        this.updated = false;
        this.priceImpact = 0;
        this.bestFee = 500;
        this.fullPathList = "";
        this.amtInExpectedFloat = 0.0;
        this.amtInMinFloat = 0.0;
        this.tokenLibrary = new TokenLibrary();
        this.primaryTokenNum = primaryTokenNum;
        this.primaryToken = this.vault.tokens[primaryTokenNum];
        // this.update(amtOut, slippage)
    }
    async update(amtOut, slippage, simple=false) {
        this.amtOut = BN(amtOut);
        this.slippage = slippage;
        // this.pathIndex = 0;
        
        this.tradable = true;
        this.reversible = true;
        var minTestAmt;
        if (this.vault.balance0.eq(BN(0)) && this.vault.balance1.eq(BN(0))) {
            this.tradable = false;
            this.reversible = false;
        } else if (this.vault.balance0.gt(BN(0)) && this.vault.balance1.eq(BN(0))) {
            this.tokenFromNum = 0;
            this.reversible = false;
        } else if (this.vault.balance1.gt(BN(0)) && this.vault.balance0.eq(BN(0))) {
            this.tokenFromNum = 1;
            this.reversible = false;
        } 

        if (this.tokenFromNum === 0) {
            this.tokenFrom = this.vault.token0;
            this.tokenTo = this.vault.token1;
            // minTestAmt = this.vault.token0MinDep.div(100);
            const tknSym = this.tokenFrom.symbol;
            const decimals = this.tokenFrom.decimals;
            const testAmtFloat = this.tokenLibrary.getPriceCheckTestAmt(tknSym);
            minTestAmt = humanToBigNum(testAmtFloat, decimals).div(100);

        } else {
            this.tokenFrom = this.vault.token1;
            this.tokenTo = this.vault.token0;
            // minTestAmt = this.vault.token1MinDep;
            const tknSym = this.tokenFrom.symbol;
            const decimals = this.tokenFrom.decimals;
            const testAmtFloat = this.tokenLibrary.getPriceCheckTestAmt(tknSym);
            minTestAmt = humanToBigNum(testAmtFloat, decimals);
        }

        // this.amtOutFloat = bigNumToFloat(this.amtOut, this.tokenFrom.decimals);
        this.amtOutFloat = this.tokenFrom.toFloat(this.amtOut);
        // console.log('amtOutFloat', this.amtOutFloat);
        this.bestPathString = '';
        var quotedIn = BN(0);
        var mostQuoted = BN(0);
        var bestPathIndex = 0;

        var thisPathMiddle = this.vault.paths[this.bestPathIndex];
        var feesOnPath=[]; 
        var addressesOnPath=[];
        var fullPathList=[];
        var fullPath='';
        var price;
        var testAmtOut;
        if (this.amtOut.gt(BN(0))) {
            testAmtOut = this.amtOut;
        } else {
            testAmtOut = minTestAmt; //we want to fetch price anyway if amtOut is 0
        }

        if (simple) {
            return this;
        }
        console.log('simulate trading...')

        const primaryTokenNumForPriceCheck = this.tokenTo === this.primaryToken ? 1 : 0;
        for (var i=0; i<this.vault.paths.length; i++) {  
            thisPathMiddle = this.vault.paths[i];
            fullPath = '0x' + this.tokenFrom.address.slice(2) + thisPathMiddle.slice(2) + this.tokenTo.address.slice(2);
            [feesOnPath, addressesOnPath] = decomposeTradePath(fullPath);
            fullPathList = createFullPathDisplay(feesOnPath, addressesOnPath, this.tokenLibrary);
            console.log('fullPath', fullPath);
            console.log('fullPathList', fullPathList);
            var priceCheck = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, primaryTokenNumForPriceCheck);
            console.log('priceCheck', priceCheck);
            // if (priceCheck > 1000){
            //     priceCheck = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, this.primaryTokenNum);
            // }
            console.log('')
            quotedIn = await this.quoter.getQuote(fullPath, testAmtOut);
            var quotedInFloat = this.tokenTo.toFloat(quotedIn);
            
            if (this.tokenFromNum === 0) {
                price = quotedInFloat / this.amtOutFloat;
            } else {
                price = this.amtOutFloat / quotedInFloat;
            }
            console.log('QUOTE: ',i , fullPathList, quotedInFloat, price);
            if (quotedIn.gt(mostQuoted)) {
                mostQuoted = quotedIn;
                bestPathIndex = i;
                // bestPrice = price;
            }
        }
        this.bestPathIndex = bestPathIndex;
        if (this.amtOut.gt(BN(0))) {
            this.amtInExpected = mostQuoted;
            this.amtInExpectedFloat = bigNumToFloat(this.amtInExpected, this.tokenTo.decimals);
            this.amtInMinFloat = this.amtInExpectedFloat * (100 - this.slippage)/100;
            this.amtInMin = humanToBigNum(this.amtInMinFloat, this.tokenTo.decimals);
        }  else {
            this.amtInExpected = BN(0);
            this.amtInExpectedFloat = 0.0;
            this.amtInMinFloat = 0.0;
            this.amtInMin = BN(0);
        }

        //get nominal price from pool directly (needs to be this way)
        //need to daisy chain the pools to get the price for multiple hops
        thisPathMiddle = this.vault.paths[this.bestPathIndex];
        fullPath = '0x' + this.tokenFrom.address.slice(2) + thisPathMiddle.slice(2) + this.tokenTo.address.slice(2);
        [feesOnPath, addressesOnPath] = decomposeTradePath(fullPath);
        fullPathList = createFullPathDisplay(feesOnPath, addressesOnPath, this.tokenLibrary);
        
        console.log('Final FULLPathList', fullPathList, this.amtInExpectedFloat);
        this.fullPathList = fullPathList;

        var temp = 1.0;
        for (i=0; i<feesOnPath.length; i++) {
            temp = temp*(1.0-feesOnPath[i]/(10000*100.0));
        }
        this.bestFee = 100*(1 - temp);
        this.price = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, 
                                                                primaryTokenNumForPriceCheck);
        //"price" = Amt Primary / Amt Secondary, by definition.
        if (this.tokenFromNum === this.primaryTokenNum) {
            this.amtInNominal = this.amtOutFloat / this.price;
        } else {
            this.amtInNominal = this.amtOutFloat * this.price;
        }

        this.priceImpact = this.amtInNominal > 0 ? 100*((this.amtInNominal*(100 - this.bestFee)/100) - this.amtInExpectedFloat) / this.amtInNominal : 0;
        //compare to current price without price impact
        console.log('FINAL this.price', this.price)
        console.log('this.bestFee', this.bestFee.toFixed(4))
        console.log('this.amtInNominal', this.amtInNominal, this.priceImpact)
        console.log('amtInExpectedFloat', this.amtInExpectedFloat)
        console.log('amtInMinFloat', this.amtInMinFloat)
        console.log('')
        
        this.updated = true;
        return this;
    }

    async execute() {
        console.log('trading...')
        const tx = await this.vault.trade(this.tokenFromNum, this.amtOut, this.amtInMin, BN(this.bestPathIndex));
        return tx;
    }
}
