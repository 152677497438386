import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';

import Dropdown from 'react-bootstrap/Dropdown';
import {Button as BootButton} from 'react-bootstrap';
import { theme, VertDiv, HorzDiv } from '../styles.js';

import {Button, FormControl, RadioGroup, 
        FormControlLabel, Radio, Switch} from '@mui/material';

import { PlayCircleFilled } from '@mui/icons-material'

import '@fontsource/roboto/300.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {getBaseURL, utcTimeToLocal, getWindowDimensions, comparisonSymbol, 
        dateToEpoch, epochToDate, epochToDateString, dateStringToEpoch} from '../functions/functions';

import { useSearchParams } from "react-router-dom";

import { SimpleCheckBox, InputWithLabel } from './SimpleComponents.js';


const baseURL = getBaseURL();


function Backtest({fixStrategyProp, setStratStringPlainProp, 
                    currentVaultStrategyProp, currentToken0Prop, currentToken1Prop,
                    fixCurrentTokensProp}) {
    const innerBC = theme.light;
    const innerBS = 'solid';
    const innerPadding = theme.padding1;
    const innerMargin = theme.margin0;
    const innerGap = theme.gap1;
    //#region setup
    function getTodaysDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    function addInverses(arr) {
        var newArr = arr;
        const L = arr.length;
        for (var i=0; i<L; i++) {
            let huh = [arr[i][1], arr[i][0]];
            newArr.push(huh);
        }
        return newArr;
    }
    function getURLCommand(obj) {
        var command = '';
        for (const [key, value] of Object.entries(obj)) {
            command += key + '=' + value + '&';
        }
        command = command.slice(0, -1);
        return command;
    }
    const [data, setData] = useState({'times': [], 'prices': [],  
                                      'buysIndex': [], 'sellsIndex': [], 
                                      'ema': [], 'portfolioValueTimes': [], 'portfolioValue': [], 
                                      'buysBTCValue': [], 'sellsBTCValue': [], 
                                      'stopLossSells': [], 'stopLossSellsBTCValue': [], 
                                      'rsi': [], 'macd': []});

    const validTokensList = ['ADA', 'AVAX', 'BCH', 'BTC', 'USD', 'ETH', 'LTC', 'LINK', 'MATIC', 'SOL', 'SUSHI', 'ROSE', 'ETC', 'XRP', 'XLM']
    const validPairsList = [['BTC', 'USD'], 
                            ['ETH', 'USD'], 
                            ['ETH', 'BTC'], 
                            ['LTC', 'USD'], 
                            ['XRP', 'USD'], 
                            ['XLM', 'USD'], 
                            ['ADA', 'USD'], 
                            ['AVAX', 'USD'], 
                            ['BCH', 'USD'], 
                            ['ETC', 'USD'],
                            ['LINK', 'USD'],
                            ['MATIC', 'USD'], 
                            ['SOL', 'USD'], 
                            ['SUSHI', 'USD'], 
                            ['ROSE', 'USD']]
    const initValidTokens = fixCurrentTokensProp ? [currentToken0Prop] : validTokensList;
    const initValidPairs = fixCurrentTokensProp ? [[currentToken0Prop, currentToken1Prop]] : addInverses(validPairsList);
    // console.log('initValidTokens:', initValidTokens);
    // console.log('initValidPairs:', initValidPairs);

    const validIntervals = ['1h', '4h', '1d', '1w'];
    const [validTokens, setValidTokens] = useState(initValidTokens);
    const [validPairs, setValidPairs] = useState(initValidPairs);
                                                  
    function getValidSecondTokens(firstToken) {
        var temp = [];
        for (let i=0; i<validPairs.length; i++) {
            if (validPairs[i][0] === firstToken) {
                temp.push(validPairs[i][1]);
            }
        }
        return temp;
    }

    const [searchParams] = useSearchParams();
    // console.log('searchParams:', searchParams);

    const methodIDs = {sma:'a', ema:'b', hma:'c', macd:'d', rsi:'e'};
    const IDsToMethod = {'a': 'sma', 'b': 'ema', 'c': 'hma', 'd': 'macd', 'e': 'rsi'}
    //decodes stratString if given
    function setDefaultValsFunction() {
        var ret = {}
        if (currentVaultStrategyProp !== null && currentVaultStrategyProp !== undefined) {
            ret.token0 = currentToken0Prop;
            ret.token1 = currentToken1Prop;
            var s = currentVaultStrategyProp

            if (s !== null && s !== undefined && s !== '') {
                const dataInt = s.slice(0, 2)
                ret.dataInterval = dataInt;
                s = s.slice(2);
                ret.smaBool = false;
                ret.emaBool = false;
                ret.hmaBool = false;
                ret.macdBool = false;
                ret.rsiBool = false;
                while (s.length > 0) {
                    var id = s.slice(0, 1);
                    var method = IDsToMethod[id];
                    s = s.slice(1);
                    if (s.length > 0) {
                        if (method === 'sma'){
                            ret.smaBool = true;
                            var p = parseInt(s.slice(0, 3));
                            var t = parseInt(s.slice(3, 4));
                            ret.smaValue = p;
                            if (t === 0) {
                                ret.smaRadioValue = 'priceCross';
                            } else {
                                ret.smaRadioValue = 'slope';
                            }
                            s = s.slice(4);
                        } else if (method === 'ema') {
                            ret.emaBool = true;
                            var p = parseInt(s.slice(0, 3));
                            var t = parseInt(s.slice(3, 4));
                            ret.emaValue = p;
                            if (t === 0) {
                                ret.emaRadioValue = 'priceCross';
                            } else {
                                ret.emaRadioValue = 'slope';
                            }
                            s = s.slice(4);
                        } else if (method === 'hma') {
                            ret.hmaBool = true;
                            var p = parseInt(s.slice(0, 3));
                            var t = parseInt(s.slice(3, 4));
                            ret.hmaValue = p;
                            if (t === 0) {
                                ret.hmaRadioValue = 'priceCross';
                            } else {
                                ret.hmaRadioValue = 'slope';
                            }
                            s = s.slice(4);
                        } else if (method === 'macd') {
                            ret.macdBool = true;
                            ret.macdNSlow = parseInt(s.slice(0, 3));
                            ret.macdNFast = parseInt(s.slice(3, 6));
                            ret.macdNSignal = parseInt(s.slice(6, 9));
                            s = s.slice(9);
                        } else if (method === 'rsi') {
                            ret.rsiBool = true;
                            ret.rsiValue = parseInt(s.slice(0, 3));
                            var bd = s.slice(3, 4);
                            if (bd === '0') {
                                ret.rsiBuyDirection = 'gt';
                            } else {
                                ret.rsiBuyDirection = 'lt';
                            }
                            ret.rsiBuyValue = parseInt(s.slice(4, 6));
                            var sd = s.slice(6, 7);
                            if (sd === '0') {
                                ret.rsiSellDirection = 'gt';
                            } else {
                                ret.rsiSellDirection = 'lt';
                            }
                            ret.rsiSellValue = parseInt(s.slice(7, 9));
                            s = s.slice(9);
                        } else {
                            console.log('error decoding strategy string');
                        }        
                    }
                }
            }
        } 
        var retDef = {'token0': 'ETH', 
                    'token1': 'USD', 
                    'startDate': dateToEpoch(new Date(2015, 0, 1, 0, 0, 0, 0)),//'2018-01-01',//,    //'2018-01-01', 
                    'useEarliest': true,
                    'endDate': dateToEpoch(new Date()),
                    'useLatest': true,
                    'startingAmt': 1, 
                    'startingAmtUnits': 'ETH', 
                    'dataInterval': '1d',
                    'logBool': false, 
                    'showRSI': false,
                    'showMACD': false,
                    'smaBool': true,
                    'smaValue': 23,
                    'smaRadioValue': 'priceCross',
                    'emaBool': true,
                    'emaValue': 31,
                    'emaRadioValue': 'priceCross',
                    'hmaBool': true,
                    'hmaValue': 62,
                    'hmaRadioValue': 'slope',
                    'rsiBool': false,
                    'rsiValue': 14,
                    'rsiBuyDirection': 'gt',
                    'rsiBuyValue': 52,
                    'rsiSellDirection': 'lt',
                    'rsiSellValue': 48,
                    'macdBool': false,
                    'macdNSlow': 24,
                    'macdNFast': 12,
                    'macdNSignal': 9,
                    'tradingFeePercent': 0.1};
        for (const [key, value] of Object.entries(retDef)) {
            if (ret[key] === undefined) {
                ret[key] = value;
            }
        }


        return ret;
    }
    
    const [defaultVals, setDefaultVals] = useState(setDefaultValsFunction());

    const [tokenSecondary, setTokenSecondary] = useState(defaultVals['token0']);
    const [tokenPrimary, setTokenPrimary] = useState(defaultVals['token1']);
    const [validSecondTokens, setValidSecondTokens] = useState(getValidSecondTokens(tokenSecondary));
                            
    const [startDate, setStartDate] = useState(defaultVals['startDate']);
    const [useEarliest, setUseEarliest] = useState(defaultVals['useEarliest']);
    const [endDate, setEndDate] = useState(defaultVals['endDate']);
    const [useLatest, setUseLatest] = useState(defaultVals['useLatest']);
    const [startingAmt, setStartingAmt] = useState(defaultVals['startingAmt']);
    const [startingAmtUnits, setStartingAmtUnits] = useState(defaultVals['startingAmtUnits']);
    const [dataInterval, setDataInterval] = useState(defaultVals['dataInterval']);

    const [logBool, setLogBool] = useState(defaultVals['logBool']);
    const [showRSI, setShowRSI] = useState(defaultVals['showRSI']);
    const [showMACD, setShowMACD] = useState(defaultVals['showMACD']);

    const [smaBool, setSmaBool] = useState(defaultVals['smaBool']);
    const [smaValue, setSmaValue] = useState(defaultVals['smaValue']);
    const [smaRadioValue, setSmaRadioValue] = useState(defaultVals['smaRadioValue']);
    const [emaBool, setEmaBool] = useState(defaultVals['emaBool']);
    const [emaValue, setEmaValue] = useState(defaultVals['emaValue']);
    const [emaRadioValue, setEmaRadioValue] = useState(defaultVals['emaRadioValue']);
    const [hmaBool, setHmaBool] = useState(defaultVals['hmaBool']);
    const [hmaValue, setHmaValue] = useState(defaultVals['hmaValue']); 
    const [hmaRadioValue, setHmaRadioValue] = useState(defaultVals['hmaRadioValue']); 

    const [rsiBool, setRsiBool] = useState(defaultVals['rsiBool']);
    const [rsiValue, setRsiValue] = useState(defaultVals['rsiValue']);
    const [rsiBuyDirection, setRsiBuyDirection] = useState(defaultVals['rsiBuyDirection']);
    const [rsiBuyValue, setRsiBuyValue] = useState(defaultVals['rsiBuyValue']);
    const [rsiSellDirection, setRsiSellDirection] = useState(defaultVals['rsiSellDirection']);
    const [rsiSellValue, setRsiSellValue] = useState(defaultVals['rsiSellValue']);

    const [macdBool, setMacdBool] = useState(defaultVals['macdBool']);
    const [macdNSlow, setMacdNSlow] = useState(defaultVals['macdNSlow']);
    const [macdNFast, setMacdNFast] = useState(defaultVals['macdNFast']);
    const [macdNSignal, setMacdNSignal] = useState(defaultVals['macdNSignal']);

    const [stopLossBool, setStopLossBool] = useState(false);
    const [stopLossPercent, setStopLossPercent] = useState(5.0);
    const [tradingFeePercent, setTradingFeePercent] = useState(defaultVals['tradingFeePercent']);

    const [walletText, setWalletText] = useState('');
    const [autoTradeBool, setAutoTradeBool] = useState(false);
    const [autoTradeButtonText, setAutoTradeButtonText] = useState('Start Auto Trading');

    const [optimizeButtonText, setOptimizeButtonText] = useState('Optimize (Coming Soon)');
    const [fetchButtonText, setFetchButtonText] = useState('Backtest Strategy');
    const [currentStrategiesList, setCurrentStrategiesList] = useState([]);

    setStratStringPlainProp(createStrategyString());

    function createStrategyString() {
        const ids = methodIDs;
        var s = dataInterval; //assumed to be 2 chars
        if (smaBool) {
            const rd = smaRadioValue === 'priceCross' ? '0' : '1';
            s += ids['sma'] + smaValue.toString().padStart(3, '0') + rd;
        }
        if (emaBool) {
            const rd = emaRadioValue === 'priceCross' ? '0' : '1';
            s += ids['ema'] + emaValue.toString().padStart(3, '0') + rd;
        }
        if (hmaBool) {
            const rd = hmaRadioValue === 'priceCross' ? '0' : '1';
            s += ids['hma'] + hmaValue.toString().padStart(3, '0') + rd;
        }
        if (macdBool) {
            s += ids['macd'] + macdNSlow.toString().padStart(3, '0') + macdNFast.toString().padStart(3, '0') + macdNSignal.toString().padStart(3, '0');
        }
        if (rsiBool) {
            const bd = rsiBuyDirection === 'gt' ? '0' : '1';
            const sd = rsiSellDirection === 'gt' ? '0' : '1';
            s += ids['rsi'] + rsiValue.toString().padStart(3, '0') + bd + rsiBuyValue.toString().padStart(3, '0') + sd + rsiSellValue.toString().padStart(3, '0');
        }
        return s;
    }

    // console.log('IN BACKTEST: currentVaultStratProp, current backtestStrat', currentVaultStrategyProp, createStrategyString());

    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // useEffect(() => {
    //     function handleResize() {
    //         console.log('BT window resized', windowDimensions);
    //         setWindowDimensions(getWindowDimensions());
    //     }
    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []); // Empty array ensures that effect is only run on mount
    
    useEffect(() => {
        // console.log('in BT useEffect, currentVaultStratProp', currentVaultStrategyProp);
        setDefaultVals(setDefaultValsFunction());
    }, [currentVaultStrategyProp]);

    function setOptionsValues(dataDict) {
        setEmaValue(dataDict.emaValue);
        setRsiValue(dataDict.smoothingParam);
        setRsiBuyValue(dataDict.rsiBuyValue);
        setRsiSellValue(dataDict.rsiSellValue);
        setMacdNSlow(dataDict.macdNSlow);
        setMacdNFast(dataDict.macdNFast);
        setMacdNSignal(dataDict.macdNSignal);
        setStopLossPercent(dataDict.stopLossPercent);

        setData(dataDict);
        // fetchData();
    }
    const logType = (logBool) ? 'log' : 'linear' ;
    function handleToken0Select(tokenSelected) {
        console.log('in handleToken0Select:', tokenSelected);
        setTokenSecondary(tokenSelected);
        const vst = getValidSecondTokens(tokenSelected);
        setValidSecondTokens(vst);
        setStartingAmtUnits(tokenSelected)
        if (!vst.includes(tokenPrimary)) {
            setTokenPrimary(vst[0]);
        }   
    }
    function handleToken1Select(tokenSelected) {
        console.log('in handleToken1Select:', tokenSelected);
        setTokenPrimary(tokenSelected);
        setStartingAmtUnits(tokenSecondary)
    }
    function handleUnitsSelect(tokenSelected) {
        console.log('in handleUnitsSelect:', tokenSelected);
        setStartingAmtUnits(tokenSelected)
    }
    function urlForAPI() {
        const trigSep = ":";
        const sep = '&'
        var stratString = '';
        if (smaBool) {
            stratString += 'sma'+sep+smaValue+sep+smaRadioValue+trigSep;
        }
        if (emaBool) {
            stratString += 'ema'+sep+emaValue+sep+emaRadioValue+trigSep;
        }
        if (hmaBool) {
            stratString += 'hma'+sep+hmaValue+sep+hmaRadioValue+trigSep;
        }
        if (rsiBool) {
            stratString += 'rsi'+sep+rsiValue+sep+rsiBuyDirection+sep+rsiBuyValue+sep+rsiSellDirection+sep+rsiSellValue+trigSep;
        }
        if (macdBool) {
            stratString += 'macd'+sep+macdNSlow+sep+macdNFast+sep+macdNSignal+trigSep;
        }
        const startTime = (startDate).toString();
        const endTime = (endDate).toString();
        console.log('start time:', startTime);
        console.log('end time:', endTime);
        const url = tokenSecondary + '/' + tokenPrimary + '/' +
                    startTime + '/' + endTime + '/' + dataInterval +'/' +
                    startingAmt + '/' + startingAmtUnits + '/' + 
                    tradingFeePercent.toString() + '/' + stratString
                    // smaBool.toString()+'/'+smaValue.toString() + '/' +
                    // emaBool.toString()+'/'+emaValue.toString() + '/' +
                    // rsiBool.toString()+'/' + rsiValue + '/' + rsiBuyDirection + '/' + rsiBuyValue.toString() + '/' + rsiSellDirection + '/' + rsiSellValue.toString() + '/' +
                    // macdBool.toString()+'/' + macdNSlow.toString() + '/' + macdNFast.toString() + '/' + macdNSignal.toString() + '/' + 
                    // stopLossBool.toString()+'/' + stopLossPercent.toString() + '/' + tradingFeePercent.toString();
        return url;
    }
    function fetchData() {
        if (!(smaBool || emaBool || hmaBool || rsiBool || macdBool)) {
            setFetchButtonText('Backtest Strategy');
            window.alert('You must select at least one strategy!');
        } else {
            var temp = getBaseURL()
            if (temp.includes('localhost')) {
                temp = temp+':8000';
            }
            const url = temp+'/api/fetch/' + urlForAPI();
            console.log('FETCH url:', url);

            const thisURL = getURLCommand(defaultVals);
            console.log(thisURL);

            setFetchButtonText('Running...');
            axios.get(url).then(res => 
                {
                    setData(res.data);
                    if (res.data.times.length > 0) {
                        setStartDate(res.data.times[0]);
                        setEndDate(res.data.times[res.data.times.length-1]);
                    }
                    
                    setFetchButtonText('Backtest Strategy');
                });     
        }
    }
    // function optimize() {
    //     setOptimizeButtonText('Optimizing...');
    //     const url = baseURL+':8000/api/optimize/' + urlForAPI();
    //     axios.get(url).then(res => 
    //         {
    //             setOptionsValues(res.data)
    //             setOptimizeButtonText('Optimize (Coming Soon)');
    //         });
    // }
    // function autoTrade() {
    //     if (autoTradeBool) {
    //         setAutoTradeButtonText('Stop Auto Trading');
    //         setAutoTradeBool(false);
    //     } else {
    //         setAutoTradeButtonText('Start Auto Trading');
    //         setAutoTradeBool(true);
    //     }
    //     const url = baseURL+':8000/api/autotrade/'+ walletText +'/' + urlForAPI();
    //     axios.get(url).then(res => 
    //     {
            
    //         // setOptionsValues(res.data);
    //         // setOptimizeButtonText('Optimize (Coming Soon)');
    //     });
    // }
    function setDataIntervalFunc(intervalString) {
        const numberEquiv = {'1m': 60, '1h': 60*60, '1d': 60*60*24, '1w': 60*60*24*7};
        const multiplier = 1; //numberEquiv[dataInterval] / numberEquiv[intervalString];
        setEmaValue(Math.round(emaValue * multiplier));
        setRsiValue(Math.round(rsiValue * multiplier));
        setMacdNSlow(Math.round(macdNSlow * multiplier));
        setMacdNFast(Math.round(macdNFast * multiplier));
        setMacdNSignal(Math.round(macdNSignal * multiplier));
        setDataInterval(intervalString);
    }
    // const localTimes = data.times.map(d => utcTimeToLocal(d));
    const localTimes = data.times.map(d => new Date(d*1000));
    const trace0 = {x: localTimes, y: data.prices, 
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'#6666ff'}, 
                    name: 'price'};
    const traceSMA = {x: localTimes, y: data.sma,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'yellow'}, 
                    opacity: 0.75,
                    name: 'sma-'+smaValue.toString()};
    const traceEMA = {x: localTimes, y: data.ema,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'green'}, 
                    opacity: 0.75,
                    name: 'ema-'+emaValue.toString()};
    const traceHMA = {x: localTimes, y: data.hma,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter',
                    mode: 'lines',
                    marker: {color:'red'},
                    opacity: 0.75,
                    name: 'hma-'+hmaValue.toString()};
    const trace2 = {x: data.portfolioValueTimes.map(d => new Date(d*1000)), 
                    y: data.portfolioValue, 
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'orange'}, 
                    name: 'portfolio value'};
    const trace3 = {x: data.buysIndex.map(d => new Date(data.times[d]*1000)), 
                    y: data.buysIndex.map(d => data.prices[d]), 
                    xaxis:'x1', yaxis:'y1',
                    mode: 'markers', 
                    marker: {color:'#22ff22', size: 8}, 
                    name: "buys ("+data.buysIndex.length.toString()+')'};
    const trace4 = {x: data.sellsIndex.map(d => new Date(data.times[d]*1000)), 
                    y: data.sellsIndex.map(d => data.prices[d]), 
                    xaxis:'x1', yaxis:'y1',
                    mode: 'markers', 
                    marker: {color:'red', size: 8}, 
                    name: 'sells ('+data.sellsIndex.length.toString()+')'};
    // const trace5 = {x: data.stopLossSells.map(d => utcTimeToLocal(d)), y:data.stopLossSellsBTCValue, 
    //                 xaxis:'x1', yaxis:'y1',
    //                 mode: 'markers',
    //                 marker: {color:'#ff00aa', size: 8},
    //                 name: 'stop loss sells ('+data.stopLossSells.length.toString()+')'};

    var numPlotRows = 1;
    const plotData = [trace0]
    plotData.push(trace2);

    if (smaBool) {
        plotData.push(traceSMA);
    }
    if (emaBool) {
        plotData.push(traceEMA);
    }
    if (hmaBool) {
        plotData.push(traceHMA);
    }

    // setShowRSI(showRSI && rsiBool);
    if (showRSI && rsiBool && data.rsi !== undefined) {
        const traceRSI = {x: localTimes, y: data.rsi, 
            xaxis:'x1', yaxis:'y2',
            mode: 'lines', marker: {color:'pink'}, name: 'RSI'};

        const traceRSIThresh0 = {x: [localTimes[0], localTimes[localTimes.length-1]], y: [rsiBuyValue, rsiBuyValue], 
                        xaxis:'x1', yaxis:'y2', 
                        mode:'lines',
                        line: {color:'white', dash: 'dash'},
                        showlegend: false};

        const traceRSIThresh1 = {x: [localTimes[0], localTimes[localTimes.length-1]], y: [rsiSellValue, rsiSellValue],
                        xaxis:'x1', yaxis:'y2',
                        mode:'lines',
                        line: {color:'white', dash: 'dash'},
                        showlegend: false};

        const traceRSIBuys = {x: data.buysIndex.map(x => localTimes[x]), y: data.buysIndex.map(x => data.rsi[x]), 
                        xaxis:'x1', yaxis:'y2',  
                        mode: 'markers', marker: {color:'green'},
                        showlegend: false};

        const traceRSISells = {x: data.sellsIndex.map(x => localTimes[x]), y: data.sellsIndex.map(x => data.rsi[x]),
                        xaxis:'x1', yaxis:'y2',
                        mode: 'markers', marker: {color:'red'}
                        ,showlegend: false};
        numPlotRows += 1;
        plotData.push(traceRSI);
        plotData.push(traceRSIThresh0);
        plotData.push(traceRSIThresh1);
        plotData.push(traceRSIBuys);
        plotData.push(traceRSISells);
    }
    if (showMACD && macdBool) {
        const traceMACD = {x: localTimes, y: data.macd, 
                            xaxis:'x1', yaxis:'y3',
                            mode: 'lines', name: 'MACD'};

        const traceMACDSignal = {x: localTimes, y: data.macdSignal, 
                                xaxis:'x1', yaxis:'y3',
                                mode: 'lines', name: 'signal'
                                ,showlegend: false};
        const traceMACDHist = {x: localTimes, y: data.macdHistogram, 
                                xaxis:'x1', yaxis:'y3',
                                type: 'bar', name: 'Histogram'
                                ,showlegend: false};

        const traceMACDBuys = {x: data.buysIndex.map(x=>localTimes[x]), y: data.buysIndex.map(x => data.macdHistogram[x]), 
                                xaxis:'x1', yaxis:'y3', 
                                mode: 'markers',
                                marker: {color:'green'},
                                showlegend: false};

        const traceMACDSells = {x: data.sellsIndex.map(x=>localTimes[x]), y: data.sellsIndex.map(x => data.macdHistogram[x]),
                                xaxis:'x1', yaxis:'y3',
                                mode: 'markers',
                                marker: {color:'red'},
                                showlegend: false};
        numPlotRows += 1;
        plotData.push(traceMACD);
        plotData.push(traceMACDSignal);
        plotData.push(traceMACDHist);
        plotData.push(traceMACDBuys);
        plotData.push(traceMACDSells);
    }
    plotData.push(trace3);
    plotData.push(trace4);

    var finalGain;
    var finalGainForAsset;
    var finalGainVsBuyAndHold;
    if (data.portfolioValue.length > 0) {
        finalGain = (data.portfolioValue[data.portfolioValue.length-1]/data.portfolioValue[0]);
        finalGainForAsset = (data.prices[data.prices.length-1]/data.prices[0]);
        finalGainVsBuyAndHold = (finalGain/finalGainForAsset).toFixed(2);
        finalGain = finalGain.toFixed(2);
        finalGainForAsset = finalGainForAsset.toFixed(2);
    }

    var subplotSpace = 0.25;
    var d1;
    var d2;
    var d3;
    if (showRSI===false && showMACD===false) {
        d1 = [0, 1];
        d2 = [0, 0];
        d3 = [0, 0];
    } else if (showRSI===true && showMACD===false) {
        d1 = [subplotSpace + 0.01, 1];
        d2 = [0, subplotSpace - 0.01];
        d3 = [0, 0];
    } else if (showRSI===false && showMACD===true) {
        d1 = [subplotSpace + 0.01, 1];
        d2 = [0, 0];
        d3 = [0, subplotSpace - 0.01];
    } else {
        d1 = [2*subplotSpace + 0.01, 1];
        d2 = [subplotSpace + 0.01, 2*subplotSpace - 0.01];
        d3 = [0, subplotSpace - 0.01];
    }
    

    function PlotDiv({maxWidthIn=1200}) {
        // console.log('BT plotting', windowDimensions);
        const aspectRatio = 1.6;
        var maxWidth = maxWidthIn;
        const maxHeight = Math.floor(maxWidth/aspectRatio);
        const plotWidth = Math.min(windowDimensions.width - 115, maxWidth);
        const plotHeight = Math.min(Math.floor(plotWidth/aspectRatio) + 100*(numPlotRows-1) + 150, maxHeight);
        // const plotWidth = 800;
        // const plotHeight = Math.floor(plotWidth/aspectRatio);
        
        const titleText = data.times.length > 0 ? tokenSecondary+'/'+tokenPrimary+'<br>'+"Final Gain: "+finalGain + 'X' + '<br>' + 'VS buy and hold: '+finalGainVsBuyAndHold+'X': '';
        const titleXPos = plotWidth > 500 ? 0.5 : 0.7;
        const titleYPos = 1.0 - 0.01*plotHeight;
        const legendXPos = 0.0 - 0.0;
        const legendYPos = 1.0 + 0.01*plotHeight;
        const plotLayout = {
                            dragmode: 'pan',
                            plot_bgcolor: theme.dark,
                            paper_bgcolor: theme.black,
                            font: {color: theme.white},
                            grid: {rows: numPlotRows, columns: 1},
                            width: plotWidth, height: plotHeight,
                            xaxis: {title: 'Date'},
                            yaxis: {title: 'price ('+tokenPrimary+')', domain: d1, type: logType, 
                                    tickcolor: '#ffffff', tickwidth: 2, gridcolor: '#aaaaaa', gridwidth: 1, side: 'right' },
                            yaxis2: {title: 'RSI', domain: d2, tickcolor: '#ffffff', tickwidth: 2},
                            yaxis3: {title: 'MACD', domain: d3, tickcolor: '#ffffff', tickwidth: 2}, 
                            autosize:true,
                            title: {text: titleText, font: {size: 12},
                                    y: titleYPos, x: titleXPos, xanchor: 'center', yanchor: 'top'},
                            margin: {l: 10, r: 50, b: 50, t: 160},
                            legend: {x: legendXPos, y: legendYPos, orientation: 'v', font: {size: 10}}
                            };
        const plotConfig = {
            scrollZoom:true,
            responsive:true,
            
        };

        return(
            <VertDiv borderColor={theme.light} backgroundColor={theme.black}
                margin='0px' padding={theme.padding0} gap='0px'>
                <HorzDiv borderStyle='none' >
                    <HorzDiv borderColor={theme.light}>
                        <label>Log Plot</label>
                        <Switch
                            checked={logBool}
                            onChange={() => {setLogBool(!logBool)}}
                        />
                    </HorzDiv>
                    <HorzDiv borderColor={theme.light}>
                        <label>Show RSI</label>
                        <Switch
                            disabled={!rsiBool}
                            checked={showRSI && rsiBool}
                            onChange={() => {setShowRSI(!showRSI)}}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </HorzDiv>
                    <HorzDiv borderColor={theme.light}>
                        <label>Show MACD</label>
                        <Switch
                            disabled={!macdBool}
                            checked={showMACD && macdBool}
                            onChange={() => {setShowMACD(!showMACD)}}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </HorzDiv>
                    <Button 
                        className="btn btn-outline-primary mx-0 mb-0"
                        sx={{p:1, m:0}} 
                        variant='contained'
                        // color='primary'
                        size='small'
                        style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                        startIcon={<PlayCircleFilled/>}
                        onClick={fetchData}
                    >
                        {fetchButtonText}
                    </Button>
                    
                </HorzDiv> 
                <Plot
                    data={plotData}
                    layout={plotLayout}
                    config={plotConfig}
                    useResizeHandler={true}
                />
                <HorzDiv borderStyle='none' >

                </HorzDiv>
            </VertDiv>
        )
    }

    function handleSetStartDate(dateString) {
        const dateUnix = dateStringToEpoch(dateString);
        setStartDate(dateUnix);
    }
    function handleSetEndDate(dateString) {
        const dateUnix = dateStringToEpoch(dateString);
        setEndDate(dateUnix);
    }
    function handleSetUseEarliest(bool) {
        setUseEarliest(bool);
        if (bool) {
            setStartDate(defaultVals.startDate);
        }
    }
    function handleSetUseLatest(bool) {
        setUseLatest(bool);
        if (bool) {
            setEndDate(defaultVals.endDate);
        }
    }
    function TokenInputDiv() {
        return (
            <VertDiv borderColor={theme.light} gap={theme.gap1} padding={innerPadding} 
                    alignItems='stretch' justifyContent='space-between'>
                <h2>Token Inputs</h2>
                <HorzDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={innerPadding} gap={innerGap}
                >
                    <label>Token0</label>
                    <select 
                        onChange={event => handleToken0Select(event.target.value)} 
                        style={{width: '80px'}}
                        disabled={fixStrategyProp} >
                        {validTokens.map(item => <option value={item} selected={item===tokenSecondary ? true : false}>{item}</option>)}
                    </select>   
                </HorzDiv>
                
                <HorzDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={innerPadding} gap={innerGap}
                >
                    <label>Token1</label>
                    <select 
                        onChange={event => handleToken1Select(event.target.value)} 
                        style={{width: '80px'}}
                        disabled={fixStrategyProp}
                    >
                        {validSecondTokens.map(item => <option value={item} selected={item===tokenPrimary ? true : false} >{item}</option>)}
                    </select>
                </HorzDiv>
                <VertDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={innerPadding} gap={innerGap}
                >   
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <label>Start Date</label>
                        <SimpleCheckBox
                            label='Use Earliest Date'
                            state={useEarliest}
                            setState={() => {handleSetUseEarliest(!useEarliest)}}
                            borderColor={theme.blue}
                        />
                    </VertDiv>
                    
                    <input className="mb-2 form-control startDate" type="date" size="10"
                        onBlur={event=>handleSetStartDate(event.target.value)} 
                        defaultValue={epochToDateString(startDate)} 
                        disabled={useEarliest}    
                    /> 
                </VertDiv>
                <VertDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={innerPadding} gap={innerGap}
                >
                    <label>End Date</label>
                    <SimpleCheckBox
                        label='Use Latest Date'
                        state={useLatest}
                        setState={() => {handleSetUseLatest(!useLatest)}}
                        borderColor={theme.blue}
                    />
                    <input className="mb-2 form-control endDate" type="date" size="10"
                        onBlur={event=>handleSetEndDate(event.target.value)} 
                        defaultValue={epochToDateString(endDate)} 
                        disabled={useLatest}
                    /> 
                </VertDiv>
                <VertDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={innerPadding} gap={innerGap}
                >
                    <label>Data Interval</label>

                    <select 
                        onChange={event => setDataIntervalFunc(event.target.value)} 
                        style={{width: '80px'}}
                        disabled={fixStrategyProp}
                    >
                        {validIntervals.map(item => <option value={item} selected={item===dataInterval ? true : false}>{item}</option>)}
                        {/* <option value="1d">1 day</option>
                        <option value="1w">1 week</option> */}
                        {/* {validSecondTokens.map(item => <option value={item} selected={item===token1 ? true : false} >{item}</option>)} */}
                    </select>

                </VertDiv>
                <InputWithLabel 
                    label='Simulated Trading Fee (%)'
                    min={0}
                    max={5}
                    precision={2}
                    step={0.01}
                    defaultValue={tradingFeePercent}
                    onBlur={event=>setTradingFeePercent(event.target.value)}
                    disabled={fixStrategyProp}
                    borderColor={theme.light}
                />
            </VertDiv>
        )
    }
    function MADiv({name, toggleBool, setBoolHandler, maValue, setValueHandler, 
                    radioValue, setRadioHandler, link}) {
        return (
            <>
                <HorzDiv borderStyle={innerBS} borderColor={innerBC}
                    margin={innerMargin} padding={'2px'} gap={innerGap}
                >
                    <VertDiv borderStyle='none' gap={theme.gap0} margin={theme.margin0}>
                        <label>
                            {<a className="LinkDiv"
                                href={link} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: 'white' }}  
                            >  
                                {name}
                            </a>}
                        </label>
                        <Switch
                            disabled={fixStrategyProp}
                            checked={toggleBool}
                            onChange={() => {setBoolHandler(!toggleBool)}}
                        />
                    </VertDiv>
                    <InputWithLabel 
                        label={name+" Period"}
                        width='6ch'
                        defaultValue={maValue}
                        onBlur={event=>setValueHandler(event.target.value)}
                        min={1}
                        max={999}
                        textAlign='left'
                        allowMouseWheel={true}
                        withIncrementer={true}
                    />
                    <FormControl>
                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                        <RadioGroup
                            
                            aria-labelledby="radio-buttons-group-label"
                            value={radioValue}
                            onChange={value=>setRadioHandler(value.target.value)}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel 
                                value="priceCross" 
                                control={<Radio size="small"/>} 
                                label="Price Cross" 
                                disabled={fixStrategyProp}
                            />
                                
                            <FormControlLabel 
                                value="slope" 
                                control={<Radio size="small"/>} 
                                label="Slope" 
                                disabled={fixStrategyProp}
                            />
                                
                        </RadioGroup>
                    </FormControl>
                </HorzDiv>
            </>
        )
    }
    function MacdDiv() {
        function setMacdSlowLocal(value) {
            setMacdNSlow(value)
            setMacdNFast(Math.min(macdNFast, value-1))
            setMacdNSignal(Math.min(macdNSignal, value-2))
        }

        function setMacdFastLocal(value) {
            setMacdNFast(value)
            setMacdNSlow(Math.max(macdNSlow, value+1))
            setMacdNSignal(Math.min(macdNSignal, value-1))
        }

        function setMacdSignalLocal(value) {
            setMacdNSignal(value)
            setMacdNSlow(Math.max(macdNSlow, value+2))
            setMacdNFast(Math.max(macdNFast, value+1))
        }
        return(
            <HorzDiv borderColor={theme.light} >
                <VertDiv borderStyle='none' gap='0px'>
                    <label>
                        <a href="https://www.investopedia.com/terms/m/macd.asp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                            MACD
                        </a>
                    </label>
                    {/* <ToggleButton value={macdBool} onToggle={(value) => setMacdBool(!value)} /> */}
                    <Switch
                        checked={macdBool}
                        disabled={fixStrategyProp}
                        onChange={() => {setMacdBool(!macdBool)}}
                    />
                </VertDiv>
                <InputWithLabel
                    label="N Slow"
                    width='6ch'
                    textAlign='left'
                    min={3}
                    max={999}
                    allowMouseWheel={true}
                    withIncrementer={true}
                    defaultValue={macdNSlow}
                    onBlur={event=>setMacdSlowLocal(event.target.value)}
                    disabled={fixStrategyProp}
                />
                <InputWithLabel
                    label="N Fast"
                    width='6ch'
                    textAlign='left'
                    min={2}
                    max={999}
                    allowMouseWheel={true}
                    withIncrementer={true}
                    defaultValue={macdNFast}
                    onBlur={event=>setMacdFastLocal(event.target.value)}
                    disabled={fixStrategyProp}
                />
                <InputWithLabel
                    label="N Signal"
                    width='6ch'
                    textAlign='left'
                    min={1}
                    max={999}
                    allowMouseWheel={true}
                    withIncrementer={true}
                    defaultValue={macdNSignal}
                    onBlur={event=>setMacdSignalLocal(event.target.value)}
                    disabled={fixStrategyProp}
                />
            </HorzDiv>
        )

    } 
    function StrategyInputDiv() {
        return (
            <VertDiv borderColor={theme.light} gap={theme.gap1} padding={innerPadding} alignItems='stretch'>
                <h2>Strategy Backtest Inputs</h2>
                <VertDiv borderColor={theme.light} gap='0px'>
                    <h5>Moving Averages</h5>
                    <MADiv 
                        name='SMA' 
                        toggleBool={smaBool} 
                        setBoolHandler={setSmaBool} 
                        maValue={smaValue}
                        setValueHandler={setSmaValue}
                        radioValue={smaRadioValue}
                        setRadioHandler={setSmaRadioValue}
                        link='https://www.investopedia.com/terms/s/sma.asp'
                    />
                    <MADiv 
                        name='EMA' 
                        toggleBool={emaBool} 
                        setBoolHandler={setEmaBool} 
                        maValue={emaValue}
                        setValueHandler={setEmaValue}
                        radioValue={emaRadioValue}
                        setRadioHandler={setEmaRadioValue}
                        link='https://www.investopedia.com/terms/e/ema.asp'
                    />
                    <MADiv 
                        name='HMA' 
                        toggleBool={hmaBool} 
                        setBoolHandler={setHmaBool} 
                        maValue={hmaValue}
                        setValueHandler={setHmaValue}
                        radioValue={hmaRadioValue}
                        setRadioHandler={setHmaRadioValue}
                        link='https://school.stockcharts.com/doku.php?id=technical_indicators:hull_moving_average'
                    />
                    
                </VertDiv>
                <MacdDiv />
                <HorzDiv borderColor={theme.light} justifyContent='space-around'>
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <label color='white'>
                            <a href="https://www.investopedia.com/terms/r/rsi.asp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                                RSI
                            </a>
                        </label>
                        <Switch
                            disabled={fixStrategyProp}
                            checked={rsiBool}
                            onChange={() => {setRsiBool(!rsiBool)}}
                        />
                        <InputWithLabel
                            label="RSI Period"
                            width='6ch'
                            textAlign='left'
                            allowMouseWheel={true}
                            withIncrementer={true}
                            min={1}
                            max={999}
                            defaultValue={rsiValue}
                            onBlur={event=>setRsiValue(event.target.value)}
                            disabled={fixStrategyProp}
                        />

                    </VertDiv>

                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <label>Buy If</label>
                        <Dropdown>
                            <BootButton variant="success" id="dropdown-buyif">{comparisonSymbol(rsiBuyDirection)}</BootButton>
                            <Dropdown.Toggle split variant="success" id="dropdown-rsi-buy" />
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={event=>setRsiBuyDirection('lt')}>{'<'}</Dropdown.Item>
                                <Dropdown.Item onClick={event=>setRsiBuyDirection('gt')}>{'>'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <InputWithLabel
                            label="Buy Threshold"
                            width='6ch'
                            textAlign='left'
                            allowMouseWheel={true}
                            withIncrementer={true}
                            min={0}
                            max={100}
                            defaultValue={rsiBuyValue}
                            onBlur={event=>setRsiBuyValue(event.target.value)}
                            disabled={fixStrategyProp}
                        />
  
                    </VertDiv>

                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <label>Sell If</label>
                        <Dropdown>
                            <BootButton variant="success" id="dropdown-buyif">{comparisonSymbol(rsiSellDirection)}</BootButton>
                            <Dropdown.Toggle split variant="success" id="dropdown-rsi-sell" />
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={event=>setRsiSellDirection('lt')}>{'<'}</Dropdown.Item>
                                <Dropdown.Item onClick={event=>setRsiSellDirection('gt')}>{'>'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <InputWithLabel
                            label="Sell Threshold"
                            width='6ch'
                            textAlign='left'
                            allowMouseWheel={true}
                            withIncrementer={true}
                            min={0}
                            max={100}
                            defaultValue={rsiSellValue}
                            onBlur={event=>setRsiSellValue(event.target.value)}
                            disabled={fixStrategyProp}
                        />
   
                    </VertDiv>
                </HorzDiv>
            </VertDiv>
        )
    }

    console.log('startDate', startDate, epochToDate(startDate),  epochToDateString(startDate));
    const thisStratString = createStrategyString();
    // console.log('BT: thisStratString', thisStratString);
    setStratStringPlainProp(thisStratString);
    //main div
    return (
        <HorzDiv backgroundColor={theme.dark} borderColor={theme.light}>
            <HorzDiv borderStyle='none' alignItems='stretch'>
                <TokenInputDiv/>
                <StrategyInputDiv/>
                {/* <AutoTradeDiv/> */}
            </HorzDiv>
            <PlotDiv maxWidthIn={950} />
        </HorzDiv>
    );
}

export default Backtest;