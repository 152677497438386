import { Vector3} from "@babylonjs/core";
import {ethers, BigNumber} from "ethers";

import axios from 'axios';

export function getAmtIn(amtOutBN, decimalsPrimary, decimalsSecondary, price, tradeType) {
    const priceDigits = Math.floor(Math.log10(price));
    const digitsTarget = 10;
    
    const extra = Math.max(0, digitsTarget - priceDigits);
    var temp = price * 10**extra;
    temp = Math.round(temp);
    const expandedPrice = BN(temp);

    const ten = BN(10);
    const decPrimary = BN(decimalsPrimary);
    const decSecondary = BN(decimalsSecondary);

    var ret = BN(0);
    if (tradeType === 'buy') {
        const numerator = amtOutBN.mul(ten.pow(decSecondary)).mul(ten.pow(extra));
        const denominator = expandedPrice.mul(ten.pow(decPrimary));
        ret = numerator.div(denominator);
    } else {
        const numerator = amtOutBN.mul(ten.pow(decPrimary)).mul(expandedPrice);
        const denominator = ten.pow(decSecondary).mul(ten.pow(extra));
        ret = numerator.div(denominator);
    }
    return ret;
}

export function decimalDigits(num) {
    const parts = num.toString().split(".");
    return parts.length > 1 ? parts[1].length : 0;
};

export function minusSlippage(amt, slippagePercent, type='float') {
    var temp;
    if (type === 'float') {
        temp = amt * (100 - slippagePercent)/100;
    } else {
        const expansion = 1000;
        temp = amt.mul(BN(100*expansion - slippagePercent*expansion)).div(BN(100*expansion));
    }
    return temp;
}

export function rangePy(start, stop, step = 1) {
    // If only one argument is provided, it's the 'stop' value, 
    // and 'start' defaults to 0
    if (arguments.length === 1) {
        stop = start;
        start = 0;
    }

    // Handle invalid step values
    if (step === 0) {
        throw new Error('Step cannot be zero');
    }

    const result = [];

    if (step > 0) {
        for (let i = start; i < stop; i += step) {
            result.push(i);
        }
    } else {
        for (let i = start; i > stop; i += step) {
            result.push(i);
        }
    }

    return result;
}

export function roundToDigits(num, n) {
    const factor = Math.pow(10, -n);
    return Math.round(num * factor) / factor;
}
export function timeIntervalSeconds(timeIntervalString) {
    var timeDelta = -1;
    if (timeIntervalString === '1h') {
        timeDelta = 60*60;
    } else if (timeIntervalString === '4h') {
        timeDelta = 4*60*60;
    } else if (timeIntervalString === '1d') {
        timeDelta = 24*60*60;
    } else if (timeIntervalString === '1w') {
        timeDelta = 7*24*60*60;
    } 
    return timeDelta;
}

export async function sha256(str) {
    // Convert the string to an array buffer
    const buffer = new TextEncoder().encode(str);
    
    // Compute the hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    
    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    
    return hashHex;
}

export function isDefined(x) {
    const ret0 = (x !== null);
    const ret1 = (x !== undefined);
    return (ret0 && ret1);
}

export function isUndefined(x) {
    const ret = !isDefined(x);
    return ret;
}

export function emaCalc(mArray, mRange) {
    var k = 2/(mRange + 1);
    // first item is just the same as the first item in the input
    var emaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
        emaArray.push(mArray[i] * k + emaArray[i - 1] * (1 - k));
    }
    return emaArray;
}

export function rmaCalc(mArray, mRange) {
    var k = 1/mRange;
    // first item is just the same as the first item in the input
    var rmaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
        rmaArray.push(mArray[i] * k + rmaArray[i - 1] * (1 - k));
    }
    return rmaArray;
}

export function trueRange(highList, lowList, closeList) {
    var trueRangeList = [highList[0] - lowList[0]];
    for (var i = 1; i < highList.length; i++) {
        var temp = highList[i] - lowList[i];
        var temp2 = Math.abs(highList[i] - closeList[i-1]);
        var temp3 = Math.abs(lowList[i] - closeList[i-1]);
        var temp4 = Math.max(temp, temp2, temp3);
        trueRangeList.push(temp4);
    }
    return trueRangeList;
}


export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// import {AutoTrade} from './AutoTrade/index.js';
export function comparisonSymbol(s) {
    if (s === 'lt') {
        return '<';
    } else if (s === 'gt') {
        return '>';
    } else {
        return 'bad option';
    }
}

export function decomposeTradePath(fullPathString) {
    const feeHexLength = 6; // 3 bytes = 6 hex characters (which are 4 bits each)
    const addressHexLength = 40; // 20 bytes = 40 hex characters (which are 4 bits each)
    var feesOnPath = [];
    var addressesOnPath = [];
    
    var temp = fullPathString.slice(2); //remove '0x

    addressesOnPath.push('0x'+temp.slice(0, addressHexLength));
    temp = temp.slice(addressHexLength);
    
    while (temp.length > 0) {
        feesOnPath.push(parseInt(temp.slice(0, feeHexLength), 16));
        temp = temp.slice(feeHexLength);
        //if (temp.length > 0) {
        addressesOnPath.push('0x'+temp.slice(0, addressHexLength));
        temp = temp.slice(addressHexLength);
        //}
    }
    return [feesOnPath, addressesOnPath];
}

export function createFullPathDisplay(feesOnPathList, addressesOnPathList, tokenLibrary) {
    var fullPathDisplay = [];
    for (var i = 0; i < addressesOnPathList.length; i++) {
        fullPathDisplay.push(tokenLibrary.getSymbol(addressesOnPathList[i]));
        if (i < feesOnPathList.length) {
            fullPathDisplay.push("->"+feesOnPathList[i]/10000.0+"%->");
        }
    }
    return fullPathDisplay;
}

export function decomposeTradePathOld(middleSection) {
    var feesOnPath = [];
    var addressesOnPath = [];
    
    var temp = middleSection.slice(2);

    const feeHexLength = 6; // 3 bytes = 6 hex characters (which are 4 bits each)
    const addressHexLength = 40; // 20 bytes = 40 hex characters (which are 4 bits each)
    while (temp.length > 0) {
        feesOnPath.push(parseInt(temp.slice(0, feeHexLength), 16)/10000.0);
        temp = temp.slice(feeHexLength);
        if (temp.length > 0) {
            addressesOnPath.push('0x'+temp.slice(0, addressHexLength));
            temp = temp.slice(addressHexLength);
        }
    }
    return [feesOnPath, addressesOnPath];
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function sqrtX96ToPrice(sqrtPriceX96, token0Decimals=18, token1Decimals=18, precision=8) {
    const sqrtPrice = BN(sqrtPriceX96);
    const priceX96 = sqrtPrice.mul(sqrtPrice)
    if (priceX96.eq(BN(0))) {
        return 0;
    }
    const ratioX96 = priceX96.mul(BN(10).pow(token0Decimals)).div(BN(10).pow(token1Decimals))
    var extra = 0;
    var temp = ratioX96.mul(BN(10).pow(extra)).div(BN(2).pow(192));
    while (temp.lt(BN(10).pow(precision)) && extra < 40) {
        extra += 1;
        temp = ratioX96.mul(BN(10).pow(extra)).div(BN(2).pow(192));
    }
    return (
        temp.toNumber() / 10 ** extra
    );
}

export function dateToEpoch(date) {
    return Math.round(date.getTime() / 1000);
}

export function epochToDate(ts){
    // ts = epoch timestamp
    // returns date obj
    return new Date(ts*1000);
}

//dateString = '2021-01-01'
export function dateStringToEpoch(dateString) {
    const withSlashes = dateString.replace(/-/g, '\/');
    const date = new Date(withSlashes);
    const dateUnix = dateToEpoch(date);
    return dateUnix;
}


export function epochToDateString(ts){
    const d = epochToDate(ts);
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    const year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
        
    if (day.length < 2) {
        day = '0' + day;
    }
        
    return [year, month, day].join('-');
}

export function utcTimeToLocal(utcTime) {
  const temp = new Date(utcTime + 'Z');
  return temp;
}

export function sumList(list) {
  return list.reduce((a, b) => a + b, 0);
}

export function inList(list, item) {
  return list.indexOf(item) > -1;
}

export function getBaseURL() {
    var baseURL = window.location.origin;
    var host = window.location.host;
    const urlPrefix = baseURL.split('//')[0];
    if (host.includes(':')) {
        host = host.split(':')[0];
    }
    baseURL = urlPrefix + '//' + host;
    return baseURL;
}

export function isString(x) {
    return Object.prototype.toString.call(x) === "[object String]"
}

export function abbreviateAddress(address, numChars=6) {
    return address.substring(0, numChars) + "..." + address.substring(address.length - numChars);
}

export function BN(num) {
  return BigNumber.from(num);
}

export function bigNumToHuman(bigNum, decimals, numShown=5) {
    return bigNumToFloat(bigNum, decimals).toFixed(numShown);
}

export function bigNumToFloat(bigNum, decimals=0) {
    return Number(ethers.utils.formatUnits(bigNum, decimals));
}

export async function getPrefetch(devMode, chainID, urlBase=null) {
    //USE LOCAL SERVER ON PREFETCH_PORT
    var temp;
    if (urlBase === null) {
        temp = getBaseURL()
        if (temp.includes('localhost')) {
            temp = temp+':8000';
        }
    } else {
        temp = urlBase;
    }
    
    const url = temp+'/api/fetchobjectinfo/' + 
                    devMode.toString() + '/' + 
                    chainID.toString();
    // console.log('PREFETCH url:', url);

    // setFetchButtonText('Running...');
    var prefetchNew = null;

    try {
        // console.log('PREFETCH url:', url);
        const res = await axios.get(url);
        // console.log('PREFETCH res:', res);
        prefetchNew = res.data;                
    } catch (error) {
        console.error('Error fetching data:', error);
    }  
    return prefetchNew; 
}

export async function requestPrefetch(urlBase, numVaults, numAutotradeAlerts, numBalanceAlerts) {
    const url = urlBase+'/api/prefetchrequest/' + 
                numVaults.toString() + '/' + 
                numAutotradeAlerts.toString() + '/' + 
                numBalanceAlerts.toString();
    var prefetchNew = null;
    var message = null;
    var match = false;
    var ret = {};
    try {
        console.log('PREFETCH url:', url);
        const res = await axios.get(url);
        console.log('PREFETCH res:', res);
        // if (res.data.prefetch) {
        //     prefetchNew = res.data.prefetch;
        //     message = res.data.message;
        //     if (message === 'success') {
        //         match = true;
        //     }
        // };
        ret = res.data;             
    } catch (error) {
        console.error('Error fetching data:', error);
    }  
    return ret; 
}

export function bigNumDivToFloat(a, b, decimalPlaces = 18) {
    const bnA = ethers.BigNumber.from(a);
    const bnB = ethers.BigNumber.from(b);

    // We multiply a by 10^decimalPlaces to simulate floating point precision
    const scale = ethers.BigNumber.from(10).pow(decimalPlaces);
    const scaledA = bnA.mul(scale);

    // We now divide the scaled a by b
    const result = scaledA.div(bnB);

    // Separate the integer part and the fractional part
    const integerPart = result.div(scale).toString();
    const fractionalPart = result.mod(scale).toString().padStart(decimalPlaces, '0');  // pad with leading zeros

    // Convert to JavaScript number
    return parseFloat(integerPart + "." + fractionalPart);
}

export function stringToInt(s) {
    return parseInt(s, 10);
}

export function stringToBool(s) {
    if (typeof s === 'boolean') {
        return s;
    }
    return s.toLowerCase() === 'true';
}

export function mapObject(obj, fn) {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key] = fn(obj[key], key, obj);
        return acc;
    }, {});
}

export function humanToBigNum(humanNum, decimals=0) {
    if (isString(humanNum)) {
        if (isNaN(humanNum)) {
            return BN(0);
        } else {
            return ethers.utils.parseUnits(humanNum, decimals);
        }
    } else {
        try {
            return ethers.utils.parseUnits(humanNum.toString(), decimals);
        } catch (error) {
            // console.log('caught error in humanToBigNum', error);
            const temp = humanNum * 10**decimals;
            const temp2 = Math.ceil(temp);
            const temp3 = BigNumber.from(temp2);
            return temp3;
        }
    }   
}

export function randomArray(length, max) {
    return Array.apply(null, Array(length)).map(function() {
        return Math.round(Math.random() * max);
    });
}

export function arrayRemoveLast(arr, numToRemove=1) {
    return arr.slice(0, arr.length - numToRemove);
}

export function arrayRemoveFirst(arr, numToRemove=1) {
    return arr.slice(numToRemove);
}

export function lerp(start, end, amt){
    return (1-amt)*start+amt*end
}

export function lerpVec3(start, end, amt){
    return new Vector3(
        lerp(start.x, end.x, amt),
        lerp(start.y, end.y, amt),
        lerp(start.z, end.z, amt),
    )
}

export function getArrayRandomElement (arr) {
    if (arr && arr.length) {
        return arr[Math.floor(Math.random() * arr.length)];
    } else {
        return null;
    }
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function splitArray(arr, size) {
  var arr2 = arr.slice(0),
      arrays = [];

  while (arr2.length > 0) {
      arrays.push(arr2.splice(0, size));
  }
  return arrays;
}


export function toVec3(arr) {
    return new Vector3(Number(arr[0]), Number(arr[2]), Number(arr[1]))
}

export function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }