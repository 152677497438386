import { useContext } from 'react';

import { UserContext } from '../../App';

import styled from 'styled-components';

import mainLogo from '../../images/orbitalLogo1.png'
import etherscanLogo from '../../images/etherscan-logo-circle.png'
import twitterLogo from '../../images/twitter.svg'
import emailLogo from '../../images/email.svg'

import {Typography} from '@mui/material';


const FooterDiv = styled.div`
    
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-image: linear-gradient(to bottom, #14183A, #13294B);

    img {
        max-height: 80px;
        resize: 'contain';
    }
`;
const Socials = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    gap: 5px;
    margin-right: 15px;
    margin-left: 50px;
    img {
        height: 25px;
    }
`;

const twitterURL = "https://twitter.com/OrbitalAutoDevs";
const etherscanURL = "https://arbiscan.io/";
const emailURL = 'orbitalautomationdevs@gmail.com';
// const docsURL = 'https://orbitalautomation.com/docs/';

function Footer(props) {
    const { baseURL } = useContext(UserContext);
    var temp = baseURL;
    if (temp.includes('localhost')) {
        temp = temp+':3000';
    }
    const docsURL = temp+'/docs';
    return (
        <FooterDiv>
            <img src={mainLogo} alt="Orbital" />
            <Typography variant="h2" style={{color: '#fff', fontFamily: "Poppins", fontSize: '1.6em' }}>
              {"Orbital"}
          </Typography>
          <Socials>
              <li><a href={twitterURL}><img src={twitterLogo}></img></a></li>
              {/* <li><a href={etherscanURL}><img src={etherscanLogo}></img></a></li> */}
              <li><a href={"mailto: "+emailURL}><img src={emailLogo}></img></a></li>
              {/* <li><a href={etherscanURL}><img src={etherscanLogo}></img></a></li> */}
              <li>
                <a href={docsURL}>
                    <Typography 
                        variant="h2" 
                        style={{color: '#fff', fontFamily: "Poppins", fontSize: '1.6em' }}>
                            Docs (coming soon)
                    </Typography>
                </a>
            </li>
          </Socials>

        </FooterDiv>
    )
}

export default Footer;