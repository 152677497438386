import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function AutotradeMenu() {

    // const tokenEthURL = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x380B14e0E9930Cd8FCC30647753F9a0de1A843F0';
    const tokenArbitrum = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf4e81ffc759219441B96F89Cd3bD3BF0368e5637';
    const arbBridge = 'https://bridge.arbitrum.io/';
    const chartArbitrum = 'https://www.dextools.io/app/arbitrum/pair-explorer/0x4c49F88F5485DCcf9D6Cb00C1f696433784acEF4';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const handleEth = () => {
    //     window.open(tokenEthURL, '_blank');
    // };
    const handleArbitrum = () => {
        window.open(tokenArbitrum, '_blank');
    };
    const handleBridge = () => {
        window.open(arbBridge, '_blank');
    };

    const handleChart = () => {
        window.open(chartArbitrum, '_blank');
    };

    return (
        <div>
        <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained" 
            // href={homePage+'/strategydemo'}
            sx={{p:1, m:1}} 
            size='small'
            style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
        >
            Autotrade Strategies
        </Button>
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={handleArbitrum} disableRipple>
                Create New
            </MenuItem>
            <MenuItem 
                onClick={handleChart} 
                disableRipple
            >
                Use Existing
            </MenuItem>
            {/* <Divider sx={{ my: 0.5 }} />

            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleBridge} disableRipple>
                Bridge
            </MenuItem> */}
        </StyledMenu>
        </div>
    );
}
