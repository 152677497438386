import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import { TextField } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import RejectedIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { Typography, Card, CardContent, FormControl, InputLabel, OutlinedInput} from '@mui/material';
import Paper from '@mui/material/Paper';

import { VertDiv, theme } from '../../styles.js';
// import { height } from '@mui/system';

// import styled from "styled-components";

import { Text } from '@chakra-ui/react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Draggable from 'react-draggable';

import { decimalDigits, isDefined, isUndefined } from '../../functions/functions.js';
import { InputWithLabel } from '../SimpleComponents.js';

import { signals, actions } from '../../classes/strategy_01.js'

const darkTheme = createTheme({
  palette: {
    type: 'dark',
  },
});

const DraggablePaperComponent = (props) => {
    return (
        <Draggable 
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
};

// const WhiteBorderTextField = styled(TextField)`
//   & label.Mui-focused {
//     color: white;
//   }
//   & .MuiOutlinedInput-root {
//     &.Mui-focused fieldset {
//       border-color: white;
//     }
//   }
// `;

//NOT WORKING!!
export function AlertDialog({open, 
                             setOpen, 
                             alertString,
                             setDialogOpenFlag=null
                            }) {
    // const [open, setOpen] = React.useState(isOpen);

    useEffect (() => {
        // setOpen(isOpen);
    }, [open])
    const handleClickOpen = () => {
        if (setDialogOpenFlag)
            setDialogOpenFlag(true);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
        // setIsOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="alert-dialog-title">
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <Text>
                            Alert!
                        </Text>
                    </VertDiv>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent 
                >
                    <DialogContentText sx={{color: 'white'}}>
                        {alertString}
                    </DialogContentText>
                </DialogContent>

                <DialogActions > 
                    <Button 
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );    
} 

export function InfoDialog({buttonInnards, 
                             titleString, 
                             contentString, 
                             buttonVariant='text',
                             setDialogOpenFlag=null
                            }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (setDialogOpenFlag)
            setDialogOpenFlag(true);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
    };

    return (
        <div>
            <Button variant={buttonVariant} onClick={handleClickOpen}>
                {buttonInnards}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="alert-dialog-title">
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        <Text>
                            {titleString}
                        </Text>
                    </VertDiv>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent >
                    <DialogContentText sx={{color: 'white'}}>
                        {contentString}
                    </DialogContentText>
                </DialogContent>

                <DialogActions > 
                    <Button 
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}



export function SettingsDialog({ title, 
                                settingsIn, 
                                setSettingsIn,
                                setDialogOpenFlag=null,
                                inputWidth='130px'
                             }) {
    const [localSettings, setLocalSettings] = useState({ ...settingsIn });
    const [isOpen, setOpen] = useState(false);
    const [removedItems, setRemovedItems] = useState({});
    const [settingsUpdates, setSettingsUpdates] = useState(0);

    const handleOpen = (e) => {
        if (setDialogOpenFlag)
            setDialogOpenFlag(true);
        setOpen(true);
    }

    const handleAddDict = (key) => {
        let dicts = [...localSettings[key]];
    
        // Check if there's a recently removed item for the key
        if (removedItems[key]) {
            dicts.push(removedItems[key]);
            
            // Clear the recently removed item for the key
            setRemovedItems(prevItems => {
                const newItems = { ...prevItems };
                delete newItems[key];
                return newItems;
            });
        } 
        else if (dicts.length > 0) {
            const lastDict = dicts[dicts.length - 1];
    
            let newDict = {};
            for (let subKey in lastDict) {
                let value = lastDict[subKey];
                if (typeof value === 'string') {
                    newDict[subKey] = "";
                } else if (typeof value === 'boolean') {
                    newDict[subKey] = false;
                } else if (typeof value === 'number') {
                    newDict[subKey] = 0;
                } else {
                    newDict[subKey] = null;  // fallback for other types
                }
            }
            dicts.push(newDict);
        } 
        else {
            dicts.push({});
        }
    
        setLocalSettings(prevSettings => ({
            ...prevSettings,
            [key]: dicts
        }));
    };
    
    function handleRemoveDict(key, index) {
        const removedItem = localSettings[key][index];
    
        setRemovedItems(prevState => ({
            ...prevState,
            [key]: removedItem
        }));
    
        const newDictList = [...localSettings[key]];
        newDictList.splice(index, 1);
        setLocalSettings(prevState => ({ ...prevState, [key]: newDictList }));
    }
    
    const handleInputChange = (event, key, subKey = null, index = null, attemptTypeDetermination=true) => {
        let newSettings = { ...localSettings };
    
        // Helper function to determine type based on existing value
        const determineValueBasedOnExistingType = (inputValue, existingValue) => {
            if (attemptTypeDetermination) {
                if (typeof existingValue === 'number') {
                    if (inputValue === '') {
                        return 0.0;
                    // } else if (Number.isInteger(existingValue)) {
                    //     return parseInt(inputValue, 10);
                    } else {
                        return parseFloat(inputValue);
                    }
                } else if (typeof existingValue === 'boolean') {
                    return event.target.checked;
                }
            }
            return inputValue; // Return as string by default if the existing type isn't recognized
        };
    
        if (subKey !== null && index !== null) {
            // This means the change occurred within an array of dictionaries
            newSettings[key][index][subKey] = determineValueBasedOnExistingType(event.target.value, newSettings[key][index][subKey]);
        } else if (index !== null) {
            // This means the change occurred within an array of primitives
            newSettings[key][index] = determineValueBasedOnExistingType(event.target.value, newSettings[key][index]);
        } else {
            // This is for top-level settings
            newSettings[key] = determineValueBasedOnExistingType(event.target.value, newSettings[key]);
        }
    
        setLocalSettings(newSettings);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setSettingsIn(localSettings);
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
    };

    const handleCancel = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
        setLocalSettings({...settingsIn});
    }

    useEffect(() => {
        // setLocalSettings({ ...settings });
        setLocalSettings(settingsIn);
    }, [settingsIn]);

    // console.log('DIALOG!', isOpen, localSettings);

    return (
        <div>
        <Button 
            sx={{p:0, paddingLeft: 1, paddingRight: 1, m:0}}
            variant='contained'
            size='small'
            style={{'borderRadius':theme.borderRadius, 
                    // 'width': '50px',
                    'fontWeight':'bold', 
                    'fontSize': '0.8em'}} 
            onClick={() => handleOpen(true)}
        >
            Settings
        </Button>
        {/* <ThemeProvider theme={darkTheme}> */}
            {/* ////DraggablePaperComponent not working. ChatGPT came up with this */}
            <Dialog 
                open={isOpen} 
                onClose={handleCancel} 
                PaperComponent={DraggablePaperComponent}
                aria-labelledby="draggable-dialog-title"
                // PaperProps={{
                //                 style: {
                //                 backgroundColor: theme.dark,
                //                 color: 'white',
                //                 boxShadow: 'none',
                //                 },
                //             }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        {Object.keys(localSettings).map((key) => {
                            if (removedItems.hasOwnProperty(key) || (Array.isArray(localSettings[key]) && typeof localSettings[key][0] === 'object')) {
                                return (
                                    <Box border={1} borderColor="grey.300" borderRadius={4} p={2} mt={2} mb={2} key={key}>
                                        <Typography variant="h6" gutterBottom>
                                            {key}
                                        </Typography>

                                        {localSettings[key].length === 0 ? (
                                            <Typography variant="body2" color="textSecondary">
                                                No items to show.
                                            </Typography>
                                        ) : (
                                            
                                        localSettings[key].map((dict, index) => (
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} key={`${key}_${index}`}>
                                                {Object.keys(dict).map(subKey => {
                                                    if (typeof dict[subKey] === 'boolean') {
                                                        return (
                                                            <FormControlLabel
                                                                key={subKey}
                                                                control={
                                                                    <Checkbox
                                                                        checked={dict[subKey]}
                                                                        onChange={(e) => handleInputChange(e, key, subKey, index)}
                                                                        name={subKey}
                                                                        // style={{ color: 'white' }}
                                                                    />
                                                                }
                                                                label={subKey}
                                                            />
                                                        );
                                                    } else if (dict[subKey] === 'buy' || 
                                                                dict[subKey] === 'sell') {
                                                        return (
                                                            <Select
                                                                key={subKey}
                                                                value={dict[subKey]}
                                                                onChange={(e) => handleInputChange(e, key, subKey, index)}
                                                                name={subKey}
                                                            >
                                                                <MenuItem value="buy">Buy</MenuItem>
                                                                <MenuItem value="sell">Sell</MenuItem>
                                                            </Select>
                                                        );
                                                    } else if (dict[subKey] === 'above' || 
                                                               dict[subKey] === 'below' || 
                                                               dict[subKey] === 'crossover' ||
                                                               dict[subKey] === 'crossunder') {
                                                        return (
                                                            <Box display="flex" justifyContent="center" key={key} mt={1}>
                                                                <FormControl variant="outlined" style={{ width: inputWidth }}>
                                                                    <InputLabel id={`${key}-label`}>{key}</InputLabel>
                                                                    <Select
                                                                        labelId={`${key}-label`}
                                                                        value={localSettings[key]}
                                                                        onChange={(e) => handleInputChange(e, key)}
                                                                        input={<OutlinedInput label={key} />}
                                                                        name={key}
                                                                    >
                                                                        <MenuItem value="above">Above</MenuItem>
                                                                        <MenuItem value="below">Below</MenuItem>
                                                                        <MenuItem value="crossover">Crossover</MenuItem>
                                                                        <MenuItem value="crossunder">Crossunder</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        );
                                                    }else {
                                                        return (
                                                            <TextField
                                                                key={subKey}
                                                                margin="dense"
                                                                name={subKey}
                                                                label={subKey}
                                                                type="text"
                                                                // defaultValue={dict[subKey]}
                                                                // onBlur={(e) => handleInputChange(e, key, subKey, index)}
                                                                value={dict[subKey]}
                                                                onChange={(e) => handleInputChange(e, key, subKey, index, false)}
                                                            />
                                                        );
                                                    }
                                                })}

                                                <IconButton onClick={() => handleRemoveDict(key, index)}>
                                                    {/* <DeleteIcon style={{ color: 'white' }}/> */}
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        )))
                                        }

                                        <Box display="flex" justifyContent="flex-end">
                                            <IconButton onClick={() => handleAddDict(key)} color="primary">
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );

                            } else if (Array.isArray(localSettings[key])) {
                                // [Your existing Array handling logic here]
                                return localSettings[key].map((_, index) => (
                                        <Box display="flex" justifyContent="center" key={`${key}_${index}`}>
                                            <TextField
                                                margin="dense"
                                                name={key}
                                                label={`${key} ${index + 1}`}
                                                type="text"
                                                defaultValue={localSettings[key][index]}
                                                onBlur={(e) => handleInputChange(e, key, undefined, index)}
                                                style={{ width: inputWidth }}
                                            />
                                        </Box>
                                    ));
                            } else if (typeof localSettings[key] === 'object' && localSettings[key] !== null) {
                                // [Your existing Object handling logic here]
                                return (
                                    <Card key={key} style={{ marginBottom: '10px' }}>
                                        <CardContent>
                                            <Typography variant="body1" gutterBottom align="center">
                                                {key}
                                            </Typography>
                                            <Box display="flex" justifyContent="space-around">
                                                {Object.keys(localSettings[key]).map(subKey => (
                                                    <TextField
                                                        key={subKey}
                                                        margin="dense"
                                                        name={subKey}
                                                        label={subKey}
                                                        type="text"
                                                        defaultValue={localSettings[key][subKey]}
                                                        onBlur={(e) => handleInputChange(e, key, subKey)}
                                                        style={{ width: inputWidth }}
                                                    />
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                );
                            } else {
                                // [Your existing single value handling logic here]
                                if (key === 'type') {
                                    return (
                                        <Box display="flex" justifyContent="center" key={key} mt={1}>
                                            <FormControl variant="outlined" style={{ width: inputWidth }}>
                                                <InputLabel id={`${key}-label`}>{key}</InputLabel>
                                                <Select
                                                    labelId={`${key}-label`}
                                                    value={localSettings[key]}
                                                    onChange={(e) => handleInputChange(e, key)}
                                                    input={<OutlinedInput label={key} />}
                                                    name={key}
                                                >
                                                    <MenuItem value="buy">Buy</MenuItem>
                                                    <MenuItem value="sell">Sell</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    );
                                } else if (key === 'condition') {
                                    return (
                                        <Box display="flex" justifyContent="center" key={key} mt={1}>
                                            <FormControl variant="outlined" style={{ width: inputWidth }}>
                                                <InputLabel id={`${key}-label`}>{key}</InputLabel>
                                                <Select
                                                    labelId={`${key}-label`}
                                                    value={localSettings[key]}
                                                    onChange={(e) => handleInputChange(e, key)}
                                                    input={<OutlinedInput label={key} />}
                                                    name={key}
                                                >
                                                    <MenuItem value="above">Above</MenuItem>
                                                    <MenuItem value="below">Below</MenuItem>
                                                    <MenuItem value="crossover">Crossover</MenuItem>
                                                    <MenuItem value="crossunder">Crossunder</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    );
                                } else {
                                    return (
                                        <Box 
                                            display="flex" 
                                            justifyContent="center" 
                                            key={key}
                                        >
                                            <TextField
                                                margin="dense"
                                                name={key}
                                                label={key}
                                                type="text"
                                                defaultValue={localSettings[key]}
                                                onBlur={(e) => handleInputChange(e, key)}
                                                style={{ width: inputWidth }}
                                            />
                                            {/* <InputWithLabel
                                                label={key}
                                                precision={3}
                                                defaultValue={localSettings[key]}
                                                onBlur={(e) => handleInputChange(e, key)}
                                                width="7rem"
                                                inputFontSize={theme.fontSizeMedium}
                                                labelFontSize={theme.fontSizeSmall}
                                            /> */}
                                        </Box>
                                    );
                                }
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Set
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            
        {/* </ThemeProvider> */}
        </div>
    );
}
  


export function ActionDialog({buttonInnards,
                             buttonVariant='contained',
                             buttonColor='primary',
                             buttonSX={},
                             buttonStyle={},
                             titleString, 
                             contentString, 
                             handleCancelIn=null, 
                             handleAcceptIn=null,
                             disabled=false,
                             setDialogOpenFlag=null
                            }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (setDialogOpenFlag)
            setDialogOpenFlag(true);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
    };

    const handleCancel = () => {
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
        if (handleCancelIn) {
            handleCancelIn();
        }
    };

    const handleAccept = () => {
        if (handleAcceptIn) {
            handleAcceptIn();
        }
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setOpen(false);
    };

    // console.log('action dialog', open)

    return (
        <div>
            <Button 
                variant={buttonVariant} 
                onClick={handleClickOpen}
                color={buttonColor}
                disabled={disabled}
                sx={buttonSX}
                style={buttonStyle}
                // sx={{p:1, m:0, height: theme.buttonThickness2, width: '100%'}}
                // style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
            >
                {buttonInnards}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="alert-dialog-title">
                    <VertDiv borderStyle='none' margin='0px' padding='0px'>
                        {/* <Typography> */}
                            {titleString}
                        {/* </Typography> */}
                    </VertDiv>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent 
                >
                    <DialogContentText sx={{color: 'white'}}>
                        {contentString}
                    </DialogContentText>
                </DialogContent>

                <DialogActions >
                    <Button 
                        variant="contained"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={handleAccept} 
                        autoFocus
                    >
                        Accept
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export function OpenNewVaultDialog({isOpenBool, setIsOpenBool, url, setDialogOpenFlag=null}) {
    const [open, setOpen] = React.useState(isOpenBool);

    React.useEffect(() => {
        if (isOpenBool) {
            if (setDialogOpenFlag)
                setDialogOpenFlag(true);
            setOpen(isOpenBool);
        }
        
    }, [isOpenBool]);

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        if (setDialogOpenFlag)
            setDialogOpenFlag(false);
        setIsOpenBool(false);
        setOpen(false);
    };

    const handleOpenNewVault = () => {
        console.log('open new vault', url);
        window.open(url, '_blank');
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="open-newvault-dialog-title"
                aria-describedby="open-newvault-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="open-newvault-dialog-title">
                    <h4>
                        {"Open New Vault"}
                    </h4>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent>
                    <DialogContentText sx={{color: 'white'}}>
                        New Vault Created!
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button 
                        variant="contained"
                        onClick={handleOpenNewVault}
                    >
                        Open New Vault
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export function TxPendingDialog({txPendingBool,
                                 txSuccessBool, 
                                 txHash,
                                 txPendingGif=null, 
                                 networkDict=null}) {
    const [open, setOpen] = React.useState(txPendingBool);

    React.useEffect(() => {
        if (txPendingBool) {
            setOpen(txPendingBool);
        }
        
    }, [txPendingBool]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        setOpen(false);
    };
    const iconSize = '120px'
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>
                        {txPendingBool ? "Transaction Pending" : "Transaction Complete"}
                    </h4>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent 
                >
                    <DialogContentText sx={{color: 'white'}}>
                        {(txPendingBool )  ? 
                            <VertDiv borderStyle='none'>
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                                Please wait...
                            </VertDiv> : 
                            
                            <div>
                                {networkDict !== null && networkDict['scanner'] !== undefined && txSuccessBool &&
                                    <VertDiv borderStyle='none'>
                                        <CheckIcon sx={{color: 'green', fontSize: 'large'}}/>
                                        Transaction Complete.
                                        {isDefined(txHash) &&
                                            <a href={networkDict['scanner'] + '/tx/' + txHash} target="_blank" rel="noreferrer">
                                                View Receipt
                                            </a>
                                        }
                                    </VertDiv>

                                }
                                {!txSuccessBool &&
                                    <VertDiv borderStyle='none'>
                                        Transaction Rejected!
                                    </VertDiv>  
                                }

                            </div>
                        }
                    </DialogContentText>
                </DialogContent>

                <DialogActions >
                    <Button 
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>

                </DialogActions>

            </Dialog>
        </div>
    );
};

export function ErrorDialog({dialogOpenBool,
                             setDialogOpenBool,
                             dialogMessage, 
                                 networkDict=null}) {
    const [open, setOpen] = React.useState(dialogOpenBool);

    React.useEffect(() => {
        if (dialogOpenBool) {
            setOpen(dialogOpenBool);
        }
        
    }, [dialogOpenBool]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        setDialogOpenBool(false);
        setOpen(false);
    };
    const iconSize = '120px'
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                                style: {
                                backgroundColor: theme.light,
                                color: 'white',
                                boxShadow: 'none',
                                },
                            }}
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>
                        Error
                    </h4>
                </DialogTitle>
                <Divider flexItem ={true} sx={{bgcolor: 'white'}} />
                <DialogContent 
                >
                    <DialogContentText sx={{color: 'white'}}>
                        <div>
                            {dialogMessage}
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions >
                    <Button 
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>

                </DialogActions>

            </Dialog>
        </div>
    );
};
