import { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';

import { UserContext } from '../../App.js'
import {theme, HorzDiv, VertDiv} from '../../styles.js';
// import { InfoDialog, ActionDialog, AlertDialog, SettingsDialog } from '../Dialogs/Dialogs';

import { sha256, dateToEpoch, isDefined } from '../../functions/functions.js';
// import {keltner, ema, limitBuy} from '../../classes/strategy_00.js';

// import {KeltnerIndicator} from './Keltner_01.js';
// import {EmaIndicator} from './Ema.js';
// import {LimitIndicator} from './Limits.js';
// import { StopLossIndicator } from './StopLoss';
import { Indicator } from './IndicatorGeneralized_00.js';

import { Button, Switch } from "@mui/material";

import { InputWithLabel, BoolIndicator, SelectList } from '../SimpleComponents.js';

import globalParams from '../../globalParams.json';


export function IndicatorGroup({priceHistory, 
                                chainlinkPrice,
                                strategyIDsIn,
                                strategyData,
                                updateStrategyData,
                                updateAllStrategyData, 
                                vaultObj, 
                                toggleAutotradeFunctionHandle, 
                                tokenSecondaryIn, 
                                graphTimeframe,
                                setGraphTimeframe,
                                graphXRange}) {
    const { windowDimensions, setWindowDimensions,
        baseURL, 
        networkDict, 
        txPending, setTxPending, 
        lastTxHash, setLastTxHash,
        errorDialogOpen, setErrorDialogOpen,
        errorMessage, setErrorMessage,
        setTxSuccess,
        devMode} = useContext(UserContext);
    
    // const strategyIDs = ["keltner", "ema", "limit", "stoploss"];
    var initList = ["limit", "ema", "keltner"]
    const [strategyIDs, setStrategyIDs] = useState(initList); //["keltner", "ema", ...]"
    // const [activeList, setActiveList] = useState(strategyIDs.map((id) => {return false}));

    var temp = {};
    for (var i=0; i<strategyIDsIn.length; i++) {
        temp[strategyIDsIn[i]] = false;
    }
    const [activeDict, setActiveDict] = useState(temp); //{"keltner": false, "ema": false, ...}

    const [numToggles, setNumToggles] = useState(0); //needed to force a re-render when activeList is updated

    const [autotradeActive, setAutotradeActive] = useState(false);
    const [autotradeActiveButtonText, setAutotradeActiveButtonText] = useState('Enable Autotrade');
    const [autotradeActiveButtonColor, setAutotradeActiveButtonColor] = useState("primary");
    
    const [isOperator, setIsOperator] = useState(false);
    const [numStrategyLoads, setNumStrategyLoads] = useState(0);
    const [strategyIsSet, setStrategyIsSet] = useState(false);

    const [backtestBool, setBacktestBool] = useState(false);
    const [backtestInitSecondaryPercent, setBacktestInitSecondaryPercent] = useState(0);
    const [secondaryToken, setSecondaryToken] = useState('');

    const [numIDUpdates, setNumIDUpdates] = useState(0);
    // const [keltColor, setKeltColor] = useState('none');
    // const activeColor = theme.green;

    function handleActivate(idIn) {
        var newActiveDict = activeDict;

        for (var i=0; i<strategyIDsIn.length; i++) {
            const id = strategyIDsIn[i];
            if (id !== idIn) {
                newActiveDict[id] = false;
            } else {
                const newState = !newActiveDict[id];
                newActiveDict[id] = newState;
            }
            
        }
        
        setActiveDict(newActiveDict);
        setNumToggles(numToggles+1); //does. stupid. fuck you react.
    }

    async function handleToggleAutotrade() {
        await toggleAutotradeFunctionHandle()
    }

    async function handleSetStrategy(clearStrat=false) {
        var strats = [];
        for (var i=0; i<strategyIDs.length; i++) {
            const id = strategyIDs[i];
            if (activeDict[id]){
                const strat = {"name": id,
                                "params": strategyData[id].strategy};
                strats.push(strat);
            }    
        }
        console.log('strats', strats, clearStrat);

        if (strats.length === 0 && clearStrat === false) {
            window.alert("no strategy currently selected")
        } else {

            var temp;
            var url;
            if (globalParams.strategyFromServer) {
                temp = globalParams.websiteURL;
            } else {
                temp = baseURL;
                if (temp.includes('localhost')) {
                    temp = temp+':8000';
                }
            }

            url = temp+'/api/setstrategy';
            console.log('Setstrategy url:', url);

            const currentEpoch = dateToEpoch(new Date());
            const out = {"dataInterval": priceHistory.dataInterval, 
                         "timestamp": currentEpoch,
                         "chainID": networkDict.chainID, 
                         "isDev": devMode,
                         "strategy": strats}

            var stratsString; 
            
            if (clearStrat) {
                stratsString = JSON.stringify({});
            } else {
                stratsString = JSON.stringify(out);
            }
            //prepend with timestamp. this is to prevent replay attacks.
            //backend will give a few seconds before invalidation.
            const hash = await sha256(currentEpoch.toString() + stratsString);

            console.log(hash);
            setTxPending(true);
            var signedMessage;
            try {
                const signer = networkDict.signer;
                signedMessage = await signer.signMessage(hash);
                console.log('signedmessage:', signedMessage);
                setTxPending(false);
                setTxSuccess(true);
            } catch (e) {
                console.log(e);
                setTxPending(false);
                setTxSuccess(false);
                return;
            }

            //first check
            const signerAddress = ethers.utils.verifyMessage(hash, signedMessage);
            console.log('signerAddress: ', signerAddress);
            if (signerAddress !== vaultObj.operator) {
                console.log('signerAddress: ', signerAddress);
                console.log('vaultObj.operator: ', vaultObj.operator);
                window.alert('signer address does not match operator address');
                return;
            }
            var toSend = {"vault": vaultObj.address,
                        "strategy": stratsString, 
                        "timestamp": currentEpoch, 
                        "hash": hash, 
                        "signature": signedMessage, 
                        "chainID": networkDict.chainID, 
                        "isDev": devMode};
            console.log('toSend: ', toSend);    

            axios.post(url, toSend)
                .then(response => {
                    console.log(response.data);  // Process response data from the server
                    // if (response.data.message === "success") {
                    //     setStrategyIsSet(true);
                    // } else {
                    //     checkIfStrategyIsSet();
                    // }
                    checkIfStrategyIsSet();
                })
                .catch(error => {
                    console.error('Error:', error);
                    checkIfStrategyIsSet();
                }
            );
        }
    }

    async function handleClearStrategy() {
        await handleSetStrategy(true);
    }

    async function handleLoadStrategy() {
        var temp;
        var url;
        if (globalParams.strategyFromServer) {
            temp = globalParams.websiteURL;
        } else {
            temp = baseURL;
            if (temp.includes('localhost')) {
                temp = temp+':8000';
            }
        }
        url = temp+'/api/getstrategy';
        console.log('Get strategy url:', url);

        // const stratsString = JSON.stringify(strats);
        const currentEpoch = dateToEpoch(new Date());
        const vaultAddress = vaultObj.address;
        //prepend with timestamp. this is to prevent replay attacks.
        //backend will give a few seconds before invalidation.
        const hash = await sha256(currentEpoch.toString() + vaultAddress);

        console.log(hash);
        setTxPending(true);
        var signedMessage;
        try {
            const signer = networkDict.signer;
            signedMessage = await signer.signMessage(hash);
            console.log('signedmessage:', signedMessage);
            setTxPending(false);
            setTxSuccess(true);
        } catch (e) {
            console.log(e);
            setTxPending(false);
            setTxSuccess(false);
            return;
        }

        //first check
        const signerAddress = ethers.utils.verifyMessage(hash, signedMessage);
        console.log('signerAddress: ', signerAddress);
        if (signerAddress !== vaultObj.operator) {
            console.log('signerAddress: ', signerAddress);
            console.log('vaultObj.operator: ', vaultObj.operator);
            window.alert('signer address does not match operator address');
            return;
        }
        var toSend = {"vault": vaultObj.address,
                        "timestamp": currentEpoch,
                        "hash": hash,
                        "signature": signedMessage,
                        "chainID": networkDict.chainID,
                        "isDev": devMode};

        console.log('toSend: ', toSend);

        axios.post(url, toSend)
            .then(response => {
                console.log(response.data);  // Process response data from the server
                if (response.data.message === "success") {
                    const stratsString = response.data.strategy;
                    var strat = JSON.parse(stratsString);
                    const timeInterval = strat.dataInterval;
                    if (timeInterval !== priceHistory.dataInterval) {
                        setGraphTimeframe(timeInterval);
                    }
                    strat = strat.strategy;
                    console.log(strat);
                    var newActiveDict = activeDict;
                    var strategyIDsNew = strategyIDs;
                    for (var i=0; i<strategyIDsIn.length; i++) {
                        const id = strategyIDsIn[i];
                        newActiveDict[id] = false;
                        for (var j=0; j<strat.length; j++) {
                            if (strat[j].name === id) {
                                newActiveDict[id] = true;
                                if (!(strategyIDs.includes(id))) {
                                    strategyIDsNew.push(id);
                                }
                                var stratData = strategyData[id];
                                stratData.strategy = strat[j].params;
                                updateStrategyData(id, stratData);
                                break;
                            }
                        }
                    }
                    setStrategyIDs(strategyIDsNew);
                    setActiveDict(newActiveDict);
                    setNumToggles(numToggles+1);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            }
        );

        setNumStrategyLoads(numStrategyLoads+1);
    }

    async function checkIfStrategyIsSet() {
        var temp = baseURL;
        if (temp.includes('localhost')) {
            temp = temp+':8000';
        }
        const url = temp+'/api/checkstrategy/'+networkDict.chainID.toString()+'/'+vaultObj.address;
        console.log('check strategy url:', url);

        axios.get(url).then(res => 
            {
                console.log(res.data);
                var isSet = res.data.strategySet;
                setStrategyIsSet(isSet);
            });
    }

    function handleSetBacktestBool(boolIn) {
        var oneIsActive = false;
        for (var i=0; i<strategyIDsIn.length; i++) {
            if (activeDict[strategyIDsIn[i]] === true) {
                oneIsActive = true;
                break;
            }
        }
        if (boolIn === true && !oneIsActive) {
            window.alert("One strategy must be active to backtest");
        } else {
            // console.log('handleSetBacktestBool: ', boolIn);
            setBacktestBool(boolIn);
        }
    }


    useEffect(() => {
        if (tokenSecondaryIn !== undefined && tokenSecondaryIn !== null && tokenSecondaryIn.symbol !== undefined) {
            setSecondaryToken(tokenSecondaryIn.symbol);
        }
    }, [tokenSecondaryIn]);

    useEffect(() => {
        if (vaultObj !== undefined && vaultObj !== null && vaultObj.autotradeActive !== undefined) {
            const atActive = vaultObj.autotradeActive;
            setAutotradeActive(atActive);
            setAutotradeActiveButtonText(atActive ? "Disable Autotrade" : "Enable Autotrade");
            setAutotradeActiveButtonColor(atActive ? "secondary" : "primary");
        }

        // try {
        //     console.log('HUH????', networkDict.address.toLowerCase(), vaultObj.operator.toLowerCase());
        // } catch (e) {
        //     console.log(e);
        // }
        
        try {
            // if (vaultObj !== undefined && vaultObj !== null && vaultObj.operator !== undefined && 
            //     networkDict !== undefined &&
            //     networkDict.address.toLowerCase() === vaultObj.operator.toLowerCase()) {
            if (isDefined(vaultObj) && isDefined(vaultObj.operator) && 
                isDefined(networkDict) && isDefined(networkDict.address) &&
                networkDict.address.toLowerCase() === vaultObj.operator.toLowerCase()) {
                setIsOperator(true);
            } else {
                setIsOperator(false);
            }
        } catch (e) {
            // console.log(e);
        }

    }, [vaultObj, txPending, networkDict]);

    useEffect(() => {
        if (isDefined(vaultObj) && isDefined(vaultObj.address)) {
            checkIfStrategyIsSet();
        }
    }, [vaultObj]);

    useEffect(() => {
        // console.log('IndicatorGroup useeffect: ', strategyIDs, strategyData);
        var tempStrategyData = strategyData;
        var keysChanged = [];
        for (var i=0; i<strategyIDsIn.length; i++) {
            const id = strategyIDsIn[i];
            if (strategyData[id] !== undefined && 
                strategyData[id].traces !== undefined && 
                strategyData[id].traces.length > 0 &&
                !(id in strategyIDs)) {
                tempStrategyData[id] = {traces: [], strategy: strategyData[id].strategy};
                keysChanged.push(id);
            }
        }
        if (keysChanged.length > 0) {
            // console.log('UPDATING ALL STRATEGY DATA', tempStrategyData);
            updateAllStrategyData(tempStrategyData);
        }
        // for (var i=0; i<keysChanged.length; i++) {
        //     const id = keysChanged[i];
        //     updateStrategyData(id, tempStrategyData[id]);
        // }
        // setNumIDUpdates(numIDUpdates+1);
    }, [strategyIDs]);

    // console.log('IndicatorGroup: ', 
    //             strategyIDs, 
    //             strategyIDs.map((id, index) => id));//, activeList, numToggles, strategyData, backtestBool, backtestInitSecondaryPercent)
    
    function BacktestDiv() {
        return(
            <VertDiv
                margin='0px'
                padding='4px'
                gap='2px'
                borderColor={theme.light}
            >
                <InputWithLabel 
                    label={"Backtest Initial "+secondaryToken+"(%)"}
                    min={0}
                    max={100}
                    step={0.01}
                    precision={2}
                    defaultValue={backtestInitSecondaryPercent}
                    width="5rem"
                    onBlur={event=>setBacktestInitSecondaryPercent(event.target.value)}
                    borderColor={theme.pink}
                    gap={'2px'}
                />
                <Button
                    sx={{m:0, p:0, width: '100px'}}
                    variant='contained'
                    size='small'
                    color={backtestBool ? "secondary" : "primary"}
                    style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    disabled={false}
                    onClick={() => {handleSetBacktestBool(!backtestBool)}}
                >   
                    {backtestBool ? "Remove Backtest" : "Show Backtest"}
                </Button>
            </VertDiv>
        );
    }

    function ControlDiv() {
        return(
            <HorzDiv
                padding={theme.padding1}
                margin='0px'
                gap={theme.gap1}
                borderColor={theme.light}
            >
                
                <VertDiv
                    margin='0px'
                    padding='2px'
                    gap='2px'
                    borderColor={theme.light}
                >
                    <Button
                        sx={{m:0, p:1}}
                        variant='contained'
                        size='large'
                        color={autotradeActiveButtonColor}
                        style={{'borderRadius':theme.borderRadius, 
                                'width': '100px',
                                'fontWeight':'bold', 
                                'fontSize': '0.8em'}}
                        onClick={() => {handleToggleAutotrade()}}
                    >   
                        {autotradeActiveButtonText}
                    </Button>
                    <Button
                        onClick={() => handleSetStrategy()}
                        variant='contained'
                        sx={{m:0, p:1}}
                        style={{'borderRadius':theme.borderRadius, 
                            'width': '100px',
                            'fontWeight':'bold', 
                            'fontSize': '0.8em'}}
                    >  
                        Set{strategyIsSet ? " New " : " "}Strategy 
                    </Button>
                    
                </VertDiv>
                {strategyIsSet &&
                    <VertDiv
                        margin='0px'
                        padding='2px'
                        gap='2px'
                        borderColor={theme.light}
                    >
                        <Button
                            onClick={handleLoadStrategy}
                            variant='contained'
                            sx={{m:0, p:1}}
                            // disabled={!strategyIsSet}
                            style={{'borderRadius':theme.borderRadius, 
                                'width': '100px',
                                'fontWeight':'bold', 
                                'fontSize': '0.8em'}}
                        >   
                            Load Strategy
                        </Button>
                        <Button
                            onClick={handleClearStrategy}
                            variant='contained'
                            sx={{m:0, p:1}}
                            // disabled={!strategyIsSet}
                            style={{'borderRadius':theme.borderRadius, 
                                'width': '100px',
                                'fontWeight':'bold', 
                                'fontSize': '0.8em'}}
                        >   
                            Clear Strategy
                        </Button>
                    </VertDiv>
                }
   

                <VertDiv 
                    margin='0px'
                    padding='2px'
                    gap='2px'
                    borderColor={theme.light}
                    borderStyle='none'
                >
                    <BoolIndicator 
                        labels={["Autotrade Enabled", 'Strategy Set', 'Running']}
                        values={[autotradeActive, strategyIsSet, autotradeActive && strategyIsSet]}
                    />
                </VertDiv>
            </HorzDiv>
        );
    }

    function SelectDiv() {
        return(
            <VertDiv
                margin='0px'
                borderColor={theme.light}
            >
                <SelectList 
                    items={strategyIDsIn} 
                    selectedIn={strategyIDs}
                    setSelectedIn={setStrategyIDs}
                />
                <HorzDiv
                    borderStyle='none'
                    borderColor={theme.light}
                    margin='0px'
                    padding='0px'
                    alignItems='stretch'
                    height='100%'
                >   
                    {
                        strategyIDs.map((id, index) => {
                            return(
                                <VertDiv
                                    key={index}
                                    padding='0px'
                                    margin='0px'
                                    gap='0px'
                                    borderStyle='solid'
                                    borderColor={theme.light}
                                    backgroundColor={theme.light}
                                >
                                    <Switch
                                        // checked={activeList[index]}
                                        label={"Select"}
                                        checked={activeDict[id]}
                                        onChange={() => {handleActivate(id)}}
                                    />
                                    <Indicator 
                                        id={id}
                                        priceHistory={priceHistory}
                                        chainlinkPrice={chainlinkPrice}
                                        parentData={strategyData}
                                        updateParentData={updateStrategyData}
                                        graphTimeframe={graphTimeframe}
                                        backTestBool={backtestBool && activeDict[id]}
                                        backTestInitPercent={backtestInitSecondaryPercent}
                                        backTestXRange={graphXRange}
                                    /> 
                                </VertDiv>
                            );
                        })
                    }
                </HorzDiv>
            </VertDiv>
        );
    }

    // console.log('IndicatorGroup: ', graphTimeframe);
    return(
        <HorzDiv
            alignItems='stretch'
            justifyContent='center'
            margin='0px'
            padding={theme.padding1}
            gap={theme.gap1}
            // borderColor={theme.light}
            backgroundColor={theme.dark}
            width='100%'
            flexWrap='wrap'
        >
            <HorzDiv 
                borderStyle='solid'
                borderColor={theme.light}
                margin='0px'
                padding={theme.padding1}
                gap={theme.gap1}
                // alignItems='stretch'
            >
                {/* <SelectDiv /> */}
                <VertDiv
                    margin='0px'
                    borderColor={theme.light}
                >
                    <SelectList 
                        items={strategyIDsIn} 
                        selectedIn={strategyIDs}
                        setSelectedIn={setStrategyIDs}
                    />
                    <HorzDiv
                        borderStyle='none'
                        borderColor={theme.light}
                        margin='0px'
                        padding='0px'
                        alignItems='stretch'
                        height='100%'
                    >   
                        {
                            strategyIDs.map((id, index) => {
                                return(
                                    <VertDiv
                                        key={index.toString()+'-'+id}
                                        padding='0px'
                                        margin='0px'
                                        gap='0px'
                                        borderStyle='solid'
                                        borderColor={theme.light}
                                        backgroundColor={theme.light}
                                    >
                                        <Switch
                                            // checked={activeList[index]}
                                            label={"Select"}
                                            checked={activeDict[id]}
                                            onChange={() => {handleActivate(id)}}
                                        />
                                        <Indicator 
                                            id={id}
                                            priceHistory={priceHistory}
                                            chainlinkPrice={chainlinkPrice}
                                            graphTimeframe={graphTimeframe}
                                            parentData={strategyData}
                                            updateParentData={updateStrategyData}
                                            paramsInitIn={strategyData[id].strategy}
                                            backTestBool={backtestBool && activeDict[id]}
                                            backTestInitPercent={backtestInitSecondaryPercent}
                                            backTestXRange={graphXRange}
                                        /> 
                                    </VertDiv>
                                );
                            })
                        }
                    </HorzDiv>
                </VertDiv>
                <BacktestDiv />
            </HorzDiv>

            {isOperator &&
                <ControlDiv />
            }
                

        </HorzDiv>
    );


}
