import { useState, useEffect } from "react";
import {Text} from "@chakra-ui/react";
import {theme, HorzDiv, VertDiv} from '../../styles';
import { SettingsDialog } from '../Dialogs/Dialogs';
import { Button } from '@mui/material';

import { dateStringToEpoch } from "../../functions/functions";
import { strategyBase } from "../../classes/strategy_01";

export function Indicator({id, 
                            priceHistory, 
                            chainlinkPrice, 
                            parentData,
                            updateParentData, 
                            graphTimeframe,
                            paramsInitIn=null,
                            backTestBool=false, 
                            backTestInitPercent=0, 
                            backTestXRange={min: "1970-10-15 0:0:0.0", 
                                            max: "2050-10-15 20:10:25.599"}}) {
    
    const [show, setShow] = useState(false);
    const [strategyClass, setStrategyClass] = useState(new strategyBase(id, chainlinkPrice, paramsInitIn));
    const [params, setParams] = useState(strategyClass.params);
    const [dialogOpenBool, setDialogOpenBool] = useState(false);
    const [traces, setTraces] = useState([]);
    const [strategyActive, setStrategyActive] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('none');
    // const [parentJustUpdated, setParentJustUpdated] = useState(false);

    // const options = strategyClass.options;


    function handleSetShow() {
        setShow(!show);
    }

    function getTraces() {
        return strategyClass.getTraces(priceHistory, params);
    }

    function filterIndeces() {
        const xMinEpoch = dateStringToEpoch(backTestXRange.min);
        const xMaxEpoch = dateStringToEpoch(backTestXRange.max);

        const et = priceHistory.timesEpoch;

        var indexMin = et.findIndex((element) => element >= xMinEpoch);
        var indexMax = et.findIndex((element) => element >= xMaxEpoch);

        if (indexMin === -1) {
            return {min:null, max:null}; //nothing in range
        }
        if (indexMax === -1) {
            indexMax = et.length-1;
        }

        return {indexMin:indexMin, indexMax:indexMax};
    }
    function filterHistory() {
        var filteredHistory = {times: [], 
                            timesEpoch: [],
                            open:[], 
                            low: [],
                            high: [],
                            close: [],
                            volume: [],
                            lastRow: priceHistory.lastRow};

        const {indexMin, indexMax} = filterIndeces();

        for (var i=indexMin; i<=indexMax; i++) {
            filteredHistory.times.push(priceHistory.times[i]);
            filteredHistory.timesEpoch.push(priceHistory.timesEpoch[i]);
            filteredHistory.open.push(priceHistory.open[i]);
            filteredHistory.low.push(priceHistory.low[i]);
            filteredHistory.high.push(priceHistory.high[i]);
            filteredHistory.close.push(priceHistory.close[i]);
            filteredHistory.volume.push(priceHistory.volume[i]);
        }
        // console.log(filteredHistory);
        return filteredHistory;
    }
    function backtest() {     
        // const conditions = strategyClass.createConditions(params);
        // const filteredHistory = filterHistory();

        const {indexMin, indexMax} = filterIndeces();
        var bt = strategyClass.backtest(priceHistory, 
                                        backTestInitPercent,
                                        params, 
                                        indexMin,
                                        indexMax);
        return bt;
        // return {traces:[]};
    }
    useEffect(() => {
        // console.log('in IndicatorGeneralized_00 useEffect', graphTimeframe, params);
        var tracesExtra = [];
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1) {

            if (show) {
                const graphTraces = getTraces();
                for (var i=0; i<graphTraces.length; i++) {
                    tracesExtra.push(graphTraces[i]);
                }
                // setTraces(tracesExtra);
            } 
            if (backTestBool && strategyClass.backtestable) {
                const bt = backtest();
                for (var i=0; i<bt.traces.length; i++) {
                    tracesExtra.push(bt.traces[i]);
                }
                // setTraces(tracesExtra);
            }
            if (tracesExtra.length != traces.length) {
                setTraces(tracesExtra);
            }
                   
        }

    }, [priceHistory, chainlinkPrice, show, backTestBool, backTestInitPercent, traces, graphTimeframe])
    useEffect(() => {
        // console.log('in IndicatorGeneralized_00 useEffect', graphTimeframe, params);
        var tracesExtra = [];
        var set = false;
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1) {

            if (show) {
                const graphTraces = getTraces();
                for (var i=0; i<graphTraces.length; i++) {
                    tracesExtra.push(graphTraces[i]);
                }
                setTraces(tracesExtra);
                set = true;
            } 
            if (backTestBool && strategyClass.backtestable) {
                const bt = backtest();
                for (var i=0; i<bt.traces.length; i++) {
                    tracesExtra.push(bt.traces[i]);
                }
                setTraces(tracesExtra);
                set = true;
            }
            if (tracesExtra.length != traces.length && !set) {
                setTraces(tracesExtra);
            }      
        }
    }, [params])

    useEffect(() => {
        var newData = {traces: traces, strategy: params};
        updateParentData(id, newData);
        // setParentJustUpdated(true);
    }, [traces, strategyActive])

    useEffect(() => {
        if (parentData[id] !== undefined &&
            parentData[id].strategy !== undefined) {
            setParams(parentData[id].strategy);
        }
    }, [parentData]);

    useEffect(() => {
        setShow(false);
        const sc = new strategyBase(id, chainlinkPrice, params);

        // console.log('HUH useEffect', id, sc, sc.params)

        setStrategyClass(sc);

        setParams(sc.params);
        setDialogOpenBool(false);
        setShow(false);
        setTraces([]);
        setStrategyActive(false);
        setBackgroundColor('none');

        updateParentData(id, {traces: [], strategy: sc.params});

    }, [id]);

    // console.log('in IndicatorGeneralized_00', graphTimeframe);
    return(
        <VertDiv 
            padding='4px'
            margin='2px'
            gap='2px'
            backgroundColor={theme.dark}
            borderStyle='none'
        >
            <Text fontSize={theme.fontSizeMedium} color='white' margin="0px" padding='0px'>
                {strategyClass.description}
            </Text>
            <SettingsDialog 
                title={strategyClass.description+' Settings'}
                settingsIn={params} 
                setSettingsIn={setParams} 
                setDialogOpenFlag={setDialogOpenBool}
            />
            <Button
                sx={{p:0, m:0}}
                color={show ? "secondary" : "primary"}
                variant='contained'
                size='small'
                style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.8em'}}
                onClick={() => {handleSetShow()}}
            >
                {show ? 'Hide' : 'Show'}
            </Button>
        </VertDiv>

    )
}