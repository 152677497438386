import { theme, VertDiv, HorzDiv } from '../styles.js';


function Docs() {
    return (
        <VertDiv
            justifyContent='flex-start' 
            alignItems='center'
            borderStyle='none'
            width='100%'
        >
            <h1>Docs Under Contruction...</h1>
        </VertDiv>
    )
}


export default Docs