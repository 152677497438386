import {useState} from 'react';


import { theme, VertDiv, HorzDiv } from '../styles.js';
import {Text } from "@chakra-ui/react";

import {List, ListItem, ListItemText, ListItemAvatar, ListItemButton, 
        ListItemIcon, Avatar, Collapse, Divider as MDivider} from '@mui/material';

import { AccountBalance as AccountBalanceIcon, 
         Wallet as WalletIcon, 
         AutoGraph as AutoGraphIcon,
         AutoMode as AutoModeIcon, 
         Preview as PreviewIcon, 
         Lock as LockIcon} from '@mui/icons-material';

import bgImage from '../images/bgImage_0.png'
import trustImage from '../images/trust.png'
import site0 from '../images/site0.png'
import site1 from '../images/site1.png'
import picManualTrade from '../images/site_manual_traded.png'
import site3 from '../images/site3.png'
// import bgVid from '../vids/output-{11,1}-{20,1}--20000.webm'

import { motion } from "framer-motion"

import OrbitalSceneSimple from '../components/OrbitalSceneSimple';

import { SlideFromLeftAnimation, FadeInAnimation, FadeInWhenVisibleAnimation } from '../components/Animations/animations.js';
import { Divider } from 'semantic-ui-react';

const UseCases = () => {
    return (
        <List sx={{ width: '100%', maxWidth: 500, color: 'white' }}>
            <ListItem divider>
            
                <ListItemAvatar>
                    <Avatar>
                        <AccountBalanceIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Create and manage your own funds"  />
      
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <WalletIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Make your fund public and earn fees" />
            </ListItem>
                
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <AutoModeIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Automate your trading strategies using advanced technical indicators" />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <AutoGraphIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Track your fund's performance and adjust your strategies as needed" />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <PreviewIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Instantly view performance of existing funds and deposit as you wish" />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <LockIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Funds are secured by Smart Contracts. Only you can withdraw your money!" />
            </ListItem>
        </List>
        );
    }
const Hero = () => {
    return (
        <motion.header
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <VertDiv alignItems='center' backgroundColor={theme.blue} maxWidth='700px' margin='0px' >
                <h3>Revolutionize Your Cryptocurrency Investment Strategy with Orbital Automation</h3>
                <h4>A powerful platform for creating and managing cryptocurrency funds and automating trading strategies.</h4>
                <VertDiv borderColor={theme.lightlight}>
                    {/* <UseCases /> */}
                </VertDiv>
                
            </VertDiv>
            
        </motion.header>
        );
    };

const Desc = () => {
    return (
        <motion.header
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <VertDiv maxWidth='700px' justifyContent='center' alignItems='stretch'
                borderStyle='solid' 
                backgroundColor={theme.dark}
            >
                <h2>Site Philosphy</h2>
                <VertDiv borderColor={theme.light}>
                    <Text align={'justify'}>
                        At Orbital, we believe that Fund Management is destined to become one of the pre-eminent 
                        use-cases for Smart Contracts, and Blockchain in general. Before the advent of the 
                        Ethereum Virtual Machine, it would have been unthinkable to deposit large sums of money
                        into a managed fund without sufficient legal gaurdrails to prevent theft. Now, secure 
                        funds can be created by anyone with a click of a button, and managed just as easily. 
                        The performance of these funds is completely transparent and can be tracked and 
                        audited by anyone.
                    </Text>
                    <Text align={'justify'}>
                        The primary utility of Orbital is to allow for a division of labor in the crypto-sphere:
                        talented traders and investors will be able to devote thier time and energy into growing
                        thier portfolios, while investors can simply deposit tokens into the funds they like and
                        withdraw at any time. In return, fund managers will be able to earn fees which take place at
                        the time of withdrawal. These fees are determined at the time of the fund creation
                        and cannot be altered.
                    </Text>
                    <Text align={'justify'}>
                        With that in mind, we're proud to release version 1.0 of the protocol, which allows for simple 
                        Two-Token Vaults, and algo-trading using a few time-tested technical indicators. In time,
                        we will add more complex functionality, such as Market Making.
                    </Text>
                </VertDiv>
            </VertDiv>
        </motion.header>
    );
}

const Hero2 = () => {
    const fs = 15;
    const backgroundColor = 'rgba(0, 0, 0, .4)';
    const borderColor = 'rgba(0, 255, 0, .50)';
    // const textList = ['Simple', 'Secure', 'Transparent'];
    const textList = ['Deploy Vaults for pennies', 
                      'Make your Vault public and earn fees',
                      'Backtest and automate your trading strategies', 
                      'Performance is tracked automatically'];
    return (
        <VertDiv width='100%'
            // justifyContent='flex-start' 
            backgroundColor='#000000' 
            borderRadius='0' 
            borderStyle='none' 
            padding={theme.padding0}
            style={{height: '600px'}}
        >
            <OrbitalSceneSimple />
            {/* {fadeInAnimation( */}

            <VertDiv 
                width='100%' 
                backgroundColor='rgba(0, 0, 0, 0)' 
                borderStyle='none'
                alignItems='flex-start' 
                justifyContent='center'
                style={{ position: 'absolute', 
                            zIndex: 1,
                            //marginLeft: 60, 
                            marginTop: 0, 
                            opacity: 1.0, 
                            height: '100%', 
                            'pointer-events': 'none' }}
            >
                {FadeInAnimation(
                    <VertDiv 
                        alignItems='flex-start'
                        backgroundColor='rgba(0, 0, 0, .4)'
                        borderColor={theme.light}
                        style={{marginLeft:40, marginRight:40}}
                    >
                        <h1 style={{color: 'white', fontSize: 60, textAlign: 'left'}}>Orbital</h1>
                        <h2 style={{color: 'white', textAlign: 'left'}}>On-Chain Fund Management System</h2>
                        <VertDiv
                            maxWidth='800px'
                            width="100%"
                            height='100%'
                            borderColor={theme.light} 
                            justifyContent='center'
                            alignItems='stretch'
                            
                            style={{opacity: 1.0}}
                        >
                            {textList.map((text) => 
                                <VertDiv 
                                    alignItems='flex-start'
                                    backgroundColor={backgroundColor}
                                    borderColor={borderColor}
                                    style={{textAlign: 'left'}}
                                >
                                    <h4 
                                        style={{color: 'white', fontsize:fs}}
                                    >
                                        {text}
                                    </h4>
                                </VertDiv>
                            )}
                        </VertDiv>
                    </VertDiv>
                , 3.0)} 
            </VertDiv>
        </VertDiv>
    );
}

const Explainer = ({tagline, image, bulletsList, fontSize=18, imgMaxWidth='500px'}) => {
    const fs = fontSize;
    return (
        FadeInWhenVisibleAnimation(
            <VertDiv backgroundColor={theme.black}>
                <h1>{tagline}</h1>
                <HorzDiv backgroundColor={theme.blue} borderStyle='none'>
                    <img src={image} alt={tagline} style={{maxWidth: imgMaxWidth, width: '100%'}} />
                    <VertDiv 
                        alignItems='flex-start' 
                        style={{fontSize:fs, paddingLeft:'1.5rem', paddingRight:'1.0rem'}}
                    >
                        {bulletsList.map((text) =>
                            <li>{text}</li>
                        )}
                    </VertDiv>
                </HorzDiv>
            </VertDiv>
        )
    );
}

const Imgs = () => {
    const fs = 17;
    return (
        <VertDiv borderStyle='none' alignItems='stretch'>
            <Explainer 
                tagline='Create'
                image={site0} 
                bulletsList={
                    ['Select your Fund\'s tokens',
                    'Make your Fund Public and earn Fees',
                    'Vaults persist until you deactivate',
                    '"Operator Fees" go to you',
                    '"User Fees" are distributed to all Vault users']
                } 
                fontSize={fs}              
            />
            <Explainer
                tagline='Browse'
                image={site1}
                bulletsList={
                    ['View existing Vaults',
                    'Performance is 100% transparent',
                    'Deposit as desired',
                    'Orbital never has access to your tokens',
                    'Operator never has access to your tokens',
                    'Withdraw any time']
                }
                fontSize={fs}
            />
            <Explainer
                tagline='Trade'
                image={picManualTrade}
                bulletsList={
                    ['Trade thru our Dex Aggregator',
                    'Track your trades visually',
                    "View Fund's Gain and APR"]
                }
                imgMaxWidth='600px'
                fontSize={fs}
            />
            <Explainer
                tagline='Automate'
                image={site3}
                bulletsList={
                    ['Backtest your trading strategies',
                    'Coding not required',
                    'Switch To Automated Mode',
                    'Let Orbital trade for you',
                    'Switch back To Manual Mode anytime']
                }
                imgMaxWidth='600px'
                fontSize={fs}
            />
        </VertDiv>
    );
}

function Faq({question, answer}){
    // const { question, answer } = props;
    const [isOpen, toggleOpen] = useState(false);
  
    return (
        <VertDiv 
            backgroundColor={theme.blue}
            borderColor={theme.green}
            alignItems='flex-start'
            onClick={() => toggleOpen(!isOpen)}
        >
            {/* <div className="faq-question">
                <span>
                    {question}
                </span>
            </div> */}
            <VertDiv 
                borderStyle='none'
                alignItems='flex-start'
                padding={theme.padding0}
                style={{textAlign: 'left'}}
            >
                {question}
            </VertDiv>
            <div 
                className="faq-answer" 
                style={
                    isOpen ? { display: "block", width: '100%', alignItems: 'flex-start'} : { display: "none" } 
                }
            >
                <MDivider flexItem={true} sx={{ bgcolor: "white" }} />
                <VertDiv
                    borderStyle='none'
                    alignItems='flex-start'
                    padding={theme.padding0}
                    style={{marginTop: '10px', textAlign: 'left'}}
                >
                    {answer}
                </VertDiv>
            </div> 
        </VertDiv>

    );
};

const FaqSection = () => {
    return(
        // FadeInWhenVisibleAnimation(
            <VertDiv 
                borderStyle='none'
                backgroundColor={theme.black}
                justifyContent="space-between"
                alignItems='center'
                width='100%'
                height='100%'
                borderRadius='0px'
                padding={theme.padding0}
                gap={theme.gap0}
            >
                    <h1>FAQ</h1>
                    {/* <li>Will Orbital Exist on chains other than Arbitrum?</li> */}
                    <VertDiv
                        maxWidth='600px'
                        width="100%"
                        height='100%'
                        borderColor={theme.light}
                        justifyContent='center'
                        alignItems='stretch'
                    >
                        <Faq
                            question="Will Orbital exist on chains other than Arbitrum?"
                            answer='Yes, we plan to deploy on other chains soon.'
                        />
                        <Faq
                            question="Why can vaults only have 2 tokens?"
                            answer='Our Smart Contracts can create vaults with any number of tokens, but we are launching with 2-token vaults for simplicity.'
                        />
                        <Faq
                            question="Does Orbital have a token?"
                            answer='We do not currrently have a token, but one is in the works.'
                        />
                        <Faq
                            question="Can I use Tradingview and Pinescript for automation?"
                            answer='Not currently, but we are working on full Tradingview integration.'
                        />
                        <Faq
                            question="Can I use leveraged trading within my Vaults?"
                            answer='Orbital Vaults are Spot-only for now. The security risks of leveraged trading are signifincant, and we have a safety-first approach. But this feature may be added in the future.'
                        />
                        
                    </VertDiv>
            </VertDiv>
        // )
        
 

    );
}

const Audit = () => {
    return (
        <HorzDiv 
            justifyContent='center'
            alignItems='center'
            // backgroundColor="rgb(0,0,0)"
            width='100%'
            borderRadius='0px'
            borderStyle='none'
        >   
            <h2>Audited By</h2>
            <a href="https://www.trust-security.xyz/orbital-audit">
                <img src={trustImage} alt="Trust" style={{width: '100px'}} />
            </a>   
        </HorzDiv>
    );
}

  
const Home = () => {
    const [showStuff, setShowStuff] = useState(false);
    return (
        <VertDiv 
            borderStyle='none'
            justifyContent="space-between"
            alignItems='center'
            width='100%'
            height='100%'
            borderRadius='0px'
            padding={theme.padding0}
            gap={theme.gap1}
            margin='0px'
            // style={{height: '100%'}}
            // style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: 'center' }} 
        >
            <Hero2 />
            <Imgs />
            <FaqSection />
            <Audit />
            
        </VertDiv>
        // </video>


    );
};
  
export default Home;