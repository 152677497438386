import { roundToDigits, rangePy, minusSlippage, BN,
         getAmtIn, bigNumToFloat, isDefined, isUndefined } from '../functions/functions.js';
import {emaCalc, trueRange, smaCalc, 
        arrayRemoveLast,  
        dateToEpoch, epochToDate} from '../functions/strategyFunctions.js';



// class trade {
//     constructor(type, price, time, amtSold, amtBought) {
//         this.type = type;
//         this.price = price;
//         this.time = time;
//         this.amtSold = amtSold;
//         this.amtBought = amtBought;
//     }
// }

export const actions = {
    'none': 'none',
    'buy': 'buy',
    'sell': 'sell'
}

export const signals = {
    'none': 'none',
    'above': 'above',
    'below': 'below',
    'crossover': 'crossover',
    'crossunder': 'crossunder'
}

export const fetchModes = {
    'continuous': 'continuous',
    'discrete': 'discrete'
}

function checkForMetConditions(controlCurve, currentControl, conditions, index=null) {
    var lastControl;
    var conditionsMet = [];
    if (controlCurve.length > 2) {
        var i;
        if (index === null) {
            i = controlCurve.length-1;
        } else {
            i = index;
        }

        lastControl = controlCurve[i-1];
        for (var j=0; j<conditions.length; j++) {
            var cond = conditions[j];
            if (cond.armed) {
                if(cond.direction === signals.below) {
                    if (currentControl < cond.thresh) {
                        conditionsMet.push(cond);
                    }
                } else if (cond.direction === signals.above) {
                    if (currentControl > cond.thresh) {
                        conditionsMet.push(cond);
                    }
                } else if (cond.direction === signals.crossover) {
                    if (lastControl < cond.thresh && currentControl > cond.thresh) {
                        conditionsMet.push(cond);
                    }
                } else if (cond.direction === signals.crossunder) {
                    if (lastControl > cond.thresh && currentControl < cond.thresh) {
                        conditionsMet.push(cond);
                    }
                }
            } else if (cond.resetValue !== null) {
                //check for reset conditions
                if (cond.resetDirection === signals.below) {
                    if (currentControl < cond.resetValue) {
                        cond.armed = true;
                    }
                } else if (cond.resetDirection === signals.above) {
                    if (currentControl > cond.resetValue) {
                        cond.armed = true;
                    }
                } else if (cond.resetDirection === signals.crossover) {
                    if (lastControl < cond.resetValue && currentControl > cond.resetValue) {
                        cond.armed = true;
                    }
                } else if (cond.resetDirection === signals.crossunder) {
                    if (lastControl > cond.resetValue && currentControl < cond.resetValue) {
                        cond.armed = true;
                    }
                }
            }
        }
    }
    return conditionsMet;
}

function keltnerChan(highList, 
                    lowsList, 
                    closeList, 
                    maPeriod=30, 
                    atrPeriod=30, 
                    multipliers=[5.0]) {
    var ma = emaCalc(closeList, maPeriod);

    var atr = emaCalc(trueRange(highList, lowsList, closeList), atrPeriod);
    // var atr = rmaCalc(trueRange(highList, lowsList, closeList), atrPeriod);

    var upperBands = [];
    var lowerBands = [];
    for (var m = 0; m < multipliers.length; m++) {
        var uband = [];
        var lband = [];
        for (var i = 0; i < ma.length; i++) {
            uband.push(ma[i] + atr[i] * multipliers[m]);
            lband.push(ma[i] - atr[i] * multipliers[m]);
        }
        upperBands.push(uband);
        lowerBands.push(lband);
    }

    return {ema: ma, atr: atr, upperBands: upperBands, lowerBands: lowerBands};
}

class limitBuy {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        var initTrigger = 0;
        var initStopLoss = 0;
        var initTakeProfits = [{value: 0, percent: 0}];
        var initSizePercent = 0;
        

        if (currentPriceIn) {
            initTrigger = roundToDigits(currentPriceIn*0.95, -2);
            initStopLoss = roundToDigits(currentPriceIn*0.90, -2);
            initTakeProfits = [{value: roundToDigits(currentPriceIn*1.20, -2), percent: 100}];
            initSizePercent = 100;
        }
        this.name = "Limit Buy";
        this.description = "Limit Buy";
        this.controlParamString = 'price';
        this.paramsInit = { sizePercent: initSizePercent,
                            trigger: initTrigger, 
                            stopLoss: initStopLoss, 
                            takeProfits: initTakeProfits};
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.continuous;
        // this.conditionsBacktest = [];
        // this.params = this.paramsInit;
        this.params = paramsIn ? paramsIn : this.paramsInit;
    }

    createConditions(params) {
        var conditions = [];
        conditions.push({index: 0,
                        type: actions.buy, 
                        thresh: params.trigger, 
                        direction: signals.below,
                        percent: params.sizePercent,
                        resetValue: 0, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [1].concat(rangePy(2, 2+params.takeProfits.length)), 
                        conditionsToDisarmIfTriggered: []});

        conditions.push({index: 1,
                        type: actions.sell, 
                        thresh: params.stopLoss, 
                        direction: signals.below,
                        percent: 100, //needs to reference the size of the trade
                        resetValue: 0, 
                        resetDirection: signals.crossover,
                        armed: false, 
                        trades: [],
                        conditionsToArmIfTriggered: [],
                        conditionsToDisarmIfTriggered: [0,1].concat(rangePy(2, 2+params.takeProfits.length))});

        for (var i=0; i<params.takeProfits.length; i++) {
            conditions.push({index: 2+i,
                            type: actions.sell,
                            thresh: params.takeProfits[i].value,
                            direction: signals.above,
                            percent: params.takeProfits[i].percent,
                            resetValue: 0,
                            resetDirection: signals.crossunder,
                            armed: false, 
                            trades: [],
                            conditionsToArmIfTriggered: [],
                            conditionsToDisarmIfTriggered: i === params.takeProfits.length-1 ? rangePy(0, 2+params.takeProfits.length) : []});
        }
        this.conditions = conditions;
    }

    controlValue(price, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        return price;
    } 
    postTradeCallback(success, tradeObj) {
        var condition = this.pendingTrade.condition;
        if (success) {
            this.pendingTrade = null;
            condition.armed = false;
            condition.trades.push(tradeObj);
            var j;
            for (j=0; j<condition.conditionsToArmIfTriggered.length; j++) {
                this.conditions[condition.conditionsToArmIfTriggered[j]].armed = true;
            }
            for (j=0; j<condition.conditionsToDisarmIfTriggered.length; j++) {
                this.conditions[condition.conditionsToDisarmIfTriggered[j]].armed = false;
            }
        }
    }
    backtestPrework(priceHistory, guiParams, conditions=null) {
        this.priceHistory = priceHistory;
        this.control = priceHistory.close;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        // console.log('getTraces: ', priceHistory, params)
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1 &&
            params.trigger > 0) {

            var bc = '#00ff00';
            var sc = '#ff0000';
            // var ema = emaCalc(priceHistory.close, params.emaPeriod);
            
            var trace;
            var tDelta = (priceHistory.times[1] - priceHistory.times[0])/1000;
            var endTime = dateToEpoch(priceHistory.times[priceHistory.times.length-1]);

            var lineEndTime = epochToDate(endTime + 100*tDelta);
            // console.log('tDelta: ', tDelta, endTime);
            var x = [priceHistory.times[0], lineEndTime];
            trace = {x: x,
                    y: [params.trigger, params.trigger], 
                    xaxis:'x1', 
                    yaxis:'y1',
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    // marker: {color:type === 'buy' ? bc : sc},
                    line: {
                        color: bc,
                        width: 1,
                        // dash: 'dot'
                    },
                    name: 'limit trigger'};
            traces.push(trace);
            if (params.stopLoss > 0) {
                trace = {x: x,
                        y: [params.stopLoss, params.stopLoss],
                        xaxis:'x1',
                        yaxis:'y1',
                        showlegend: false,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: sc,
                            width: 1,
                            dash: 'dot'
                        },
                        name: 'stop loss'};
                traces.push(trace);
            }
            for (var i=0; i<params.takeProfits.length; i++) {
                if (params.takeProfits[i].value > 0) {
                    trace = {x: x,
                            y: [params.takeProfits[i].value, params.takeProfits[i].value],
                            xaxis:'x1',
                            yaxis:'y1',
                            showlegend: false,
                            type: 'scatter',
                            mode: 'lines',
                            line: {
                                color: sc,
                                width: 1,
                                dash: 'dot'
                            },
                            name: 'take profit ' + (i+1).toString()};
                    traces.push(trace);
                }
            }

        }

        return traces;
    }
    //END INTERFACE METHODS
}

class limitSell {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        var initTrigger = 0;
        var initStopLoss = 0;
        var initTakeProfits = [{value: 0, percent: 0}];
        var initSizePercent = 0;
        

        if (currentPriceIn) {
            initTrigger = roundToDigits(currentPriceIn*1.05, -2);
            initStopLoss = roundToDigits(currentPriceIn*1.10, -2);
            initTakeProfits = [{value: roundToDigits(currentPriceIn*0.80, -2), percent: 100}];
            initSizePercent = 100;
        }
        this.name = "Limit Sell";
        this.description = "Limit Sell";
        this.controlParamString = 'price';
        this.paramsInit = { sizePercent: initSizePercent,
                            trigger: initTrigger, 
                            stopLoss: initStopLoss, 
                            takeProfits: initTakeProfits};
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.continuous;
        // this.conditionsBacktest = [];
        // this.params = this.paramsInit;
        this.params = paramsIn ? paramsIn : this.paramsInit;
    }

    createConditions(params) {
        var conditions = [];

        //take profits
        conditions.push({index: 0,
                        type: actions.sell, 
                        thresh: params.trigger, 
                        direction: signals.above,
                        percent: params.sizePercent,
                        resetValue: 0, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [1].concat(rangePy(2, 2+params.takeProfits.length)), 
                        conditionsToDisarmIfTriggered: []});

        conditions.push({index: 1,
                        type: actions.buy, 
                        thresh: params.stopLoss, 
                        direction: signals.above,
                        percent: 100, //needs to reference the size of the trade
                        resetValue: 0, 
                        resetDirection: signals.crossover,
                        armed: false, 
                        trades: [],
                        conditionsToArmIfTriggered: [],
                        conditionsToDisarmIfTriggered: [0,1].concat(rangePy(2, 2+params.takeProfits.length))});

        for (var i=0; i<params.takeProfits.length; i++) {
            conditions.push({index: 2+i,
                            type: actions.buy,
                            thresh: params.takeProfits[i].value,
                            direction: signals.below,
                            percent: params.takeProfits[i].percent,
                            resetValue: 0,
                            resetDirection: signals.crossunder,
                            armed: false, 
                            trades: [],
                            conditionsToArmIfTriggered: [],
                            conditionsToDisarmIfTriggered: i === params.takeProfits.length-1 ? rangePy(0, 2+params.takeProfits.length) : []});
        }

        this.conditions = conditions;
    }
    controlValue(price, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        return price;
    } 

    backtestPrework(priceHistory, guiParams, conditions=null) {
        this.priceHistory = priceHistory;
        this.control = priceHistory.close;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        // console.log('getTraces: ', priceHistory, params)
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1 &&
            params.trigger > 0) {

            // var trg = params.trigger;

            var bc = '#00ff00';
            var sc = '#ff0000';
            // var ema = emaCalc(priceHistory.close, params.emaPeriod);
            
            var trace;
            var tDelta = (priceHistory.times[1] - priceHistory.times[0])/1000;
            var endTime = dateToEpoch(priceHistory.times[priceHistory.times.length-1]);

            var lineEndTime = epochToDate(endTime + 100*tDelta);
            // console.log('tDelta: ', tDelta, endTime);
            var x = [priceHistory.times[0], lineEndTime];
            trace = {x: x,
                    y: [params.trigger, params.trigger], 
                    xaxis:'x1', 
                    yaxis:'y1',
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    // marker: {color:type === 'buy' ? bc : sc},
                    line: {
                        color: sc,
                        width: 1,
                        // dash: 'dot'
                    },
                    name: 'limit trigger'};
            traces.push(trace);
            if (params.stopLoss > 0) {
                trace = {x: x,
                        y: [params.stopLoss, params.stopLoss],
                        xaxis:'x1',
                        yaxis:'y1',
                        showlegend: false,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: bc,
                            width: 1,
                            dash: 'dot'
                        },
                        name: 'stop loss'};
                traces.push(trace);
            }
            for (var i=0; i<params.takeProfits.length; i++) {
                if (params.takeProfits[i].value > 0) {
                    trace = {x: x,
                            y: [params.takeProfits[i].value, params.takeProfits[i].value],
                            xaxis:'x1',
                            yaxis:'y1',
                            showlegend: false,
                            type: 'scatter',
                            mode: 'lines',
                            line: {
                                color: bc,
                                width: 1,
                                dash: 'dot'
                            },
                            name: 'take profit ' + (i+1).toString()};
                    traces.push(trace);
                }
            }
        }
        return traces;
    }
    //END INTERFACE METHODS
}

class limit {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        var initTrigger = 0;
        // var initStopLoss = 0;
        // var initTakeProfits = [{value: 0, percent: 0}];
        var initSizePercent = 0;
        var initType = actions.sell;

        var m = initType === actions.sell ? 1.05 : 0.95;
        if (currentPriceIn) {
            initTrigger = roundToDigits(currentPriceIn*m, -2);
            // initStopLoss = roundToDigits(currentPriceIn*1.10, -2);
            // initTakeProfits = [{value: roundToDigits(currentPriceIn*0.80, -2), percent: 100}];
            initSizePercent = 100;
        }
        this.name = "Limit (Simple)";
        this.description = "Limit (Simple)";
        this.controlParamString = 'price';
        this.paramsInit = { type: initType,
                            sizePercent: initSizePercent,
                            trigger: initTrigger, 
                            condition: signals.above};
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.continuous;
        // this.conditionsBacktest = [];
        // this.params = this.paramsInit;
        this.params = paramsIn ? paramsIn : this.paramsInit;
    }

    createConditions(params) {
        var conditions = [];

        //take profits
        conditions.push({index: 0,
                        type: params.type, 
                        thresh: params.trigger, 
                        direction: params.condition,
                        percent: params.sizePercent,
                        resetValue: -1, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []});

        // conditions.push({index: 1,
        //                 type: actions.buy, 
        //                 thresh: params.stopLoss, 
        //                 direction: signals.above,
        //                 percent: 100, //needs to reference the size of the trade
        //                 resetValue: 0, 
        //                 resetDirection: signals.crossover,
        //                 armed: false, 
        //                 trades: [],
        //                 conditionsToArmIfTriggered: [],
        //                 conditionsToDisarmIfTriggered: [0,1].concat(rangePy(2, 2+params.takeProfits.length))});

        // for (var i=0; i<params.takeProfits.length; i++) {
        //     conditions.push({index: 2+i,
        //                     type: actions.buy,
        //                     thresh: params.takeProfits[i].value,
        //                     direction: signals.below,
        //                     percent: params.takeProfits[i].percent,
        //                     resetValue: 0,
        //                     resetDirection: signals.crossunder,
        //                     armed: false, 
        //                     trades: [],
        //                     conditionsToArmIfTriggered: [],
        //                     conditionsToDisarmIfTriggered: i === params.takeProfits.length-1 ? rangePy(0, 2+params.takeProfits.length) : []});
        // }

        this.conditions = conditions;
    }
    controlValue(price, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        return price;
    } 

    backtestPrework(priceHistory, guiParams, conditions=null) {
        this.priceHistory = priceHistory;
        this.control = priceHistory.close;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        // console.log('getTraces: ', priceHistory, params)
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1 &&
            params.trigger > 0) {

            // var trg = params.trigger;

            var bc = '#00ff00';
            var sc = '#ff0000';
            // var ema = emaCalc(priceHistory.close, params.emaPeriod);
            
            var trace;
            var tDelta = (priceHistory.times[1] - priceHistory.times[0])/1000;
            var endTime = dateToEpoch(priceHistory.times[priceHistory.times.length-1]);

            var lineEndTime = epochToDate(endTime + 100*tDelta);
            // console.log('tDelta: ', tDelta, endTime);
            var x = [priceHistory.times[0], lineEndTime];
            trace = {x: x,
                    y: [params.trigger, params.trigger], 
                    xaxis:'x1', 
                    yaxis:'y1',
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    // marker: {color:type === 'buy' ? bc : sc},
                    line: {
                        color: params.type === actions.buy ? bc : sc,
                        width: 1,
                        // dash: 'dot'
                    },
                    name: 'limit trigger'};
            traces.push(trace);
            // if (params.stopLoss > 0) {
            //     trace = {x: x,
            //             y: [params.stopLoss, params.stopLoss],
            //             xaxis:'x1',
            //             yaxis:'y1',
            //             showlegend: false,
            //             type: 'scatter',
            //             mode: 'lines',
            //             line: {
            //                 color: bc,
            //                 width: 1,
            //                 dash: 'dot'
            //             },
            //             name: 'stop loss'};
            //     traces.push(trace);
            // }
            // for (var i=0; i<params.takeProfits.length; i++) {
            //     if (params.takeProfits[i].value > 0) {
            //         trace = {x: x,
            //                 y: [params.takeProfits[i].value, params.takeProfits[i].value],
            //                 xaxis:'x1',
            //                 yaxis:'y1',
            //                 showlegend: false,
            //                 type: 'scatter',
            //                 mode: 'lines',
            //                 line: {
            //                     color: bc,
            //                     width: 1,
            //                     dash: 'dot'
            //                 },
            //                 name: 'take profit ' + (i+1).toString()};
            //         traces.push(trace);
            //     }
            // }
        }
        return traces;
    }
    //END INTERFACE METHODS
}

class ema {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        this.name = "EMA";
        this.description = "EMA";
        this.controlParamString = '(price - ema)';
        this.paramsInit = {emaPeriod: 30};
        this.params = paramsIn ? paramsIn : this.paramsInit;
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)
        this.color = '#ff00ff'

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.discrete;
    }

    createConditions(params) {
        var conditions = [];
        var condition = {type: actions.buy, 
                        thresh: 0, 
                        direction: signals.above,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);
        condition = {type: actions.sell, 
                        thresh: 0, 
                        direction: signals.below,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.above,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);

        this.conditions = conditions;
    }

    //INTERFACE METHODS
    controlValue(currentPrice, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        return currentPrice - this.ema[index];
    }

    backtestPrework(priceHistory, guiParams, conditions=null) {
        // this.priceHistory = priceHistory;
        var ema = emaCalc(priceHistory.close, guiParams.emaPeriod);
        //control parameter
        var control = [];
        for (var i=0; i<ema.length; i++) {
            control.push(priceHistory.close[i] - ema[i]);
        }
        //control curve(s) and conditions contained to subclasses
        this.ema = ema;
        this.control = control;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        var ema = emaCalc(priceHistory.close, params.emaPeriod);
        var trace;
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                y: ema, 
                xaxis:'x1', 
                yaxis:'y1',
                showlegend: false,
                type: 'scatter',
                mode: 'lines',
                marker: {color:this.color},
                name: 'ema'};
        traces.push(trace);

        return traces;
    }
}

class sma {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        this.name = "SMA";
        this.description = "SMA";
        this.controlParamString = '(price - sma)';
        this.paramsInit = {smaPeriod: 30};
        this.params = paramsIn ? paramsIn : this.paramsInit;
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)
        this.color = '#ff0055'

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.discrete;
    }

    createConditions(params) {
        var conditions = [];
        var condition = {type: actions.buy, 
                        thresh: 0, 
                        direction: signals.above,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);
        condition = {type: actions.sell, 
                        thresh: 0, 
                        direction: signals.below,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.above,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);

        this.conditions = conditions;
    }

    controlValue(currentPrice, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        return currentPrice - this.sma[index];
    }
    backtestPrework(priceHistory, guiParams, conditions=null) {
        // this.priceHistory = priceHistory;
        var sma = smaCalc(priceHistory.close, guiParams.smaPeriod);
        //control parameter
        var control = [];
        for (var i=0; i<sma.length; i++) {
            control.push(priceHistory.close[i] - sma[i]);
        }
        //control curve(s) and conditions contained to subclasses
        this.sma = sma;
        this.control = control;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        var sma = smaCalc(priceHistory.close, params.smaPeriod);
        var trace;
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                y: sma, 
                xaxis:'x1', 
                yaxis:'y1',
                showlegend: false,
                type: 'scatter',
                mode: 'lines',
                marker: {color:this.color},
                name: 'sma'};
        traces.push(trace);

        return traces;
    }
}

class smaCross {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        this.name = "Golden/Death Cross";
        this.description = "SMA Cross";
        this.controlParamString = '(smaShort - smaLong)';
        this.paramsInit = {smaPeriodShort: 50, 
                           smaPeriodLong: 200};
        this.params = paramsIn ? paramsIn : this.paramsInit;
        this.trades = [];
        this.backtestable = true;
        this.conditions = [];
        this.slippage = 0.1; //(%)
        this.colorShort = '#00ff00'
        this.colorLong = '#ff0000'

        this.priceHistory = {open: [],
                            high: [],
                            low: [],
                            close: [],
                            volume: [],
                            times: [], 
                            timesEpoch: []};
        this.mode = fetchModes.discrete;
    }

    createConditions(params) {
        var conditions = [];
        var condition = {type: actions.buy, 
                        thresh: 0, 
                        direction: signals.above,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.below,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);
        condition = {type: actions.sell, 
                        thresh: 0, 
                        direction: signals.below,
                        percent: 100,
                        resetValue: 0, 
                        resetDirection: signals.above,
                        armed: true, 
                        trades: [], 
                        conditionsToArmIfTriggered: [], 
                        conditionsToDisarmIfTriggered: []};
        conditions.push(condition);

        this.conditions = conditions;
    }

    controlValue(currentPrice, indexIn=null) {
        if (isUndefined(this.control)) {
            throw new Error('control is undefined'); //need to run backtestPrework first
        }
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }
        
        return this.smaShort[index] - this.smaLong[index];
    }
    backtestPrework(priceHistory, guiParams, conditions=null) {
        // this.priceHistory = priceHistory;
        var smaShort = smaCalc(priceHistory.close, guiParams.smaPeriodShort);
        var smaLong = smaCalc(priceHistory.close, guiParams.smaPeriodLong);
        //control parameter
        var control = [];
        for (var i=0; i<smaShort.length; i++) {
            control.push(smaShort[i] - smaLong[i]);
        }
        //control curve(s) and conditions contained to subclasses
        this.smaShort = smaShort;
        this.smaLong = smaLong;
        this.control = control;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
    }
    getTraces(priceHistory, params) {
        var traces = [];
        var smaShort = smaCalc(priceHistory.close, params.smaPeriodShort);
        var smaLong = smaCalc(priceHistory.close, params.smaPeriodLong);
        var trace;
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                y: smaShort, 
                xaxis:'x1', 
                yaxis:'y1',
                showlegend: false,
                type: 'scatter',
                mode: 'lines',
                marker: {color:this.colorShort},
                name: 'smaShort'};
        traces.push(trace);
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
            y: smaLong, 
            xaxis:'x1', 
            yaxis:'y1',
            showlegend: false,
            type: 'scatter',
            mode: 'lines',
            marker: {color:this.colorLong},
            name: 'smaLong'};
        traces.push(trace);

        return traces;
    }
}

class keltner {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        this.name = "Keltner Channels";
        this.description = "Keltner Channels";
        this.controlParamString = '(price - ema)/atr';
        this.info = "Keltner Channels is a volatility based indicator and uses the EMA (Exponential Moving Average) and ATR (average True Range) to create a control curve. " + 
                    "The control curve is the difference between the price and the EMA, divided by the ATR. ";

        this.paramsInit = {emaPeriod: 30, 
                        atrPeriod: 30.0, 
                        // conditions: [{type: 'buy', multiplier: -5.0, percent: 50, resetValue: 1.0, armed: true}, 
                        //              {type: 'sell', multiplier: 10.0, percent: 100, resetValue: -2.0, armed: false}]
                        conditions: [{multiplier: 4.0, percent: 50.0, resetValue: -2.0, armed: true}, 
                                     {multiplier: 8.0, percent: 100.0, resetValue: -4.0, armed: true}]
                        // multiplier: [5.0, 10.0, 15.0],
                        // resetValues: [-1.0, -5.0, -7.0], 
                        // percents: [25, 50, 100],
                        // armed: [true, true, true]
                        };
        this.backtestable = true;
        this.mode = fetchModes.continuous;
        this.params = paramsIn === null ? this.paramsInit : paramsIn;
        // this.params = this.paramsInit;
    }

    createConditions(paramsIn=null) {
        var params = paramsIn === null ? this.params : paramsIn;
        var conditions = [];
        for (var i=0; i<params.conditions.length; i++) {
            var c = params.conditions[i];
            // var typ = c.type;
            var condition = {type: 'buy', 
                            thresh: -c.multiplier, 
                            direction: signals.below,
                            percent: c.percent,
                            resetValue: -c.resetValue, 
                            resetDirection: signals.above,
                            armed: c.armed, 
                            trades: [], 
                            conditionsToArmIfTriggered: [], 
                            conditionsToDisarmIfTriggered: []};
            conditions.push(condition);
            condition = {type: 'sell', 
                            thresh: c.multiplier, 
                            direction: signals.above,
                            percent: c.percent,
                            resetValue: c.resetValue, 
                            resetDirection: signals.below,
                            armed: c.armed, 
                            trades: [], 
                            conditionsToArmIfTriggered: [], 
                            conditionsToDisarmIfTriggered: []};
            conditions.push(condition);
        }
        this.conditions = conditions;
    }
    //INTERFACE METHODS
    controlValue(price, indexIn=null) {
        var index = indexIn;
        if (isUndefined(this.control)) {
            throw new Error('control is undefined');
        }
        if (index === null) {
            index = this.control.length-1;
        }
        return (price - this.ema[index])/this.atr[index];
    }
    backtestPrework(priceHistory, 
                    guiParamsIn=null, 
                    conditions=null) {
        var guiParams = guiParamsIn === null ? this.params : guiParamsIn;
        const emaPeriod = guiParams.emaPeriod;
        const atrPeriod = guiParams.atrPeriod;
        // const multipliers = guiParams.multiplier;
        var multipliers = [];
        for (var i=0; i<guiParams.conditions.length; i++) {
            multipliers.push(guiParams.conditions[i].multiplier);
        }

        const kelt = keltnerChan(priceHistory.high, 
                                priceHistory.low, 
                                priceHistory.close, 
                                emaPeriod,
                                atrPeriod,
                                multipliers);
        
        //control parameter
        var mList = [];
        for (var i=0; i<kelt.ema.length; i++) {
            var thisM = (priceHistory.close[i] - kelt.ema[i])/kelt.atr[i];
            mList.push(thisM);
        }
        this.ema = kelt.ema;
        this.atr = kelt.atr;
        this.control = mList;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
        return this;
    }
    getTraces(priceHistory, params) {
        const emaPeriod = params.emaPeriod;
        const atrPeriod = params.atrPeriod;
        // const multipliers = params.multiplier;
        var multipliers = [];
        for (var i=0; i<params.conditions.length; i++) {
            multipliers.push(params.conditions[i].multiplier);
        }

        var traces = [];
        const kelt = keltnerChan(priceHistory.high, 
                            priceHistory.low, 
                            priceHistory.close, 
                            emaPeriod,
                            atrPeriod,
                            multipliers);
        var trace;
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                y: kelt.ema, 
                xaxis:'x1', 
                yaxis:'y1',
                showlegend: false,
                type: 'scatter',
                mode: 'lines',
                marker: {color:'#0000ff'},
                name: 'ema'};
        traces.push(trace);
        for (var i=0; i<kelt.upperBands.length; i++) {
            trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                    y: kelt.upperBands[i],
                    xaxis:'x1', 
                    yaxis:'y1',
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    marker: {color:'#ff0066'},
                    line: {
                        color:'#ff0066',
                        width: 1,
                        dash: 'dot'
                    },
                    name: 'keltUp-'+multipliers[i].toString()};

            traces.push(trace);
            trace = {x: priceHistory.times, y: kelt.lowerBands[i],
                    xaxis:'x1', yaxis:'y1',
                    
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    marker: {color:'#00ff66'},
                    line: {
                        color:'#00ff66',
                        width: 1,
                        dash: 'dot'
                    },
                    name: 'keltLow-'+multipliers[i].toString()};
            traces.push(trace);    
        } 
        return traces;
    }
}

class keltnerAdvanced {
    constructor(currentPriceIn=null, paramsIn=null) {
        //keep params outside to make react happy.
        this.name = "Keltner Channels Advanced";
        this.description = "Keltner Channels Advanced";
        this.controlParamString = '(price - ema)/atr';
        this.info = "Keltner Channels is a volatility based indicator and uses the EMA (Exponential Moving Average) and ATR (average True Range) to create a control curve. " + 
                    "The control curve is the difference between the price and the EMA, divided by the ATR. ";

        this.paramsInit = {emaPeriod: 30, 
                        atrPeriod: 30.0, 
                        conditions: [{type: 'buy', multiplier: -5.0, percent: 50, resetValue: 2.0, armed: true},
                                     {type: 'sell', multiplier: 5.0, percent: 50, resetValue: -2.0, armed: true},
                                     {type: 'buy', multiplier: -10.0, percent: 100, resetValue: 4.0, armed: true},
                                     {type: 'sell', multiplier: 10.0, percent: 100, resetValue: -4.0, armed: true}]
                        // conditions: [{multiplier: 4.0, percent: 50.0, resetValue: -2.0, armed: true}, 
                        //              {multiplier: 8.0, percent: 100.0, resetValue: -4.0, armed: true}]
                        };
        this.backtestable = true;
        this.mode = fetchModes.continuous;
        this.params = paramsIn === null ? this.paramsInit : paramsIn;
    }

    createConditions(paramsIn=null) {
        var params = paramsIn === null ? this.params : paramsIn;
        var conditions = [];
        for (var i=0; i<params.conditions.length; i++) {
            var c = params.conditions[i];
            // var typ = c.type;
            var condition = {type: c.type, 
                            thresh: c.multiplier, 
                            direction: c.type === 'buy' ? signals.below : signals.above,
                            percent: c.percent,
                            resetValue: c.resetValue, 
                            resetDirection: c.type === 'buy' ? signals.above : signals.below,
                            armed: c.armed, 
                            trades: [], 
                            conditionsToArmIfTriggered: [], 
                            conditionsToDisarmIfTriggered: []};
            conditions.push(condition);
            // condition = {type: 'sell', 
            //                 thresh: c.multiplier, 
            //                 direction: signals.above,
            //                 percent: c.percent,
            //                 resetValue: c.resetValue, 
            //                 resetDirection: signals.below,
            //                 armed: c.armed, 
            //                 trades: [], 
            //                 conditionsToArmIfTriggered: [], 
            //                 conditionsToDisarmIfTriggered: []};
            // conditions.push(condition);
        }
        this.conditions = conditions;
    }
    //INTERFACE METHODS
    controlValue(price, indexIn=null) {
        var index = indexIn;
        if (isUndefined(this.control)) {
            throw new Error('control is undefined');
        }
        if (index === null) {
            index = this.control.length-1;
        }
        return (price - this.ema[index])/this.atr[index];
    }
    backtestPrework(priceHistory, 
                    guiParamsIn=null, 
                    conditions=null) {
        var guiParams = guiParamsIn === null ? this.params : guiParamsIn;
        const emaPeriod = guiParams.emaPeriod;
        const atrPeriod = guiParams.atrPeriod;
        // const multipliers = guiParams.multiplier;
        var multipliers = [];
        for (var i=0; i<guiParams.conditions.length; i++) {
            multipliers.push(guiParams.conditions[i].multiplier);
        }

        const kelt = keltnerChan(priceHistory.high, 
                                priceHistory.low, 
                                priceHistory.close, 
                                emaPeriod,
                                atrPeriod,
                                multipliers);
        
        //control parameter
        var mList = [];
        for (var i=0; i<kelt.ema.length; i++) {
            var thisM = (priceHistory.close[i] - kelt.ema[i])/kelt.atr[i];
            mList.push(thisM);
        }
        this.ema = kelt.ema;
        this.atr = kelt.atr;
        this.control = mList;
        if (!conditions) {
            this.createConditions(guiParams);
        } else {
            this.conditions = conditions;
        }
        return this;
    }
    getTraces(priceHistory, params) {
        const emaPeriod = params.emaPeriod;
        const atrPeriod = params.atrPeriod;
        // const multipliers = params.multiplier;
        var multipliers = [];
        for (var i=0; i<params.conditions.length; i++) {
            multipliers.push(params.conditions[i].multiplier);
        }

        var traces = [];
        const kelt = keltnerChan(priceHistory.high, 
                                priceHistory.low, 
                                priceHistory.close, 
                                emaPeriod,
                                atrPeriod,
                                multipliers);
        var trace;
        trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                y: kelt.ema, 
                xaxis:'x1', 
                yaxis:'y1',
                showlegend: false,
                type: 'scatter',
                mode: 'lines',
                marker: {color:'#0000ff'},
                name: 'ema'};
        traces.push(trace);
        for (var i=0; i<kelt.upperBands.length; i++) {
            var type = params.conditions[i].type;
            var clr = type === 'sell' ? '#ff0066' : '#00ff66';
            trace = {x: arrayRemoveLast(priceHistory.times, 0), 
                    y: kelt.upperBands[i],
                    xaxis:'x1', 
                    yaxis:'y1',
                    showlegend: false,
                    type: 'scatter',
                    mode: 'lines',
                    marker: {color:clr},
                    line: {
                        color:clr,
                        width: 1,
                        dash: 'dot'
                    },
                    name: 'kelt-'+type+'('+multipliers[i].toString()+')'};

            traces.push(trace);
            // trace = {x: priceHistory.times, y: kelt.lowerBands[i],
            //         xaxis:'x1', yaxis:'y1',
                    
            //         showlegend: false,
            //         type: 'scatter',
            //         mode: 'lines',
            //         marker: {color:'#00ff66'},
            //         line: {
            //             color:'#00ff66',
            //             width: 1,
            //             dash: 'dot'
            //         },
            //         name: 'keltLow-'+multipliers[i].toString()};
            // traces.push(trace);    
        } 
        return traces;
    }
}

export class strategyBase {
    constructor(subclassName, currentPriceIn=null, params=null, conditions=null) {
        this.strat = null;
        if (subclassName === 'keltner') {
            this.strat = new keltner(currentPriceIn, params);
        } else if (subclassName === 'keltnerAdvanced') {
            this.strat = new keltnerAdvanced(currentPriceIn, params);
        } else if (subclassName === 'ema') {
            this.strat = new ema(currentPriceIn, params);
        } else if (subclassName === 'sma') {
            this.strat = new sma(currentPriceIn, params);
        } else if (subclassName === 'smaCross') {
            this.strat = new smaCross(currentPriceIn, params);
        } else if (subclassName === 'limitBuy') {
            this.strat = new limitBuy(currentPriceIn, params);
        } else if (subclassName === 'limitSell') {
            this.strat  = new limitSell(currentPriceIn, params);
        } else if (subclassName === 'limit') {
            this.strat  = new limit(currentPriceIn, params);
        }else {
            throw new Error('strategyBase: unknown subclass name: ', subclassName);
        }
        this.name = this.strat.name;
        this.description = this.strat.description;
        this.paramsInit = this.strat.paramsInit;
        // this.params = params ? params : this.strat.params; //handled by child class
        this.params = this.strat.params;
        // this.currentParams = currentParams ? currentParams : this.paramsInit;
        this.backtestable = this.strat.backtestable;
        this.mode = this.strat.mode;
        this.strat.conditions = conditions;
        this.closed = false;
    }
    getConditions() {
        var ret = [];
        if (isDefined(this.strat) && isDefined(this.strat.conditions)) {
            ret = this.strat.conditions;
        }
        return ret;
    }
    getPendingTrade() {
        let ret = {action: actions.none, amtOut: 0, amtIn: 0, amtInMin: 0};
        // if (isDefined(this.strat) && isDefined(this.strat.pendingTrade)) {
        //     ret = this.strat.pendingTrade;
        // }
        if (isDefined(this.pendingTrade)) {
            ret = this.pendingTrade;
        }
        return ret;
    }
    getTraces(priceHistory, params) {
        return this.strat.getTraces(priceHistory, params);
    }
    //MOVE ACTION TO TAKE TO STRATEGY BASE
    //ADD controlValue(price, index) to individual strategies to check conditions after slippage
    actionToTake(currentPrice, walletAmts, indexIn=null, slippage=0.15, amtsType='float', tokenDecimals=null) {
        var index = indexIn;
        if (index === null) {
            index = this.control.length-1;
        }

        const controlCurrent = this.strat.controlValue(currentPrice, index);
        // console.log('controlCurrent: ', this.control, controlCurrent, this.conditions, index);

        var conditionsMet = checkForMetConditions(this.control, controlCurrent, this.conditions, index);
        var cond = null;
        var action = actions.none;
        var amtOut = 0;
        var amtIn = 0;
        var amtInMin = 0;
        var priceAfterSlippage;
        if (conditionsMet.length > 0) {
            cond = conditionsMet[0];
            action = cond.type;
            //MUCH ROOM TO CLEAN THIS UP.
            if (action === actions.buy) {
                //simulated buy
                if (amtsType === 'float') {
                    amtOut = walletAmts.primary*cond.percent/100;
                    if (amtOut === 0) {
                        action = actions.none;
                        cond = null;
                    } else {
                        amtIn = amtOut/currentPrice;
                        // amtInMin = amtIn*(100-slippage)/100;
                        amtInMin = minusSlippage(amtIn, slippage);
                        priceAfterSlippage = amtOut/amtInMin;
                        if (amtInMin <= 0) {
                            action = actions.none;
                            cond = null;
                        }
                    }
                } else { //BN type
                    amtOut = minusSlippage(walletAmts.primary, 100-cond.percent, 'BN');
                    if (amtOut.eq(BN(0))) {
                        action = actions.none;
                        cond = null;
                    } else {
                        if (tokenDecimals === null) {
                            throw new Error('tokenDecimals must be set for BN type');
                        }
                        amtIn = getAmtIn(amtOut, 
                                        tokenDecimals.primary,
                                        tokenDecimals.secondary,
                                        currentPrice, 
                                        'buy');
                        amtInMin = minusSlippage(amtIn, slippage, 'BN');
                        priceAfterSlippage = bigNumToFloat(amtOut, tokenDecimals.primary)/bigNumToFloat(amtInMin, tokenDecimals.secondary);
                    }
                }

            } else if (action === actions.sell) {
                //simulated sell
                if (amtsType === 'float') {
                    amtOut = walletAmts.secondary*cond.percent/100;
                    if (amtOut === 0) {
                        action = actions.none;
                        cond = null;
                    } else {
                        amtIn = amtOut*currentPrice;
                        // amtInMin = amtIn*(100-slippage)/100;
                        amtInMin = minusSlippage(amtIn, slippage);
                        priceAfterSlippage = amtInMin/amtOut;
                    }
                } else {
                    amtOut = minusSlippage(walletAmts.secondary, 100-cond.percent, 'BN');
                    if (amtOut.eq(BN(0))) {
                        action = actions.none;
                        cond = null;
                    } else {
                        if (tokenDecimals === null) {
                            throw new Error('tokenDecimals must be set for BN type');
                        }
                        // const decimalsPrimary = BN(tokenDecimals.primary)
                        // const decimalsSecondary = BN(tokenDecimals.secondary)
                        amtIn = getAmtIn(amtOut, 
                                        tokenDecimals.primary,
                                        tokenDecimals.secondary,
                                        currentPrice, 
                                        'sell');
                        amtInMin = minusSlippage(amtIn, slippage, 'BN');
                        priceAfterSlippage = bigNumToFloat(amtInMin, tokenDecimals.primary)/bigNumToFloat(amtOut, tokenDecimals.secondary);
                        
                    }
                }
            }
        }

        
        //FINAL TEST AFTER SLIPPAGE
        if (action !== actions.none) {
            //check conditions met again with slippage
            var actionAfterSlippage = actions.none;
            const controlAfterSlippage = this.controlValue(priceAfterSlippage, index);
            const conditionsMetAfterSlippage = checkForMetConditions(this.control, controlAfterSlippage, this.conditions, index);
            // if (isDefined(priceAfterSlippage)) {
            //     console.log('currentPrice, priceAfterSlippage: ', currentPrice, priceAfterSlippage)
            //     console.log('controlCurrent, controlAfterSlippage: ', controlCurrent, controlAfterSlippage)
            // }
            if (conditionsMetAfterSlippage.length > 0) {
                cond = conditionsMetAfterSlippage[0];
                actionAfterSlippage = cond.type;
                if (actionAfterSlippage !== action) {
                    action = actions.none;
                    cond = null;
                    amtOut = 0;
                    amtIn = 0;
                    amtInMin = 0;
                }
            } else {
                action = actions.none;
                cond = null;
                amtOut = 0;
                amtIn = 0;
                amtInMin = 0;
            }
        }
    
        this.pendingTrade = {action: action, amtOut: amtOut, amtIn: amtIn, amtInMin: amtInMin, 
                            condition: cond, controlCurrent: controlCurrent};
        return this.pendingTrade;
    } 
        // const ret = this.strat.actionToTake(currentPrice, walletAmts, index, slippage);
        // // console.log('control:', this.strat.controlCurrent);
        // return ret;
    controlValue(currentPrice, indexIn=null) {
        let ret = this.strat.controlValue(currentPrice, indexIn);
        // this.control = this.strat.control;
        // this.conditions = this.strat.conditions;
        return ret;
    }
    postTradeCallback(success, tradeObj) {
        // this.strat.postTradeCallback(success, tradeObj);
        var condition = this.pendingTrade.condition;
        if (success) {
            this.pendingTrade = null;
            condition.armed = false;
            condition.trades.push(tradeObj);
            var j;
            for (j=0; j<condition.conditionsToArmIfTriggered.length; j++) {
                this.conditions[condition.conditionsToArmIfTriggered[j]].armed = true;
            }
            for (j=0; j<condition.conditionsToDisarmIfTriggered.length; j++) {
                this.conditions[condition.conditionsToDisarmIfTriggered[j]].armed = false;
            }
            this.closed = true;
        } else {
            this.pendingTrade = null;
        }
    }
    backtestPrework(priceHistory, guiParams, conditions=null) {
        this.strat.backtestPrework(priceHistory, guiParams, conditions);
        this.control = this.strat.control;

        // if (!conditions) {
        //     this.conditions = this.strat.conditions;
        // } else {
        //     this.conditions = conditions;
        //     this.strat.conditions = conditions;
        // }
        this.conditions = this.strat.conditions;

        return this;
    }
    backtest(priceHistory, 
            backTestInitPercent, 
            guiParams,
            startingIndexIn=null,
            endingIndexIn=null) {
        
        var bt;
        if (priceHistory !== undefined &&
            priceHistory.times !== undefined &&
            priceHistory.times.length > 1) {
    
            var startingIndex = startingIndexIn;
            var endingIndex = endingIndexIn;
            if (startingIndex === null) {
                startingIndex = 0;
            }
            if (endingIndex === null) {
                endingIndex = priceHistory.times.length-1;
            }
            
            // console.log(this, this.strat);
            this.backtestPrework(priceHistory,
                                        guiParams);
            
            // console.log(this, this.strat);
            var thisTrade = {};
    
            var buys = [];
            var sells = [];
            var trades = [];
    
            const initTime = priceHistory.times[startingIndex];
            const initPrice = priceHistory.close[startingIndex];
            const initAmts = {primary: minusSlippage(initPrice, backTestInitPercent),//initPrice*(100-backTestInitPercent)/100, 
                              secondary: 1*backTestInitPercent/100};
            thisTrade = {type: 'init',
                        price: initPrice,
                        time: initTime,
                        amts: initAmts,
                        value: initAmts.primary + initAmts.secondary*initPrice,
                        tx: ''}; //should work out to initPrice
            var walletAmts = initAmts;
            trades.push(thisTrade);
    
            var i;
            for (i=startingIndex+1; i<=endingIndex; i++) {
                var currentPrice = priceHistory.close[i];
                // if (currentPrice > 30000) {
                //     var huh = 1;
                // }
                // const {action, condition} = this.strat.actionToTake(priceHistory, currentPrice, conditions, i);
                // console.log(this, this.strat);
                const {action, amtOut, amtIn, amtInMin} = this.actionToTake(currentPrice, walletAmts, i);
                if (action !== actions.none) {
                    const thisPrice = currentPrice;
                    // const lastTrade = trades[trades.length-1];
                    
                    thisTrade = {};
                    var tradeSuccess = false;
                    if (action === actions.buy) {
                        //simulated buy
                        const amtPrimaryToSwap = amtOut;
                        const amtSecondaryToGain = amtIn;
                        const amtReceived = amtSecondaryToGain;
                        const newAmts = {primary: walletAmts.primary - amtPrimaryToSwap,
                                        secondary: walletAmts.secondary + amtSecondaryToGain};
                        thisTrade = {type: action,
                                    price: thisPrice,
                                    time: priceHistory.times[i],
                                    amtOut: amtOut,
                                    amtReceived: amtReceived,
                                    amts: newAmts, 
                                    value: newAmts.primary + newAmts.secondary*thisPrice};
                        buys.push(thisTrade);
                        trades.push(thisTrade);
                        tradeSuccess = true;
                        walletAmts = newAmts;
                    } else if (action === actions.sell) {
                        //simulated sell
                        const amtSecondaryToSwap = amtOut;
                        const amtPrimaryToGain = amtIn;
                        const amtReceived = amtPrimaryToGain;
                        const newAmts = {primary: walletAmts.primary + amtPrimaryToGain,
                                        secondary: walletAmts.secondary - amtSecondaryToSwap};
                        thisTrade = {type: 'sell',
                                    price: thisPrice,
                                    time: priceHistory.times[i],
                                    amtOut: amtOut,
                                    amtReceived: amtReceived,
                                    amts: newAmts,
                                    value: newAmts.primary + newAmts.secondary*thisPrice};
                        sells.push(thisTrade);
                        trades.push(thisTrade);
                        tradeSuccess = true;
                        walletAmts = newAmts;
                    }
                    this.postTradeCallback(tradeSuccess, thisTrade);
                }
            }
            
            
            
            const finalTime = priceHistory.times[endingIndex];
            const finalPrice = priceHistory.close[endingIndex];
            const finalAmts = trades[trades.length-1].amts;
            const finalValue = finalAmts.primary + finalAmts.secondary*finalPrice;
 
            var finalState = {price: finalPrice,
                                time: finalTime,
                                amts: finalAmts,
                                value: finalValue};
            bt = {buys: buys, 
                    sells: sells, 
                    trades: trades, 
                    finalState: finalState, 
                    traces: []};
        
            var x = [];
            var y = [];
            var xBuys = [];
            var yBuys = [];
            
            var xSells = [];
            var ySells = [];
            for (i=0; i<bt.trades.length; i++) {
                x.push(bt.trades[i].time);
                y.push(bt.trades[i].value);
                if (bt.trades[i].type === 'buy') {
                    xBuys.push(bt.trades[i].time);
                    yBuys.push(bt.trades[i].price);
                } else {
                    xSells.push(bt.trades[i].time);
                    ySells.push(bt.trades[i].price);
                }
            }
            x.push(bt.finalState.time);
            y.push(bt.finalState.value);
            var tracesExtra = [];
            var trace;
            trace = {x: x,
                    y: y,
                    xaxis:'x1',
                    yaxis:'y1',
                    showlegend: true,
                    legendgroup: 'backtest',
                    type: 'scatter',
                    mode: 'lines',
                    line: {
                        color: '#ffff00',
                        width: 1,
                        dash: 'dot'
                    },
                    name: 'backtest performance'};
            tracesExtra.push(trace);
    
            trace = {x: xBuys,
                    y: yBuys,
                    xaxis:'x1',
                    yaxis:'y1',
                    showlegend: true,
                    legendgroup: 'backtest',
                    type: 'scatter',
                    mode: 'markers',
                    marker: {
                        color: '#00ff00',
                        size: 10,
                        symbol: 'triangle-up'
                    },
                    name: 'backtest buys'};
            tracesExtra.push(trace);
    
            trace = {x: xSells,
                    y: ySells,
                    xaxis:'x1',
                    yaxis:'y1',
                    showlegend: true,
                    legendgroup: 'backtest',
                    type: 'scatter',
                    mode: 'markers',
                    marker: {
                        color: '#ff0000',
                        size: 10,
                        symbol: 'triangle-down'
                    },
                    name: 'backtest sells'};
            tracesExtra.push(trace);
    
            bt['traces'] = tracesExtra;
            return bt;
        
        } else {
            console.log("Price history not found");
            bt = {buys: [], 
                sells: [], 
                trades: [], 
                finalState: {price: 0,
                            time: 0, 
                            amts: {primary: 0, secondary: 0}, 
                            value: 0},
                traces: []};
            
            return bt
        }
    }
}


