import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StratPlot from "../components/StratPlot";
// import Deposit from "../components/Deposit";


const BacktestDiv = styled.div`
    ${'' /* height: 110px; */}
    ${'' /* max-width: 1000px; */}
    color: #fff;
    ${'' /* background-color: #001E3C; */}
    ${'' /* background-image: linear-gradient(to bottom right, #001E3C, #06406F); */}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    img {
        ${'' /* max-height: 386px; */}
        ${'' /* height: 100%; */}
        ${'' /* max-height: 386px; */}
        margin-bottom: 20px;
        width: 100%;
        max-width: 1020px;
        resize: 'contain';
    }
`;

const StrategyDemo = () => {
    return (
        <>
            {/* <Header /> */}
            {/* <BacktestDiv>
                <Deposit />
            </BacktestDiv> */}
            
            <StratPlot fixStrategy={false} url='/strategydemo' />
            
            {/* <Footer /> */}
        </>

    );
};
  
export default StrategyDemo;