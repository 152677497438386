import React, { Component, useState, useEffect } from 'react';
import styled from "styled-components";
import { ethers } from "ethers";
import ercABI from "./Interfaces/Arbitrum/erc20Generic.json";
import simpleVault_abi from "./Interfaces/Arbitrum/simpleVault.json";

import {Button, TextField} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const WalletDiv = styled.div`
    ${'' /* height: 110px; */}
    ${'' /* max-width: 1000px; */}
    color: #fff;
    ${'' /* background-color: #001E3C; */}
    ${'' /* background-image: linear-gradient(to bottom right, #001E3C, #06406F); */}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #A2B533;
    border-radius: 5px;
`;

const DepositDiv = styled.div`
    ${'' /* height: 110px; */}
    ${'' /* max-width: 1000px; */}
    ${'' /* color: #fff; */}
    ${'' /* background-color: #001E3C; */}
    ${'' /* background-image: linear-gradient(to bottom right, #001E3C, #06406F); */}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
`;

const FundDiv = styled.div`
    ${'' /* height: 110px; */}
    ${'' /* max-width: 1000px; */}
    ${'' /* color: #fff; */}
    ${'' /* background-color: #001E3C; */}
    ${'' /* background-image: linear-gradient(to bottom right, #001E3C, #06406F); */}
    display: flex;
    flex-direction: column;
`;

const InputDiv = styled.div`
    border: 1px solid #A2B533;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding: 5px;
    margin-bottom: 20px;
`;
const DescriptionDiv = styled.div`
    max-width: 400px;
`;

function abbreviate(address) {
    return address.substring(0, 6) + "..." + address.substring(address.length - 4);
}

function Metamask() {
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [balance, setBalance] = useState(null);
    const [provider, setProvider] = useState(null);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const [chainID, setChainID] = useState(null);
    const [walletIsInvested, setWalletIsInvested] = useState(false);
    const simpleVaultAddress = '0x9EbF060717672a8Eb9EBb0c9939510255F323544';

    const [sv, setSV] = useState(null);
    const [currentToken, setCurrentToken] = useState(null);
    const [currentTokenAddress, setCurrentTokenAddress] = useState('');
    const [currentTokenBalance, setCurrentTokenBalance] = useState(0);
    const [currentTokenName, setCurrentTokenName] = useState('');
    const [currentTokenDeposited, setCurrentTokenDeposited] = useState(0);
    const [currentTokenDecimals, setCurrentTokenDecimals] = useState(0);

    const [otherToken, setOtherToken] = useState(null);
    const [otherTokenAddress, setOtherTokenAddress] = useState('');
    const [otherTokenBalance, setOtherTokenBalance] = useState(0);
    const [otherTokenName, setOtherTokenName] = useState('');
    const [otherTokenDecimals, setOtherTokenDecimals] = useState(0);

    const [fundBalance, setFundBalance] = useState(0);
    const [ownerShare, setOwnerShare] = useState(0);

    const [depositAmount, setDepositAmount] = useState(0);
    const [numTransactions, setNumTransactions] = useState(0);

    async function connectToMetamask() {
        const providerNew = new ethers.providers.Web3Provider(window.ethereum)
        setProvider(providerNew);
        const accounts = await providerNew.send("eth_requestAccounts", []);
        const balance = await providerNew.getBalance(accounts[0]);
        const network = await providerNew.getNetwork();
        
        const balanceInEther = ethers.utils.formatEther(balance);
        // const daiContract = new ethers.Contract('0x6b175474e89094c44da98b954eedeac495271d0f', ERC20_ABI, provider);
        // const tokenName = await daiContract.name();
        // const tokenBalance = await daiContract.balanceOf(accounts[0]);
        // const tokenUnits = await daiContract.decimals();
        // const tokenBalanceInEther = ethers.utils.formatUnits(tokenBalance, tokenUnits);
        setSelectedAddress(accounts[0]);
        setBalance(balanceInEther);
        setCurrentNetwork(network.name);
        setChainID(network.chainId);
    }

    async function getFundInfo() {
        const sv = new ethers.Contract(simpleVaultAddress, simpleVault_abi, provider);
        const currentTokenAddress = await sv.getCurrentToken();
        const otherTokenAddress = await sv.getOtherToken();
        const currentTokenNew = new ethers.Contract(currentTokenAddress, ercABI, provider);
        const otherTokenNew = new ethers.Contract(otherTokenAddress, ercABI, provider);
        const currentTokenName = await currentTokenNew.symbol();
        const otherTokenName = await otherTokenNew.symbol();

        setCurrentToken(currentTokenNew);
        setOtherToken(otherTokenNew);
        setCurrentTokenAddress(currentTokenAddress);
        setOtherTokenAddress(otherTokenAddress);

        setCurrentTokenName(currentTokenName);
        setOtherTokenName(otherTokenName);

        setCurrentTokenBalance(await currentTokenNew.balanceOf(selectedAddress));
        setOtherTokenBalance(await otherTokenNew.balanceOf(selectedAddress));

        setCurrentTokenDecimals(await currentTokenNew.decimals());
        setOtherTokenDecimals(await otherTokenNew.decimals());

        setFundBalance(await sv.getFundBalance());
        setOwnerShare(await sv.getBalance(selectedAddress));
    }

    useEffect(() => {
        getFundInfo();
    }, [selectedAddress, numTransactions]);

    function myFormatNum(bigNum, decimals) {
        return Number(ethers.utils.formatUnits(bigNum, decimals)).toFixed(5);
    }

    function makeTable() {
        console.log('making table, ownerShare:::', ownerShare);
        return(
            <TableContainer 
                component={Paper} 
                sx={{maxWidth: '400px', border: '1px solid #fff', borderRadius: '10px', padding: '10px', 
                     backgroundColor: '#AAA'}}
            >
                <Table size="small" aria-label="a dense table" 
                       >
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">{currentTokenName}</TableCell>
                            <TableCell align="center">{otherTokenName}</TableCell>
                            {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCell component="th" scope="row" sx={{fontSize: 34, fontWeight: 'medium',color: '#FFF'}}>
                                {"Wallet Amts"}
                            </TableCell>
                            <TableCell align="center">
                                {myFormatNum(currentTokenBalance, currentTokenDecimals)}
                            </TableCell>
                            <TableCell align="center">
                                {myFormatNum(otherTokenBalance, otherTokenDecimals)}
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {"Total Fund Amts"}
                            </TableCell>
                            <TableCell align="center">
                                {myFormatNum(fundBalance, currentTokenDecimals)}
                            </TableCell>
                            <TableCell align="center">
                                {ethers.utils.formatUnits(0.0, otherTokenDecimals)}
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {"Your Deposited Amts"}
                            </TableCell>
                            <TableCell align="center">
                                {myFormatNum(ownerShare, currentTokenDecimals)}
                            </TableCell>
                            <TableCell align="center">
                                {myFormatNum(0.0, otherTokenDecimals)}
                            </TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>            
        );
    }
    async function depositTokens() {
        console.log("depositing tokens:::", ethers.utils.formatUnits(depositAmount, currentTokenDecimals));
        const signer = provider.getSigner();
        const allowance = await currentToken.allowance(selectedAddress, simpleVaultAddress);
        console.log("allowance:::", ethers.utils.formatUnits(allowance, currentTokenDecimals));
        var tx;
        if (allowance.lt(depositAmount)) {
            tx = await currentToken.connect(signer).approve(simpleVaultAddress, ethers.constants.MaxUint256);
            await tx.wait();
        }
        const sv = new ethers.Contract(simpleVaultAddress, simpleVault_abi, provider);
        tx = await sv.connect(signer).deposit(currentTokenAddress, depositAmount);
        await tx.wait();
        setNumTransactions(numTransactions + 1);
    }

    async function withdrawTokens() {
        console.log("withdrawing tokens...");
        const signer = provider.getSigner();
        const sv = new ethers.Contract(simpleVaultAddress, simpleVault_abi, provider);
        var tx;
        tx = await sv.connect(signer).withdraw();
        await tx.wait();
        setNumTransactions(numTransactions + 1);
    }
    function setDepositAmtHandler(value) {
        const da = ethers.BigNumber.from(value).mul(ethers.BigNumber.from(10).pow(currentTokenDecimals));
        console.log('da:::', da);
        setDepositAmount(da);
    }
    function fundButton() {
        console.log("owner share: ", ownerShare);
        return(
            <FundDiv>
                <DepositDiv>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            depositTokens();
                        }}
                    >
                        {"Deposit "+currentTokenName}
                    </Button>

                    <TextField
                            variant="outlined"
                            // color='white'
                            size='small'
                            label={"Deposit Amount"}
                            type='number'
                            sx={{maxWidth: '17ch', mt: '5px'}}
                            inputProps={{ style: { color: "white" } } }
                            InputLabelProps={{ style: { color: "white" } } }
                            // onChange={event => setValueHandler(event.target.value)}
                            onBlur={event=>setDepositAmtHandler(event.target.value)}
                    />
                </DepositDiv>
                <Button 
                    variant="contained" 
                    onClick={() => {
                        withdrawTokens();
                    }}
                >
                    Withdraw All
                </Button>
            </FundDiv>


        );

    }

    function renderMetamask() {
        if (!selectedAddress) {
            return (
                <Button 
                    sx={{p:1, m:1}} 
                    variant='contained'
                    // color='primary'
                    size='small'
                    style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    onClick={() => connectToMetamask()}
                >
                    Connect Wallet
                </Button>
            )
        } else if (chainID !== 42161) {
            return (
                <div>
                    <p>Wrong Network, must be Arbitrum One</p>
                    <Button 
                        sx={{p:1, m:1}} 
                        variant='contained'
                        // color='primary'
                        size='small'
                        style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                        onClick={() => connectToMetamask()}
                    >
                        Connect Wallet
                    </Button>
                </div>

            )
        } else {
            // getFundInfo();
            return (
                <WalletDiv>
                    <DescriptionDiv>
                        <h5>
                            {"Welcome " +abbreviate(selectedAddress)+", to our very first auto-trade pilot vault!"}
                        </h5>
                        <h6>
                            {"This strategy trades " + 'WETH' + ' and ' + 'DAI' + ' and is currently holding '+currentTokenName+'. After you deposit funds, trades will be automatically executed per the strategy shown below. In a future update, you will be able to create your own strategies.'}
                        </h6>
                    </DescriptionDiv>

                    <InputDiv>
                        <p>{makeTable()}</p>
                        <p>{fundButton()}</p>
                    </InputDiv>
                    
                    <DescriptionDiv>
                        <p>
                            {'Click "BACKTEST STRATEGY" below to view historical performance. '+ "(Past results don't guarantee future performance. )" }
                        </p>
                    </DescriptionDiv>
                    
                </WalletDiv>
            );
        }
    }

    return(
        <>
            {renderMetamask()}
        </>
    );
  
}

export default Metamask;