import { useEffect, useRef } from "react";
import { Engine, Scene } from "@babylonjs/core";

export default ({ antialias, engineOptions, adaptToDeviceRatio, 
                  sceneOptions, onRender, onSceneReady, 
                  onSceneReadyDict, 
                  ...rest}) => {
    const reactCanvas = useRef(null);

    // set up basic engine and scene
    useEffect(() => {
        const { current: canvas } = reactCanvas;

        if (!canvas) return;

        const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
        const scene = new Scene(engine, sceneOptions);
        if (scene.isReady()) {
            onSceneReady(scene, onSceneReadyDict);
        } else {
            scene.onReadyObservable.addOnce((scene) => onSceneReady(scene, onSceneReadyDict));
        }

        engine.runRenderLoop(() => {
        if (typeof onRender === "function") onRender(scene);
            // try {
            //     scene.render();
            // }
            // catch (e) {
            //     console.log(e);
            // }
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        if (window) {
            window.addEventListener("resize", resize);
        }

        return () => {
            scene.getEngine().dispose();

            if (window) {
                window.removeEventListener("resize", resize);
            }
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, onSceneReadyDict]);

    return <canvas 
                style={{ width: "100%", height: "100%", outline: "none" }}
                ref={reactCanvas} 
                {...rest} 
                
            />;
    // return <canvas ref={reactCanvas} />;
};