import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../App'
import { ethers } from "ethers";
import { Button } from '@mui/material';
import { abbreviateAddress, isDefined } from '../../functions/functions';
import { VertDiv, HorzDiv, theme } from '../../styles';
import { TxPendingDialog, AlertDialog, InfoDialog, ErrorDialog } from '../Dialogs/Dialogs';
// import devContractAddresses from '../Interfaces/VaultV2/addresses/dev/addresses.json';
// import releaseContractAddresses from '../Interfaces/VaultV2/addresses/release/addresses.json';



function ConnectWalletButton({networkName}) {
    const {networkDict, setNetworkDict, defaultNetworkDict, 
           txPending, setTxPending, lastTxHash, setLastTxHash,
           errorDialogOpen, setErrorDialogOpen, errorMessage, setErrorMessage,
           txSuccess, setTxSuccess, 
           devMode, 
        //    selectedAddress, setSelectedAddress,
           contractAddresses, setContractAddresses, 
           devContractAddresses, releaseContractAddresses, 
           walletAddress, setWalletAddress  
        } = useContext(UserContext);

    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    // const [firstLoad, setFirstLoad] = useState(true);

    var chainID = 0;
    if (networkName === "mainnet-fork") {
        chainID = 1;//'Arbitrum';
    } else if (networkName === 'arbitrum') {
        chainID = 42161;
    } else {
        chainID = 1;
    }
    var initialAddress = null;

    if (networkDict !== undefined && networkDict !== null) {
        if ('address' in networkDict) {
            initialAddress = networkDict['address'];
        }
    }
    // const [selectedAddress, setSelectedAddress] = useState(initialAddress);
    const [txList, setTxList] = useState([]);



    // useEffect(() => {
    //     txList.push(lastTxHash);
    //     setTxList(txList);
    // }, [lastTxHash]);



    useEffect(() => {
        // console.log('useEffect in connectWalletButton called');
        const checkMetamaskConnection = async () => {
            if (window.ethereum && !walletAddress) {
                const providerNew = new ethers.providers.Web3Provider(window.ethereum);
                const accounts = await providerNew.listAccounts();
                const network = await providerNew.getNetwork();
    
                if (accounts.length > 0 && network.chainId === chainID) {
                    // MetaMask is connected and on the correct network
                    console.log('Already connected to MetaMask');
                    await updateNetworkInfo(providerNew, accounts);
                }
            }
        };
    
        checkMetamaskConnection();

    }, []);

    useEffect(() => {
        const t0 = isDefined(defaultNetworkDict);
        const t1 = networkDict === null;
        const t2 = networkDict === undefined;
        const t3 = networkDict ? Object.keys(networkDict).length === 0 : true;

        if (t0 && (t1 || t2 || t3)) {
            setNetworkDict(defaultNetworkDict);
        }
    }, [defaultNetworkDict]); // upon first load
    //     }
    // }, [defaultNetworkDict]); //upon first load

    // Detect change in Metamask account
    useEffect(() => {
        // console.log('in ConnectWallet useEffect: Account Change:', networkDict);
        if (networkDict !== null && Object.keys(networkDict).length !== 0) {
            // console.log('in ConnectWalletButton UseEffect: Account Change:', networkDict);
            // console.log('in ConnectWalletButton UseEffect: Account Change:', networkDict);
            window.ethereum.on("chainChanged", () => {
                console.log('in chainChanged: Account Change:', networkDict);
                window.location.reload();
            });
            // window.ethereum.on("accountsChanged", () => {
            //     window.location.reload();
            // });
        }
    }, [networkDict]);

    const updateNetworkInfo = async (providerNew, accounts) => {
        const network = await providerNew.getNetwork();
        let networkInf = {};
    
        if (devMode) {
            if (devContractAddresses[chainID.toString()] !== undefined) {
                setContractAddresses(devContractAddresses[chainID.toString()]);
                networkInf = {
                    'provider': providerNew,
                    'signer': providerNew.getSigner(),
                    'accounts': accounts,
                    'address': accounts[0],
                    'network': network,
                    'chainID': network.chainId,
                    'scanner': 'https://arbiscan.io'
                };
            }
        } else {
            if (releaseContractAddresses[chainID.toString()] !== undefined) {
                setContractAddresses(releaseContractAddresses[chainID.toString()]);
                networkInf = {
                    'provider': providerNew,
                    'signer': providerNew.getSigner(),
                    'accounts': accounts,
                    'address': accounts[0],
                    'network': network,
                    'chainID': network.chainId,
                    'scanner': 'https://arbiscan.io'
                };
            }
        }
    
        setNetworkDict(networkInf);
        setWalletAddress(accounts[0]);
    };
    
    async function connectToMetamask() {
        const providerNew = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await providerNew.send("eth_requestAccounts", []);
        const network = await providerNew.getNetwork();
    
        if (network.chainId !== chainID) {
            alert('Wrong network. Must use Arbitrum.');
            console.log('Wrong network. Must use Arbitrum.');
        } else {
            console.log('Connected to Arbitrum');
            await updateNetworkInfo(providerNew, accounts);
        }
    }

    function disconnectFromMetamask() {
        setWalletAddress(null);
        console.log('Disconnected', defaultNetworkDict);
        setNetworkDict(defaultNetworkDict);
    }
    
    function connectButton() {
        // console.log('in Connect Button: selectedAddress: ', selectedAddress);
        if (!walletAddress) {
            return (
                <Button 
                    sx={{p:1, m:0}} 
                    variant='contained'
                    // color='primary'
                    size='small'
                    style={{'borderRadius':theme.borderRadius, 
                            'fontWeight':'bold', 
                            'fontSize': '0.7em', 
                            'height': '47px'}}
                    onClick={() => connectToMetamask()}
                >
                    Connect Metamask
                </Button>
            )
        } else {
            return (
                <Button 
                    sx={{p:1, m:0}} 
                    variant='contained'
                    color='secondary'
                    size='small'
                    style={{'borderRadius':theme.borderRadius, 
                            'fontWeight':'bold', 
                            'fontSize': '0.7em', 
                            'height': '47px'}}
                    onClick={() => disconnectFromMetamask()}
                >
                    Disconnect {abbreviateAddress(walletAddress)}
                </Button>
            )
        } 
    }
    function TxButton() {
        if (walletAddress) {
            return (
                <Button 
                    sx={{p:1, m:1}} 
                    variant='contained'
                    color='primary'
                    size='small'
                    style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                    // onClick={() => sendTx()}
                >
                    View Txs
                </Button>
            )
        } else {
            return <></>;
        }
    }
    function TxListDiv() {
        if (txList.length > 0) {
            return (
                <VertDiv>
                    <h3>Transactions</h3>
                    <ul>
                        {txList.map((tx) => {
                            return (
                                <li key={tx}>
                                    {tx}
                                </li>
                            )
                        })}
                    </ul>
                </VertDiv>
            )
        } else {
            return <></>;
        }
    }

    // function TxPendingDiv() {
    //     return(

    //     );
    // }
    return(
        <HorzDiv 
            borderStyle='none' 
            margin='0px' 
            padding='0px'
        >
            {connectButton()}
            <TxPendingDialog 
                txPendingBool={txPending}
                txSuccessBool={txSuccess}
                txHash={lastTxHash}
                networkDict={networkDict}
            />
            <ErrorDialog
                dialogOpenBool={errorDialogOpen}
                setDialogOpenBool={setErrorDialogOpen}
                dialogMessage={errorMessage}
            />
        </HorzDiv>
    );
}

export default ConnectWalletButton