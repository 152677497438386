import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Deposit from "../components/Deposit";
import ExistingVaultForm from "../components/ExistingVaultV2";
import { useSearchParams, useLocation } from "react-router-dom";
import {useState, useEffect} from 'react';

function ExistingVaultPage() {
    const [searchParams] = useSearchParams();
    const [vaultAddress, setVaultAddress] = useState('');

    const { state } = useLocation();

    useEffect(() => {
        if (searchParams.get('address')) {
            console.log('searchParams.get(address): ', searchParams.get('address'));
            setVaultAddress(searchParams.get('address'));
        }
    }, [searchParams]);

    // console.log('in existingVaultPage: vaultAddress:', vaultAddress, 'state:', state);
    return (
        <>
            {/* <Header showConnectButton={true} showAutotrade={true}/> */}
            {/* <BacktestDiv>
                <Deposit />
            </BacktestDiv> */}
            
            {/* <StratPlot fixStrategy={false} url='/strategydemo' /> */}
            {vaultAddress !== '' && <ExistingVaultForm address={vaultAddress} minimal={false}/>}
            
            {/* <Footer /> */}
        </>

    );
};
  
export default ExistingVaultPage;