// import { useRef } from "react";

import { motion, useInView } from "framer-motion"



export function FadeInAnimation(innerDiv, duration=0.5) {
    return (
        <motion.header 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: duration }}
        >
            {innerDiv}
        </motion.header>
    )
}

export function FadeInWhenVisibleAnimation(innerDiv, duration=0.5) {

    return (
        <motion.div          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition="all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: .5, scale: 0.9 }
          }}
        >
          {innerDiv}
        </motion.div>
      );

    // const ref = useRef(null);
    // const isInView = useInView(ref, { once: true });
    // return (
    //     <motion.header 
    //         transform= {isInView ? "none" : "translateX(-200px)"}
    //         opacity= {isInView ? 1 : 0}
    //         transition= "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
    //         // initial={{ opacity: 0 }}
    //         // animate={{ opacity: 1 }}
    //         // transition={{ duration: duration }}
    //     >
    //         {innerDiv}
    //     </motion.header>
    // )
}

export function SlideFromLeftAnimation(innerDiv, duration=0.5) {
    return (
        <motion.div
            initial={{ x: -1000 }}
            animate={{ x: 0 }}
            viewport={{ once: true}}
            transition={{ duration: duration }}

        >
            {innerDiv}
        </motion.div>
    )
}