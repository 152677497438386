import React from "react";
import { Vector3, HemisphericLight, MeshBuilder, ArcRotateCamera, Mesh, 
         ParticleSystem, Texture, StandardMaterial, Plane,
         SceneLoader, AbstractMesh, PointLight, Viewport} 
            from "@babylonjs/core";

import {AdvancedDynamicTexture, StackPanel, Button,  
        Control, RadioGroup, SelectionPanel, TextBlock} from '@babylonjs/gui';

import SceneComponent from "./SceneComponent"; 
// import "@babylonjs/loaders/glTF";
import axios from "axios";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import {getBaseURL, lerpVec3, toVec3, randomInt, getOS, splitArray} from '../functions/functions.js';



function framifyRawData(times, path, timeDelta, tubeLength) {
    var headSegment = 0;

    var headPosition = path[0];
    var tailPosition = path[0];
    var periodTime = times[0];
    var pathEndTime = times[times.length - 1];
    var tailTime = times[0];
    var headPositions = [];
    var tubePaths = [];

    while (periodTime < pathEndTime) {
        var tubePositions = []
        while (periodTime > times[(headSegment + 1)]) {
            headSegment++;
        }      

        var headRatio = (periodTime - times[headSegment]) / (times[(headSegment + 1)] - times[headSegment]);

        headPosition = lerpVec3(path[headSegment], 
                                path[(headSegment + 1)], 
                                headRatio);

        headPositions.push(headPosition);
        
        if (periodTime < tubeLength) {
            tailTime = 0;
        } else {
            tailTime = periodTime - tubeLength;
        }

        tubePositions = [headPosition];
        var i;
        for (i = headSegment; i > 0; i--) {
            var segmentTime = times[i];
            if (tailTime <= segmentTime) {
                tubePositions.push(path[i]);
            } else {
                break
            }
        }
        if (i < path.length) {
            tailPosition = lerpVec3(path[i], 
                                    path[i+1], 
                                    (tailTime - times[i]) / (times[i+1] - times[i]));
            tubePositions.push(tailPosition);
        }
        // while (tubePositions.length < path.length) {
        //     tubePositions.push(tailPosition);
        // }

        tubePaths.push(tubePositions);     
        periodTime += timeDelta;
    }
    return ({headPositions, tubePaths});
}

var camera;
let newDataFetched = false;


let sceneTime = 0;
let currentFrame = 0;
let previousFrame = 0;
let maxFrame = 0;


let spheres = [];
let tubes = [];
let spherePositions = [];
let tubePaths = [];
let particleSystems = [];
let timesRaw = []
let pathsRaw = []

    // pathsTotal = paramsDict['numPaths'];
    // numToFetch = 5;
    // numTubesTotal = Math.min(pathsTotal, maxTubes);

let sphereRadius = 0.2;
let tubeRadius = 0.08;
let tubeLength = 30.0;
let deltaFrame = 7.0;
// let sphereRadius = 1.0;
// let tubeRadius = 0.6;
// let tubeLength = 100.0;
// let deltaFrame = 25.0;


let numPathsToRender = 0;
let numTubesToRender = 0;
let numParticlesToRender = 0;

let numToFetch = 20000; //3000;
let currentlyFetching = false;

let maxParticles = 100;

let showSpheres = true;
let showParticles = true;
let showTubes = true;
let sphereMaterial;
let tubeMaterial;

let scene;
let advancedTexture;
let advancedTexture2;
let panel;
let panel2;
// let button; #show kitty button
// let radioGroup;
// let selectionBox;

let textBlock;
let textBlockState;

let buttonSpheres;
let buttonTubes;
let buttonParticles;
let buttonSphereColor;
let buttonTubeColor;

let buttonXCross;
let buttonYCross;

let canvas;
let os = getOS();

let newState = 'random'; //"{{1,{3,2,1}},{1,{4,2,-1}},{1,{5,2,1}}}";
let disposingNow = false;
let complexity = 0;
let stateString;
// let allStates = [];
// let currentState = '';
let sphereColors = [new Color3(.2, 1, .5), new Color3(0, 1, 0)];
let tubeColors = [new Color3(1, 1, 0), new Color3(1, 0, 0)];
let numSchemes = sphereColors.length;
let currentSchemeSpheres = 0;
let currentSchemeTubes = 0;

let showXCross = false;
let showYCross = false;



async function fetchData(startingNumber, numToFetch) {
    if (!disposingNow) {
        var baseURL = getBaseURL();
        if (baseURL.includes('localhost')) {
            baseURL = baseURL+':8000';
        }
        const numToFetchThisTime = Math.min(numToFetch, numPathsToRender - startingNumber);
        const url = baseURL+'/api/fetchtubes/'+newState.replaceAll('{', '[').replaceAll('}', ']')+
                    '/'+startingNumber.toString()+'/'+numToFetchThisTime.toString();
        // axios.get(url).then(res => setData(res.data));
        console.log('fetching data from:', url);
        textBlock.text = 'loading '+startingNumber.toString()+' of '+ (numPathsToRender).toString();
        await axios.get(url).then(res => 
            {
                // console.log('data', res.data);
                timesRaw = [];
                pathsRaw = [];
                newState = res.data.state;
                // allStates = res.data.allStates;
                // if (firstDropdownList.length === 0) {
                //     parseAllStates(res.data.allStates);
                // }
                // console.log('returned state', newState);
                for (let i = 0; i < res.data.times.length; i++) {
                    timesRaw.push(res.data.times[i].map(Number));
                    pathsRaw.push(res.data.positions[i].map(toVec3));
                }
    
                for (let i = 0; i < timesRaw.length; i++) {
                    const ret = framifyRawData(timesRaw[i], pathsRaw[i], deltaFrame, tubeLength)
                    spherePositions.push(ret.headPositions)
                    tubePaths.push(ret.tubePaths)   
                }
                newDataFetched = true;
            });
            if (newDataFetched) { 
                // data = {'times': [] , 'positions': []};
                for (let i = spheres.length; i < spherePositions.length; i++) {
                    var thisSphere = MeshBuilder.CreateSphere('sphere', 
                                                                { segments: 1, diameter: sphereRadius*2 }, 
                                                                scene);

                    let thisMaterial = new StandardMaterial("sphereMaterial", scene);
                    thisMaterial.diffuseColor = new Color3(.2, 1, .7);
                    thisMaterial.specularColor = new Color3(.2, 1, .7);
                    thisSphere.material = thisMaterial;
                    thisSphere.name = 'sphere-'+i.toString();
            
                    thisSphere.position = spherePositions[i][0];
                    spheres.push(thisSphere);

                    if (spherePositions[i].length > maxFrame - 1) {
                        maxFrame = spherePositions[i].length - 1
                    }

                    if (i < maxParticles) {
                        // ParticleHelper.CreateDefault(spheres[i]).start();
                        var PS = new ParticleSystem("kittyPS", 2000 , scene);
                        const starFName = 'star' + randomInt(0, 1).toString() + '.png';
                        PS.particleTexture = new Texture("./textures/" + starFName, scene);
                        PS.emitter = thisSphere;
                        PS.minSize = 0.1;
                        PS.maxSize = 0.3;
                        PS.minEmitPower = 0.0;
                        PS.maxEmitPower = 0.0;
                        PS.emitRate = 300;
                        PS.direction1 = new Vector3(0, -1, -1);
                        PS.direction2 = new Vector3(0, -1, 1);
                        PS.minLifeTime = 0.5;
                        PS.maxLifeTime = 1.4;
                        const del = 0.08;
                        PS.minEmitBox = new Vector3(-del, -del, -del);
                        PS.maxEmitBox = new Vector3(del, del, del);
                        PS.start();
                        particleSystems.push(PS);
                    }
        
                    if (tubes.length < numTubesToRender) {
                        var theseTubes = [];
                        for (let j = 0; j < tubePaths[i].length; j++) {
                            var thisTube = MeshBuilder.CreateTube('tube', 
                                                                    { path: tubePaths[i][j], 
                                                                    radius: tubeRadius, 
                                                                    tessellation: 3, cap: Mesh.NO_CAP, 
                                                                    updatable: false }, 
                                                                    scene);
                            thisTube.position = Vector3.Zero();
                            thisTube.material = tubeMaterial;
                            thisTube.setEnabled(false);
                            theseTubes.push(thisTube);
                        }
                        tubes.push(theseTubes);
                    }
                    // console.log('num spheres', spheres.length);
                }
                newDataFetched = false;
            }
            currentlyFetching = false;
            if (spheres.length >= numPathsToRender) {
                textBlock.text = 'Loaded '+numPathsToRender.toString()+' paths';
            }
    }   
}

var setComplexity = function(value) {
    console.log('in setComplexity', value);
    switch(value) {
        case 0:
            numPathsToRender = 50;//Math.floor(pathsTotal/20); //sphere paths
            numTubesToRender = 0; // Math.min(pathsToRender, maxTubes); //tubes paths
            numParticlesToRender = 0;
            break;
        case 1:
            // pathsToRender = Math.floor(pathsTotal/4);
            numPathsToRender = 200;  //Math.floor(100);
            numTubesToRender = 0;  //Math.min(pathsToRender, maxTubes);
            numParticlesToRender = 0;
            break;
        case 2:
            // pathsToRender = Math.floor(pathsTotal/4);
            numPathsToRender = 500;  //Math.floor(100);
            numTubesToRender = 0;  //Math.min(pathsToRender, maxTubes);
            numParticlesToRender = 0;
            break;
        case 3:
            numPathsToRender = 3000;//pathsTotal;
            numTubesToRender = 0; //Math.min(pathsToRender, maxTubes);
            numParticlesToRender = 0;
            break;
        case 4:
            numPathsToRender = 6000;//pathsTotal;
            numTubesToRender = 0; //Math.min(pathsToRender, maxTubes);
            numParticlesToRender = 0;
            break;
        case 5:
            numPathsToRender = 10000;
            numTubesToRender = 0;
            numParticlesToRender = 0;
        // case 3:
        //     numPathsToRender = 3000;//pathsTotal;
        //     numTubesToRender = 300; //Math.min(pathsToRender, maxTubes);
        //     numParticlesToRender = 100;
        //     break;
        // case 2:
        //     numPathsToRender = 3000;//pathsTotal;
        //     numTubesToRender = 300; //Math.min(pathsToRender, maxTubes);
        //     numParticlesToRender = 100;
        //     break;
        default:
            break;
    }

    // let i;
    // for (i = 0; i < Math.min(spheres.length, numParticlesToRender); i++) {
    //     spheres[i].setEnabled(true);
    //     particleSystems[i].start();
    // }
    // if (spheres.length > numPathsToRender) {
    //     for (i = numPathsToRender; i < spheres.length; i++) {
    //         spheres[i].setEnabled(false);
    //     }
    // }
    // if (tubes.length > numTubesToRender) {
    //     for (i = numTubesToRender; i < tubes.length; i++) {
    //         for (let j = 0; j < tubes[i].length; j++) {
    //             tubes[i][j].setEnabled(false);
    //         }
    //     }
    // }
}

function convertStateString(st) {
    var ret = st.replaceAll('{', '').replaceAll('}', '').replaceAll(' ', '').split(',');
    ret = splitArray(ret, 4);
    // ret = ret.map(item => item.splice(0, 1));
    var s = '';
    for (let i = 0; i < ret.length; i++) {
        ret[i].splice(0,1);
        s += '|' + ret[i].toString().replace('[' , '').replace(']', '').replaceAll(' ', '') + '>';
        if (i < ret.length - 1) {
            s += ' + ';
        }
    }
    if (ret.length > 1) {
        s = '(' + s + ')/sqrt(' + ret.length + ')';
    }
    return s;
}

const onSceneReady = (scene, paramsDict) => {
    // pathsTotal = paramsDict['numPaths'];
    // numToFetch = 5;
    // numTubesTotal = Math.min(pathsTotal, maxTubes);
    maxFrame = 0;
    currentFrame = 0;
    previousFrame = 0;
    newState = paramsDict['stateName'];
    stateString = convertStateString(newState);
    complexity = parseInt(paramsDict['complexity']);
    sphereRadius = paramsDict['sphereRadius'];
    console.log('in onSceneReady...', {newState});

    // setComplexity(complexity);
    numPathsToRender = paramsDict['numPaths'];
    numTubesToRender = paramsDict['numTubes'];
    numParticlesToRender = paramsDict['numParticles'];

    if (numParticlesToRender == 0) {
        showParticles = false;
    }
    if (numTubesToRender == 0) {
        showTubes = false;
    }
    if (newState !== '') {
        console.log('initializing scene...');
        disposeOfAllObjects();
        var initRadius = paramsDict['cameraRadius'];
        camera = new ArcRotateCamera("camera1", 0, 3.14*80/180, initRadius, new Vector3(0,0,0), scene);

        camera.lowerRadiusLimit = 2.2;
        camera.fov = 1.3;
        console.log('controls:', camera.inputs.attached);
        //not working...
        // for (var key in camera.inputs.attached) {
        //     console.log('key', key);
        //     camera.inputs.attached[key].detachControl();
        // }
    
        canvas = scene.getEngine().getRenderingCanvas();
    
        canvas.addEventListener("wheel", evt => evt.preventDefault());
    
        // This attaches the camera to the canvas
        camera.attachControl(canvas, true);
    
        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
        var light2 = new PointLight("light2", new Vector3(0, 5, 0), scene);
    
        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.5;
        light2.intensity = 1.0;
    
        scene.clearColor = new Color3(0.0, 0.0, 0.0);
        
    
        sphereMaterial = new StandardMaterial("sphereMaterial", scene);
        sphereMaterial.diffuseColor = sphereColors[currentSchemeSpheres];
        sphereMaterial.specularColor = sphereColors[currentSchemeSpheres];
    
        tubeMaterial = new StandardMaterial("tubeMaterial", scene);
        tubeMaterial.diffuseColor = tubeColors[currentSchemeSpheres];
        tubeMaterial.specularColor = tubeColors[currentSchemeSpheres];
    
        
        advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        advancedTexture.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        advancedTexture.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        advancedTexture2 = AdvancedDynamicTexture.CreateFullscreenUI("UI2");
        advancedTexture2.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        advancedTexture2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    
        panel = new StackPanel();
        panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        panel2 = new StackPanel();
        panel2.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        panel2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;


        const textWidth = '180px';
        textBlock = new TextBlock("user message", "initializing...")
        textBlock.width = textWidth;
        textBlock.height = '40px';
        textBlock.color = 'white';
        textBlock.background = 'black';
        textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        textBlockState = new TextBlock("state name", "State: " + stateString)
        textBlockState.width = '400px';
        textBlockState.height = '40px';
        textBlockState.color = 'white';
        textBlockState.background = 'black';
        // textBlockState.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_TOP;
        // textBlock.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        buttonSpheres = Button.CreateSimpleButton("spheres button", "Show Spheres");
        buttonSpheres.width = textWidth;
        buttonSpheres.height = '40px';
        buttonSpheres.color = 'white';
        buttonSpheres.background = 'green';
        if (showSpheres) {
            buttonSpheres.background = 'green';
        }  else {
            buttonSpheres.background = 'red';
        }
        buttonSpheres.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        buttonTubes = Button.CreateSimpleButton("tubes button", "Show Tubes");
        buttonTubes.width = textWidth;
        buttonTubes.height = "40px";
        buttonTubes.color = "white";
        buttonTubes.background = "green";
        if (showTubes) {
            buttonTubes.background = "green";
        } else {
            buttonTubes.background = "red";
        }
        
        buttonTubes.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    
        buttonParticles = Button.CreateSimpleButton("but", "Show Streamers");
        buttonParticles.width = textWidth;
        buttonParticles.height = "40px";
        buttonParticles.color = "white";
        if (showParticles) {
            buttonParticles.background = "green";
        } else {
            buttonParticles.background = "red";
        }
    
        buttonParticles.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        buttonXCross = Button.CreateSimpleButton("but", "Show X-Cross");
        buttonXCross.width = textWidth;
        buttonXCross.height = "40px";
        buttonXCross.color = "white";
        if (showXCross) {
            buttonXCross.background = "green";
            scene.clipPlane = new Plane(1, 0, 0, 0);
        } else {
            buttonXCross.background = "red";
            scene.clipPlane = null;
        }
        buttonXCross.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        buttonYCross = Button.CreateSimpleButton("but", "Show Z-Cross");
        buttonYCross.width = textWidth;
        buttonYCross.height = "40px";
        buttonYCross.color = "white";
        if (showYCross) {
            buttonYCross.background = "green";
            scene.clipPlane3 = new Plane(0, 1, 0, 0);
        } else {
            buttonYCross.background = "red";
            scene.clipPlane3 = null;
        }
        buttonYCross.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        // buttonSphereColor = Button.CreateSimpleButton("but", "Sphere Color");
        // buttonSphereColor.width = textWidth;
        // buttonSphereColor.height = "40px";
        // buttonSphereColor.color = "white";
        // buttonSphereColor.background = "blue";
        // buttonSphereColor.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

        // buttonTubeColor = Button.CreateSimpleButton("but", "Tube Color");
        // buttonTubeColor.width = textWidth;
        // buttonTubeColor.height = "40px";
        // buttonTubeColor.color = "white";
        // buttonTubeColor.background = "yellow";
        // buttonTubeColor.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        
        // advancedTexture.addControl(panel);
        // advancedTexture2.addControl(panel2);
        
        // panel.addControl(textBlock);
        // panel.addControl(buttonSpheres);
        // panel.addControl(buttonTubes);
        // panel.addControl(buttonParticles);
        // panel.addControl(buttonXCross);
        // panel.addControl(buttonYCross);

        // panel.addControl(buttonSphereColor);
        // panel.addControl(buttonTubeColor);

        // panel2.addControl(textBlockState);

               
    }
    
};

async function disposeOfAllObjects () {
    var i;
    var j;
    disposingNow = true;
    for (i = 0; i < particleSystems.length; i++) {
        particleSystems[i].stop();
        await particleSystems[i].dispose();
        particleSystems[i] = null;
    }
    for (i = 0; i < spheres.length; i++) {
        await spheres[i].dispose();
        spheres[i] = null;
    }
    for (i = 0; i < tubes.length; i++) {
        for (j = 0; j < tubes[i].length; j++) {
            await tubes[i][j].dispose();
            tubes[i][j] = null;
        }
    }
    spheres = [];
    tubes = [];
    spherePositions = [];
    tubePaths = [];
    particleSystems = [];
    timesRaw = [];
    pathsRaw = [];
    // newState = "random";
    disposingNow = false;
}

const onRender = (scene) => {
    if (!disposingNow) {
        var deltaTimeInMillis = scene.getEngine().getDeltaTime();
        sceneTime += deltaTimeInMillis/1000;

        camera.alpha += 0.4 * deltaTimeInMillis/1000;
        // camera.beta += 0.01 * 0.2*Math.sin(sceneTime/100);

    
        // if (canvas.width < 500) {
        //     buttonTubes.isVisible = false;
        //     buttonParticles.isVisible = false;
        //     // selectionBox.isVisible = false;
        //     panel.isVisible = false;
        // } else {
        //     buttonTubes.isVisible = true;
        //     buttonParticles.isVisible = true;
        //     // selectionBox.isVisible = true;
        //     panel.isVisible = true;
        // }
    
        var i;
        var j;
        buttonSpheres.onPointerClickObservable.add(function() {
            showSpheres = !showSpheres;
            if (showSpheres) {
                buttonSpheres.background = "green";
                for (i = 0; i < spheres.length; i++) {
                    // spheres[i].setEnabled(true);
                    spheres[i].isVisible = true;
                } 
            } else {
                buttonSpheres.background = "red";
                for (i = 0; i < spheres.length; i++) {
                    // spheres[i].setEnabled(false);
                    spheres[i].isVisible = false;
                }
            }
        });

        buttonParticles.onPointerUpObservable.add(function () {
            showParticles = !showParticles;
            if (showParticles) {
                buttonParticles.background = "green";
                for (i = 0; i < particleSystems.length; i++) {
                    particleSystems[i].start();
                }
                
            } else {
                buttonParticles.background = "red";
                for (i = 0; i < particleSystems.length; i++) {
                    particleSystems[i].stop();
                }
            }
        });
        buttonTubes.onPointerUpObservable.add(function () {
            showTubes = !showTubes;
            if (showTubes) {
                buttonTubes.background = "green";
                // for (i = 0; i < tubes.length; i++) {
                //     for (j = 0; j < tubes[i].length; j++) {
                //         tubes[i][j].setEnabled(true);
                //     }
                // }
            } else {
                buttonTubes.background = "red";
                for (i = 0; i < tubes.length; i++) {
                    for (j = 0; j < tubes[i].length; j++) {
                        tubes[i][j].setEnabled(false);
                    }
                }
            }
        });

        buttonXCross.onPointerUpObservable.add(function () {
            showXCross = !showXCross;
            if (showXCross) {
                buttonXCross.background = "green";
                scene.clipPlane = new Plane(1, 0, 0, 0);
            } else {
                buttonXCross.background = "red";
                scene.clipPlane = null;
            }
        });

        buttonYCross.onPointerUpObservable.add(function () {
            showYCross = !showYCross;
            if (showYCross) {
                buttonYCross.background = "green";
                scene.clipPlane3 = new Plane(0, 1, 0, 0);
            } else {
                buttonYCross.background = "red";
                scene.clipPlane3 = null;
            }
        });
        
        // buttonSphereColor.onPointerUpObservable.add(function () {
        //     currentSchemeSpheres = (currentSchemeSpheres + 1) % numSchemes;
        //     sphereMaterial.diffuseColor = sphereColors[currentSchemeSpheres];
        //     sphereMaterial.specularColor = sphereColors[currentSchemeSpheres];
        //     // sphereMaterial.emissiveColor = sphereColors[currentScheme];
        // });
        // buttonTubeColor.onPointerUpObservable.add(function () {
        //     currentSchemeTubes = (currentSchemeTubes + 1) % numSchemes;
        //     tubeMaterial.diffuseColor = tubeColors[currentSchemeTubes];
        //     tubeMaterial.specularColor = tubeColors[currentSchemeTubes];
        //     // tubeMaterial.emissiveColor = tubeColors[currentScheme];
        // });

     
    
        if (spherePositions.length < numPathsToRender && !currentlyFetching) {
            newDataFetched = false;
            currentlyFetching = true;
            console.log("fetching data", spherePositions.length, numPathsToRender, numToFetch);
            fetchData(spherePositions.length, numToFetch);
            console.log("done fetching data");
        }
    
        previousFrame = currentFrame;
        // console.log('current frame', currentFrame);
        if (currentFrame === maxFrame) {
            currentFrame = 0;
        } else {
            currentFrame++;
        }
    
        for (i = 0; i < Math.min(spheres.length, numPathsToRender); i++) {
            if (spheres[i] !== undefined) {
                spheres[i].position = spherePositions[i][currentFrame];
            }
            // if (i < tubes.length && i < numTubesToRender) {
            //     if (tubes[i][previousFrame] !== undefined) {
            //         tubes[i][previousFrame].setEnabled(false);
            //     }
            //     if (tubes[i][currentFrame] !== undefined) {
            //         tubes[i][currentFrame].setEnabled(true);
            //     }
            // }
        }
        if (showTubes) {
            for (i = 0; i < Math.min(tubes.length, numTubesToRender); i++) {
                if (tubes[i][previousFrame] !== undefined) {
                    tubes[i][previousFrame].setEnabled(false);
                }
                if (tubes[i][currentFrame] !== undefined) {
                    tubes[i][currentFrame].setEnabled(true);
                }
                
            }
        }
    }
};

function SceneSimple({stateName, 
                      numPaths=500, 
                      numTubes=0,
                      numParticles=0,
                      cameraRadius=20.0, 
                      sphereRadius=0.2}) {
    return (
        <>
            <SceneComponent 
                antialias 
                onSceneReady={onSceneReady} 
                onRender={onRender} 
                id="babylon-canvas" 
                // onSceneReadyDict={{'numPaths': numPaths, 
                //                   'showKitty': showKitty}}
                // onSceneReadyDict={{'numPaths': numPaths}}
                onSceneReadyDict={{'stateName': stateName,  
                                   'numPaths': numPaths,
                                   'numTubes': numTubes,
                                   'numParticles': numParticles,
                                   'cameraRadius': cameraRadius,
                                   'sphereRadius': sphereRadius}}
            />
        </>
    );
}

function areEqual(prevProps, nextProps) {
    const isEqual = (prevProps.stateName === nextProps.stateName && prevProps.complexity === nextProps.complexity);
    return (isEqual);
}

export default React.memo(SceneSimple, areEqual);
