import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App'
import styled from 'styled-components';

import {Typography, Button, Menu, MenuItem, ThemeProvider, createTheme} from '@mui/material';

import { getBaseURL } from '../../functions/functions';

// import mainLogo from '../../images/roboKittyLogo.png'
import mainLogo from '../../images/orbitalLogo1.png'
// import telegramLogo from '../../images/telegram.svg'
import etherscanLogo from '../../images/etherscan-logo-circle.png'
import twitterLogo from '../../images/twitter.svg'
import emailLogo from '../../images/email.svg'
import arbitrumLogo from '../../images/arbitrum-logo.png'

import BuyMenu from './BuyMenu';
import AutotradeMenu from './AutotradeMenu';
import ConnectWalletButton from './ConnectWalletButton';

// import ChartMenu from './ChartMenu';
import { VertDiv, HorzDiv, theme } from '../../styles';

import { Link } from 'react-router-dom';

import { InfoDialog } from '../Dialogs/Dialogs';


const HeaderDiv = styled.div`
    height: 70px;
    width: 100%;
    color: #fff;
    ${'' /* background-color: #14183A; */}
    background-image: linear-gradient(to bottom right, #001E3C, #035598);
    ${'' /* background-image: linear-gradient(to bottom, #14183A, #13294B); */}
    ${'' /* background-image: linear-gradient(to bottom right, #14183A, #13294B); */}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 0px;
`;

const twitterURL = "https://twitter.com/OrbitalAutoDevs";
const etherscanURL = "https://etherscan.io/token/0x380b14e0e9930cd8fcc30647753f9a0de1a843f0";
const emailURL = 'orbitalautomationdevs@gmail.com';

const tokenBuyURL = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x380B14e0E9930Cd8FCC30647753F9a0de1A843F0';
const chartURL = 'https://www.dextools.io/app/ether/pair-explorer/0x155f5a6a5a184c47a0fe545ca857fade929b1aad';
// const baseURL = getBaseURL();
// var homePage = baseURL;

// if (baseURL.includes('localhost')) {
//     homePage = baseURL + ':3000'
// } else {
//     homePage = baseURL
// }

// const Header = () => {
function HeaderOld({showConnectButton, showAutotrade}) {  //causes lots of rerenders for some reason, prob needs correct useEffect
    // const {windowDimensions, networkDict, setNetworkDict, setCurrentRoute} = useContext(UserContext);
    const [largeMenu, setLargeMenu] = useState(true);

    // useEffect(() => {
    //     // console.log('IN Header eseEffect: windowDimensions', windowDimensions);
    //     if (windowDimensions.width >= 475) {
    //         setLargeMenu(true);
    //     } else {
    //         setLargeMenu(false);
    //     }
    // }, [windowDimensions]);

    const themeHeader = createTheme({
        components: {
          MuiList: {
            styleOverrides: {
              root: {
                backgroundColor: theme.light,
                color: 'white',
              },
            },
          },
        },
      });
    
    function MenuDiv() {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        return(
            <>
            {largeMenu &&
                <HorzDiv borderStyle='none' margin='0px' padding='0px' gap={theme.gap1} flexWrap='nowrap'>
                    <Link to='/autotrade' style={{ textDecoration: 'none' }}>
                        <Button 
                            variant="contained" 
                            sx={{p:1, m:0}} 
                            size='small'
                            style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.7em', width: '110px'}}
                        >
                            Vaults List
                        </Button> 
                    </Link>
                    <ConnectWalletButton 
                        networkName='arbitrum'
                    />
                </HorzDiv>
            }
            {!largeMenu &&
                <HorzDiv borderStyle='none' margin='0px' padding='0px' gap={theme.gap1} flexWrap='nowrap'>
                    <ThemeProvider theme={themeHeader}>
                        <Button
                            variant='contained'
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            sx={{p:1, m:1}}
                            size='small'
                            style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.7em'}}
                            onClick={handleClick}
                        >
                            Menu
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>
                                <ConnectWalletButton
                                    networkName='arbitrum'
                                />
                            </MenuItem>
                            <MenuItem onClick={handleClose} >
                                <Link to='/autotrade' style={{ textDecoration: 'none' }}>
                                    <Button
                                        variant="contained"
                                        sx={{p:1, m:1}}
                                        size='small'
                                        style={{'borderRadius':theme.borderRadius, 'fontWeight':'bold', 'fontSize': '0.7em', width: '110px'}}
                                    >
                                        Vaults List
                                    </Button>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </ThemeProvider>
                </HorzDiv>
            }
            </>
        );
    }    

    return (
        <HeaderDiv>
            {/* <HorzDiv borderStyle='none' flexWrap='nowrap' alignContent='flex-start'
                margin={theme.margin0} padding={theme.padding0} gap={theme.gap1}>
                <Link 
                    to='/'
                >
                    <img src={mainLogo} alt="Main logo" style={{width: '90px'}} />
                </Link>
                <Typography variant="h2" style={{color: '#fff', fontFamily: "Poppins", fontSize: '1.4em' }}>
                    {"Orbital"}
                </Typography>
            </HorzDiv> */}

            <HorzDiv borderStyle='none' padding={theme.padding1} gap={theme.gap1} flexWrap='nowrap' justifyContent='center'>
                <MenuDiv />
                {/* <img src={arbitrumLogo} alt="Arbitrum logo" style={{width: '40px'}} /> */}
                {/* <Socials>
                    <li><a href={twitterURL}><img src={twitterLogo}></img></a></li>
                    <li><a href={etherscanURL}><img src={etherscanLogo}></img></a></li>
                    <li><a href={"mailto: "+emailURL}><img src={emailLogo}></img></a></li>
                </Socials> */}
            </HorzDiv>
        </HeaderDiv>

    );
};

function Header({showConnectButton, showAutotrade=true, minimal=false, launchText="Launch App"}) { //seems to work better than HeaderOld, but doesn't have the small menu option.
    // const [launchText, setLaunchText] = useState("Launch App");
    const buttonColor = launchText === "Launch App" ? '#038803' : '#1565C0';
    if (minimal) {
        return (<></>)
    } else {
        return (
            <HeaderDiv>
                <HorzDiv 
                    borderStyle='none' 
                    flexWrap='nowrap' 
                    alignContent='flex-start' 
                    margin={theme.margin0} 
                    padding={theme.padding0} 
                    gap={'4px'}
                >
                    <Link 
                        to='/'
                    >
                        <img src={mainLogo} alt="Main logo" style={{width: '65px'}} />
                    </Link>
                    <Typography 
                        variant="h2" 
                        style={{color: '#fff', fontFamily: "Poppins", fontSize: '1.2em' }}
                    >
                        {"Orbital"}
                    </Typography>
                </HorzDiv>
    
                <HorzDiv 
                    borderStyle='none' 
                    padding={theme.padding1} 
                    gap={theme.gap1} 
                    flexWrap='nowrap' 
                    justifyContent='center'
                    alignItems='start'
                >
                    {showAutotrade &&
                        <Link to='/autotrade' style={{ textDecoration: 'none' }}>
                            <Button 
                                variant="contained" 
                                sx={{p:1, m:0}} 
                                size='small'
                                style={{'borderRadius':theme.borderRadius, 
                                        'fontWeight':'bold', 
                                        'fontSize': '0.7em', 
                                        // width: '110px',
                                        height: '47px',
                                        backgroundColor: buttonColor, 
                                        primaryColor: "0x"}}
                            >
                                {launchText}
                            </Button> 
                        </Link>
                    }
                    
                    <ConnectWalletButton 
                        networkName='arbitrum'
                    />
                    <img src={arbitrumLogo} alt="Arbitrum logo" style={{width: '47px', margin: '0px'}} />
                </HorzDiv>   
            </HeaderDiv> 
        );
    }
    

};
  
export default Header;