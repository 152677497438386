import {ethers} from 'ethers';
import {bigNumToFloat, humanToBigNum, BN, getBaseURL, 
        decomposeTradePath, createFullPathDisplay, 
        stringToInt, stringToBool, mapObject, dateToEpoch, 
        timeIntervalSeconds } from '../functions/functions.js';
import {Token, TokenLibrary, Router} from './classes.js';
import erc20Abi from '../components/Interfaces/erc20Generic.json';
import quoterAbi from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';

// import uniRouterAbi from '../components/Interfaces/Arbitrum/V3Router.json';
// import uniFactoryAbi from '../components/Interfaces/Arbitrum/V3Factory.json';
// import uniNFTAbi from '../components/Interfaces/Arbitrum/V3NFT.json';
// import uniPoolAbi from '../components/Interfaces/Arbitrum/V3Pool.json';
// import vaultFactoryAbi from '../components/Interfaces/Arbitrum/VaultFactory.json';
// import vaultManagerAbi from '../components/Interfaces/Arbitrum/VaultManager.json';
// import gasStationAbi from '../components/Interfaces/Arbitrum/GasStation.json';
// import quoterAbi from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';
import axios from 'axios';

// import ethIcon from '../images/icon/eth.svg';
// import btcIcon from '../images/icon/btc.svg';
// import usdcIcon from '../images/icon/usdc.svg';
// import gmxIcon from '../images/icon/gmx.svg';
// import usdtIcon from '../images/icon/usdt.svg';
// import arbIcon from '../images/arbitrum-logo.png';

// import devArbitrumContractInfo from '../components/Interfaces/VaultV2/dev/42161/combined.json' //main interfaces deployed at deploy time
// import releaseContractInfo from '../components/Interfaces/VaultV2/release/42161/combined.json' //main interfaces deployed at deploy time

import AuxInfoABI from '../components/Interfaces/VaultV2/IAuxInfo.json' //interfaces for contracts deployed during normal operation
import RouterInfoABI from '../components/Interfaces/VaultV2/IRouterInfo.json' //interfaces for contracts deployed during normal operation
import VaultV2ABI from '../components/Interfaces/VaultV2/IVaultV2.json'
import VaultFactoryV2ABI from '../components/Interfaces/VaultV2/IVaultFactoryV2.json'
import VaultManagerV2ABI from '../components/Interfaces/VaultV2/IVaultManagerV2.json'
import VaultInfoABI from '../components/Interfaces/VaultV2/IVaultInfo.json'
import GasStationABI from '../components/Interfaces/VaultV2/IGasStation.json'
import ChainlinkInterfaceABI from '../components/Interfaces/VaultV2/IChainlinkInterface.json'



export class Quoter {
    constructor(provider, addressIn='0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6') {
        //!!!!!!!!!!!!!!!!!!!!!! needs fixing for other networks
        // this.provider = ethers.getDefaultProvider('https://arb1.arbitrum.io/rpc');//providerIn;
        this.provider = provider;
        this.abi = quoterAbi.abi;
        
        this.address = addressIn;
        this.contract = new ethers.Contract(addressIn, this.abi, this.provider);
    }
    async getQuoteSingle(tokenIn, tokenOut, amountIn, fee) {
        const tx = await this.contract.callStatic.quoteExactInputSingle(tokenIn, 
                                                                        tokenOut, 
                                                                        fee, 
                                                                        amountIn.toString(), 
                                                                        0);
        return tx;
    }
    async getQuote(encodedPath, amountIn) {
        const tx = await this.contract.callStatic.quoteExactInput(encodedPath,
                                                                  amountIn.toString());
        return tx;
    }
}

export class ChainlinkInterface {
	constructor(address, provider) {
		this.provider = provider;
		this.address = address;
		this.abi = ChainlinkInterfaceABI;
		this.contract = new ethers.Contract(address, this.abi, provider);
		this.signer = provider.getSigner();
	}

    async owner() {
        return await this.contract.owner();
    }

    async maxSlippageMillipercent() {
        return await this.contract.maxSlippageMillipercent();
    }

    async setSequencerUptimeFeed(feedAddress) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setSequencerUptimeFeed(feedAddress);
    }

    async getSequencerUptimeFeed() {
        return await this.contract.getSequencerUptimeFeed();
    }

    async sequencerUptime() {
        return await this.contract.sequencerUptime();
    }

    async addPriceFeed(token, priceFeed) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.addPriceFeed(token, priceFeed);
    }

    async removePriceFeed(token) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.removePriceFeed(token);
    }

    async getPriceFeed(token) {
        return await this.contract.getPriceFeed(token);
    }

    async getPrice(token) {
        return await this.contract.getPrice(token);
    }

    async getRelativePrice(token, tokenBase) {
        // var price, decimals, priceBase, decimalsBase;
        var ret0, ret1;
        ret0 = await this.contract.getPrice(token);
        ret1 = await this.contract.getPrice(tokenBase);

        var p0 = ret0.price/10**ret0.decimals;
        var p1 = ret1.price/10**ret1.decimals;

        return p0/p1;
    }

    async getMinReceived(tokenFrom, tokenTo, amtIn, slippageMillipercent) {
        return await this.contract.getMinReceived(tokenFrom, tokenTo, amtIn, slippageMillipercent);
    }
}

export class AuxInfo {
    constructor(address, provider, signerIn=null) {
        this.provider = provider;
        this.address = address;
        this.abi = AuxInfoABI;
        if(signerIn) {
            this.contract = new ethers.Contract(address, this.abi, signerIn);
            this.signer = signerIn;
        } else {
            this.contract = new ethers.Contract(address, this.abi, provider);
            this.signer = provider.getSigner();
        }
        this.allowedRouters = [];
        this.initialized = false;
    }

    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && "AuxInfo" in prefetch) {
            this.numAllowedRouters = stringToInt(prefetch.AuxInfo.numAllowedRouters);
            for (let i = 0; i < this.numAllowedRouters; i++) {
                // const routerAddress = prefetch.AuxInfo.allowedRouters[i];
                // const routerInfoTemp = await this.getRouterInfo(routerAddress);
                // const routerInfoTemp = prefetch[routerAddress].info;
                const routerInfoContractAddress = prefetch.AuxInfo.routerInfoContractAddresses[i];
                const routerInfo = new RouterInfo(routerInfoContractAddress, this.provider);
                await routerInfo.init(prefetch);
                this.allowedRouters.push(routerInfo);
            }
        } else {
            this.numAllowedRouters = bigNumToFloat(await this.getNumAllowedRouters());
            for (let i = 0; i < this.numAllowedRouters; i++) {
                const routerAddress = await this.getAllowedRouter(i);
                const routerInfoTemp = await this.getRouterInfo(routerAddress);
                const routerInfoContractAddress = routerInfoTemp.routerInfoContractAddress;
                const routerInfo = new RouterInfo(routerInfoContractAddress, this.provider);
                await routerInfo.init();
                this.allowedRouters.push(routerInfo);
            }
        }
        this.initialized = true;
    }

    async getNumAllowedTokens() {
        return await this.contract.getNumAllowedTokens();
    }
    async getAllowedToken(index) {
        return await this.contract.getAllowedToken(index);
    }
    async getAllowedTokens() {
        return await this.contract.getAllowedTokens();
    }
    async getAllowedTokenInfo(token) {
        return await this.contract.getAllowedTokenInfo(token);
    }
    async setAllowedTokenInfo(token, initialDenominator) {
        const contractWithSigner = this.contract.connect(this.signer);
        await contractWithSigner.setAllowedTokenInfo(token, initialDenominator);
    }
    async allowToken(token, initialDenominator) {
        const contractWithSigner = this.contract.connect(this.signer);
        await contractWithSigner.allowToken(token, initialDenominator);
    }
    async disallowToken(token) {
        const contractWithSigner = this.contract.connect(this.signer);
        await contractWithSigner.disallowToken(token);
    }
    async isTokenAllowed(token) {
        return await this.contract.isTokenAllowed(token);  
    }
    async areTokensAllowed(tokens) {
        return await this.contract.areTokensAllowed(tokens);
    }
    async getNumAllowedRouters() {
        return await this.contract.getNumAllowedRouters();
    }
    async getAllowedRouter(index) {
        return await this.contract.getAllowedRouter(index);
    }
    async getRouterInfo(routerAddress) {
        return await this.contract.getRouterInfo(routerAddress);
    }
    async isRouterAllowed(routerAddress) {
        return await this.contract.isRouterAllowed(routerAddress);
    }
    async allowRouter(routerAddress, nameIn, routerType) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.allowRouter(routerAddress, nameIn, routerType);
    }
    async disallowRouter(routerAddress) {
        const contractWithSigner = this.contract.connect(this.signer);
        await contractWithSigner.disallowRouter(routerAddress);
    }
}

export class RouterInfo {
    constructor(contractAddress, provider, signerIn=null) {
        this.provider = provider;
        this.contractAddress = contractAddress;
        this.abi = RouterInfoABI;
        if(signerIn) {
            this.contract = new ethers.Contract(contractAddress, this.abi, signerIn);
            this.signer = signerIn;
        } else {
            this.contract = new ethers.Contract(contractAddress, this.abi, provider);
            this.signer = provider.getSigner();
        }
        this.address = null;
        this.routerType = null;
        this.initialized = false;
    }

    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && this.contractAddress in prefetch) {
            let d = prefetch[this.contractAddress];
            this.address = d.routerAddress;
            this.routerType = BN(d.routerType);
            ////needed?????
            // this.info = d.info;
        } else {
            this.address = await this.getRouterAddress();
            this.routerType = await this.getRouterType();
            ///NEEDED?????
            // this.info = await this.getInfo();
        }
        this.initialized = true;
    }
  
    async getName() {
        return await this.contract.getName();
    }
  
    async getRouterAddress() {
        return await this.contract.getRouterAddress();
    }
  
    async getRouterType() {
        return await this.contract.getRouterType();
    }
  
    async getInfo() {
        return await this.contract.getInfo();
    }
  
    async getNumAllowedPairs() {
        return await this.contract.getNumAllowedPairs();
    }
  
    async getAllowedPair(index) {
        return await this.contract.getAllowedPair(index);
    }
  
    async isPairAllowed(token0, token1) {
        return await this.contract.isPairAllowed(token0, token1);
    }
  
    async getNumAllowedPaths(token0, token1) {
        return await this.contract.getNumAllowedPaths(token0, token1);
    }
  
    async getAllowedPath(token0, token1, pathIndex) {
        return await this.contract.getAllowedPath(token0, token1, pathIndex);
    }
  
    async allowPath(token0, token1, path) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.allowPath(token0, token1, path);
    }
  
    async disallowPath(token0, token1, pathIndex) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.disallowPath(token0, token1, pathIndex);
    }
}

export class VaultFactoryV2 {
    constructor(address, provider, signerIn=null) {
        this.provider = provider;
        this.address = address;
        this.abi = VaultFactoryV2ABI;
        if(signerIn) {
            this.contract = new ethers.Contract(address, this.abi, signerIn);
            this.signer = signerIn;
        } else {
            this.contract = new ethers.Contract(address, this.abi, provider);
            this.signer = provider.getSigner();
        }
        this.vaultManager = null;
        this.AuxInfo = null; //new AuxInfo(network, provider);
        this.allowedRouters = [];
        this.allowedPairs = [];
        this.deployedVaults = [];
        this.initialized = false;
    }

    async init(prefetch=null) {
        this.initialized = false;

        var auxInfoAddress;
        if (prefetch !== undefined && prefetch !== null && "VaultFactoryV2" in prefetch) {
            auxInfoAddress = prefetch.VaultFactoryV2.auxInfoAddress;
            this.AuxInfo = new AuxInfo(auxInfoAddress, this.provider);
            await this.AuxInfo.init(prefetch);
            this.allowedRouters = this.AuxInfo.allowedRouters;
            this.allowedPairs = await this.getAllowedPairs(prefetch);
            this.numVaults = stringToInt(prefetch.VaultFactoryV2.numVaults);
            this.deployedVaults = prefetch.VaultFactoryV2.deployedVaults;
            this.feeOwner = stringToInt(prefetch.VaultFactoryV2.feeOwner);
        } else {
            this.AuxInfo = new AuxInfo(await this.getAuxInfoAddress(), this.provider);
            const numAllowedRouters = bigNumToFloat(await this.AuxInfo.getNumAllowedRouters(), 0);
            for (let i = 0; i < numAllowedRouters; i++) {
                const routerAddress = await this.AuxInfo.getAllowedRouter(i);
                const routerInfoTemp = await this.AuxInfo.getRouterInfo(routerAddress);
                const routerInfoContractAddress = routerInfoTemp.routerInfoContractAddress; 
                const routerInfo = new RouterInfo(routerInfoContractAddress, this.provider);
                await routerInfo.init();
                this.allowedRouters.push(routerInfo);
            }
            this.allowedPairs = await this.getAllowedPairs();
            this.deployedVaults = await this.getDeployedVaults();
        }

        this.initialized = true;
    }

    async refresh() {
        this.deployedVaults = await this.getDeployedVaults();
    }

    async getAllowedTokens() {
        return await this.AuxInfo.getAllowedTokens();
    }

    async getDeployedVaults() {
        let dv = [];
        const numVaults = bigNumToFloat(await this.getNumVaults(), 0);
        for (let i = 0; i < numVaults; i++) {
            const vaultAddress = await this.getVaultAddress(i);
            dv.push(vaultAddress);
        }
        return dv;
    }

    async getAllowedPairs(prefetch=null) {
        let allowedPairs = [];

        for (const routerInfoContract of this.allowedRouters) {
            const routerInfoContractAddress = routerInfoContract.contractAddress;
            if (prefetch !== undefined && prefetch !== null && routerInfoContractAddress in prefetch) {
                var thisAllowedPairs = prefetch[routerInfoContractAddress].allowedPairs;
                for (const allowedPair of thisAllowedPairs) {
                    allowedPairs.push(allowedPair);
                }
            } else {
                const numAllowedPairs = bigNumToFloat(await routerInfoContract.getNumAllowedPairs(), 0);
                for (let i = 0; i < numAllowedPairs; i++) {
                    const allowedPair = await routerInfoContract.getAllowedPair(i);
                    allowedPairs.push(allowedPair);
                }
            }

        }
        return allowedPairs;
    }

    async getCreationBlock() {
        return await this.contract.getCreationBlock();
    }
  
    async getVaultManagerAddress() {
        return await this.contract.getVaultManagerAddress();
    }
  
    async getVaultInfoAddress() {
        return await this.contract.getVaultInfoAddress();
    }
  
    async getAuxInfoAddress() {
        return await this.contract.getAuxInfoAddress();
    }
  
    async getMaxTokensPerVault() {
        return await this.contract.getMaxTokensPerVault();
    }
  
    async setMaxTokensPerVault(maxTokensPerVaultIn) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setMaxTokensPerVault(maxTokensPerVaultIn);
    }
  
    async getFeeOwner() {
        return await this.contract.getFeeOwner();
    }
  
    async setFeeOwner(feeOwnerIn) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setFeeOwner(feeOwnerIn);
    }
  
    async getNumVaults() {
        return await this.contract.getNumVaults();
    }
  
    async getVaultAddress(index) {
        return await this.contract.getVaultAddress(index);
    }
  
    async isVaultDeployed(vaultAddress) {
        return await this.contract.isVaultDeployed(vaultAddress);
    }
  
    async deploy(params) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.deploy(params);
    }
}

export class VaultManagerV2 {
    constructor(address, provider, signerIn=null) {
        this.provider = provider;
        this.address = address;
        this.abi = VaultManagerV2ABI;
        if(signerIn) {
            this.contract = new ethers.Contract(address, this.abi, signerIn);
            this.signer = signerIn;
        } else {
            this.contract = new ethers.Contract(address, this.abi, provider);
            this.signer = provider.getSigner();
        }
        this.creationBlock = 0;
        this.history = [];
        this.initialized = false;
    }

    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && "VaultManagerV2" in prefetch) {
            this.creationBlock = stringToInt(prefetch.VaultManagerV2.creationBlock);
            this.numAutoTraders = stringToInt(prefetch.VaultManagerV2.numAutoTraders);
            this.autoTraders = prefetch.VaultManagerV2.autoTraders;
            this.autoTrader = prefetch.VaultManagerV2.autoTrader;
        } else {
            this.creationBlock = await this.getCreationBlock();
        }
        this.initialized = true;
    }

    async owner() {
        return await this.contract.owner();
    }
    // async getAutoTrader() {
    //     var at = await this.contract.getAutoTrader();
    //     this.autoTrader = at;
    //     return at;
    // }

    async numAutoTraders() {
        var n = await this.contract.numAutoTraders();
        this.numAutoTraders = n;
        return n;
    }

    async getAutoTrader(index=0) {
        var at = await this.contract.getAutoTrader(index);
        if (index === 0) {
            this.autoTrader = at;
        }
        return at;
    }
    // async setAutoTrader(autoTraderIn) {
    //     const contractWithSigner = this.contract.connect(this.signer);
    //     return await contractWithSigner.setAutoTrader(autoTraderIn);
    // }

    async getGasStationParam() {
        return await this.contract.getGasStationParam();
    }

    async setGasStationParam(gasStationParamIn) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setGasStationParam(gasStationParamIn);
    }

    async getTrade5MinTime() {
        return await this.contract.getTrade5MinTime();
    }

    async setTrade5MinTime(trade5MinTimeIn) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setTrade5MinTime(trade5MinTimeIn);
    }

    async getUseGasStation() {
        return await this.contract.getUseGasStation();
    }

    async setUseGasStation(useGasStationIn) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setUseGasStation(useGasStationIn);
    }

    async getCreationBlock() {
        return await this.contract.getCreationBlock();
    }

    async getGasStationAddress() {
        return await this.contract.getGasStationAddress();
    }

    async getOwnerFeesDest() {
        return await this.contract.getOwnerFeesDest();
    }

    async setOwnerFeesDest(newOwnerFeesDest) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setOwnerFeesDest(newOwnerFeesDest);
    }

    async deposit(vaultAddress, amts) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.deposit(vaultAddress, amts);
    }

    async withdraw(vaultAddress, percentage) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.withdraw(vaultAddress, percentage);
    }

    async trade(vaultAddress, params) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.trade(vaultAddress, params);
    }

    async fetchHistory(isDevBool, chainIDIn, fetchNewInfo=false) {
        var baseURL = getBaseURL()
        if (baseURL.includes('localhost')) {
            baseURL = baseURL+':8000';
        }
        var url0;
        
        url0 = baseURL+'/api/fetchvaultmanagerhistory/'+isDevBool.toString()+'/'+chainIDIn.toString()+'/'+fetchNewInfo.toString();
        
        console.log('calling url: '+url0);
        await axios.get(url0).then(res0 => 
            {
                this.history = res0.data;
                // if (this.history.length > 0) {
                //     //remove last element
                //     this.history.pop();
                // }
                // this.initialized = true;
            }
        );
        return this;
    }
}

export class VaultInfo {
	constructor(address, provider) {
        this.provider = provider;
        this.address = address;
        this.abi = VaultInfoABI;
        this.contract = new ethers.Contract(this.address, this.abi, provider);
	}

	async getVaultInfo(vaultAddress) {
		return await this.contract.getVaultInfo(vaultAddress);
	}

	async getAmtsNeededForDeposit(vaultAddress, indexOfReferenceToken, amtIn) {
		return await this.contract.getAmtsNeededForDeposit(vaultAddress, indexOfReferenceToken, amtIn);
	}

	async getUserBalances(vaultAddress, userAddress) {
        const userBalances = await this.contract.getUserBalances(vaultAddress, userAddress);
		return userBalances;
	}

    async getAutotradeAlertInfo() {
        const alertInfo = await this.contract.getAutotradeAlertInfo();
        return alertInfo;
    }

    async getBalanceAlertInfo() {
        const alertInfo = await this.contract.getBalanceAlertInfo();
        return alertInfo;
    }

    async getAllAlertInfo() {
        const alertInfo = await this.contract.getAllAlertInfo();
        return alertInfo;
    }
}

export class VaultV2 {
	constructor(address, provider, vaultInfoObj=null, signerIn=null, autoTrader=null) {
        this.provider = provider;
        this.address = address;
		this.abi = VaultV2ABI;

        if(signerIn) {
            this.contract = new ethers.Contract(address, this.abi, signerIn);
            this.signer = signerIn;
        } else {
            this.contract = new ethers.Contract(address, this.abi, provider);
            this.signer = provider.getSigner();
        }
		
        this.vaultInfoObj = vaultInfoObj;
        this.autotraderAddress = autoTrader;
	}

    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && this.address in prefetch) {
            const d = prefetch[this.address];
            this.name = d.name;
            this.isActive = stringToBool(d.isActive);
            this.allowOtherUsers = stringToBool(d.allowOtherUsers);
            this.operator = d.operator;
            this.balances = d.balances.map(x => BN(x));
            this.creationTime = stringToInt(d.creationTime);
            // this.fees = d.feeRate.map(x => stringToInt(x));
            this.fees = mapObject(d.feeRate, value => BN(value));
            // this.D = d.D;
            this.strategy = d.strategy;
            this.autotradeActive = stringToBool(d.autotradeActive);
            this.oldestTradeTime = stringToInt(d.oldestTradeTime);
        } else {
            if (this.vaultInfoObj === null) {
                this.name = await this.contract.name();
                this.isActive = await this.contract.isActive();
                this.allowOtherUsers = await this.contract.allowOtherUsers();
                this.operator = await this.contract.operator();
                this.balances = await this.balances();
                
            } else {
                const vi = await this.vaultInfoObj.contract.getVaultInfo(this.address);
                this.name = vi.name;
                this.isActive = vi.isActive;
                this.allowOtherUsers = vi.allowOtherUsers;
                this.operator = vi.operator;
                this.balances = vi.balances;
            }
        }

        this.totalBalance = BN(0);
        for (const balance of this.balances) {
            this.totalBalance = this.totalBalance.add(balance);
        }
        // this.creationTime = await this.contract.getCreationTime();

    }

	async D() {
		return await this.contract.D();
	}

	async setD(DIn) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.setD(DIn);
	}

	async N(user) {
		return await this.contract.N(user);
	}

	async setN(user, NIn) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.setN(user, NIn);
	}

	async isTokenAllowed(token) {
		return await this.contract.isTokenAllowed(token);
	}

	async getToken(index) {
		return await this.contract.getToken(index);
	}

	async getTokens() {
		return await this.contract.getTokens();
	}

	async getOldestTradeTime() {
		return await this.contract.getOldestTradeTime();
	}

	async getFees() {
		return await this.contract.getFees();
	}

	async deactivate() {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.deactivate();
	}

	async setAllowOtherUsers(allow) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.setAllowOtherUsers(allow);
	}

    async setOperator(operatorAddress) {
        const contractWithSigner = this.contract.connect(this.signer);
        return await contractWithSigner.setOperator(operatorAddress);
    }

	// async setStrategy(stratString) {
	// 	const contractWithSigner = this.contract.connect(this.signer);
	// 	return await contractWithSigner.setStrategy(stratString);
	// }

	async setStrategyAndActivate(stratString, activate) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.setStrategyAndActivate(stratString, activate);
	}

	async setAutotrade(status) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.setAutotrade(status);
	}

	async balance(token) {
		return await this.contract.balance(token);
	}

	async balances() {
        return await this.contract.getBalanceTracked();
		// return await this.contract.balances();
	}

    async totalBalance() {
        return await this.contract.totalBalance();
    }

    async getUserBalances(userAddress) {
        return await this.contract.getUserBalances(userAddress);
    }

	async increaseAllowance(token, spenderAddress, value) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.increaseAllowance(token, spenderAddress, value);
	}

	async tradeV2(routerAddress, amountIn, amountOutMin, path) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.tradeV2(routerAddress, amountIn, amountOutMin, path);
	}

	async tradeV3(routerAddress, params) {
		const contractWithSigner = this.contract.connect(this.signer);
		return await contractWithSigner.tradeV3(routerAddress, params);
	}
}

export class VaultAggregated {
    constructor(VaultFactoryObjIn,
                VaultManagerV2ObjIn, 
                VaultInfoObjIn, 
                VaultV2ObjIn, 
                networkDictIn, 
                walletsToWatchList, 
                isDevBool=false, 
                signerIn=null, 
                primaryTokenList=['USDC', 'USDT', 'DAI', 'WBTC']) {
        this.isDevBool = isDevBool;
        this.factoryObj = VaultFactoryObjIn;
        this.managerObj = VaultManagerV2ObjIn;
        this.infoObj = VaultInfoObjIn;
        this.vaultObj = VaultV2ObjIn;

        this.address = this.vaultObj.address;
        this.vaultFactory = VaultFactoryObjIn.contract;
        

        this.manager = VaultManagerV2ObjIn.contract;
        this.autoTrader = VaultManagerV2ObjIn.autoTrader;
        // //needs to be merged with this.manager (remove this.manager)
        // //currently, this.VM is only for fetching vm history from the backend.
        // this.VM = new VaultManager(providerIn); 
        this.networkDict = networkDictIn;
        this.provider = this.networkDict.provider;
        this.signer = signerIn ? signerIn : this.provider.getSigner();

        console.log('this.signer: ', this.signer);

        // if(signerIn) {
        //     this.contract = new ethers.Contract(address, this.abi, signerIn);
        //     this.signer = signerIn;
        // } else {
        //     this.contract = new ethers.Contract(address, this.abi, provider);
        //     this.signer = provider.getSigner();
        // }
        this.walletsToWatch = walletsToWatchList;
        this.allowances = {};
        this.balances = {};
        
        this.initialized = false;
        this.confirmations = 1;

        this.primaryTokenList = primaryTokenList;
    }
    async init(prefetch=null) {
        if (prefetch !== undefined && prefetch !== null && this.address in prefetch) {
            this.vaultInfo = prefetch[this.address];
            this.owner = prefetch.VaultManagerV2.owner;
            this.gasStationAddress = prefetch.VaultManagerV2.gasStationAddress;
            this.useGasStation = stringToBool(prefetch.VaultManagerV2.useGasStation);
            this.gasStation = new ethers.Contract(this.gasStationAddress, GasStationABI, this.provider);
            if (this.useGasStation) {
                this.gasStationBalance = await this.getGasStationBalance();
            } else {
                this.gasStationBalance = BN(0);
            }
            this.balances[this.address] = prefetch[this.address].balances.map(x => BN(x));
        } else {
            this.vaultInfo = await this.infoObj.contract.getVaultInfo(this.address);
            this.owner = await this.manager.owner();
            this.gasStationAddress = await this.manager.getGasStationAddress();
            this.gasStation = new ethers.Contract(this.gasStationAddress, GasStationABI, this.provider);
            this.useGasStation = await this.manager.getUseGasStation();

            if (this.useGasStation) {
                this.gasStationBalance = await this.getGasStationBalance();
            } else {
                this.gasStationBalance = BN(0);
            }

            this.balances[this.address] = this.vaultInfo.balances;
        }

        this.name = this.vaultInfo.name;
        this.creationTime = stringToInt(this.vaultInfo.creationTime);
        this.strategy = this.vaultInfo.strategy;
        this.tokenList = this.vaultInfo.tokenList;
        this.numTokens = this.tokenList.length;

        //!must be updated for more than 2 tokens
        this.token0Add = this.tokenList[0];
        this.token1Add = this.tokenList[1];

        this.routerInfos = this.factoryObj.allowedRouters;

        // //!must update for more than one router!!!
        this.router = null;
        this.routers = [];
        for (const element of this.routerInfos) {
            const routerType = element.routerType;
            if (routerType.eq(BN(1))) { //check for uniswap V# type router
                this.routerAddress = element.address;
                this.router = new Router(this.routerAddress, this.provider);
                await this.router.init(prefetch);
                this.routers.push(this.router);
                break;
            }
        }
        if (this.router == null) {
            console.log('Error: V3 type router not found');
        }

        this.isActive = stringToBool(this.vaultInfo.isActive);
        this.autotradeActive = stringToBool(this.vaultInfo.autotradeActive);
        this.allowOtherUsers = stringToBool(this.vaultInfo.allowOtherUsers);
        
        this.operator = this.vaultInfo.operator;

        this.fees = mapObject(this.vaultInfo.feeRate, val => BN(val));
        this.feeOwner = this.fees.owner;
        this.feeOperator = this.fees.operator;
        this.feeUsers = this.fees.users;
        if (!(this.operator in this.walletsToWatch)){
            this.walletsToWatch.push(this.operator);
        }

        const t0Contract = new ethers.Contract(this.token0Add, erc20Abi, this.provider);
        const t1Contract = new ethers.Contract(this.token1Add, erc20Abi, this.provider);
        this.token0 = new Token(this.token0Add, t0Contract, this.provider);
        this.token1 = new Token(this.token1Add, t1Contract, this.provider);
        await this.token0.init(prefetch);
        await this.token1.init(prefetch);
        this.pairString = this.token0.symbol + "-" + this.token1.symbol;
        this.tokens = [this.token0, this.token1];

        this.token0MinDep = BN(1);
        this.token1MinDep = BN(1);

        this.tokenPrimaryNum = -1;
        this.tokenPrimary = null;
        this.tokenSecondary = null;

        for (var i=0; i<this.primaryTokenList.length; i++) {
            if (this.token0.symbol === this.primaryTokenList[i]) {
                this.tokenPrimaryNum = 0;
                this.tokenPrimary = this.token0;
                this.tokenSecondary = this.token1;
                break;
            }
            if (this.token1.symbol === this.primaryTokenList[i]) {
                this.tokenPrimaryNum = 1;
                this.tokenPrimary = this.token1;
                this.tokenSecondary = this.token0;
                break;
            }
        }

        await this.update(false, false);
        this.initialized = true;
    }
    async autotradeActiveFunction() {
        const vi = await this.infoObj.contract.getVaultInfo(this.address);
        return vi.isActive && vi.autotradeActive;
    }
    async update(updateVMHistory=false, refreshBasicStuff=true){
        if (refreshBasicStuff) {
            this.vaultInfo = await this.infoObj.contract.getVaultInfo(this.address);
            this.owner = await this.manager.owner();
            this.isActive = this.vaultInfo.isActive;
            this.autotradeActive = this.vaultInfo.autotradeActive;
            this.allowOtherUsers = this.vaultInfo.allowOtherUsers;
            this.strategy = this.vaultInfo.strategy;
            this.useGasStation = await this.manager.getUseGasStation();
            this.gasStationBalance = await this.getGasStationBalance();  
        } 

        this.balancesTotal = this.vaultInfo.balances.map(x => BN(x));

        this.totalBalance = BN(0);
        for (const balance of this.balancesTotal) {
            this.totalBalance = this.totalBalance.add(balance);
        }

        this.balance0 = this.balancesTotal[0];
        this.balance1 = this.balancesTotal[1];

        for (const wallet of this.walletsToWatch) {
            this.allowances[wallet] = [
                await this.token0.getAllowance(wallet, this.manager.address),
                await this.token1.getAllowance(wallet, this.manager.address)
            ];
            if (wallet.toLowerCase() !== wallet)
                this.allowances[wallet.toLowerCase()] = this.allowances[wallet];
            const ub = await this.infoObj.getUserBalances(this.address, wallet);
            this.balances[wallet] = ub;
            this.balances[wallet.toLowerCase()] = ub;
            // for (let i = 0; i < ub.length; i++) {
            //     this.balances[wallet].push(ub[i]);
            // }
        }
        this.paths = await this.getAllowedPaths();

        if (updateVMHistory) {
            this.managerObj.fetchHistory(this.isDevBool, this.networkDict.chainID, true); //update vm history on server and pass back to here
        }
        return this;
    }
    async fetchHistory(isDevBool, chainIDIn, fetchNewInfo=false) {
        // const sb = startBlockIn ? startBlockIn : this.managerObj.creationBlock;
        await this.managerObj.fetchHistory(isDevBool, chainIDIn, fetchNewInfo);
        return this;
    }
    //balance of connected wallet
    async getGasStationBalance() {
        var add=null;
        if (this.signer.address !== undefined && this.signer.address !== null) {
            add = this.signer.address;
        } else if (this.signer._address !== undefined && this.signer._address !== null) {
            add = this.signer._address;
        } else {
            return BN(0);
        }
        return await this.gasStation.balanceOf(add);
        
    }
    async depositGasStation(amountEth) {
        const tx = await this.gasStation.connect(this.signer).deposit({value: amountEth});
        await tx.wait(this.confirmations);
        await this.update();
    }
    async withdrawGasStation(amountEth) {
        const currentBalance =  await this.getGasStationBalance();
        var amountToWithdraw;
        if (amountEth > currentBalance) {
            amountToWithdraw = currentBalance;
        } else {
            amountToWithdraw = amountEth;
        }
        const tx = await this.gasStation.connect(this.signer).withdraw(amountToWithdraw);
        await tx.wait(this.confirmations);
        await this.update();
    }

    async getAllowedPaths() {
        //NEEDS UPDATING FOR MORE THAN 2 TOKENS
        
        var paths = {};
        paths[this.token0.address] = {};
        paths[this.token0.address][this.token1.address] = [];

        paths[this.token1.address] = {};
        paths[this.token1.address][this.token0.address] = [];
        
        let numPaths = await this.routerInfos[0].getNumAllowedPaths(this.token0.address, this.token1.address);
        // const paths = [];
        for (let i = 0; i < numPaths; i++) {
            paths[this.token0.address][this.token1.address].push(await this.routerInfos[0].getAllowedPath(this.token0.address, this.token1.address, i));
        }

        numPaths = await this.routerInfos[0].getNumAllowedPaths(this.token1.address, this.token0.address);
        for (let i = 0; i < numPaths; i++) {
            paths[this.token1.address][this.token0.address].push(await this.routerInfos[0].getAllowedPath(this.token1.address, this.token0.address, i));
        }

        return paths;
    }
    getBalances(address) {
        if (address !== null && this.balances[address] !== undefined) {
            return this.balances[address];
        } else {
            var ret = [];
            for (let i = 0; i < this.numTokens; i++) {
                ret.push(BN(0));
            }
            return ret;
        }
    }
    async getVaultBalances() {
        const vi = await this.infoObj.getVaultInfo(this.address);
        console.log('VI: ', vi);

        var amtPrimary = BN(0);
        var amtSecondary = BN(0);
        if (vi.tokenList[0] === this.tokenPrimary.address) {
            amtPrimary = vi.balances[0];
            amtSecondary = vi.balances[1];
        } else {
            amtPrimary = vi.balances[1];
            amtSecondary = vi.balances[0];
        }
        const amtPrimaryFloat = this.tokenPrimary.toFloat(amtPrimary)
        const amtSecondaryFloat = this.tokenSecondary.toFloat(amtSecondary)
        const balances = {primary: amtPrimary, secondary: amtSecondary}
        const balancesFloat = {primary: amtPrimaryFloat, secondary: amtSecondaryFloat}   
        return {'balances': balances, 'balancesFloat': balancesFloat}     
    }
    getAllowances(address) {
        if (address !== null && this.allowances[address] !== undefined) {
            return this.allowances[address];
        } else {
            var ret = [];
            for (let i = 0; i < this.numTokens; i++) {
                ret.push(BN(0));
            }
            return ret;
        }
    }
    showBalance(num, decimalsToShow=4, account='pool') {
        if (account === 'pool') {
            if (num === 0) {
                return this.token0.toHuman(this.balance0, decimalsToShow);
            } else {
                return this.token1.toHuman(this.balance1, decimalsToShow);
            }
        } else {
            if (this.balances[account] !== undefined) {
                if (num === 0) {
                    return this.token0.toHuman(this.balances[account][0], decimalsToShow);
                } else {
                    return this.token1.toHuman(this.balances[account][1], decimalsToShow);
                }
            } else {
                return 0;
            }
        }
    }
    async setStrategy(strategy, activate=true) {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            // if (activate) {
            //     tx = await this.vaultObj.setStrategyAndActivate(strategy, true);
            // } else {
            //     tx = await this.vaultObj.setStrategy(strategy);
            // }
            tx = await this.vaultObj.setStrategyAndActivate(strategy, true); //removed "setStrategy" function from contract

            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async deactivate() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.isActive) {
                tx = await this.vaultObj.deactivate();
            } else {
                console.log('Vault is already inactive')
            }
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async setOperator(newOperator) {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            tx = await this.vaultObj.setOperator(newOperator);
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async toggleAutotrade() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.autotradeActive) {
                tx = await this.vaultObj.setAutotrade(false);
                await tx.wait(this.confirmations);
                await this.update(true);
            } else {
                if (this.isActive) {
                    tx = await this.vaultObj.setAutotrade(true);
                    await tx.wait(this.confirmations);
                    await this.update(true);
                }
            }
            
        }
        return tx;
    }
    async togglePublic() {
        var tx;
        const walletAddress = await this.signer.getAddress();
        if (walletAddress === this.owner || walletAddress === this.operator) {
            if (this.allowOtherUsers) {
                tx = await this.vaultObj.setAllowOtherUsers(false);
            } else {
                tx = await this.vaultObj.setAllowOtherUsers(true);
            }
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
    async deposit(amt0, amt1) {
        const tx = await this.manager.connect(this.signer).deposit(this.address, [amt0, amt1]);
        await tx.wait(this.confirmations);
        await this.update(true);
        return tx;
    }
    async withdraw(percentage) {
        const tx = await this.manager.connect(this.signer).withdraw(this.address, BN(percentage));
        await tx.wait(this.confirmations);
        await this.update(true);
        return tx;
    }
    // needs work
    async trade(spendTokenAddress, receiveTokenAddress, spendAmt, receiveAmtMin, routerIndex, pathIndex, 
                gasPriceEstimateMultiplier=1.05, gasLimitEstimateMultiplier=1.05) {
        
        console.log( "in vault trade: ", spendTokenAddress, receiveTokenAddress, spendAmt, receiveAmtMin, routerIndex, pathIndex);

        const routerAddress = this.routers[routerIndex].address
        var tx;
        const walletAddress = await this.signer.getAddress();
        
        var autoTraderActive = this.vaultObj.autotradeActive;
        if (walletAddress === this.operator || (autoTraderActive && walletAddress === this.autoTrader)) {
            const params = {'spendToken': spendTokenAddress, 
                            'receiveToken': receiveTokenAddress,
                            'spendAmt': BN(spendAmt),
                            'receiveAmtMin': BN(receiveAmtMin), 
                            'routerAddress': routerAddress,
                            'pathIndex': BN(pathIndex)};

            const connectedContract = this.manager.connect(this.signer);
            const expansion = 1000;

            var gasPrice = await this.provider.getGasPrice();
            const gpm = BN(Math.floor(gasPriceEstimateMultiplier*expansion));
            gasPrice = gasPrice.mul(gpm).div(BN(expansion));


            var gasEstimate = await connectedContract.estimateGas.trade(this.address, params);
            const glm = BN(Math.floor(gasLimitEstimateMultiplier*expansion));
            gasEstimate = gasEstimate.mul(glm).div(BN(expansion));
            
            tx = await connectedContract.trade(this.address, params, {gasLimit: gasEstimate, gasPrice: gasPrice});
            // tx = await connectedContract.trade(this.address, params);
            await tx.wait(this.confirmations);
            await this.update(true);
        }
        return tx;
    }
}

export class PriceFetcher {
    constructor() {
        this.baseURL = getBaseURL()
        if (this.baseURL.includes('localhost')) {
            this.baseURL = this.baseURL+':8000';
        }
        this.tokenAlterations = {'DAI': 'USD', 'USDC': 'USD', 'USDT': 'USD', 'WETH': 'ETH', 'WBTC': 'BTC'} 
        // this.priceHistory = {'times': [],
        //                     'timesEpoch': [], 
        //                     'close': [], 
        //                     'high': [], 
        //                     'low': [], 
        //                     'open': [], 
        //                     'volume': [], 
        //                     'dataInterval':''};  
    }
    async fetchHistory(tokenSecondaryIn, tokenPrimaryIn, startDateIn, endDateIn, dataInterval) {
        const tokenPrimary = this.tokenAlterations[tokenPrimaryIn] ? this.tokenAlterations[tokenPrimaryIn] : tokenPrimaryIn
        const tokenSecondary = this.tokenAlterations[tokenSecondaryIn] ? this.tokenAlterations[tokenSecondaryIn] : tokenSecondaryIn
        var startTime;
        var endTime;
        if (startDateIn instanceof Date){
            startTime = dateToEpoch(startDateIn).toString();
        } else {
            startTime = startDateIn;
        }
        if (endDateIn instanceof Date){
            endTime = dateToEpoch(endDateIn).toString();
        } else {
            endTime = endDateIn;
        }
        // const startDate = startDateIn.toISOString().split('T')[0];
        // const endDate = endDateIn.toISOString().split('T')[0];

        var url = this.baseURL+'/api/fetchhistorytv/'+tokenSecondary+'/'+tokenPrimary+'/'+startTime.toString()+'/'+endTime.toString()+'/'+dataInterval;
        var priceHistory;
        await axios.get(url).then(res => 
            {
                priceHistory = res.data;
                priceHistory.timesEpoch = res.data.times;
                // const localTimes = res.data.times.map(d => utcTimeToLocal(d));
                priceHistory.times = res.data.times.map(d => new Date(d*1000));
                priceHistory.dataInterval = dataInterval;
                // this.priceHistory = priceHistory;
            }
        );
        // this.priceHistory = priceHistory;
        return priceHistory;
    }
    dataPointsMissing(priceHistory, dataInterval) {
        const lastNormalTimeFetched = priceHistory.timesEpoch[priceHistory.times.length-1];
        var timeDelta = timeIntervalSeconds(dataInterval); 

        //check if new datapoint is expected.
        const timeSinceLastFetch = Date.now() / 1000 - lastNormalTimeFetched;
        // console.log('timeSinceLastFetch: ', timeSinceLastFetch, timeDelta);
        return (timeSinceLastFetch / timeDelta) 
    }
    async updatePriceData(priceHistoryIn, 
                            chainlinkInterface,
                            graphTimeframe,
                            tokenSecondary,
                            tokenPrimary) {
        let priceHistoryNew;
        if (priceHistoryIn && priceHistoryIn.dataInterval && priceHistoryIn.dataInterval === graphTimeframe) {
            priceHistoryNew = priceHistoryIn;
        } else {
            priceHistoryNew = await this.fetchHistory(tokenSecondary.symbol, 
                                                    tokenPrimary.symbol, 
                                                    priceHistoryIn.times[0], 
                                                    priceHistoryIn.times[priceHistoryIn.times.length-1], 
                                                    graphTimeframe);
        }
        const lastNormalTimeFetched = priceHistoryNew.timesEpoch[priceHistoryNew.times.length-1];
        var timeDelta = timeIntervalSeconds(graphTimeframe); 

        //check if new datapoint is expected.
        if (this.dataPointsMissing(priceHistoryNew, graphTimeframe) >= 1) {
            // console.log('fetching price history...');
            const startDate = new Date((lastNormalTimeFetched + timeDelta)*1000);
            const endDate = new Date();

            let pricehistoryBrandNew = await this.fetchHistory(tokenSecondary.symbol, 
                                                                tokenPrimary.symbol, 
                                                                startDate, 
                                                                endDate, 
                                                                graphTimeframe);
            priceHistoryNew.close = priceHistoryNew.close.concat(pricehistoryBrandNew.close);
            priceHistoryNew.high = priceHistoryNew.high.concat(pricehistoryBrandNew.high);
            priceHistoryNew.low = priceHistoryNew.low.concat(pricehistoryBrandNew.low);
            priceHistoryNew.open = priceHistoryNew.open.concat(pricehistoryBrandNew.open);
            priceHistoryNew.times = priceHistoryNew.times.concat(pricehistoryBrandNew.times);
            priceHistoryNew.timesEpoch = priceHistoryNew.timesEpoch.concat(pricehistoryBrandNew.timesEpoch);
            priceHistoryNew.volume = priceHistoryNew.volume.concat(pricehistoryBrandNew.volume);

            priceHistoryNew.lastRow = pricehistoryBrandNew.lastRow;
        }

        let currentPrice = await chainlinkInterface.getRelativePrice(tokenSecondary.address, tokenPrimary.address);

        priceHistoryNew.lastRow.time = Date.now() / 1000;
        priceHistoryNew.lastRow.close = currentPrice;
        if (currentPrice > priceHistoryNew.lastRow.high) {
        priceHistoryNew.lastRow.high = currentPrice;
        } else if (currentPrice < priceHistoryNew.lastRow.low) {
        priceHistoryNew.lastRow.low = currentPrice;
        }

        return {priceHistoryNew: priceHistoryNew, currentPrice: currentPrice};
    }

}
export class Trade {
    constructor(vault, quoterIn, tokenFromNum=0, slippage=0.1, primaryTokenNum=1) { 
        this.vault = vault;
        this.quoter = quoterIn;
        this.tokenFromNum = tokenFromNum;
        this.slippage = slippage;
        this.updated = false;
        this.priceImpact = 0;
        this.bestFee = 500;
        this.fullPathList = "";
        this.amtInExpectedFloat = 0.0;
        this.amtInMinFloat = 0.0;
        this.tokenLibrary = new TokenLibrary();
        this.primaryTokenNum = primaryTokenNum;
        this.primaryToken = this.vault.tokens[primaryTokenNum];
        this.amtOut = BN(0);
        this.amtOutFloat = 0.0;
        // this.update(amtOut, slippage)
    }
    async update(amtOut, slippage, simple=false) {
        this.amtOut = BN(amtOut);
        this.slippage = slippage;
        // this.pathIndex = 0;
        
        this.tradable = true;
        this.reversible = true;
        var minTestAmt;

        
        if (this.vault.balance0.eq(BN(0)) && this.vault.balance1.eq(BN(0))) {
            this.tradable = false;
            this.reversible = false;
        } else if (this.vault.balance0.gt(BN(0)) && this.vault.balance1.eq(BN(0))) {
            this.tokenFromNum = 0;
            this.reversible = false;
        } else if (this.vault.balance1.gt(BN(0)) && this.vault.balance0.eq(BN(0))) {
            this.tokenFromNum = 1;
            this.reversible = false;
        } 

        if (this.tokenFromNum === 0) {
            this.tokenFrom = this.vault.token0;
            this.tokenTo = this.vault.token1;
            // minTestAmt = this.vault.token0MinDep.div(100);
            const tknSym = this.tokenFrom.symbol;
            const decimals = this.tokenFrom.decimals;
            const testAmtFloat = this.tokenLibrary.getPriceCheckTestAmt(tknSym);
            minTestAmt = humanToBigNum(testAmtFloat, decimals).div(100);

        } else {
            this.tokenFrom = this.vault.token1;
            this.tokenTo = this.vault.token0;
            // minTestAmt = this.vault.token1MinDep;
            const tknSym = this.tokenFrom.symbol;
            const decimals = this.tokenFrom.decimals;
            const testAmtFloat = this.tokenLibrary.getPriceCheckTestAmt(tknSym);
            minTestAmt = humanToBigNum(testAmtFloat, decimals);
        }

        const paths = this.vault.paths[this.tokenFrom.address][this.tokenTo.address];

        // this.amtOutFloat = bigNumToFloat(this.amtOut, this.tokenFrom.decimals);
        this.amtOutFloat = this.tokenFrom.toFloat(this.amtOut);
        // console.log('amtOutFloat', this.amtOutFloat);
        this.bestPathString = '';
        var quotedIn = BN(0);
        var mostQuoted = BN(0);
        var bestPathIndex = 0;

        var thisPathMiddle = paths[this.bestPathIndex];
        var feesOnPath=[]; 
        var addressesOnPath=[];
        var fullPathList=[];
        var fullPath='';
        var price;
        var testAmtOut;
        if (this.amtOut.gt(BN(0))) {
            testAmtOut = this.amtOut;
        } else {
            testAmtOut = minTestAmt; //we want to fetch price anyway if amtOut is 0
        }

        if (simple) {
            return this;
        }
        console.log('simulate trading...')

        const primaryTokenNumForPriceCheck = this.tokenTo === this.primaryToken ? 1 : 0;
        for (var i=0; i < paths.length; i++) {  
            console.log('I', i);
            thisPathMiddle = paths[i];
            fullPath = '0x' + this.tokenFrom.address.slice(2) + thisPathMiddle.slice(2) + this.tokenTo.address.slice(2);
            [feesOnPath, addressesOnPath] = decomposeTradePath(fullPath);
            fullPathList = createFullPathDisplay(feesOnPath, addressesOnPath, this.tokenLibrary);
            // console.log('fullPath', fullPath);
            // console.log('fullPathList', fullPathList);
            
            // var priceCheck = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, primaryTokenNumForPriceCheck);
            // console.log('priceCheck', priceCheck);
            // if (priceCheck > 1000){
            //     priceCheck = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, this.primaryTokenNum);
            // }
            console.log('')
            quotedIn = await this.quoter.getQuote(fullPath, testAmtOut);
            var quotedInFloat = this.tokenTo.toFloat(quotedIn);
            
            if (this.tokenFromNum === 0) {
                price = quotedInFloat / this.amtOutFloat;
            } else {
                price = this.amtOutFloat / quotedInFloat;
            }
            console.log('QUOTE: ',i , fullPathList, quotedInFloat, price);
            if (quotedIn.gt(mostQuoted)) {
                mostQuoted = quotedIn;
                bestPathIndex = i;
                // bestPrice = price;
            }
        }
        this.bestPathIndex = bestPathIndex;
        if (this.amtOut.gt(BN(0))) {
            this.amtInExpected = mostQuoted;
            this.amtInExpectedFloat = bigNumToFloat(this.amtInExpected, this.tokenTo.decimals);
            this.amtInMinFloat = this.amtInExpectedFloat * (100 - this.slippage)/100;
            this.amtInMin = humanToBigNum(this.amtInMinFloat, this.tokenTo.decimals);
        }  else {
            this.amtInExpected = BN(0);
            this.amtInExpectedFloat = 0.0;
            this.amtInMinFloat = 0.0;
            this.amtInMin = BN(0);
        }

        //get nominal price from pool directly (needs to be this way)
        //need to daisy chain the pools to get the price for multiple hops
        thisPathMiddle = paths[this.bestPathIndex];
        fullPath = '0x' + this.tokenFrom.address.slice(2) + thisPathMiddle.slice(2) + this.tokenTo.address.slice(2);
        [feesOnPath, addressesOnPath] = decomposeTradePath(fullPath);
        fullPathList = createFullPathDisplay(feesOnPath, addressesOnPath, this.tokenLibrary);
        
        console.log('Final FULLPathList', fullPathList, this.amtInExpectedFloat);
        this.fullPathList = fullPathList;

        var temp = 1.0;
        for (i=0; i<feesOnPath.length; i++) {
            temp = temp*(1.0-feesOnPath[i]/(10000*100.0));
        }
        this.bestFee = 100*(1 - temp);
        this.price = await this.vault.router.getPriceFromPath(feesOnPath, addressesOnPath, 
                                                                primaryTokenNumForPriceCheck);
        //"price" = Amt Primary / Amt Secondary, by definition.
        if (this.tokenFromNum === this.primaryTokenNum) {
            this.amtInNominal = this.amtOutFloat / this.price;
        } else {
            this.amtInNominal = this.amtOutFloat * this.price;
        }

        //formula seems to not work in dev mode. Not sure why. Double check in production.
        this.priceImpact = this.amtInNominal > 0 ? 100*((this.amtInNominal*(100 - this.bestFee)/100) - this.amtInExpectedFloat) / this.amtInNominal : 0;
        // this.priceImpact = this.amtInNominal > 0 ? 100*((this.amtInNominal*(100 - 0)/100) - this.amtInExpectedFloat) / this.amtInNominal : 0;
        //compare to current price without price impact
        console.log('FINAL this.price', this.price)
        console.log('this.bestFee', this.bestFee.toFixed(4))
        console.log('this.amtInNominal', this.amtInNominal, this.priceImpact)
        console.log('amtInExpectedFloat', this.amtInExpectedFloat)
        console.log('amtInMinFloat', this.amtInMinFloat)
        console.log('')
        
        this.updated = true;
        return this;
    }

    async execute(amtInMinOverride=null) {
        var amtOut;
        var amtInMin;
        
        amtOut = this.amtOut;
        
        if (amtInMinOverride !== null) {
            amtInMin = amtInMinOverride;
        } else {
            amtInMin = this.amtInMin;
        }
        console.log('trading...')
        const routerIndex = 0; //NEEDS TO BE FIXED FOR MORE THAN ONE ROUTER
        const tx = await this.vault.trade(this.tokenFrom.address, 
                                          this.tokenTo.address, 
                                          amtOut, 
                                          amtInMin, 
                                          routerIndex,
                                          BN(this.bestPathIndex));
        return tx;
    }
}