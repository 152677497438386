import Metamask from "./Metamask";
import arbitrumLogo from '../images/arbitrum-logo.png';
import styled from 'styled-components';


function utcTimeToLocal(utcTime) {
    const temp = new Date(utcTime + 'Z');
    return temp;
}

const MainBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin: 10px;
    ${'' /* border: 1px solid #A2B533;
    border-radius: 5px; */}
    width: 100%;
    max-width: 668px;
`;

const HorzFlex = styled.div`
    height: 60px;
    width: 100%;
    color: #fff;
    ${'' /* background-image: linear-gradient(to bottom, #14183A, #13294B); */}
    ${'' /* background-image: linear-gradient(to bottom right, #14183A, #13294B); */}
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

const VertFlex = styled.div`
    height: 100%;
    width: 100%;
    color: #fff;
    ${'' /* background-image: linear-gradient(to bottom, #14183A, #13294B); */}
    ${'' /* background-image: linear-gradient(to bottom right, #14183A, #13294B); */}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

function Deposit(props) {

    return (
        <MainBody>
            <VertFlex>
                <HorzFlex>
                    <img 
                        src={arbitrumLogo} 
                        alt="Arbitrum Logo" 
                        style={{width: '60px'}}
                    />
                    <h3>
                        Deposit here for Auto-trading.
                    </h3>
                    
                </HorzFlex>
                <Metamask />
            </VertFlex>
        </MainBody>
    );
   
    
}

export default Deposit;