import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';

import Dropdown from 'react-bootstrap/Dropdown';
import {Button as BootButton} from 'react-bootstrap';
// import Multiselect from 'react-bootstrap-multiselect'

import {PlotBody, PlotInputs, DataInputs, LabelInputs, StrategyInputs, VerticalLabelInputs, 
        DepositInputs, PlotWindow, PlotControls} from '../../styles.js';

import {Button, TextField, FormControl, RadioGroup, 
        FormControlLabel, Radio, Switch} from '@mui/material';

import { PlayCircleFilled, RunCircle } from '@mui/icons-material'

import '@fontsource/roboto/300.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {getBaseURL, utcTimeToLocal, getWindowDimensions} from '../../functions/functions';

import { useSearchParams } from "react-router-dom";


const baseURL = getBaseURL();


// import {AutoTrade} from './AutoTrade/index.js';
function comparisonSymbol(s) {
    if (s === 'lt') {
        return '<';
    } else if (s === 'gt') {
        return '>';
    } else {
        return 'bad option';
    }
}

// function localTimeToUTC(localTime) {
//     var localTime = new Date(localTime.toString() + 'Z');
//     localTime = localTime.toUTCString();
//     return localTime;
// }



function StratPlot(props) {
    function getTodaysDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    function addInverses(arr) {
        var newArr = arr;
        const L = arr.length;
        for (var i=0; i<L; i++) {
            let huh = [arr[i][1], arr[i][0]];
            newArr.push(huh);
        }
        return newArr;
    }
    function getURLCommand(obj) {
        var command = '';
        for (const [key, value] of Object.entries(obj)) {
            command += key + '=' + value + '&';
        }
        command = command.slice(0, -1);
        return command;
    }
    
    const [data, setData] = useState({'times': [], 'prices': [],  
                                      'buysIndex': [], 'sellsIndex': [], 
                                      'ema': [], 'portfolioValueTimes': [], 'portfolioValue': [], 
                                      'buysBTCValue': [], 'sellsBTCValue': [], 
                                      'stopLossSells': [], 'stopLossSellsBTCValue': [], 
                                      'rsi': [], 'macd': []});

    // const [optData, setOptData] = useState({});

    const initValidTokens = ['ADA', 'AVAX', 'BCH', 'BTC', 'USD', 'ETH', 'LTC', 'LINK', 'MATIC', 'SOL', 'SUSHI', 'ROSE', 'ETC', 'XRP', 'XLM'];
    const initValidPairs = addInverses([['BTC', 'USD'], 
                            ['ETH', 'USD'], 
                            ['ETH', 'BTC'], 
                            ['LTC', 'USD'], 
                            ['XRP', 'USD'], 
                            ['XLM', 'USD'], 
                            ['ADA', 'USD'], 
                            ['AVAX', 'USD'], 
                            ['BCH', 'USD'], 
                            ['ETC', 'USD'],
                            ['LINK', 'USD'],
                            ['MATIC', 'USD'], 
                            ['SOL', 'USD'], 
                            ['SUSHI', 'USD'], 
                            ['ROSE', 'USD']]);
    const validIntervals = ['1d', '1w'];

    const [validTokens, setValidTokens] = useState(initValidTokens);
    const [validPairs, setValidPairs] = useState(initValidPairs);
    // const [validIntervals, setValidIntervals] = useState(['1d', '1w']);
                                                  
    function getValidSecondTokens(firstToken) {
        var temp = [];
        for (let i=0; i<validPairs.length; i++) {
            if (validPairs[i][0] === firstToken) {
                temp.push(validPairs[i][1]);
            }
        }
        return temp;
    }

    const [searchParams] = useSearchParams();
    console.log('searchParams:', searchParams);

    
    var defaultVals = {'token0': 'ETH', 
                       'token1': 'USD', 
                       'startDate': '2018-01-01', 
                       'endDate': getTodaysDate(),
                       'startingAmt': 1, 
                        'startingAmtUnits': 'ETH', 
                        'dataInterval': '1d',
                        'logBool': false, 
                        'showRSI': false,
                        'showMACD': false,
                        'smaBool': true,
                        'smaValue': 23,
                        'smaRadioValue': 'priceCross',
                        'emaBool': true,
                        'emaValue': 31,
                        'emaRadioValue': 'priceCross',
                        'hmaBool': true,
                        'hmaValue': 62,
                        'hmaRadioValue': 'slope',
                        'rsiBool': false,
                        'rsiValue': 14,
                        'rsiBuyDirection': 'gt',
                        'rsiBuyValue': 52,
                        'rsiSellDirection': 'lt',
                        'rsiSellValue': 48,
                        'macdBool': true,
                        'macdNSlow': 26,
                        'macdNFast': 12,
                        'macdNSignal': 9,
                        'tradingFeePercent': 0.5
                        };
    for (const [key, value] of searchParams.entries()) {
        if (searchParams.get(key) !== undefined) {
            if (key === 'token0') {
                if (validTokens.includes(value)) {
                    defaultVals[key] = value;
                }
            } else if (key === 'token1') {
                const valSeconds = getValidSecondTokens(defaultVals['token0']);
                if (valSeconds.includes(value)) {
                    defaultVals[key] = value;
                } else {
                    defaultVals[key] = valSeconds[0];
                }
            } else {
                if (typeof defaultVals[key] === 'number') {
                    defaultVals[key] = parseFloat(value);
                } else if (typeof defaultVals[key] === 'boolean') {
                    defaultVals[key] = (value === 'true');
                } else {
                    defaultVals[key] = value;
                }
                
            }
        }
    }
    console.log('defaultVals:', defaultVals);

    const [token0, setToken0] = useState(defaultVals['token0']);
    const [token1, setToken1] = useState(defaultVals['token1']);
    const [validSecondTokens, setValidSecondTokens] = useState(getValidSecondTokens(token0));
                            
    const [startDate, setStartDate] = useState(defaultVals['startDate']);
    const [endDate, setEndDate] = useState(defaultVals['endDate']);
    const [startingAmt, setStartingAmt] = useState(defaultVals['startingAmt']);
    const [startingAmtUnits, setStartingAmtUnits] = useState(defaultVals['startingAmtUnits']);
    const [dataInterval, setDataInterval] = useState(defaultVals['dataInterval']);

    const [logBool, setLogBool] = useState(defaultVals['logBool']);
    const [showRSI, setShowRSI] = useState(defaultVals['showRSI']);
    const [showMACD, setShowMACD] = useState(defaultVals['showMACD']);

    const [smaBool, setSmaBool] = useState(defaultVals['smaBool']);
    const [smaValue, setSmaValue] = useState(defaultVals['smaValue']);
    const [smaRadioValue, setSmaRadioValue] = useState(defaultVals['smaRadioValue']);
    const [emaBool, setEmaBool] = useState(defaultVals['emaBool']);
    const [emaValue, setEmaValue] = useState(defaultVals['emaValue']);
    const [emaRadioValue, setEmaRadioValue] = useState(defaultVals['emaRadioValue']);
    const [hmaBool, setHmaBool] = useState(defaultVals['hmaBool']);
    const [hmaValue, setHmaValue] = useState(defaultVals['hmaValue']); 
    const [hmaRadioValue, setHmaRadioValue] = useState(defaultVals['hmaRadioValue']); 

    const [rsiBool, setRsiBool] = useState(defaultVals['rsiBool']);
    const [rsiValue, setRsiValue] = useState(defaultVals['rsiValue']);
    const [rsiBuyDirection, setRsiBuyDirection] = useState(defaultVals['rsiBuyDirection']);
    const [rsiBuyValue, setRsiBuyValue] = useState(defaultVals['rsiBuyValue']);
    const [rsiSellDirection, setRsiSellDirection] = useState(defaultVals['rsiSellDirection']);
    const [rsiSellValue, setRsiSellValue] = useState(defaultVals['rsiSellValue']);

    const [macdBool, setMacdBool] = useState(defaultVals['macdBool']);
    const [macdNSlow, setMacdNSlow] = useState(defaultVals['macdNSlow']);
    const [macdNFast, setMacdNFast] = useState(defaultVals['macdNFast']);
    const [macdNSignal, setMacdNSignal] = useState(defaultVals['macdNSignal']);

    const [stopLossBool, setStopLossBool] = useState(false);
    const [stopLossPercent, setStopLossPercent] = useState(5.0);
    const [tradingFeePercent, setTradingFeePercent] = useState(defaultVals['tradingFeePercent']);

    const [walletText, setWalletText] = useState('');
    const [autoTradeBool, setAutoTradeBool] = useState(false);
    const [autoTradeButtonText, setAutoTradeButtonText] = useState('Start Auto Trading');

    const [optimizeButtonText, setOptimizeButtonText] = useState('Optimize (Coming Soon)');
    const [fetchButtonText, setFetchButtonText] = useState('Backtest Strategy');
    const [currentStrategiesList, setCurrentStrategiesList] = useState([]);

    // function getWindowDimensions() {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return {
    //       width,
    //       height
    //     };
    // }

    function urlForUser() {
        const sep = '&'
        var stratString = '';

        stratString += 'smaBool='+smaBool + sep;
        stratString += 'smaValue='+smaValue+sep;
        stratString += 'smaRadioValue='+smaRadioValue+sep;

        stratString += 'emaBool='+emaBool + sep;
        stratString += 'emaValue='+emaValue+sep;
        stratString += 'emaRadioValue='+emaRadioValue+sep;

        stratString += 'hmaBool='+hmaBool + sep;
        stratString += 'hmaValue='+hmaValue+sep;
        stratString += 'hmaRadioValue='+hmaRadioValue+sep;

        stratString += 'rsiBool='+rsiBool + sep;
        stratString += 'rsiValue='+rsiValue+sep
        stratString += 'rsiBuyDirection='+rsiBuyDirection+sep;
        stratString += 'rsiBuyValue='+rsiBuyValue+sep
        stratString += 'rsiSellDirection=' + rsiSellDirection+sep;
        stratString += 'rsiSellValue='+rsiSellValue+sep;

        // stratString += 'macd'+sep+macdNSlow+sep+macdNFast+sep+macdNSignal+trigSep;
        stratString += 'macdBool='+macdBool + sep;
        stratString += 'macdNSlow='+macdNSlow+sep;
        stratString += 'macdNFast='+macdNFast+sep;
        stratString += 'macdNSignal='+macdNSignal+sep;

        var url = '';
        if (props.url == null){
            url = '/backtest?'
        } else {
            url = props.url + '?'
        }
        
        url += 'token0='+token0+sep;
        url += 'token1='+token1+sep;
        url += 'startDate='+startDate+sep;
        url += 'endDate='+endDate+sep;
        url += 'startingAmt='+startingAmt+sep;
        url += 'startingAmtUnits='+startingAmtUnits+sep;
        url += 'dataInterval='+dataInterval+sep;
        url += 'tradingFeePercent='+tradingFeePercent+sep;
        url += stratString;

        // var url = '/backtest?'+token0 + '/' + token1 + '/' +
        //             startDate + '/' + endDate + '/' + dataInterval +'/' +
        //             startingAmt + '/' + startingAmtUnits + '/' +  stratString
                    // smaBool.toString()+'/'+smaValue.toString() + '/' +
                    // emaBool.toString()+'/'+emaValue.toString() + '/' +
                    // rsiBool.toString()+'/' + rsiValue + '/' + rsiBuyDirection + '/' + rsiBuyValue.toString() + '/' + rsiSellDirection + '/' + rsiSellValue.toString() + '/' +
                    // macdBool.toString()+'/' + macdNSlow.toString() + '/' + macdNFast.toString() + '/' + macdNSignal.toString() + '/' + 
                    // stopLossBool.toString()+'/' + stopLossPercent.toString() + '/' + tradingFeePercent.toString();
        return url;
    }
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
    
    const userURL = urlForUser();
    console.log('user url:', userURL);
    window.history.replaceState(null, "Orbital Strategy", userURL);


    function setOptionsValues(dataDict) {
        setEmaValue(dataDict.emaValue);
        setRsiValue(dataDict.smoothingParam);
        setRsiBuyValue(dataDict.rsiBuyValue);
        setRsiSellValue(dataDict.rsiSellValue);
        setMacdNSlow(dataDict.macdNSlow);
        setMacdNFast(dataDict.macdNFast);
        setMacdNSignal(dataDict.macdNSignal);
        setStopLossPercent(dataDict.stopLossPercent);

        setData(dataDict);
        // fetchData();
    }

    const logType = (logBool) ? 'log' : 'linear' ;
    

    function printDY() {
        // console.log('token0:', token0);
        // console.log('data:', data);
        console.log('logPlot:', logBool);
        console.log('logType:', logType);
        console.log('startingAmtUnits:', startingAmtUnits);
        console.log('macdBool:', macdBool);
        console.log('radioValue:', smaRadioValue);
        // console.log('dataY:', dataY);
        // console.log('dataList:', dataList);
    }

    function handleToken0Select(tokenSelected) {
        console.log('in handleToken0Select:', tokenSelected);
        setToken0(tokenSelected);
        const vst = getValidSecondTokens(tokenSelected);
        setValidSecondTokens(vst);
        setStartingAmtUnits(tokenSelected)
        if (!vst.includes(token1)) {
            setToken1(vst[0]);
        }   
    }

    function handleToken1Select(tokenSelected) {
        console.log('in handleToken1Select:', tokenSelected);
        setToken1(tokenSelected);
        setStartingAmtUnits(token0)
    }

    function handleUnitsSelect(tokenSelected) {
        console.log('in handleUnitsSelect:', tokenSelected);
        setStartingAmtUnits(tokenSelected)
    }

    function urlForAPI() {
        const trigSep = ":";
        const sep = '&'
        var stratString = '';
        if (smaBool) {
            stratString += 'sma'+sep+smaValue+sep+smaRadioValue+trigSep;
        }
        if (emaBool) {
            stratString += 'ema'+sep+emaValue+sep+emaRadioValue+trigSep;
        }
        if (hmaBool) {
            stratString += 'hma'+sep+hmaValue+sep+hmaRadioValue+trigSep;
        }
        if (rsiBool) {
            stratString += 'rsi'+sep+rsiValue+sep+rsiBuyDirection+sep+rsiBuyValue+sep+rsiSellDirection+sep+rsiSellValue+trigSep;
        }
        if (macdBool) {
            stratString += 'macd'+sep+macdNSlow+sep+macdNFast+sep+macdNSignal+trigSep;
        }

        const url = token0 + '/' + token1 + '/' +
                    startDate + '/' + endDate + '/' + dataInterval +'/' +
                    startingAmt + '/' + startingAmtUnits + '/' +  stratString
                    // smaBool.toString()+'/'+smaValue.toString() + '/' +
                    // emaBool.toString()+'/'+emaValue.toString() + '/' +
                    // rsiBool.toString()+'/' + rsiValue + '/' + rsiBuyDirection + '/' + rsiBuyValue.toString() + '/' + rsiSellDirection + '/' + rsiSellValue.toString() + '/' +
                    // macdBool.toString()+'/' + macdNSlow.toString() + '/' + macdNFast.toString() + '/' + macdNSignal.toString() + '/' + 
                    // stopLossBool.toString()+'/' + stopLossPercent.toString() + '/' + tradingFeePercent.toString();
        return url;
    }

    function fetchData() {
        if (!(smaBool || emaBool || hmaBool || rsiBool || macdBool)) {
            setFetchButtonText('Backtest Strategy');
            window.alert('You must select at least one strategy!');
        } else {
            var temp = getBaseURL()
            if (temp.includes('localhost')) {
                temp = temp+':8000';
            }
            const url = temp+'/api/fetch/' + urlForAPI();
            console.log('FETCH url:', url);

            const thisURL = getURLCommand(defaultVals);
            console.log(thisURL);

            setFetchButtonText('Running...');
            axios.get(url).then(res => 
                {
                    setData(res.data);
                    setFetchButtonText('Backtest Strategy');
                });     
        }
    }

    // useEffect(() => {
    //     console.log('fetching...');
    //     fetchData();

    //   } , [])

    function optimize() {
        setOptimizeButtonText('Optimizing...');
        const url = baseURL+':8000/api/optimize/' + urlForAPI();
        axios.get(url).then(res => 
            {
                setOptionsValues(res.data)
                setOptimizeButtonText('Optimize (Coming Soon)');
            });
    }

    function autoTrade() {
        if (autoTradeBool) {
            setAutoTradeButtonText('Stop Auto Trading');
            setAutoTradeBool(false);
        } else {
            setAutoTradeButtonText('Start Auto Trading');
            setAutoTradeBool(true);
        }
        const url = baseURL+':8000/api/autotrade/'+ walletText +'/' + urlForAPI();
        axios.get(url).then(res => 
        {
            
            // setOptionsValues(res.data);
            // setOptimizeButtonText('Optimize (Coming Soon)');
        });
    }

    function fetchStrategies() {
        const url = baseURL+':8000/api/fetchstrategies/';
        axios.get(url).then(res => 
        {       
            setCurrentStrategiesList(res.data);
            // setOptionsValues(res.data)
            // setOptimizeButtonText('Optimize (Coming Soon)');
        });
    }

    function setDataIntervalFunc(intervalString) {
        const numberEquiv = {'1m': 60, '1h': 60*60, '1d': 60*60*24, '1w': 60*60*24*7};
        const multiplier = 1; //numberEquiv[dataInterval] / numberEquiv[intervalString];
        setEmaValue(Math.round(emaValue * multiplier));
        setRsiValue(Math.round(rsiValue * multiplier));
        setMacdNSlow(Math.round(macdNSlow * multiplier));
        setMacdNFast(Math.round(macdNFast * multiplier));
        setMacdNSignal(Math.round(macdNSignal * multiplier));
        setDataInterval(intervalString);
    }
    
    const localTimes = data.times.map(d => utcTimeToLocal(d));
    const trace0 = {x: localTimes, y: data.prices, 
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'#6666ff'}, 
                    name: 'price'};
    const traceSMA = {x: localTimes, y: data.sma,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'yellow'}, 
                    opacity: 0.75,
                    name: 'sma-'+smaValue.toString()};
    const traceEMA = {x: localTimes, y: data.ema,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'green'}, 
                    opacity: 0.75,
                    name: 'ema-'+emaValue.toString()};
    const traceHMA = {x: localTimes, y: data.hma,
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter',
                    mode: 'lines',
                    marker: {color:'red'},
                    opacity: 0.75,
                    name: 'hma-'+hmaValue.toString()};
    const trace2 = {x: data.portfolioValueTimes.map(d => utcTimeToLocal(d)), y: data.portfolioValue, 
                    xaxis:'x1', yaxis:'y1',
                    type: 'scatter', 
                    mode: 'lines', 
                    marker: {color:'orange'}, 
                    name: 'portfolio value'};
    const trace3 = {x: data.buysIndex.map(d => utcTimeToLocal(data.times[d])), 
                    y: data.buysIndex.map(d => data.prices[d]), 
                    xaxis:'x1', yaxis:'y1',
                    mode: 'markers', 
                    marker: {color:'#22ff22', size: 8}, 
                    name: "buys ("+data.buysIndex.length.toString()+')'};
    const trace4 = {x: data.sellsIndex.map(d => utcTimeToLocal(data.times[d])), 
                    y: data.sellsIndex.map(d => data.prices[d]), 
                    xaxis:'x1', yaxis:'y1',
                    mode: 'markers', 
                    marker: {color:'red', size: 8}, 
                    name: 'sells ('+data.sellsIndex.length.toString()+')'};
    // const trace5 = {x: data.stopLossSells.map(d => utcTimeToLocal(d)), y:data.stopLossSellsBTCValue, 
    //                 xaxis:'x1', yaxis:'y1',
    //                 mode: 'markers',
    //                 marker: {color:'#ff00aa', size: 8},
    //                 name: 'stop loss sells ('+data.stopLossSells.length.toString()+')'};

    var numPlotRows = 1;
    const plotData = [trace0]
    plotData.push(trace2);

    if (smaBool) {
        plotData.push(traceSMA);
    }
    if (emaBool) {
        plotData.push(traceEMA);
    }
    if (hmaBool) {
        plotData.push(traceHMA);
    }

    // setShowRSI(showRSI && rsiBool);
    if (showRSI && rsiBool && data.rsi !== undefined) {
        const traceRSI = {x: localTimes, y: data.rsi, 
            xaxis:'x1', yaxis:'y2',
            mode: 'lines', marker: {color:'pink'}, name: 'RSI'};

        const traceRSIThresh0 = {x: [localTimes[0], localTimes[localTimes.length-1]], y: [rsiBuyValue, rsiBuyValue], 
                        xaxis:'x1', yaxis:'y2', 
                        mode:'lines',
                        line: {color:'white', dash: 'dash'},
                        showlegend: false};

        const traceRSIThresh1 = {x: [localTimes[0], localTimes[localTimes.length-1]], y: [rsiSellValue, rsiSellValue],
                        xaxis:'x1', yaxis:'y2',
                        mode:'lines',
                        line: {color:'white', dash: 'dash'},
                        showlegend: false};

        const traceRSIBuys = {x: data.buysIndex.map(x => localTimes[x]), y: data.buysIndex.map(x => data.rsi[x]), 
                        xaxis:'x1', yaxis:'y2',  
                        mode: 'markers', marker: {color:'green'},
                        showlegend: false};

        const traceRSISells = {x: data.sellsIndex.map(x => localTimes[x]), y: data.sellsIndex.map(x => data.rsi[x]),
                        xaxis:'x1', yaxis:'y2',
                        mode: 'markers', marker: {color:'red'}
                        ,showlegend: false};
        numPlotRows += 1;
        plotData.push(traceRSI);
        plotData.push(traceRSIThresh0);
        plotData.push(traceRSIThresh1);
        plotData.push(traceRSIBuys);
        plotData.push(traceRSISells);
    }

    if (showMACD && macdBool) {
        const traceMACD = {x: localTimes, y: data.macd, 
                            xaxis:'x1', yaxis:'y3',
                            mode: 'lines', name: 'MACD'};

        const traceMACDSignal = {x: localTimes, y: data.macdSignal, 
                                xaxis:'x1', yaxis:'y3',
                                mode: 'lines', name: 'signal'
                                ,showlegend: false};
        const traceMACDHist = {x: localTimes, y: data.macdHistogram, 
                                xaxis:'x1', yaxis:'y3',
                                type: 'bar', name: 'Histogram'
                                ,showlegend: false};

        const traceMACDBuys = {x: data.buysIndex.map(x=>localTimes[x]), y: data.buysIndex.map(x => data.macdHistogram[x]), 
                                xaxis:'x1', yaxis:'y3', 
                                mode: 'markers',
                                marker: {color:'green'},
                                showlegend: false};

        const traceMACDSells = {x: data.sellsIndex.map(x=>localTimes[x]), y: data.sellsIndex.map(x => data.macdHistogram[x]),
                                xaxis:'x1', yaxis:'y3',
                                mode: 'markers',
                                marker: {color:'red'},
                                showlegend: false};
        numPlotRows += 1;
        plotData.push(traceMACD);
        plotData.push(traceMACDSignal);
        plotData.push(traceMACDHist);
        plotData.push(traceMACDBuys);
        plotData.push(traceMACDSells);
    }

    plotData.push(trace3);
    plotData.push(trace4);

    // if (stopLossBool) {
    //     plotData.push(trace5);
    // }

    var finalGain;
    var finalGainForAsset;
    var finalGainVsBuyAndHold;
    if (data.portfolioValue.length > 0) {
        finalGain = (data.portfolioValue[data.portfolioValue.length-1]/data.portfolioValue[0]);
        finalGainForAsset = (data.prices[data.prices.length-1]/data.prices[0]);
        finalGainVsBuyAndHold = (finalGain/finalGainForAsset).toFixed(2);
        finalGain = finalGain.toFixed(2);
        finalGainForAsset = finalGainForAsset.toFixed(2);
    }

    var subplotSpace = 0.25;
    var d1;
    var d2;
    var d3;
    if (showRSI===false && showMACD===false) {
        d1 = [0, 1];
        d2 = [0, 0];
        d3 = [0, 0];
    } else if (showRSI===true && showMACD===false) {
        d1 = [subplotSpace + 0.01, 1];
        d2 = [0, subplotSpace - 0.01];
        d3 = [0, 0];
    } else if (showRSI===false && showMACD===true) {
        d1 = [subplotSpace + 0.01, 1];
        d2 = [0, 0];
        d3 = [0, subplotSpace - 0.01];
    } else {
        d1 = [2*subplotSpace + 0.01, 1];
        d2 = [subplotSpace + 0.01, 2*subplotSpace - 0.01];
        d3 = [0, subplotSpace - 0.01];
    }

    const aspectRatio = 1.6;
    const plotWidth = Math.min(windowDimensions.width - 50, 1600);
    const plotHeight = Math.min(Math.max(Math.floor(plotWidth/aspectRatio), 600 + 100*(numPlotRows-1)), 1000);
    // const titleText = token0+'/'+token1+' Strategy Final Gain: '+finalGain + 'X'
    const titleText = data.times.length > 0 ? token0+'/'+token1+'<br>'+"Final Gain: "+finalGain + 'X' + '<br>' + 'VS buy and hold: '+finalGainVsBuyAndHold+'X': '';
    const titleXPos = plotWidth > 500 ? 0.5 : 0.7;
    const titleYPos = 1.0 - 0.01*plotHeight;
    const legendXPos = 0.0;
    const legendYPos = 1.0 + 0.01*plotHeight;
    const plotLayout = {
                        dragmode: 'pan',
                        plot_bgcolor: '#0A1929',
                        paper_bgcolor: '#161616',
                        font: {color: '#FFFFFF'},
                        grid: {rows: numPlotRows, columns: 1},
                        width: plotWidth, height: plotHeight,
                        xaxis: {title: 'Date'},
                        yaxis: {title: 'price ('+token1+')', domain: d1, type: logType, 
                                tickcolor: '#ffffff', tickwidth: 2, gridcolor: '#aaaaaa', gridwidth: 1},
                        yaxis2: {title: 'RSI', domain: d2, tickcolor: '#ffffff', tickwidth: 2},
                        yaxis3: {title: 'MACD', domain: d3, tickcolor: '#ffffff', tickwidth: 2},
                        // autosize: false, width: '600', height: '500', 
                        autosize:true,
                        // margin: {l: 50, r: 10, t: 50, b: 50},
                        // title: token0+'/'+token1+' Strategy Final Gain: '+finalGain + 'X',
                        title: {text: titleText,
                                y: titleYPos, x: titleXPos, xanchor: 'center', yanchor: 'top'},
                        margin: {l: 70, r: 30, b: 70, t: 160},
                        // xaxis: {title: 'Date'},  
                        // yaxis: {title: 'Price ('+token1+')', type: logType}, 
                        // showlegend: true,
                        legend: {x: legendXPos, y: legendYPos, orientation: 'v', font: {size: 11}}
                        };
    const plotConfig = {
        scrollZoom:true,
        responsive:true
    }
    
    return (
        <PlotBody>
            <PlotInputs>
                <TokenInputDiv/>

                <StrategyInputDiv/>

                {/* <AutoTradeDiv/> */}
            </PlotInputs>
            <PlotWindow>
                <PlotControls>
                    <LabelInputs>
                        <label>Log Plot</label>
                        <Switch
                            checked={logBool}
                            onChange={() => {setLogBool(!logBool)}}
                        />
                    </LabelInputs>
                    <LabelInputs>
                        <label>Show RSI</label>
                        <Switch
                            disabled={!rsiBool}
                            checked={showRSI && rsiBool}
                            onChange={() => {setShowRSI(!showRSI)}}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </LabelInputs>
                    <LabelInputs>
                        <label>Show MACD</label>
                        <Switch
                            disabled={!macdBool}
                            checked={showMACD && macdBool}
                            onChange={() => {setShowMACD(!showMACD)}}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </LabelInputs>
                    <Button 
                        className="btn btn-outline-primary mx-2 mb-3"
                        sx={{p:1, m:1}} 
                        variant='contained'
                        // color='primary'
                        size='small'
                        style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                        startIcon={<PlayCircleFilled/>}
                        onClick={fetchData}>
                        {fetchButtonText}
                    </Button>

                    {/* <Button 
                        className="btn btn-outline-primary mx-2 mb-3"
                        sx={{p:1, m:1}}
                        variant='contained' 
                        // color='white'
                        size='small'
                        style={{'borderRadius':'25px', 'fontWeight':'bold', 'fontSize': '0.8em'}}
                        startIcon={<RunCircle/>}
                        disabled={true}
                        // onClick={optimize}>
                        onClick={printDY}>
                        {optimizeButtonText}
                    </Button> */}
                    
                </PlotControls> 
                <Plot
                    data={plotData}
                    layout={plotLayout}
                    config={plotConfig}
                    useResizeHandler={true}
                    // style={{width: '1000px', height: '800px'}}

                    // frames={plotState.frames}
                    // config={plotState.config}
                    // onInitialized={figure => setFigure(figure)}
                    // onUpdate={figure => setFigure(figure)}
                />
            </PlotWindow>            
        </PlotBody>
    );


    function TokenInputDiv() {
        return (
            <DataInputs>
            <h2>Token Inputs</h2>
            <LabelInputs>
                <label>Token0</label>
                <select 
                    onChange={event => handleToken0Select(event.target.value)} 
                    style={{width: '80px'}}
                    disabled={props.fixStrategy} >
                    {validTokens.map(item => <option value={item} selected={item===token0 ? true : false}>{item}</option>)}
                </select>
                
            </LabelInputs>
            
            <LabelInputs>
                <label>Token1</label>
                <select 
                    onChange={event => handleToken1Select(event.target.value)} 
                    style={{width: '80px'}}
                    disabled={props.fixStrategy}
                >
                    {validSecondTokens.map(item => <option value={item} selected={item===token1 ? true : false} >{item}</option>)}
                </select>

                
            </LabelInputs>
            <LabelInputs>
                <label>Start Date</label>
                <input className="mb-2 form-control startDate" type="date" size="10"
                    onBlur={event=>setStartDate(event.target.value)} defaultValue={startDate} /> 
            </LabelInputs>
            <LabelInputs>
                <label>End Date</label>
                <input className="mb-2 form-control endDate" type="date" size="10"
                    onBlur={event=>setEndDate(event.target.value)} defaultValue={endDate} /> 
            </LabelInputs>
            <LabelInputs>
                <label>Data Interval</label>

                <select 
                    onChange={event => setDataIntervalFunc(event.target.value)} 
                    style={{width: '80px'}}
                    disabled={props.fixStrategy}
                >
                    {validIntervals.map(item => <option value={item} selected={item===dataInterval ? true : false}>{item}</option>)}
                    {/* <option value="1d">1 day</option>
                    <option value="1w">1 week</option> */}
                    {/* {validSecondTokens.map(item => <option value={item} selected={item===token1 ? true : false} >{item}</option>)} */}
                </select>

            </LabelInputs>
            <LabelInputs>
                <label>Starting Amt</label>
                <input className="mb-2 form-control startingAmt" size="3" onBlur={event=>setStartingAmt(event.target.value)} defaultValue={startingAmt} />
                <select onChange={event => handleUnitsSelect(event.target.value)} style={{width: '80px'}}>
                    <option value={token0} selected={token0===startingAmtUnits ? true : false}>{token0}</option>
                    <option value={token1} selected={token1===startingAmtUnits ? true : false}>{token1}</option>
                    {/* {validSecondTokens.map(item => <option value={item} selected={item===token1 ? true : false} >{item}</option>)} */}
                </select>
            </LabelInputs>
            <LabelInputs>
                <label>Simulated Trading Fee (%)</label>
                <input className="mb-2 form-control tradingFeePercent" size="3" onBlur={event=>setTradingFeePercent(event.target.value)} defaultValue={tradingFeePercent} />
            </LabelInputs>
        </DataInputs>
        )
    }

    function MADiv({name, toggleBool, setBoolHandler, maValue, setValueHandler, 
                    radioValue, setRadioHandler, link}) {
        return (
            <>
                <LabelInputs>
                    <VerticalLabelInputs>
                        <label>
                            {<a className="LinkDiv"
                                href={link} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: 'white' }}  
                            >  
                                {name}
                            </a>}
                        </label>
                        <Switch
                            disabled={props.fixStrategy}
                            checked={toggleBool}
                            onChange={() => {setBoolHandler(!toggleBool)}}
                        />
                    </VerticalLabelInputs>
                    <TextField
                        variant="outlined"
                        // color='white'
                        size='small'
                        label={name + " Period"}
                        type='number'
                        sx={{width: '9ch', mt: '5px'}}
                        inputProps={{ style: { color: "white" } } }
                        InputLabelProps={{ style: { color: "white" } } }
                        defaultValue={maValue}
                        disabled={props.fixStrategy}
                        // onChange={event => setValueHandler(event.target.value)}
                        onBlur={event=>setValueHandler(event.target.value)}
                    />
                    <FormControl>
                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                        <RadioGroup
                            
                            aria-labelledby="radio-buttons-group-label"
                            value={radioValue}
                            onChange={value=>setRadioHandler(value.target.value)}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel 
                                value="priceCross" 
                                control={<Radio size="small"/>} 
                                label="Price Cross" 
                                disabled={props.fixStrategy}
                            />
                                
                            <FormControlLabel 
                                value="slope" 
                                control={<Radio size="small"/>} 
                                label="Slope" 
                                disabled={props.fixStrategy}
                            />
                                
                        </RadioGroup>
                    </FormControl>
                </LabelInputs>
            </>
        )
    }

    function StrategyInputDiv() {
        return (
            <StrategyInputs>
            <h2>Strategy Backtest Inputs</h2>
            <VerticalLabelInputs>
                <MADiv 
                    name='SMA' 
                    toggleBool={smaBool} 
                    setBoolHandler={setSmaBool} 
                    maValue={smaValue}
                    setValueHandler={setSmaValue}
                    radioValue={smaRadioValue}
                    setRadioHandler={setSmaRadioValue}
                    link='https://www.investopedia.com/terms/s/sma.asp'
                />
                <MADiv 
                    name='EMA' 
                    toggleBool={emaBool} 
                    setBoolHandler={setEmaBool} 
                    maValue={emaValue}
                    setValueHandler={setEmaValue}
                    radioValue={emaRadioValue}
                    setRadioHandler={setEmaRadioValue}
                    link='https://www.investopedia.com/terms/e/ema.asp'
                />
                <MADiv 
                    name='HMA' 
                    toggleBool={hmaBool} 
                    setBoolHandler={setHmaBool} 
                    maValue={hmaValue}
                    setValueHandler={setHmaValue}
                    radioValue={hmaRadioValue}
                    setRadioHandler={setHmaRadioValue}
                    link='https://school.stockcharts.com/doku.php?id=technical_indicators:hull_moving_average'
                />
                
            </VerticalLabelInputs>
            <LabelInputs>
                <VerticalLabelInputs>
                    <label>
                        <a href="https://www.investopedia.com/terms/m/macd.asp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                            MACD
                        </a>
                    </label>
                    {/* <ToggleButton value={macdBool} onToggle={(value) => setMacdBool(!value)} /> */}
                    <Switch
                        checked={macdBool}
                        disabled={props.fixStrategy}
                        onChange={() => {setMacdBool(!macdBool)}}
                    />
                </VerticalLabelInputs>
                
                <TextField
                    variant="outlined"
                    // color='secondary'
                    size='small'
                    label="N Slow"
                    type='number'
                    sx={{width: '9ch', mt: '5px'}}
                    inputProps={{ style: { color: "white" } } }
                    InputLabelProps={{ style: { color: "white" } } }
                    defaultValue={macdNSlow}
                    disabled={props.fixStrategy}
                    onBlur={event=>setMacdNSlow(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    // color='secondary'
                    size='small'
                    label="N Fast"
                    type='number'
                    sx={{width: '9ch', mt: '5px'}}
                    inputProps={{ style: { color: "white" } } }
                    InputLabelProps={{ style: { color: "white" } } }
                    defaultValue={macdNFast}
                    disabled={props.fixStrategy}
                    onBlur={event=>setMacdNFast(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    color='secondary'
                    size='small'
                    label="N Signal"
                    type='number'
                    sx={{width: '9ch', mt: '5px'}}
                    inputProps={{ style: { color: "white" } } }
                    InputLabelProps={{ style: { color: "white" } } }
                    defaultValue={macdNSignal}
                    disabled={props.fixStrategy}
                    onBlur={event=>setMacdNSignal(event.target.value)}
                />

            </LabelInputs>
            <LabelInputs>
                <VerticalLabelInputs>
                    <label color='white'>
                        <a href="https://www.investopedia.com/terms/r/rsi.asp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                            RSI
                        </a>
                    </label>
                    {/* <ToggleButton value={rsiBool} onToggle={(value) => setRsiBools(!value)} /> */}
                    <Switch
                        disabled={props.fixStrategy}
                        checked={rsiBool}
                        onChange={() => {setRsiBool(!rsiBool)}}
                    />
                    <TextField
                        variant="outlined"
                        color='secondary'
                        size='small'
                        label="RSI Period"
                        type='number'
                        sx={{width: '10ch', mt: '15px'}}
                        inputProps={{ style: { color: "white" } } }
                        InputLabelProps={{ style: { color: "white" } } }
                        defaultValue={rsiValue}
                        disabled={props.fixStrategy}
                        onBlur={event=>setRsiValue(event.target.value)}
                    />
                </VerticalLabelInputs>

                <VerticalLabelInputs>
                    <label>Buy If</label>
                    <Dropdown>
                        <BootButton variant="success" id="dropdown-buyif">{comparisonSymbol(rsiBuyDirection)}</BootButton>
                        <Dropdown.Toggle split variant="success" id="dropdown-rsi-buy" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={event=>setRsiBuyDirection('lt')}>{'<'}</Dropdown.Item>
                            <Dropdown.Item onClick={event=>setRsiBuyDirection('gt')}>{'>'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <input className="mb-2 form-control rsiBuyValue" size="3" onBlur={event=>setRsiBuyValue(event.target.value)} defaultValue={rsiBuyValue} /> */}
                    <TextField
                        variant="outlined"
                        color='secondary'
                        size='small'
                        label="Buy Threshold"
                        type='number'
                        sx={{width: '10ch', mt: '10px'}}
                        inputProps={{ style: { color: "white" } } }
                        InputLabelProps={{ style: { color: "white" } } }
                        defaultValue={rsiBuyValue}
                        disabled={props.fixStrategy}
                        onBlur={event=>setRsiBuyValue(event.target.value)}
                    />
                </VerticalLabelInputs>

                <VerticalLabelInputs>
                    <label>Sell If</label>
                    <Dropdown>
                        <BootButton variant="success" id="dropdown-buyif">{comparisonSymbol(rsiSellDirection)}</BootButton>
                        <Dropdown.Toggle split variant="success" id="dropdown-rsi-sell" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={event=>setRsiSellDirection('lt')}>{'<'}</Dropdown.Item>
                            <Dropdown.Item onClick={event=>setRsiSellDirection('gt')}>{'>'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <input className="mb-2 form-control rsiBuyValue" size="3" onBlur={event=>setRsiSellValue(event.target.value)} defaultValue={rsiSellValue} /> */}
                    <TextField
                        variant="outlined"
                        // color='secondary'
                        size='small'
                        label="Sell Threshold"
                        type='number'
                        sx={{width: '10ch', mt: '10px'}}
                        inputProps={{ style: { color: "white" } } }
                        InputLabelProps={{ style: { color: "white" } } }
                        defaultValue={rsiSellValue}
                        disabled={props.fixStrategy}
                        onBlur={event=>setRsiSellValue(event.target.value)}
                    />
                </VerticalLabelInputs>
            </LabelInputs>


        </StrategyInputs>
        )
    }

    function AutoTradeDiv() {
        return (
            <DepositInputs>
            <h2>Auto-Trade</h2>
            <TextField 
                variant="outlined" 
                color='secondary' 
                size='small' label="Wallet Address" 
                type='text' 
                sx={{width: '20', mt: '5px'}} 
                value={walletText} 
                onChange={event=>setWalletText(event.target.value)} />
            <VerticalLabelInputs>
                <Button 
                    className="btn btn-outline-primary mx-2 mb-3"
                    sx={{p:1, m:1}}
                    variant='contained' 
                    color='primary'
                    size='small'
                    style={{'borderRadius':'50px', 'fontWeight':'bold'}}
                    onClick={()=>fetchStrategies()}
                    disabled={true}>
                    Fetch Current Strategies
                </Button>
            </VerticalLabelInputs>
            <VerticalLabelInputs>
                <Button 
                    className="btn btn-outline-primary mx-2 mb-3"
                    sx={{p:1, m:1}}
                    variant='contained' 
                    color='primary'
                    size='small'
                    style={{'borderRadius':'50px', 'fontWeight':'bold'}}
                    onClick={()=>autoTrade()}
                    disabled={true}>
                    {autoTradeButtonText}
                </Button>
            </VerticalLabelInputs>
            <VerticalLabelInputs>
                <Button 
                    className="btn btn-outline-primary mx-2 mb-3"
                    sx={{p:1, m:1}}
                    variant='contained' 
                    color='primary'
                    size='small'
                    style={{'borderRadius':'50px', 'fontWeight':'bold'}}
                    // startIcon={<RunCircle/>}
                    disabled={true}>
                    Create Fund
                </Button>

                <Button 
                    className="btn btn-outline-primary mx-2 mb-3"
                    sx={{p:1, m:1}}
                    variant='contained' 
                    color='primary'
                    size='Big'
                    style={{'borderRadius':'50px', 'fontWeight':'bold'}}
                    // startIcon={<RunCircle/>}
                    disabled={true}>
                    Manage Fund
                </Button>  
            </VerticalLabelInputs>   
        </DepositInputs>
        )
    }
}

export default StratPlot;