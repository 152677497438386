
import React from 'react';

// import TriangleUpSVG from '../../images/triangleUp.svg';
// import TriangleDownSVG from '../../images/triangleDown.svg';


export const TriangleUpMarker = ({ color, size }) => {
    const triangleUpPath = "M10 0 L20 17.32 L0 17.32 Z";
    // const triangleDownPath = "M10 17.32 L20 0 L0 0 Z";
    const path = triangleUpPath;

    return (
        <svg 
            width={size} 
            height={size * 0.866} // For equilateral triangle
            viewBox="0 0 20 17.32" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: color }}
        >
            <path d={path} fill="currentColor"/>
        </svg>
    );
  };


export const TriangleDownMarker = ({ color, size }) => {
    // const triangleUpPath = "M10 0 L20 17.32 L0 17.32 Z";
    const triangleDownPath = "M10 17.32 L20 0 L0 0 Z";
    const path = triangleDownPath;

    return (
        <svg 
            width={size} 
            height={size * 0.866} // For equilateral triangle
            viewBox="0 0 20 17.32" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: color }}
        >
            <path d={path} fill="currentColor"/>
        </svg>
    );
};